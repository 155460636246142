import Message from "./Message";

const HeartBeatInterval = 10000; // 10秒一次HeartBeat
export enum State {
  Closed,
  Connecting,
  Reconnecting,
  Active,
}

export type delActivated = (_Endpoint: Endpoint) => void;
export type delDeActivated = (
  _Endpoint: Endpoint,
  _bFirstDeActivated: boolean
) => void;
export type delOnMessage = (_Endpoint: Endpoint, _Message: Message) => void;
export default class Endpoint {
  address: string = "";
  webSocket!: WebSocket;
  state: State = State.Closed;
  incomingMessage?: Message = undefined;
  reconnectTries: number = 0;
  UserClosed: boolean = false;
  m_HeartBeatTimer: NodeJS.Timer | undefined = undefined;

  constructor(
    address: string,
    _factivated?: delActivated,
    _fdeactivated?: delDeActivated,
    _fonMessage?: delOnMessage
  ) {
    this.address = address;
    this.activated = _factivated;
    this.deactivated = _fdeactivated;
    this.onMessage = _fonMessage;
    this.open();
  }

  // activated event, when the socket is open
  activated?: delActivated = undefined;

  // deactivated event
  deactivated?: delDeActivated = undefined;

  onMessage?: delOnMessage = undefined;

  //var that = this;

  open = () => {
    if (this.webSocket != null) {
      this.webSocket.onopen = null;
      this.webSocket.onclose = null;
      this.webSocket.onmessage = null;
      this.webSocket.close(4002, "ReconnectClose");
    }

    //console.log(this.address);

    this.UserClosed = false;
    this.webSocket = new WebSocket(this.address, ["WSNetMQ"]);
    this.webSocket.binaryType = "arraybuffer";
    this.state = State.Connecting;

    this.webSocket.onopen = this.onopen.bind(this);
    this.webSocket.onclose = this.onclose.bind(this);
    this.webSocket.onmessage = this.onmessage.bind(this);

    this.reconnectTries++;
  };

  close = () => {
    this.UserClosed = true;
    /* --------------------------------------- */
    // Code 正常關閉為 1000
    // 可使用的Code為 3000-4999, 使用4000-4999比較好
    // reason可代 123 byte內的字串, 編碼為UTF-8
    /* --------------------------------------- */
    if (this.webSocket) this.webSocket.close(4001, "UserClose");
  };

  onopen = () => {
    //console.log("[EndPoint] WebSocket opened to " + this.address);
    this.reconnectTries = 0;

    this.state = State.Active;

    if (this.activated != undefined) {
      this.activated(this);
    }

    this.StartHeartBeat();
  };

  onclose = () => {
    //console.log("[EndPoint] WebSocket closed " + this.address);
    var stateBefore = this.state;
    this.state = State.Closed;
    if (this.deactivated) {
      let bFirstDisConnect = stateBefore == State.Active; // 由連線狀態斷線時的第一次
      this.deactivated(this, bFirstDisConnect);
    }

    this.StopHeartBeat();

    if (this.UserClosed) return;

    if (this.reconnectTries <= 0) this.open();
    else
      setTimeout(() => {
        this.open();
      }, 5000);
  };

  onmessage = (ev: any) => {
    if (ev.data instanceof Blob) {
      let processFrameFunc = this.processFrame;
      var fileReader = new FileReader();
      fileReader.onload = function () {
        if (this.result instanceof ArrayBuffer)
          // 不確定這個判斷該不該加
          processFrameFunc(this.result);
      };
      fileReader.readAsArrayBuffer(ev.data);
    } else if (ev.data instanceof ArrayBuffer) {
      this.processFrame(ev.data);
    }
    // Other message type are not supported and will just be dropped
  };

  processFrame = (frame: ArrayBuffer) => {
    var view = new Uint8Array(frame);
    var more = view[0];

    if (this.incomingMessage == undefined) {
      this.incomingMessage = new Message();
    }

    this.incomingMessage.addBuffer(view.subarray(1));

    // last message
    if (more == 0) {
      if (this.onMessage != undefined) {
        this.onMessage(this, this.incomingMessage);
      }

      this.incomingMessage = undefined;
    }
  };

  getIsActive = () => {
    return this.state == State.Active;
  };

  getIsConnecting = () => {
    return this.state == State.Connecting;
  };

  write = (message: Message) => {
    var messageSize = message.getSize();
    for (var j = 0; j < messageSize; j++) {
      var frame = message.getBuffer(j);

      var data = new Uint8Array(frame.length + 1);
      data[0] = j == messageSize - 1 ? 0 : 1; // set the more byte

      data.set(frame, 1);

      this.webSocket.send(data);
    }
    this.ReSetHeartBeat();
  };

  SendHeartBeat = () => {
    if (this.state == State.Active) {
      var data = new Uint8Array(1);
      data[0] = 2;
      this.webSocket.send(data);
    }
  };
  StartHeartBeat = () => {
    this.m_HeartBeatTimer = setInterval(this.SendHeartBeat, HeartBeatInterval);
  };
  ReSetHeartBeat = () => {
    if (this.StopHeartBeat()) this.StartHeartBeat();
  };
  StopHeartBeat = () => {
    if (this.m_HeartBeatTimer) {
      clearInterval(this.m_HeartBeatTimer);
      this.m_HeartBeatTimer = undefined;
      return true;
    }
    return false;
  };
}
