import { TradeTimeState } from "./kcModel_Enum";

export default class kcTradeTimeModel {
  constructor(props: object) {
    Object.assign(this, { ...props });
  }

  /* ---------------------------Member--------------------------- */
  State: TradeTimeState = TradeTimeState.UnDef;
  MinuteOffset: number = 0;

  ToString() {
    let szRet = "";
    szRet += TradeTimeState[this.State] + ", ";
    szRet += this.MinuteOffset + ", ";
    return szRet;
  }

  /* ---------------------------static Function--------------------------- */
}
