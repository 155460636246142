import moment, { Moment } from "moment";
import { MomentExtensions } from "../kcExternal";

const _TradeType = [
  "未定義",
  "出入金",
  "分潤",
  "委託",
  "委託失敗",
  "委託取消",
  "委託已成交",
  "成交",
  "成交已平倉",
  "平倉",
  "多空相互平倉",
  "改單",
  "管理員增單",
] as const;
export type TradeType = typeof _TradeType[number];

const _BuySellType = [
  "",
  "Non",
  "Buy",
  "Sell",
  "BuyLimit",
  "BuyStop",
  "SellLimit",
  "SellStop",
] as const;
export type BuySellType = typeof _BuySellType[number];

export default class kcHistoryTrade {
  constructor(props?: object) {
    if (props) Object.assign(this, { ...props });

    this.RefTime = MomentExtensions.Parse_Generalized(this.RefTime);
    this.Time = MomentExtensions.Parse(this.Time);
    this.DeadlineTime = MomentExtensions.Parse_Generalized(this.DeadlineTime);
    this.CommissionTime = MomentExtensions.Parse_Generalized(
      this.CommissionTime
    );
    this.IBAllocationTime = MomentExtensions.Parse_Generalized(
      this.IBAllocationTime
    );
  }

  TradeIdx: number = -1;
  RefTradeIdx: number = -1;
  RefTime: Moment | undefined = undefined; // 關聯單的日期
  //   AccIdx: number = -1;
  Account: string = "";
  Type: TradeType = "未定義";
  BuySellType: BuySellType = "Non";
  StockCode: string = "";
  Time: Moment = moment(0);

  Price: number = 0;

  StopProfitPrice: number = -1; // 停利
  StopLossPrice: number = -1; // 停損
  Vol: number = 0; // 成交量
  HVol: number = 0; // 成交量(手)

  // 掛單交易
  DeadlineTime: Moment | undefined = undefined;

  // 出入金
  USDRate: number = 1; // 原始貨幣兌美金比率 => 原始獲利 = Money / USDRate
  PriceDiff: number = 0; // 平倉價差
  Money: number = 0; // 正 入金 負出金 單位:美金

  // 金額相關
  PrepaidMoney: number = 0; // 預付款
  SwapMoney: number = 0; // 庫存費
  CommissionMoney: number = 0; // 傭金(分潤)
  CommissionTime: Moment | undefined = undefined; // 傭金(分潤)時間, null代表尚未分配

  //   IBAllocation:kcIBAllocationModel[] = []; // IB分配 Length固定4 [自己,上1,上2,上3]
  IBAllocationTime: Moment | undefined = undefined; // IB分配時間

  // 其他
  //   Device: string = ""; // 呼叫的設備
  //   RefDevice: string = ""; // 關聯單的呼叫的設備
  Ex: string = "";
  //   RO: string = ""; // RealOrder F:FakeOrder R:RealOrder

  Clone(): kcHistoryTrade {
    return new kcHistoryTrade(this);
  }

  /* ------------------------------------------------ */
  // static function
  static isBuySellType(_BuySellType: any) {
    return _BuySellType.includes(_BuySellType);
  }
  static Validate_BuySellType(_BuySellType: string): boolean {
    if (!kcHistoryTrade.isBuySellType(_BuySellType)) return false;

    let Value = _BuySellType as BuySellType;
    if (Value === "" || Value === "Non") return false;
    return true;
  }
}
