import * as React from "react";
import {
  StyleProp,
  StyleSheet,
  TextStyle,
  ViewStyle,
  TextInput,
  Modal,
  ActivityIndicator,
} from "react-native";
import { Text, View } from "../components/Themed";
import { Button } from "react-native-elements";
import { Ionicons } from "@expo/vector-icons";
import { kcSetState, kcSetUnmount } from "../kcExternal";
import { kcData } from "../kcData";
import kcHistoryTrade, { BuySellType } from "../kcModel/kcHistoryTrade";
import { ObjectExtensions } from "../kcExternal";
import { GotoTradeScreen } from "../navigation/RootNavigation";
import RowValue from "../components/kcRowValue";
import { kcCommodityModel } from "../kcModel";

type IState = {
  Visable: boolean;
  loading: boolean;
};

type IProp = {
  //navigation: any;
};

export enum OrderModalType {
  New,
  Update,
  Balance,
  Delete,
}

export default class KC_OrderModal extends React.Component<IProp, IState> {
  state: IState = {
    Visable: false,
    loading: false,
  };

  szTitle: string = "";
  szDetail: string = "";
  bSuccess: boolean = false;
  mdTrade?: kcHistoryTrade = undefined;
  OrderType?: OrderModalType = undefined;
  mdCommodity?: kcCommodityModel = undefined;

  private m_bWaitingOrderCallback: boolean = false;

  constructor(props: IProp) {
    super(props);
    kcData.TradeEvent_Add(this.OnTradeResault);
  }

  componentDidMount() {
    //console.log(this.props.navigation);
  }

  shouldComponentUpdate(nextProps: IProp, nextState: IState) {
    if (!ObjectExtensions.ObjectsEqual(nextState, this.state)) return true;
    if (!ObjectExtensions.ObjectsEqual(nextProps, this.props)) return true;
    return false;
  }
  componentWillUnmount() {
    kcSetUnmount(this, true);
  }

  Order = (_mdTrade: kcHistoryTrade, _Type: OrderModalType) => {
    if (this.m_bWaitingOrderCallback) return;

    let fTrade: (() => void) | undefined = undefined;
    switch (_Type) {
      case OrderModalType.New:
        fTrade = () => {
          kcData.Trade_NewOrder(
            _mdTrade.StockCode,
            _mdTrade.BuySellType,
            _mdTrade.Vol,
            _mdTrade.Price,
            _mdTrade.StopProfitPrice,
            _mdTrade.StopLossPrice,
            _mdTrade.DeadlineTime
          );
        };
        break;
      case OrderModalType.Update:
        fTrade = () => {
          kcData.Trade(_mdTrade);
        };
        break;
      case OrderModalType.Balance:
        fTrade = () => {
          kcData.Trade_Balance(_mdTrade);
        };
        break;
      case OrderModalType.Delete:
        fTrade = () => {
          kcData.Trade_Delete(_mdTrade);
        };
        break;
    }

    if (fTrade === undefined) return;
    this.m_bWaitingOrderCallback = true;
    this.OrderType = _Type;
    kcSetState(this, { loading: true, Visable: true }, () => {
      setTimeout(fTrade, 500);
    });
  };

  OnTradeResault = (
    _bSucceed: boolean,
    _szMsg: string,
    _HTrade: kcHistoryTrade
  ) => {
    this.mdTrade = _HTrade;
    this.mdCommodity = kcData.GetCommodity(this.mdTrade.StockCode);
    this.bSuccess = _bSucceed;

    if (this.mdTrade.HVol == 0 && this.mdTrade.Vol != 0) {
      if (this.mdCommodity) {
        this.mdTrade.HVol = this.mdTrade.Vol / this.mdCommodity.ContractSize;
      }
    }

    let Title = "";
    switch (this.OrderType) {
      case OrderModalType.New:
        Title = "下單";
        break;
      case OrderModalType.Update:
        Title = "改單";
        break;
      case OrderModalType.Balance:
        Title = "平倉";
        break;
      case OrderModalType.Delete:
        Title = "刪單";
        break;
    }

    if (_bSucceed) {
      this.szTitle = Title + "成功";
      this.szDetail = _szMsg;
    } else {
      this.szTitle = Title + "失敗";
      this.szDetail = _szMsg;
    }
    //console.log(_HTrade);

    this.m_bWaitingOrderCallback = false;
    kcSetState(this, { loading: false });
  };

  render() {
    if (this.state.Visable == false) return <></>;
    let FloatNum = 2;
    if (this.mdCommodity) FloatNum = this.mdCommodity.FloatNum;

    let PriceValue = "";
    if (this.mdTrade && this.OrderType != OrderModalType.Delete) {
      if (
        this.mdTrade.BuySellType == "Buy" ||
        this.mdTrade.BuySellType == "Sell"
      )
        PriceValue = "市價";
      else PriceValue = this.mdTrade.Price.toFixed(FloatNum);
    }

    return (
      <Modal animationType="fade" transparent={true} visible={true}>
        <View
          style={{
            flex: 1,
            backgroundColor: "#000000BB",
            justifyContent: "center", // 上下位置
            alignItems: "center", // 左右位置
          }}
        >
          {this.state.loading && <ActivityIndicator size="large" />}
          {!this.state.loading && (
            <View
              style={{
                flexDirection: "column",
                margin: 0,
                alignItems: "center",
                backgroundColor: "white",
                borderRadius: 10,
                width: 300,
              }}
            >
              <Text
                selectable={false}
                style={{
                  marginTop: 10,
                  fontSize: 20,
                  color: "#000000",
                }}
              >
                {this.szTitle}
              </Text>
              <Text
                selectable={false}
                style={{
                  fontSize: 16,
                  color: "#000000",
                  textAlign: "left",
                  marginTop: 10,
                  marginLeft: 30,
                  marginRight: 30,
                }}
              >
                {this.szDetail}
              </Text>

              <View
                style={{
                  flexDirection: "column",
                  width: 260,
                  backgroundColor: "#ffffff",
                }}
              >
                {/* <RowValue
                  Title="單號"
                  Value={"#" + this.mdTrade?.TradeIdx}
                  style={{ marginTop: 10 }}
                  TitleStyles={styles.RowTitleStyles}
                  ValueStyles={styles.RowValueStyles}
                ></RowValue> */}
                <RowValue
                  Title="商品"
                  Value={this.mdTrade?.StockCode}
                  style={{ marginTop: 10 }}
                  TitleStyles={styles.RowTitleStyles}
                  ValueStyles={styles.RowValueStyles}
                ></RowValue>
                <RowValue
                  Title="類型"
                  Value={this.mdTrade?.BuySellType}
                  style={{ marginTop: 10 }}
                  TitleStyles={styles.RowTitleStyles}
                  ValueStyles={styles.RowValueStyles}
                ></RowValue>
                <RowValue
                  Title="手數"
                  Value={this.mdTrade?.HVol.toFixed(2)}
                  style={{ marginTop: 10 }}
                  TitleStyles={styles.RowTitleStyles}
                  ValueStyles={styles.RowValueStyles}
                ></RowValue>
                {PriceValue.length > 0 && (
                  <RowValue
                    Title="價格"
                    Value={PriceValue}
                    style={{ marginTop: 10 }}
                    TitleStyles={styles.RowTitleStyles}
                    ValueStyles={styles.RowValueStyles}
                  ></RowValue>
                )}
                {this.mdTrade &&
                  this.mdTrade.StopLossPrice &&
                  this.mdTrade.StopLossPrice != -1 && (
                    <RowValue
                      Title="停損價"
                      Value={this.mdTrade?.StopLossPrice.toFixed(FloatNum)}
                      style={{ marginTop: 10 }}
                      TitleStyles={styles.RowTitleStyles}
                      ValueStyles={styles.RowValueStyles}
                    ></RowValue>
                  )}
                {this.mdTrade &&
                  this.mdTrade.StopProfitPrice &&
                  this.mdTrade.StopProfitPrice != -1 && (
                    <RowValue
                      Title="停利價"
                      Value={this.mdTrade?.StopProfitPrice.toFixed(FloatNum)}
                      style={{ marginTop: 10 }}
                      TitleStyles={styles.RowTitleStyles}
                      ValueStyles={styles.RowValueStyles}
                    ></RowValue>
                  )}
                {this.mdTrade && this.mdTrade.DeadlineTime && (
                  <RowValue
                    Title="期限"
                    Value={this.mdTrade?.DeadlineTime.format(
                      "YYYY/MM/DD HH:mm"
                    )}
                    style={{ marginTop: 10 }}
                    TitleStyles={styles.RowTitleStyles}
                    ValueStyles={styles.RowValueStyles}
                  ></RowValue>
                )}
              </View>

              <View
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  flexDirection: "row",
                  backgroundColor: "#00000000",
                  //alignSelf: "stretch",
                  width: 260,
                }}
              >
                <Button
                  containerStyle={{ flex: 1 }}
                  buttonStyle={{
                    backgroundColor: "#4480EE",
                    height: 30,
                    marginLeft: 10,
                    marginRight: 10,
                    borderRadius: 10,
                  }}
                  titleStyle={{ fontSize: 14 }}
                  title="查看庫存"
                  onPress={() => {
                    kcSetState(this, { Visable: false });
                    GotoTradeScreen();
                  }}
                />

                {(this.OrderType == OrderModalType.New ||
                  this.OrderType == OrderModalType.Update) && (
                  <Button
                    containerStyle={{ flex: 1 }}
                    buttonStyle={{
                      backgroundColor: "#4480EE",
                      height: 30,
                      marginLeft: 10,
                      marginRight: 10,
                      borderRadius: 10,
                    }}
                    titleStyle={{ fontSize: 14 }}
                    title="確定"
                    onPress={() => {
                      kcSetState(this, { Visable: false });
                    }}
                  />
                )}
              </View>
            </View>
          )}
        </View>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  NumberBtnContainer: {
    //flex: 1,
    backgroundColor: "#00000000",
    justifyContent: "center",
  },
  NumberBtn: {
    //backgroundColor: "#505050",
    backgroundColor: "#00000000",
    height: 20,
    borderRadius: 0,
    borderWidth: 0,
    padding: 0,
    width: 50,
  },
  RowTitleStyles: {
    color: "#888888",
    fontSize: 16,
  },
  RowValueStyles: {
    color: "#000000",
    fontSize: 16,
    textAlign: "right",
  },
});
