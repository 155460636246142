export enum KLineDispalyType {
  NormalKLine = 0, // 一般K線
  //kcKLine = 1,
  USKLine = 1, // 美國線
  CloseKLine = 2, // 折線(收盤線)
}

export enum eMaType {
  SMa = 0,
  EMa = 1,
  WMa = 2,
}

export const KLineDispalyTypeToString = (
  _KLineDispalyType: KLineDispalyType
) => {
  switch (_KLineDispalyType) {
    case KLineDispalyType.NormalKLine:
      return "蠟燭圖";
    case KLineDispalyType.USKLine:
      return "美國線";
    case KLineDispalyType.CloseKLine:
      return "折線";
    default:
      break;
  }
  return "未定義";
};
