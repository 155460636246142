const Encoding = require("text-encoding"); // npm install text-encoding --save
const UTF8Encoder = new Encoding.TextEncoder();
const UTF8Decoder = new Encoding.TextDecoder();

export default class StringConverter {
  static UTF8_GetBytes(_sz: string): Uint8Array {
    return UTF8Encoder.encode(_sz);
  }
  static UTF8_GetString(_Buffer: Uint8Array): string {
    return UTF8Decoder.decode(_Buffer);
  }
}
