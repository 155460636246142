import {
  ArrayExtensions,
  CompressionExtensions,
  StorageHelper,
} from "../kcExternal";
import { Buffer } from "buffer";

const StorageKey: string = "RCL"; // Storage的key, 最終格式為 "RCL{帳號}"
const SecretData: boolean = true; // 加密Storage存檔的值(目前只有壓縮)

type ReadStorageResault = {
  KeyOK: boolean;
  ReadOK: boolean;
  Resault: string[];
};

declare type RegListInfo = {
  CurrentAccount: string;
  RegList: string[];
};

export type RegListUpdateParam = {
  CurrentAccount: string;
  RegList: string[];
  Added: string[];
  Removed: string[];
};
declare type delRegListUpdate = (_Param: RegListUpdateParam) => void;

declare type AccountChangeCallBack = (_Param: {
  Resault: boolean;
  Changed: boolean;
  CurrentAccount: string;
  RegList: string[];
}) => void;

export default class RegListHelper {
  private static DefaultRegList: string[] = ["XAUUSD"];

  private static Account: string;
  private static RegList: string[] = [];

  // 變更現在的Account
  public static ChangeAccount(
    _szAccount: string,
    _CahngeCallBack?: AccountChangeCallBack
  ) {
    if (typeof _szAccount !== "string") {
      let SendList = RegListHelper.GetRegList();
      _CahngeCallBack?.call(this, {
        Resault: false,
        Changed: false,
        CurrentAccount: RegListHelper.Account,
        RegList: SendList,
      });
      return;
    }

    if (_szAccount === RegListHelper.Account) {
      let SendList = RegListHelper.GetRegList();
      _CahngeCallBack?.call(this, {
        Resault: true,
        Changed: false,
        CurrentAccount: RegListHelper.Account,
        RegList: SendList,
      });
      RegListHelper._SendTradeEvent(SendList, [], []);
      return;
    }

    RegListHelper._ReadStorage(_szAccount)
      .then((_Resault) => {
        if (_Resault.KeyOK) {
          let ReadedSet = new Set(_Resault.Resault);
          for (let szDefault of RegListHelper.DefaultRegList) {
            if (!ReadedSet.has(szDefault)) _Resault.Resault.push(szDefault);
          }

          // 更新 Account和RegList
          RegListHelper.Account = _szAccount;
          RegListHelper.RegList.length = 0;
          RegListHelper.RegList.push(..._Resault.Resault);
        }

        let SendList = RegListHelper.GetRegList();
        _CahngeCallBack?.call(this, {
          Resault: true,
          Changed: true,
          CurrentAccount: RegListHelper.Account,
          RegList: SendList,
        });

        RegListHelper._SendTradeEvent(SendList, [], []);
      })
      .catch((_reasson) => {});
  }
  // 現在的Account在使用的註冊列表
  public static GetRegList(): string[] {
    return [...RegListHelper.RegList];
  }
  // 現在的Account與在使用的註冊列表
  public static GetRegListInfo(): RegListInfo {
    return {
      CurrentAccount: RegListHelper.Account,
      RegList: RegListHelper.GetRegList(),
    };
  }
  // 更新整個註冊列表
  public static UpdateRegList(_Items: string[]) {
    let aItmes: string[] = [...new Set(_Items)]; // 用Set過濾掉重複項目
    if (RegListHelper._IsEqualList(RegListHelper.RegList, aItmes)) return;

    RegListHelper._UpdateRegListCore(aItmes, true);
  }
  // 註冊列表 加入
  public static AppendRegListItem(
    _Items: string | string[],
    _bSendEvent = true
  ): boolean {
    if (!_Items) return false;

    let bChanged: boolean = false;
    let CurrentSet = new Set(RegListHelper.RegList);
    if (typeof _Items === "string") {
      bChanged = RegListHelper.TryAndAdd(CurrentSet, _Items) || bChanged;
    } else if (Array.isArray(_Items)) {
      for (let Item of _Items) {
        if (typeof Item === "string")
          bChanged = RegListHelper.TryAndAdd(CurrentSet, Item) || bChanged;
      }
    }

    if (!bChanged) return false;

    RegListHelper._UpdateRegListCore([...CurrentSet.values()], _bSendEvent);
    return true;
  }
  public static RemoveRegListItem(
    _Items: string | string[],
    _bSendEvent = true
  ): boolean {
    if (!_Items) return false;

    let bChanged: boolean = false;
    let CurrentSet = new Set(RegListHelper.RegList);
    if (typeof _Items === "string") {
      bChanged = RegListHelper.TryAndRemove(CurrentSet, _Items) || bChanged;
    } else if (Array.isArray(_Items)) {
      for (let Item of _Items) {
        if (typeof Item === "string")
          bChanged = RegListHelper.TryAndRemove(CurrentSet, Item) || bChanged;
      }
    }

    if (!bChanged) return false;

    RegListHelper._UpdateRegListCore([...CurrentSet.values()], _bSendEvent);
    return true;
  }
  public static ContainsItem(_Item: string): boolean {
    return this.RegList.findIndex((_Reged) => _Reged == _Item) >= 0;
  }

  /* --------------------------------Trade Event----------------------------------------- */

  private static OnRegListUpdate_Event: delRegListUpdate[] = [];
  public static RegListUpdateEvent_Add = (
    RegListUpdateCallback: delRegListUpdate
  ) => {
    if (RegListUpdateCallback)
      RegListHelper.OnRegListUpdate_Event.push(RegListUpdateCallback);
  };
  public static RegListUpdateEvent_Remove = (
    RegListUpdateCallback: delRegListUpdate
  ) => {
    if (RegListUpdateCallback) {
      let nIdx = RegListHelper.OnRegListUpdate_Event.indexOf(
        RegListUpdateCallback
      );
      if (nIdx >= 0) RegListHelper.OnRegListUpdate_Event.splice(nIdx, 1);
    }
  };
  private static _SendTradeEvent = (
    _RegList: string[],
    _Added: string[],
    _Removed: string[]
  ): void => {
    for (let i = 0; i < RegListHelper.OnRegListUpdate_Event.length; i++) {
      RegListHelper.OnRegListUpdate_Event[i]({
        CurrentAccount: RegListHelper.Account,
        RegList: _RegList,
        Added: _Added,
        Removed: _Removed,
      });
    }
  };

  /* ----------------------------核心function-------------------------------- */
  // Update
  private static _UpdateRegListCore(_Items: string[], _bSendEvent: boolean) {
    // 防呆在外面判掉
    // let aItmes: string[] = [...new Set(_Items)]; // 用Set過濾掉重複項目
    // if (RegListHelper.IsEqualList(RegListHelper.RegList, aItmes)) return;

    let aAdded = ArrayExtensions.Difference(_Items, RegListHelper.RegList);
    let aRemoved = ArrayExtensions.Difference(RegListHelper.RegList, _Items);
    RegListHelper.RegList.length = 0;
    RegListHelper.RegList.push(..._Items);

    // 存檔
    RegListHelper._SaveStorage(RegListHelper.Account, _Items);

    // 發送Event
    if (_bSendEvent)
      RegListHelper._SendTradeEvent(
        RegListHelper.GetRegList(),
        aAdded,
        aRemoved
      );
  }
  // 判斷兩個Array是否相等
  private static _IsEqualList(a: string[], b: string[]): boolean {
    let aIsArray = Array.isArray(a);
    let bIsArray = Array.isArray(b);

    if (!aIsArray && !bIsArray) return true;
    if (!aIsArray || !bIsArray) return false;

    if (a.length != b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (typeof a[i] !== typeof b[i] || a[i] !== b[i]) return false;
    }
    return true;
  }
  // 用Account轉成Storage用的Key
  private static _ToAccountStorageKey(_szAccount: string): string {
    if (!_szAccount) return "";
    return StorageKey + _szAccount;
  }
  // Storage 讀取
  private static async _ReadStorage(
    _szAccount: string
  ): Promise<ReadStorageResault> {
    let Resault: ReadStorageResault = {
      KeyOK: false,
      ReadOK: false,
      Resault: [],
    };

    let szKey = RegListHelper._ToAccountStorageKey(_szAccount);
    if (!szKey) return Resault;
    Resault.KeyOK = true;

    let AllKeys = await StorageHelper.GetAllKeys();
    if (AllKeys.indexOf(szKey) < 0) return Resault;
    Resault.ReadOK = true;

    try {
      let Base64String = await StorageHelper.ReadData(szKey); // 讀取 Base64編碼資料
      if (Base64String) {
        let aRegList: string[];
        if (SecretData) {
          /* ------------------------------------------- */
          // 壓縮版本
          let ZipBuffer = Buffer.from(Base64String, "base64"); // Base64編碼 => 壓縮Buffer
          let szJson = CompressionExtensions.Zlib_UnZip_toString(ZipBuffer); // 壓縮Buffer => JSON string
          aRegList = JSON.parse(szJson); // JSON string => RegList
        } else {
          /* ------------------------------------------- */
          // 純文字版本
          aRegList = JSON.parse(Base64String);
        }

        Resault.Resault = aRegList;
      }
    } catch (e) {}
    return Resault;
  }
  // Storage 存檔
  private static async _SaveStorage(_szAccount: string, _RegList: string[]) {
    let szKey = RegListHelper._ToAccountStorageKey(_szAccount);
    if (!szKey) return;

    let aData: string[] = [];
    if (_RegList) aData.push(..._RegList);

    // if (!aData) {
    //   // 沒資料的話 清掉Storage
    //   StorageHelper.RemoveValue(szKey);
    //   return;
    // }

    let Base64String = "";

    if (SecretData) {
      /* ------------------------------------------- */
      // 壓縮版本
      let szJson = JSON.stringify(aData); // RegList => JSON string
      let ZipBuffer = Buffer.from(
        CompressionExtensions.Zlib_Zip_byString(szJson)
      ); // JSON string => 壓縮Buffer
      Base64String = ZipBuffer.toString("base64"); // 壓縮Buffer => Base64編碼
    } else {
      /* ------------------------------------------- */
      // 純文字版本
      Base64String = JSON.stringify(aData);
    }

    return StorageHelper.SaveData(szKey, Base64String);
  }
  // 沒有個話才加入, 回傳是否有加入
  private static TryAndAdd<T>(_Set: Set<T>, _Item: T): boolean {
    if (_Set.has(_Item)) return false;
    _Set.add(_Item);
    return true;
  }
  // 沒有個話才加入, 回傳是否有加入
  private static TryAndRemove<T>(_Set: Set<T>, _Item: T): boolean {
    if (!_Set.has(_Item)) return false;
    _Set.delete(_Item);
    return true;
  }
}
