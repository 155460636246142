import moment, { Moment } from "moment";
import { ArrayExtensions, MomentExtensions } from "../kcExternal";
import {
  kcAccountInformationModel,
  kcAccountModel,
  kcCommodityModel,
  kcHistoryTrade,
  kcInventoryModel,
} from "../kcModel";
import kcHistoryTradeLDFCollection from "./kcHistoryTradeLDFCollection";
import kcHistoryTradeLDFModel from "./kcHistoryTradeLDFModel";
import kcInventoryLDFModel from "./kcInventoryLDFModel";
import kcUnitModel from "./kcUnitModel";

export enum AccountLDFUpdateType {
  Info,
  Inventory,
  Quote,
  HistoryTrade,
  HistoryTradeUpdate,
}

export type delGetQuote = (_szStockCode: string) => kcUnitModel | undefined;
export type delGetToUSD = (_szStockCode: string) => number | undefined;
export type delGetCommodity = (
  _szStockCode: string
) => kcCommodityModel | undefined;
export type OnAccountLDFUpdate_Event = (
  _mdSender: kcAccountLDFModel,
  _szAccount: string,
  _Type: AccountLDFUpdateType,
  _Param1: any,
  _Param2: any
) => void;

export type OnInventoryUpdate_Event = (
  _mdSender: kcAccountLDFModel,
  _szAccount: string,
  _Type: AccountLDFUpdateType.Inventory | AccountLDFUpdateType.Quote,
  _StockCode: string,
  _Inventory: kcInventoryLDFModel
) => void;

export default class kcAccountLDFModel {
  public static Current: kcAccountLDFModel | undefined = undefined;

  constructor(
    _mdAccount: kcAccountModel,
    _fGet: delGetQuote,
    _fGetToUSD: delGetToUSD,
    _fGetCommodity: delGetCommodity
  ) {
    this.FuncGetQuote = _fGet;
    this.FuncGetToUSD = _fGetToUSD;
    this.FuncGetCommodity = _fGetCommodity;

    this.m_mdAccount = _mdAccount;
    this.m_mdInformation = _mdAccount.Information;

    let tUtcNow = MomentExtensions.UtcNow();
    let t: Moment = moment([tUtcNow.year(), tUtcNow.month(), 1]).utc();
    this.m_HistoryCollection_All = new kcHistoryTradeLDFCollection("All");
    this.m_HistoryCollection_ThisMonth = new kcHistoryTradeLDFCollection(
      t.format("yyyy-MM"),
      t,
      moment(t).add(1, "M")
    );

    this.ReSetAccount(_mdAccount);

    kcAccountLDFModel.Current = this;
  }

  public ReSetAccount(_mdAccount: kcAccountModel) {
    this.m_mdAccount = _mdAccount;
    this.m_mdInformation = _mdAccount.Information;

    this.UpdateInventory(_mdAccount.Inventory, false);
    this._InitHistoryTrade(_mdAccount.Inventory);
  }

  private FuncGetQuote: delGetQuote;
  private FuncGetToUSD: delGetToUSD;
  private FuncGetCommodity: delGetCommodity;

  private m_mdAccount: kcAccountModel;
  private m_mdInformation: kcAccountInformationModel;

  public AllHistoryIn: boolean = false;

  get Information(): kcAccountInformationModel {
    return this.m_mdInformation;
  }

  mlInventory: kcInventoryLDFModel[] = [];
  mlHistory: kcHistoryTradeLDFModel[] = [];

  m_HistoryCollection_All: kcHistoryTradeLDFCollection;
  m_HistoryCollection_ThisMonth: kcHistoryTradeLDFCollection;

  // [DisplayName("認證\r\n狀態")]
  // public LDFAccountConfirm IsConfirm { get { return m_mdInformation.IsConfirm; } }
  // [DisplayName("序號")]
  // public int AccIdx { get { return m_mdInformation.AccIdx; } }
  // [DisplayName("註冊\r\n日期")]
  // public DateTime RegTime { get { return m_mdInformation.RegTime; } }
  public get RegTime() {
    return this.m_mdInformation.RegTime;
  }
  // [DisplayName("帳號")]
  // public string Account { get { return m_mdInformation.Account; } }
  public get Account() {
    return this.m_mdInformation.Account;
  }
  // [DisplayName("密碼")]
  // public string Password { get { return m_mdInformation.Password; } }
  // [DisplayName("觀摩密碼")]
  // public string PasswordFollow { get { return m_mdInformation.PasswordFollow; } }
  // [DisplayName("姓名")]
  // public string Name { get { return m_mdInformation.Name; } }
  public get Name() {
    return this.m_mdInformation.Name;
  }
  // [DisplayName("國家")]
  // public string Country { get { return RootAccountLDF?.Country; } }
  // [DisplayName("洲/省")]
  // public string Continent { get { return RootAccountLDF?.Country; } }
  // [DisplayName("城市")]
  // public string City { get { return RootAccountLDF?.Country; } }
  // [DisplayName("地址")]
  // public string Address { get { return RootAccountLDF?.Country; } }
  // [DisplayName("郵編")]
  // public string PostalCode { get { return RootAccountLDF?.Country; } }
  // [DisplayName("電話")]
  // public string Phone { get { return RootAccountLDF?.Country; } }
  // [DisplayName("電子\r\n郵件")]
  // public string EMail { get { return m_mdInformation.EMail; } }
  public get EMail() {
    return this.m_mdInformation.EMail;
  }
  // [DisplayName("交易\r\n倍數")]
  // public uint Multiple { get { return m_mdInformation.Multiple; } }
  public get Multiple() {
    return this.m_mdInformation.Multiple;
  }
  // [DisplayName("點差")]
  // public int PointDiff { get { return m_mdInformation.PointDiff; } }
  public get PointDiff() {
    return this.m_mdInformation.PointDiff;
  }
  // [DisplayName("帳號\r\n類別")]
  // public LDFAccountType AccountType { get { return m_mdInformation.AccountType; } }
  public get AccountType() {
    return this.m_mdInformation.AccountType;
  }
  // [DisplayName("組別")]
  // public LDFGroupType GroupType { get { return m_mdInformation.GroupType; } }
  public get GroupType() {
    return this.m_mdInformation.GroupType;
  }
  // [DisplayName("利潤分成時間")]
  // public LDFCommissionTimeType CommissionTimeType { get { return m_mdInformation.CommissionTimeType; } }
  // [DisplayName("利潤分成比例")]
  // public int CommissionRate { get { return m_mdInformation.CommissionRate; } }
  public get CommissionRate() {
    return this.m_mdInformation.CommissionRate;
  }

  // [DisplayName("註釋")]
  // public string Ex { get { return m_mdInformation.Ex; } }
  public get Ex() {
    return this.m_mdInformation.Ex;
  }
  // [DisplayName("跟單戶列表")]
  // public List<int> SubAccountIdxs { get { return m_mdInformation.SubAccountIdxs; } }
  // [DisplayName("經理戶")]
  // public int ParentAccountIdx { get { return m_mdInformation.ParentAccountIdx; } }

  // [DisplayName("目前經理人")]
  // public string ParentName
  // {
  //     get
  //     {
  //         if (m_mdInformation.ParentAccountIdx != -1)
  //             return kcDataInfo.m_mlAccLDF_All[m_mdInformation.ParentAccountIdx].Name;
  //         else
  //             return "無";
  //     }
  // }

  /* ----------------------------------------------------------------------------------------------- */
  // 庫存相關
  private m_SecurityMoney: number = 0; // 餘額 出\入金 or 平倉完畢才會變動
  private m_TotalProfitMoney: number = 0; // 浮動虧損
  private m_NetWorthMoney: number = 0; // 淨值                              (餘額 + 浮動虧損)
  private m_TotalPrepaidMoney: number = 0; // 已用預付款 進出場才會改變           (進場價值/槓桿)累家
  private m_CanUsePrepaidMoney: number = 0; // 可用預付款                         (淨值 - 已用預付款)
  private m_PrepaidRate: number = 0; // 爆倉比例%                          (淨值 % 已用預付款) * 100
  private m_TotalCommissionMoney: number = 0; // 傭金累計
  private m_TotalProfitCommissionMoney: number = 0; // 單純獲利的尚未分潤的金額
  private m_dCommissionOweProfit: number = 0; // 積欠獲利(分潤時計算)
  private m_dPDCommissionOweProfit: number = 0; // 預扣積欠獲利(平倉時計算)
  private m_dPDCommissionMoney: number = 0; // 預扣需分潤金額(平倉時計算)

  // [DisplayName("餘額")]
  // public decimal SecurityMoney { get { return m_SecurityMoney; } }
  public get SecurityMoney() {
    return this.m_SecurityMoney;
  }
  // [DisplayName("浮動損益")]
  // public decimal TotalProfitMoney { get { return m_TotalProfitMoney; } }
  public get TotalProfitMoney() {
    return this.m_TotalProfitMoney;
  }
  // [DisplayName("淨值")]
  // public decimal NetWorthMoney { get { return m_NetWorthMoney; } }
  public get NetWorthMoney() {
    return this.m_NetWorthMoney;
  }
  // [DisplayName("已用預付款")]
  // public decimal TotalPrepaidMoney { get { return m_TotalPrepaidMoney; } }
  public get TotalPrepaidMoney() {
    return this.m_TotalPrepaidMoney;
  }
  // [DisplayName("可用預付款")]
  // public decimal CanUsePrepaidMoney { get { return m_CanUsePrepaidMoney; } }
  public get CanUsePrepaidMoney() {
    return this.m_CanUsePrepaidMoney;
  }
  // [DisplayName("爆倉比例")]
  // public decimal PrepaidRate { get { return m_PrepaidRate; } }
  public get PrepaidRate() {
    return this.m_PrepaidRate;
  }
  // [DisplayName("傭金累計")]
  // public decimal TotalCommissionMoney { get { return m_TotalCommissionMoney; } }
  public get TotalCommissionMoney() {
    return this.m_TotalCommissionMoney;
  }
  // [DisplayName("獲利傭金累計")]
  // public decimal TotalProfitCommissionMoney { get { return m_TotalProfitCommissionMoney; } }
  public get TotalProfitCommissionMoney() {
    return this.m_TotalProfitCommissionMoney;
  }
  // [DisplayName("積欠獲利")]
  // public decimal CommissionOweProfit { get { return m_dCommissionOweProfit; } }
  public get CommissionOweProfit() {
    return this.m_dCommissionOweProfit;
  }
  // [DisplayName("總積欠獲利")]
  // public decimal PDCommissionOweProfit { get { return m_dPDCommissionOweProfit; } }
  public get PDCommissionOweProfit() {
    return this.m_dPDCommissionOweProfit;
  }
  // [DisplayName("分潤預扣款")]
  // public decimal PDCommissionMoney { get { return m_dPDCommissionMoney; } }
  public get PDCommissionMoney() {
    return this.m_dPDCommissionMoney;
  }

  public UpdateQuote(_mdQuote: kcUnitModel): void {
    var ml = this.mlInventory.filter((x) => x.StockCode == _mdQuote.StockCode);
    if (ml.length == 0) return;

    let ToUSD = this.FuncGetToUSD(_mdQuote.StockCode);
    if (!ToUSD) return;

    for (let v of ml) v.UpdateQuote(_mdQuote, ToUSD);

    this._UpdateMoneyValue();

    kcAccountLDFModel.SendAccountLDFUpdate_Event(
      this,
      this.Account,
      AccountLDFUpdateType.Quote,
      ml,
      _mdQuote.StockCode
    );
  }
  public UpdateInformation(_Information: kcAccountInformationModel): void {
    let ChangePointDiff: boolean =
      _Information.PointDiff != this.m_mdInformation.PointDiff;
    this.m_mdInformation.Update(_Information);

    kcAccountLDFModel.SendAccountLDFUpdate_Event(
      this,
      this.Account,
      AccountLDFUpdateType.Info,
      this.m_mdInformation,
      ChangePointDiff
    );

    if (ChangePointDiff) this.UpdateInventory(this.m_mdAccount.Inventory);
  }
  public UpdateInventory(
    _mdInventory: kcInventoryModel,
    _UpdateHistory: boolean = true
  ): void {
    if (_UpdateHistory)
      this.InventoryUpdateHistoryTrade(_mdInventory.mlInventory);

    this.m_mdAccount.Inventory = _mdInventory;
    this.mlInventory.length = 0;

    for (let v of _mdInventory.mlInventory) {
      if (v.Type != "委託" && v.Type != "成交") continue;

      let mdCommodity = this.FuncGetCommodity(v.StockCode);
      let Quote: kcUnitModel | undefined = this.FuncGetQuote(v.StockCode);
      let ToUSD: number | undefined = this.FuncGetToUSD(v.StockCode);

      let Inv: kcInventoryLDFModel = new kcInventoryLDFModel(
        v,
        this.m_mdAccount.Information,
        mdCommodity
      );

      if (v.Type == "委託") Inv.nSepcSortIdx = 2; // 特殊排序

      if (Quote && ToUSD) Inv.UpdateQuote(Quote, ToUSD);

      this.mlInventory.push(Inv);
    }
    this._UpdateMoneyValue();
    kcAccountLDFModel.SendAccountLDFUpdate_Event(
      this,
      this.Account,
      AccountLDFUpdateType.Inventory,
      this.mlInventory,
      null
    );
  }
  public UpdateHistoryTrade(_mlHistoryTrade: kcHistoryTrade[]): void {
    // 只有呼叫才會進來 有近來直接取代 因為它會是最新的

    var mlChange: kcHistoryTradeLDFModel[] = [];
    var mlNew = _mlHistoryTrade;
    for (let New of mlNew) {
      if (New.Type == "分潤" || New.Type == "出入金") {
        var Change = new kcHistoryTradeLDFModel(
          New,
          New,
          undefined,
          this.m_mdAccount.Information
        );
        mlChange.push(Change);
      }

      if (New.Type == "平倉") {
        //var HTradeIn = mlNew.FindLast(x => x.TradeIdx == New.RefTradeIdx);
        var HTradeIn = ArrayExtensions.FindLast(
          mlNew,
          (x) => x.TradeIdx == New.RefTradeIdx
        );

        //var Quote = this.FuncGetQuote(New.StockCode);
        var mdCommodity = this.FuncGetCommodity(New.StockCode);
        if (!HTradeIn || !mdCommodity) {
          // let msg = "";
          // if (!HTradeIn) msg += ", !HTradeIn";
          // if (!mdCommodity) msg += `, !mdCommodity => [${New.StockCode}]`;
          // console.log(`[kcAccountLDFModel] UpdateHistoryTrade() ${msg})`);
          continue;
        }

        var Change = new kcHistoryTradeLDFModel(
          HTradeIn,
          New,
          mdCommodity,
          this.m_mdAccount.Information
        );
        mlChange.push(Change);
      }
      if (New.Type == "委託取消") {
        var Change = kcHistoryTradeLDFModel.CreateCancelTrade(
          New,
          this.m_mdAccount.Information
        );
        mlChange.push(Change);
      }
      if (New.Type == "委託失敗") {
        var Change = kcHistoryTradeLDFModel.CreateFailTrade(
          New,
          this.m_mdAccount.Information
        );
        mlChange.push(Change);
      }
    }
    this.mlHistory = mlChange;

    this.m_HistoryCollection_All.Clear();
    this.m_HistoryCollection_ThisMonth.Clear();
    this.m_HistoryCollection_All.AddRows(mlChange);
    this.m_HistoryCollection_ThisMonth.AddRows(mlChange);

    this.AllHistoryIn = true;

    kcAccountLDFModel.SendAccountLDFUpdate_Event(
      this,
      this.Account,
      AccountLDFUpdateType.HistoryTrade,
      mlChange,
      null
    );
  }
  private InventoryUpdateHistoryTrade(_mlInventory: kcHistoryTrade[]): void {
    // 要在更新庫存前呼叫 使用兩次庫存差異產生 歷史
    var mlChange: kcHistoryTradeLDFModel[] = [];
    var mlNew = _mlInventory;
    var mlPre = this.m_mdAccount.Inventory.mlInventory;
    for (let New of mlNew) {
      //　上次庫存已經處理過的
      var Pre = mlPre.find((x) => x.TradeIdx == New.TradeIdx);
      if (Pre != null && Pre.Type == New.Type) continue;

      if (New.Type == "分潤" || New.Type == "出入金") {
        var Change = new kcHistoryTradeLDFModel(
          New,
          New,
          undefined,
          this.m_mdAccount.Information
        );
        mlChange.push(Change);
      }

      if (New.Type == "平倉") {
        //var HTradeIn = mlNew.FindLast(x => x.TradeIdx == New.RefTradeIdx);
        var HTradeIn = ArrayExtensions.FindLast(
          mlNew,
          (x) => x.TradeIdx == New.RefTradeIdx
        );
        //var Quote = this.FuncGetQuote(New.StockCode);
        var mdCommodity = this.FuncGetCommodity(New.StockCode);
        if (!HTradeIn || !mdCommodity) {
          // let msg = "";
          // if (!HTradeIn) msg += ", !HTradeIn";
          // if (!mdCommodity) msg += `, !mdCommodity => [${New.StockCode}]`;
          // console.log(
          //   `[kcAccountLDFModel] InventoryUpdateHistoryTrade() ${msg})`
          // );
          continue;
        }
        var Change = new kcHistoryTradeLDFModel(
          HTradeIn,
          New,
          mdCommodity,
          this.m_mdAccount.Information
        );
        mlChange.push(Change);
      }
      if (New.Type == "委託取消") {
        var Change = kcHistoryTradeLDFModel.CreateCancelTrade(
          New,
          this.m_mdAccount.Information
        );
        mlChange.push(Change);
      }
      if (New.Type == "委託失敗") {
        var Change = kcHistoryTradeLDFModel.CreateFailTrade(
          New,
          this.m_mdAccount.Information
        );
        mlChange.push(Change);
      }
    }

    this.mlHistory.push(...mlChange);
    this.m_HistoryCollection_All.AddRows(mlChange);
    this.m_HistoryCollection_ThisMonth.AddRows(mlChange);

    if (mlChange.length != 0) {
      kcAccountLDFModel.SendAccountLDFUpdate_Event(
        this,
        this.Account,
        AccountLDFUpdateType.HistoryTradeUpdate,
        mlChange,
        null
      );
    }
  }
  private _InitHistoryTrade(_mdInventory: kcInventoryModel): void {
    // 第一次庫存回來時候才需呼叫 使用庫存盡可能轉出歷史
    var mlChange: kcHistoryTradeLDFModel[] = [];
    var mlNew = _mdInventory.mlInventory;
    for (var New of mlNew) {
      if (New.Type == "分潤" || New.Type == "出入金") {
        var Change = new kcHistoryTradeLDFModel(
          New,
          New,
          undefined,
          this.m_mdAccount.Information
        );
        mlChange.push(Change);
      }

      if (New.Type == "平倉") {
        var HTradeIn = ArrayExtensions.FindLast(
          mlNew,
          (x) => x.TradeIdx == New.RefTradeIdx
        );

        //var Quote = this.FuncGetQuote(New.StockCode);
        var mdCommodity = this.FuncGetCommodity(New.StockCode);
        if (!HTradeIn || !mdCommodity) {
          let msg = "";
          if (!HTradeIn) msg += ", !HTradeIn";
          if (!mdCommodity) msg += `, !mdCommodity => [${New.StockCode}]`;
          //console.log(`[kcAccountLDFModel] _InitHistoryTrade() ${msg})`);
          continue;
        }

        var Change = new kcHistoryTradeLDFModel(
          HTradeIn,
          New,
          mdCommodity,
          this.m_mdAccount.Information
        );
        mlChange.push(Change);
      }
    }

    this.mlHistory = mlChange;
    this.m_HistoryCollection_All.Clear();
    this.m_HistoryCollection_ThisMonth.Clear();
    this.m_HistoryCollection_All.AddRows(mlChange);
    this.m_HistoryCollection_ThisMonth.AddRows(mlChange);

    kcAccountLDFModel.SendAccountLDFUpdate_Event(
      this,
      this.Account,
      AccountLDFUpdateType.HistoryTrade,
      mlChange,
      null
    );
  }
  private _UpdateMoneyValue(): void {
    // 每次都重算
    if (!this.m_mdAccount) return;

    this.m_SecurityMoney = this.m_mdAccount.Inventory.SecurityMoney;
    this.m_TotalPrepaidMoney = this.m_mdAccount.Inventory.TotalPrepaidMoney;
    this.m_TotalCommissionMoney =
      this.m_mdAccount.Inventory.TotalCommissionMoney;
    this.m_TotalProfitCommissionMoney =
      this.m_mdAccount.Inventory.TotalProfitCommissionMoney;
    this.m_dCommissionOweProfit =
      this.m_mdAccount.Inventory.CommissionOweProfit;
    this.m_dPDCommissionOweProfit =
      this.m_mdAccount.Inventory.PDCommissionOweProfit;
    this.m_dPDCommissionMoney = this.m_mdAccount.Inventory.PDCommissionMoney;

    this.m_TotalProfitMoney = 0;

    for (var v of this.mlInventory) {
      if (v.Type == "成交") {
        this.m_TotalProfitMoney += v.ProfitMoney;
        this.m_TotalProfitMoney += v.SwapMoney;
        //m_TotalProfitMoney -= v.AutoCommissionMoney;
      }
    }

    this.m_NetWorthMoney = this.m_SecurityMoney + this.m_TotalProfitMoney;
    this.m_CanUsePrepaidMoney = this.m_NetWorthMoney - this.m_TotalPrepaidMoney;

    if (this.m_NetWorthMoney <= 0) {
      this.m_PrepaidRate = 0;
    } else if (this.m_TotalPrepaidMoney == 0) {
      this.m_PrepaidRate = 100000;
    } else {
      this.m_PrepaidRate =
        (this.m_NetWorthMoney / this.m_TotalPrepaidMoney) * 100;
    }
  }

  /* ---------------------------------------------------------------- */
  // 額外的Functions
  public UpdateCommodity(_Commoditys: kcCommodityModel[]) {
    for (let md of _Commoditys) {
      for (let mdInv of this.mlInventory) mdInv.UpdateCommodity(md);
      for (let mdHis of this.mlHistory) mdHis.UpdateCommodity(md);
    }
  }
  public GetInventoryCommoditys() {
    let Commoditys: string[] = [];
    if (this.mlInventory) {
      for (let md of this.mlInventory) {
        if (Commoditys.findIndex((q) => q == md.StockCode) < 0)
          Commoditys.push(md.StockCode);
      }
    }
    return Commoditys;
  }
  public GetInventoryList(): kcInventoryLDFModel[] {
    return this.mlInventory;
  }
  public GetInventory(_nTradeIdx: number): kcInventoryLDFModel | undefined {
    if (_nTradeIdx >= 0)
      return this.mlInventory.find((md) => md.TradeIdx === _nTradeIdx);
    return undefined;
  }
  public GetInventoryAsync(
    _nTradeIdx: number,
    _fCallback: (_mdInventoryLDF: kcInventoryLDFModel | undefined) => void
  ): void {
    if (!this.AllHistoryIn) {
      setTimeout(() => {
        this.GetInventoryAsync(_nTradeIdx, _fCallback);
      }, 200);
      return;
    }

    let mdInventory = this.GetInventory(_nTradeIdx);
    _fCallback?.call(this, mdInventory);
  }
  public GetInventorys(_szStockCode: string): kcInventoryLDFModel[] {
    if (
      _szStockCode &&
      typeof _szStockCode === "string" &&
      _szStockCode.length > 0
    )
      return this.mlInventory.filter((md) => md.StockCode === _szStockCode);
    return [];
  }
  public GetHistoryList(): kcHistoryTradeLDFModel[] {
    return this.mlHistory;
  }
  public GetHistory(_nTradeIdx: number): kcHistoryTradeLDFModel | undefined {
    if (_nTradeIdx >= 0)
      return this.mlHistory.find(
        (md) =>
          md.TradeIdx === _nTradeIdx ||
          md.TradeIdxOut === _nTradeIdx ||
          md.RefTradeIdx === _nTradeIdx
      );
    return undefined;
  }
  public GetHistorys(_szStockCode: string): kcHistoryTradeLDFModel[] {
    if (
      _szStockCode &&
      typeof _szStockCode === "string" &&
      _szStockCode.length > 0
    )
      return this.mlHistory.filter((md) => md.StockCode === _szStockCode);
    return [];
  }

  // static Event
  private static AccountLDFUpdate_Event: OnAccountLDFUpdate_Event[] = [];
  public static AccountLDFUpdateEvent_Add(
    AccountLDFUpdateCallback: OnAccountLDFUpdate_Event
  ) {
    kcAccountLDFModel.AccountLDFUpdate_Event.push(AccountLDFUpdateCallback);
  }
  public static AccountLDFUpdateEvent_Remove(
    AccountLDFUpdateCallback: OnAccountLDFUpdate_Event
  ) {
    let nIdx = kcAccountLDFModel.AccountLDFUpdate_Event.indexOf(
      AccountLDFUpdateCallback
    );
    if (nIdx >= 0) kcAccountLDFModel.AccountLDFUpdate_Event.splice(nIdx, 1);
  }
  private static SendAccountLDFUpdate_Event(
    _mdSender: kcAccountLDFModel,
    _szAccount: string,
    _Type: AccountLDFUpdateType,
    _Param1?: any,
    _Param2?: any
  ): void {
    for (let i = 0; i < kcAccountLDFModel.AccountLDFUpdate_Event.length; i++) {
      kcAccountLDFModel.AccountLDFUpdate_Event[i](
        _mdSender,
        _szAccount,
        _Type,
        _Param1,
        _Param2
      );
    }

    // 額外針對庫存的每一張單發送Event
    if (
      _Type === AccountLDFUpdateType.Inventory ||
      _Type === AccountLDFUpdateType.Quote
    ) {
      if (_Param1 && Array.isArray(_Param1)) {
        let mlInvs: kcInventoryLDFModel[] = _Param1 as kcInventoryLDFModel[];

        for (let mdInv of mlInvs) {
          let szStockCode = "";
          if (
            _Type === AccountLDFUpdateType.Quote &&
            _Param2 &&
            typeof _Param2 === "string"
          )
            szStockCode = _Param2;
          else szStockCode = mdInv.StockCode;

          kcAccountLDFModel.SendInventoryUpdate_Event(
            _mdSender,
            _szAccount,
            _Type,
            szStockCode,
            mdInv
          );
        }
      }
    }
  }

  private static InventoryUpdate_Event: OnInventoryUpdate_Event[] = [];
  public static InventoryUpdateEvent_Add(
    InventoryUpdateCallback: OnInventoryUpdate_Event
  ) {
    kcAccountLDFModel.InventoryUpdate_Event.push(InventoryUpdateCallback);
  }
  public static InventoryUpdateEvent_Remove(
    InventoryUpdateCallback: OnInventoryUpdate_Event
  ) {
    let nIdx = kcAccountLDFModel.InventoryUpdate_Event.indexOf(
      InventoryUpdateCallback
    );
    if (nIdx >= 0) kcAccountLDFModel.InventoryUpdate_Event.splice(nIdx, 1);
  }
  private static SendInventoryUpdate_Event(
    _mdSender: kcAccountLDFModel,
    _szAccount: string,
    _Type: AccountLDFUpdateType.Inventory | AccountLDFUpdateType.Quote,
    _StockCode: string,
    _Inventory: kcInventoryLDFModel
  ): void {
    for (let i = 0; i < kcAccountLDFModel.InventoryUpdate_Event.length; i++) {
      kcAccountLDFModel.InventoryUpdate_Event[i](
        _mdSender,
        _szAccount,
        _Type,
        _StockCode,
        _Inventory
      );
    }
  }
}
