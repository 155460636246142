import * as React from "react";
import { StyleSheet, Modal } from "react-native";
import { Text, View } from "../components/Themed";
import { Button } from "react-native-elements";
import { kcSetState, kcSetUnmount } from "../kcExternal";
import moment, { Moment } from "moment";
import { kcColorObj, kcColor } from "../constants/Colors";
import KC_DatePicker from "../components/kcDatePicker";

type IState = {
  Visable: boolean;
  sTime: Moment;
  eTime: Moment;
};

type IProp = {
  OnTimeRangeChange?: (_sTime: Moment, _eTime: Moment) => void;
};

export default class KC_DateRangeModal extends React.Component<IProp, IState> {
  state: IState = {
    Visable: false,
    sTime: moment.utc().add(-7, "d"),
    eTime: moment.utc().add(7, "d"),
  };

  constructor(props: IProp) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {
    kcSetUnmount(this, true);
  }

  public Show = (_sTime: Moment, _eTime: Moment) => {
    kcSetState(this, { Visable: true, sTime: _sTime, eTime: _eTime });
  };

  private OnBtnOkClick = () => {
    if (this.props.OnTimeRangeChange)
      this.props.OnTimeRangeChange(this.state.sTime, this.state.eTime);
    kcSetState(this, { Visable: false });
  };

  private OnBtnCancelClick = () => {
    kcSetState(this, { Visable: false });
  };

  private OnStartValueChange = (_value: Moment | null, _dateString: string) => {
    kcSetState(this, { sTime: _value });
  };

  private OnEndValueChange = (_value: Moment | null, _dateString: string) => {
    kcSetState(this, { eTime: _value });
  };

  render() {
    if (this.state.Visable == false) return <></>;
    return (
      <Modal animationType="fade" transparent={true} visible={true}>
        <View
          style={{
            flex: 1,
            backgroundColor: "#000000BB",
            justifyContent: "center", // 上下位置
            alignItems: "center", // 左右位置
          }}
        >
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: kcColor("Background"),
              borderRadius: 10,
              width: 300,
            }}
          >
            <Text
              style={{
                marginTop: 10,
                fontSize: 20,
                color: kcColor("Title"),
              }}
            >
              {"選擇時間範圍"}
            </Text>
            <Text
              style={{
                fontSize: 12,
                color: kcColor("Yellow"),
                textAlign: "left",
                //marginTop: 10,
                marginRight: 10,
                marginBottom: 20,
                //alignSelf: "flex-end",
              }}
            >
              {"格林威治標準時間"}
            </Text>

            <View
              style={{
                flexDirection: "column",
                width: 260,
                backgroundColor: "#00000000",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  backgroundColor: "#00000000",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <Text style={styles.RowTitleStyles}>{"起始時間"}</Text>
                <View
                  style={{
                    flexDirection: "row",
                    backgroundColor: kcColor("DateTimeBackground"),
                    justifyContent: "center",
                    alignItems: "center",
                    width: 160,
                    borderRadius: 10,
                  }}
                >
                  <KC_DatePicker
                    onChange={this.OnStartValueChange}
                    defaultValue={this.state.sTime}
                    EnableTime={false}
                  />
                </View>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  backgroundColor: "#00000000",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <Text style={styles.RowTitleStyles}>{"結束時間"}</Text>
                <View
                  style={{
                    flexDirection: "row",
                    backgroundColor: kcColor("DateTimeBackground"),
                    justifyContent: "center",
                    alignItems: "center",
                    width: 160,
                    borderRadius: 10,
                  }}
                >
                  <KC_DatePicker
                    onChange={this.OnEndValueChange}
                    defaultValue={this.state.eTime}
                    EnableTime={false}
                  />
                </View>
              </View>
            </View>

            <View
              style={{
                marginTop: 20,
                marginBottom: 20,
                flexDirection: "row",
                backgroundColor: "#00000000",
                //alignSelf: "stretch",
                width: 260,
              }}
            >
              <Button
                containerStyle={{ flex: 1 }}
                buttonStyle={{
                  backgroundColor: kcColor("Broder"),
                  height: 30,
                  marginLeft: 10,
                  marginRight: 10,
                  borderRadius: 10,
                }}
                titleStyle={{ fontSize: 14 }}
                title="取消"
                onPress={() => {
                  this.OnBtnCancelClick();
                }}
              />
              <Button
                containerStyle={{ flex: 1 }}
                buttonStyle={{
                  backgroundColor: kcColor("Blue"),
                  height: 30,
                  marginLeft: 10,
                  marginRight: 10,
                  borderRadius: 10,
                }}
                titleStyle={{ fontSize: 14 }}
                title="確定"
                onPress={() => {
                  this.OnBtnOkClick();
                }}
              />
            </View>
          </View>
        </View>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  RowTitleStyles: {
    flex: 1,
    color: kcColor("SubTitle"),
    fontSize: 16,
  },
});
