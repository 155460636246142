import * as React from "react";
import {
  StyleSheet,
  ScrollView,
  View,
  Dimensions,
  ScaledSize,
  Pressable,
  SafeAreaView,
  Animated,
} from "react-native";
import { Text } from "../components/Themed";
import { kcData, OrderParamHelper } from "../kcData";
import { kcSetState, kcSetUnmount } from "../kcExternal";
import { Button, SearchBar } from "react-native-elements";
import { RouteProp } from "@react-navigation/native";
import {
  StackHeaderLeftButtonProps,
  StackHeaderTitleProps,
  StackNavigationProp,
} from "@react-navigation/stack";
import {
  TabQuoteParamList,
  TabTECPParamList,
  TabTradeParamList,
} from "../types";
import RegListHelper, { RegListUpdateParam } from "../kcData/RegListHelper";
import { kcCommodityModel } from "../kcModel";
import { kcExchangeCode } from "../kcModel/kcModel_Enum";
import { Ionicons, FontAwesome, AntDesign } from "@expo/vector-icons";
import { kcColor } from "../constants/Colors";
import { MaterialIcons } from "@expo/vector-icons";

type CommodityItem = { Registed: boolean; Commodity: kcCommodityModel };
type ExcListType = { Exchange: kcExchangeCode; Stocks: CommodityItem[] };

type IState = {
  Update: boolean; // 只是用來SetState讓下面重畫, 沒別的用
  SearchText: string;
  expanded: boolean;
  Favorite?: ExcListType;
  DisplayList: ExcListType[];
  DimensionsWindow: ScaledSize;
  //RegistedItems: string[];
};

type IProp = {
  route:
    | RouteProp<TabQuoteParamList, "TabQuoteScreen_Select">
    | RouteProp<TabTECPParamList, "TabTECPScreen_Select">
    | RouteProp<TabTradeParamList, "TabTradeScreen_Select">;
  navigation:
    | StackNavigationProp<TabQuoteParamList, "TabQuoteScreen_Select">
    | StackNavigationProp<TabTECPParamList, "TabTECPScreen_Select">
    | StackNavigationProp<TabTradeParamList, "TabTradeScreen_Select">;
};

class TabQuoteScreen_SelectCommodity extends React.Component<IProp, IState> {
  state: IState = {
    Update: true,
    SearchText: "",
    expanded: false,
    Favorite: undefined,
    DisplayList: [],
    DimensionsWindow: Dimensions.get("window"),
    //RegistedItems: RegListHelper.GetRegList(),
  };

  /* ------------------------------------------------------------- */
  // Member
  reg: string = "";
  ExchangeList: ExcListType[] = [];
  Favorite: ExcListType = { Exchange: kcExchangeCode.UnDef, Stocks: [] };

  /* ------------------------------------------------------------- */
  // HookFunc
  componentDidMount() {
    this.props.navigation.addListener("focus", this.OnComponent_Focused);
    this.props.navigation.addListener("blur", this.OnComponent_blur);
    Dimensions.addEventListener("change", this.onDimensionsChange);
    RegListHelper.RegListUpdateEvent_Add(this.OnRegListChanged);

    this.props.navigation.setOptions({
      //headerTitle: this.Header.bind(this, ""),
      //headerTitle: this.Render_HeaderTitle2,
      headerTitle: this.Render_HeaderTitle.bind(this, ""),
      headerLeft: this.Render_HeaderLeft, // this.Render_HeaderLeft.bind(this),
      headerTitleContainerStyle: {
        marginRight: 5,
        flex: 1,
      },
      // headerTitleContainerStyle: {
      //   flexDirection: "row",
      //   alignItems: "center",
      //   justifyContent: "center",
      //   flex: 1,
      //   borderWidth: 1,
      //   borderColor: "green",
      //   // margin: 0,
      //   // marginLeft: 0,
      //   height: 60,
      // },
    });

    this.InitExchangeList();
  }

  componentWillUnmount() {
    this.props.navigation.removeListener("focus", this.OnComponent_Focused);
    this.props.navigation.removeListener("blur", this.OnComponent_blur);
    Dimensions.removeEventListener("change", this.onDimensionsChange);
    RegListHelper.RegListUpdateEvent_Remove(this.OnRegListChanged);
    kcSetUnmount(this, true);
  }

  shouldComponentUpdate(nextProps: IProp, nextState: IState) {
    return true;
  }

  /* ------------------------------------------------------------- */

  // 整理成By各個交易所的List
  private InitExchangeList = () => {
    let CommodityList = kcData.GetCommodityList();
    if (!CommodityList || CommodityList.length === 0) {
      setTimeout(this.InitExchangeList, 500);
      return;
    }
    let ExcList: ExcListType[] = [];
    for (let i = 0; i < CommodityList.length; i++) {
      let mdCommodity = CommodityList[i];
      let ExcItem = ExcList.find(
        (q) => q.Exchange === mdCommodity.ExchangeCode
      );
      if (!ExcItem) {
        ExcItem = { Exchange: mdCommodity.ExchangeCode, Stocks: [] };
        ExcList.push(ExcItem);
      }
      ExcItem.Stocks.push({ Commodity: mdCommodity, Registed: false });
    }
    ExcList.sort((a, b) => a.Exchange - b.Exchange);

    this.ExchangeList = ExcList;

    this.UpdateRegistedState(RegListHelper.GetRegList());

    kcSetState(this, {
      DisplayList: this.ExchangeList,
      Favorite: this.Favorite,
    });
  };

  private UpdateRegistedState = (_RegList: string[]) => {
    let RegSet = new Set(_RegList);
    let FavoriteItems: ExcListType["Stocks"] = [];
    for (let mdExc of this.ExchangeList) {
      for (let mdCommodity of mdExc.Stocks) {
        mdCommodity.Registed = RegSet.has(mdCommodity.Commodity.StockCode);
        if (mdCommodity.Registed) FavoriteItems.push(mdCommodity);
      }
    }

    // 更新自選
    this.Favorite.Stocks.length = 0;
    this.Favorite.Stocks.push(...FavoriteItems);
  };

  private updateSearch = (_Text: string) => {
    this.props.navigation.setOptions({
      headerTitle: this.Render_HeaderTitle.bind(this, _Text),
    });
    kcSetState(this, { SearchText: _Text });
  };

  /* ------------------------------------------------------------- */
  // On
  private OnComponent_Focused = () => {};

  private OnComponent_blur = () => {};

  private onDimensionsChange = ({
    window,
    screen,
  }: {
    window: ScaledSize;
    screen: ScaledSize;
  }) => {
    this.setState({ DimensionsWindow: window });
  };

  private OnRegListChanged = (_Param: RegListUpdateParam) => {
    this.UpdateRegistedState(_Param.RegList);
    kcSetState(this, { Update: this.state.Update });
  };

  /* ------------------------------------------------------------- */
  //Render
  private Render_HeaderLeft: (
    props: StackHeaderLeftButtonProps
  ) => React.ReactNode = (props) => {
    return (
      <Button
        // style={{ width: 48, height: 48 }}
        icon={
          <Ionicons name="ios-chevron-back" size={24} color={kcColor("Icon")} />
        }
        iconContainerStyle={{ alignSelf: "center" }}
        buttonStyle={styles.button}
        onPress={() => {
          // if (this.props.navigation.canGoBack()){ this.props.navigation.goBack();}
          // else{
          this.props.navigation.pop();
          //}
        }}
      />
    );
  };
  private Render_HeaderTitle2: (
    props: StackHeaderTitleProps
  ) => React.ReactNode = (props) => {
    console.log(props);
    return (
      <View
        style={{
          flexDirection: "row",
          flex: 1,
          borderColor: "blue",
          borderWidth: 1,
          justifyContent: "center",
          //height: 10,
          //width: 100,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            //flex: 1,
            borderColor: "red",
            borderWidth: 1,
            height: 30,
            width: 200,
          }}
        >
          <Text
            style={{
              color: "#FFFFFF",
              // transform: [{ rotate: "45deg" }],
              transform: [{ rotateX: "80deg" }],
              borderColor: "green",
              borderWidth: 1,
            }}
          >
            12345
          </Text>
        </View>

        {/* <View
          style={{
            flexDirection: "row",
            flex: 1,
            borderColor: "blue",
            borderWidth: 1,
            justifyContent: "center",
            //height: 10,
            //width: 100,
          }}
        ></View> */}
      </View>
    );
    return (
      <View
        style={{
          justifyContent: "center",
          flex: 1,
          backgroundColor: "#00000000",
        }}
      >
        <SearchBar
          key="StockSearchBar"
          platform="default"
          placeholder="搜尋"
          onChange={(e) => {
            this.updateSearch(e.nativeEvent.text);
          }}
          value={_SearchText}
          textBreakStrategy="highQuality"
          showSoftInputOnFocus={true}
          //showLoading={true}
          containerStyle={[
            styles.BackGroundColor,
            {
              borderTopWidth: 0,
              borderBottomWidth: 0,
              //backgroundColor: "green",
            },
          ]}
          // rightIconContainerStyle={{
          //   width: 30,
          //   height: 30,
          //   backgroundColor: "green",
          // }}
          // inputStyle={{
          //   height: 30,
          //   //backgroundColor: "#00FFFF"
          //   borderTopWidth: 1,
          //   borderTopColor: "green",
          // }}
          inputContainerStyle={{
            // height: 30,
            borderRadius: 13,
            backgroundColor: kcColor("ListTitleBackground"),
          }}
          // style={{
          //   height: 30,
          //   backgroundColor: "#ffff00",
          // }}
          // searchIcon={<></>}
        ></SearchBar>
      </View>
    );
  };
  private Render_HeaderTitle = (_SearchText: string) => {
    return (
      <View
        style={{
          justifyContent: "center",
          flex: 1,
          backgroundColor: "#00000000",
        }}
      >
        <SearchBar
          key="StockSearchBar"
          platform="default"
          placeholder="搜尋"
          onChange={(e) => {
            this.updateSearch(e.nativeEvent.text);
          }}
          value={_SearchText}
          textBreakStrategy="highQuality"
          showSoftInputOnFocus={true}
          //showLoading={true}
          containerStyle={[
            styles.BackGroundColor,
            {
              borderTopWidth: 0,
              borderBottomWidth: 0,
              //backgroundColor: "green",
            },
          ]}
          // rightIconContainerStyle={{
          //   width: 30,
          //   height: 30,
          //   backgroundColor: "green",
          // }}
          // inputStyle={{
          //   height: 30,
          //   //backgroundColor: "#00FFFF"
          //   borderTopWidth: 1,
          //   borderTopColor: "green",
          // }}
          inputContainerStyle={{
            // height: 30,
            borderRadius: 13,
            backgroundColor: kcColor("ListTitleBackground"),
          }}
          // style={{
          //   height: 30,
          //   backgroundColor: "#ffff00",
          // }}
          // searchIcon={<></>}
        ></SearchBar>
      </View>
    );
  };

  render() {
    let { Favorite, DisplayList, SearchText } = this.state;
    const BottomViewHeight = 40;
    const EffectiveHeigh = this.state.DimensionsWindow.height - 60;

    return (
      <SafeAreaView
        style={{
          backgroundColor: kcColor("Background"),
          flexDirection: "column",
          height: EffectiveHeigh,
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <ScrollView
          style={{
            backgroundColor: "#00000000",
            flex: 1,
          }}
        >
          {Favorite && (
            <ExcList
              key={`${kcExchangeCode[Favorite.Exchange]}`}
              Update={this.state.Update}
              DataItem={Favorite}
              ScarchText={SearchText}
              ParentProp={this.props}
              Expanded
            ></ExcList>
          )}
          {DisplayList.map((_Item, _idx) => (
            <ExcList
              key={`${kcExchangeCode[_Item.Exchange]}`}
              Update={this.state.Update}
              DataItem={_Item}
              ScarchText={SearchText}
              ParentProp={this.props}
            ></ExcList>
          ))}

          <View style={{ backgroundColor: "#00000000", height: 60 }} />
        </ScrollView>
        <View
          style={{ backgroundColor: "#00000000", height: BottomViewHeight }}
        />
      </SafeAreaView>
    );
  }
}

const EachItemHeigh = 51;
const DefaultTime = 200;
const EachItemTime = 0;
type IExcListProp = {
  Update: boolean;
  DataItem: ExcListType;
  ScarchText: string;
  ParentProp: IProp;
  Expanded?: boolean;
  // RegistedItems: string[];
};
type IExcListState = {
  DispalyItems: CommodityItem[];
  Expanded: boolean;
};
class ExcList extends React.Component<IExcListProp, IExcListState> {
  constructor(_Props: IExcListProp) {
    super(_Props);
  }
  state: IExcListState = {
    DispalyItems: [],
    Expanded: this.props.Expanded !== undefined ? this.props.Expanded : false,
  };

  /* ------------------------------------------------------------- */
  // Member
  ScarchText: string = "";
  AnimatedValue = new Animated.Value(this.state.Expanded ? 100 : 0);

  /* ------------------------------------------------------------- */
  // HookFunc
  componentDidMount() {
    let mlItems = this.props.DataItem.Stocks;
    mlItems = this.FilterItem_bySearch(mlItems, this.props.ScarchText);
    //mlItems = this.FilterItem_byRegister(mlItems);
    kcSetState(this, { DispalyItems: mlItems });
  }

  shouldComponentUpdate(nextProps: IExcListProp, nextState: IExcListState) {
    let mlItems = this.props.DataItem.Stocks;
    if (
      nextProps.ScarchText != this.ScarchText ||
      this.props.DataItem.Exchange === kcExchangeCode.UnDef
    ) {
      let szPreSearch = this.ScarchText;
      let szNextSearch = nextProps.ScarchText;
      mlItems = this.FilterItem_bySearch(mlItems, szNextSearch);
      nextState.DispalyItems = mlItems;

      //if (szNextSearch.length > szPreSearch.length) nextState.Expanded = true;
      if (szNextSearch.length > szPreSearch.length) {
        this.setExpanded(true);
      }
    }

    return true;
  }

  /* ------------------------------------------------------------- */

  private FilterItem_bySearch = (_Src: CommodityItem[], _szScarch: string) => {
    this.ScarchText = _szScarch;
    let Stocks = _Src;
    let mlItems: CommodityItem[] = Stocks.filter((md) => {
      if (
        md.Commodity.StockCode.toLowerCase().indexOf(_szScarch.toLowerCase()) >=
        0
      )
        return true;
      if (
        md.Commodity.StockName.toLowerCase().indexOf(_szScarch.toLowerCase()) >=
        0
      )
        return true;
      return false;
    });
    return mlItems;
  };

  private FilterItem_byRegister = (_Src: CommodityItem[]) => {
    let Stocks = _Src;
    let mlItems: CommodityItem[] = Stocks.filter((md) => {
      return !md.Registed;
    });
    return mlItems;
  };

  private setExpanded = (_Expanded: boolean) => {
    const Cnt = this.state.DispalyItems.length;
    const ItemsTime = Cnt * EachItemTime;
    Animated.timing(this.AnimatedValue, {
      toValue: _Expanded ? 100 : 0,
      duration: DefaultTime + ItemsTime,
      useNativeDriver: false,
    }).start();

    kcSetState(this, { Expanded: _Expanded });
  };

  private OnExpandedPressed = () => {
    this.setExpanded(!this.state.Expanded);
  };

  private ChangeRegStock = (_szStockCode: string, _bAdd: boolean) => {
    if (_bAdd) RegListHelper.AppendRegListItem(_szStockCode);
    else RegListHelper.RemoveRegListItem(_szStockCode);
  };

  private ChangeSelectCommodity = (_szStockCode: string) => {
    if (this.props.ParentProp.route.name == "TabTECPScreen_Select") {
      let Navigation = this.props.ParentProp.navigation as StackNavigationProp<
        TabTECPParamList,
        "TabTECPScreen_Select"
      >;
      Navigation.navigate("TabTECPScreen", { StockCode: _szStockCode });
    } else {
      RegListHelper.AppendRegListItem(_szStockCode);
      OrderParamHelper.ChangeNewOrderCommodity(_szStockCode);
      this.props.ParentProp.navigation.pop();
    }
  };

  private ToCommodityInfoPage = (_szStockCode: string) => {
    let NowScreen = this.props.ParentProp.route.name;
    if (NowScreen == "TabQuoteScreen_Select") {
      let Navigation = this.props.ParentProp.navigation as StackNavigationProp<
        TabQuoteParamList,
        "TabQuoteScreen_Select"
      >;
      Navigation.push("TabQuoteScreen_Info", { StockCode: _szStockCode });
    } else if (NowScreen == "TabTECPScreen_Select") {
      let Navigation = this.props.ParentProp.navigation as StackNavigationProp<
        TabTECPParamList,
        "TabTECPScreen_Select"
      >;
      Navigation.push("TabTECPScreen_Info", { StockCode: _szStockCode });
    } else if (NowScreen == "TabTradeScreen_Select") {
      let Navigation = this.props.ParentProp.navigation as StackNavigationProp<
        TabTradeParamList,
        "TabTradeScreen_Select"
      >;
      Navigation.push("TabTradeScreen_Info", { StockCode: _szStockCode });
    }
  };

  /* ------------------------------------------------------------- */
  // Render
  render() {
    let { DispalyItems } = this.state;
    let { Exchange } = this.props.DataItem;
    if (DispalyItems.length == 0) return <View></View>;

    let AccordionICon = undefined;
    let AccordionTitle = kcExchangeCode[Exchange];
    if (Exchange == kcExchangeCode.SpotMetals) AccordionTitle = "貴金屬";
    else if (Exchange == kcExchangeCode.Forex) AccordionTitle = "外匯";
    else if (Exchange == kcExchangeCode.SpotEnergies) AccordionTitle = "能源";
    else AccordionTitle = kcExchangeCode[Exchange];

    if (Exchange == kcExchangeCode.UnDef) {
      AccordionICon = (
        <FontAwesome
          selectable={false}
          name={"star"}
          size={24}
          color={kcColor("Yellow")}
          style={{ marginRight: 10 }}
        />
      );
      AccordionTitle = "自選商品";
    }

    const Cnt = this.state.DispalyItems.length;
    const ItemsHeigh = Cnt * EachItemHeigh;
    let AnimatedItemsHeigh = this.AnimatedValue.interpolate({
      inputRange: [0, 100],
      outputRange: [0, ItemsHeigh],
    });
    let AnimatedItemsOpacity = this.AnimatedValue.interpolate({
      inputRange: [0, 100],
      outputRange: [0, 1],
    });
    let AnimatedIconRotate = this.AnimatedValue.interpolate({
      inputRange: [0, 100],
      outputRange: ["0deg", "-180deg"],
    });

    return (
      <View style={styles_ExcList.ListArea}>
        {/* // Title */}
        <Pressable
          style={styles_ExcList.ListTitle}
          onPress={(e) => {
            this.OnExpandedPressed();
          }}
        >
          {AccordionICon}

          <View style={styles_ExcList.ListTitle_Text}>
            <Text selectable={false} style={styles_ExcList.ListItemTextStyle}>
              {AccordionTitle}
            </Text>
            <Text
              selectable={false}
              style={[styles_ExcList.ListItemTextStyle, { marginLeft: 10 }]}
            >
              {`(${DispalyItems.length})`}
            </Text>
          </View>
          <Animated.View
            style={{
              marginLeft: 10,
              marginRight: 10,
              transform: [{ rotate: AnimatedIconRotate }],
            }}
          >
            <MaterialIcons
              name="arrow-drop-down"
              size={30}
              color={kcColor("Icon")}
              selectable={false}
            />
          </Animated.View>
        </Pressable>

        {/* // Items */}
        <Animated.View
          style={{
            height: AnimatedItemsHeigh,
            overflow: "hidden",
            opacity: AnimatedItemsOpacity,
            flexDirection: "column",
          }}
        >
          {DispalyItems.map((md, idx) => {
            let WrapKey = `${kcExchangeCode[this.props.DataItem.Exchange]}_${
              md.Commodity.StockCode
            }`;
            return (
              <View key={WrapKey} style={styles_ExcList.ItemsWrap}>
                <Pressable
                  style={[styles_ExcList.IConWraper]}
                  onPress={() => {
                    this.ChangeSelectCommodity(md.Commodity.StockCode);
                  }}
                >
                  <Ionicons
                    selectable={false}
                    name="ios-chevron-back"
                    size={24}
                    color={kcColor("Icon")}
                    onPress={() => {
                      this.ChangeSelectCommodity(md.Commodity.StockCode);
                    }}
                  />
                </Pressable>

                <Pressable
                  style={styles_ExcList.CommodityInfo}
                  // onLongPress={() => {
                  //   this.ChangeSelectCommodity(md.Commodity.StockCode);
                  // }}
                  onPress={() => {
                    this.ChangeSelectCommodity(md.Commodity.StockCode);
                  }}
                >
                  <Text
                    selectable={false}
                    style={styles_ExcList.ListItemTextStyle}
                  >
                    {md.Commodity.StockCode}
                  </Text>
                  <Text
                    selectable={false}
                    style={styles_ExcList.ListItemSubTextStyle}
                  >
                    {md.Commodity.StockName}
                  </Text>
                </Pressable>

                <Pressable
                  style={styles_ExcList.IConWraper}
                  onPress={() => {
                    this.ToCommodityInfoPage(md.Commodity.StockCode);
                  }}
                >
                  <Ionicons
                    selectable={false}
                    name={"information-circle-sharp"}
                    //name={"information-circle-outline"}
                    size={28}
                    color={kcColor("Blue")}
                    onPress={() =>
                      this.ToCommodityInfoPage(md.Commodity.StockCode)
                    }
                  />
                </Pressable>
              </View>
            );
          })}
        </Animated.View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  BackGroundColor: {
    backgroundColor: "#00000000",
  },

  button: {
    backgroundColor: "#00000000",
    borderColor: "red",
    borderWidth: 0,
    borderRadius: 15,
  },
});

const styles_ExcList = StyleSheet.create({
  ListArea: {
    flexDirection: "column",
    backgroundColor: kcColor("ListTitleBackground"),
  },

  ListTitle: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    padding: 10,
    borderColor: kcColor("Border"),
    borderBottomWidth: 1,
  },

  ListTitle_Text: {
    flexDirection: "row",
    flex: 1,
  },

  ListItemTextStyle: {
    color: kcColor("Title"),
    fontWeight: "400",
    fontSize: 16,
  },
  ListItemSubTextStyle: {
    color: kcColor("SubTitle"),
    fontSize: 14,
  },
  ItemsWrap: {
    flexDirection: "row",
    flex: 1,
    paddingVertical: 5,
    paddingHorizontal: 16,
    backgroundColor: kcColor("ListValueBackground"),
    borderColor: kcColor("Border"),
    borderBottomWidth: 1,
  },
  IConWraper: {
    width: 30,
    alignItems: "center",
    justifyContent: "center",
  },
  CommodityInfo: {
    flex: 1,
    flexWrap: "nowrap",
    marginLeft: 20,
    alignItems: "flex-start",
    justifyContent: "center",
  },
});

export default TabQuoteScreen_SelectCommodity;
