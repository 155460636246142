import * as React from "react";
import { StyleSheet, ScrollView, SafeAreaView } from "react-native";
import { Text, View } from "../components/Themed";
import { kcSetState, kcSetUnmount } from "../kcExternal";
import { Button } from "react-native-elements";
import { kcData, kcAccountLDFModel, kcHistoryTradeLDFModel } from "../kcData";
import moment, { Moment } from "moment";
import {
  AccountLDFUpdateType,
  OnAccountLDFUpdate_Event,
} from "../kcData/kcAccountLDFModel";
import KC_HistoryScreenRow from "../components/KC_HistoryScreenRow";
import KC_HistoryScreenAccInfo from "../components/KC_HistoryScreenAccInfo";
import KC_DateRangeModal from "../components/KC_DateRangeModal";
import { kcColorObj, kcColor } from "../constants/Colors";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";

type IState = {
  nFixedRedraw: number; // 笨方式 用來強制繪圖
  mlHistoryTradedata_InView: kcHistoryTradeLDFModel[];
  sTime: Moment;
  eTime: Moment;
  bIsScreenFocus: boolean;
};

type IProp = {
  navigation: any;
};

class Screen extends React.Component<IProp, IState> {
  state: IState = {
    nFixedRedraw: 0, // 笨方式 用來強制繪圖
    mlHistoryTradedata_InView: [],
    sTime: moment.utc().add(-7, "d"),
    eTime: moment.utc().add(7, "d"),
    bIsScreenFocus: true,
  };

  nFixedRedraw: number = 0;
  m_dicRowAnimateding: Map<string, boolean> = new Map<string, boolean>();
  m_bAnyRowAnimateding: boolean = false;
  mlHistoryTradedata: kcHistoryTradeLDFModel[] = [];
  KC_DateRangeModal: any = undefined;

  constructor(props: IProp) {
    super(props);
    this.KC_DateRangeModal = React.createRef();
  }

  componentDidMount() {
    this.props.navigation.setOptions({
      headerTitle: this.Render_HeaderTitle,
    });

    this.props.navigation.addListener("focus", this.OnFocus);
    this.props.navigation.addListener("blur", this.OnBlur);
    kcAccountLDFModel.AccountLDFUpdateEvent_Add(this.OnAccountLDFUpdate);
  }

  componentWillUnmount() {
    this.props.navigation.removeListener("focus", this.OnFocus);
    this.props.navigation.removeListener("blur", this.OnBlur);
    kcAccountLDFModel.AccountLDFUpdateEvent_Remove(this.OnAccountLDFUpdate);
    kcSetUnmount(this, true);
  }

  private OnFocus = () => {
    kcSetState(this, { bIsScreenFocus: true });
    this.GetAccount_async();
  };

  private OnBlur = () => {
    kcSetState(this, { bIsScreenFocus: false });
  };

  private OnAccountLDFUpdate: OnAccountLDFUpdate_Event = (
    _mdSender: kcAccountLDFModel,
    _szAccount: string,
    _Type: AccountLDFUpdateType,
    _Param1: any,
    _Param2: any
  ) => {
    switch (_Type) {
      case AccountLDFUpdateType.HistoryTrade:
      case AccountLDFUpdateType.HistoryTradeUpdate: {
        this.mlHistoryTradedata = _mdSender.mlHistory;
        this.UpdateHistoryRange(
          _mdSender.mlHistory,
          this.state.sTime,
          this.state.eTime
        );
        break;
      }
    }
  };

  private GetAccount_async = async () => {
    let mdAccount = kcData.AccountLDF();
    if (!mdAccount) {
      setTimeout(this.GetAccount_async, 100);
      return;
    }

    this.OnAccountLDFUpdate(
      mdAccount,
      mdAccount.Account,
      AccountLDFUpdateType.HistoryTrade,
      undefined,
      undefined
    );
    this.FixedRedrawUI();
  };

  private UpdateHistoryRange = (
    _mlAllData: kcHistoryTradeLDFModel[],
    _sTime: Moment,
    _eTime: Moment
  ) => {
    let mlInView: kcHistoryTradeLDFModel[] = [];
    let sTime = _sTime.startOf("day");
    let eTime = _eTime.endOf("day");

    _mlAllData.forEach((md) => {
      let TimeOut = md.TimeOut;
      if (TimeOut.isBefore(sTime) || TimeOut.isAfter(eTime)) return;
      mlInView.push(md);
    });

    kcSetState(this, {
      mlHistoryTradedata_InView: mlInView,
    });
  };

  private CheckCanUpdate = () => {
    let Ret = this.state.bIsScreenFocus && !this.m_bAnyRowAnimateding;
    return Ret;
  };

  private FixedRedrawUI = () => {
    this.nFixedRedraw = this.nFixedRedraw + 1;
    kcSetState(this, { nFixedRedraw: this.nFixedRedraw }); // 強制更動畫面
  };

  private ShowDateRangeModal = () => {
    this.KC_DateRangeModal.current.Show(this.state.sTime, this.state.eTime);
  };

  /* ------------------------------------------------------------- */
  // On
  private OnCanUpdate = () => {
    return this.CheckCanUpdate();
  };

  private OnRowAnimatedingChange = (
    _mdHistoryTrade: kcHistoryTradeLDFModel,
    _IsAnimateding: boolean
  ) => {
    this.m_dicRowAnimateding.set(
      _mdHistoryTrade.TradeIdx.toString(),
      _IsAnimateding
    );
    if (_IsAnimateding) {
      this.m_bAnyRowAnimateding = true;
    } else {
      let bAllReady = true;
      this.m_dicRowAnimateding.forEach((_Ising) => {
        if (_Ising) bAllReady = false;
      });
      if (!bAllReady) return;
      this.m_bAnyRowAnimateding = false;
      this.FixedRedrawUI();
    }
  };

  private OnDateRangeChange = (_sTime: Moment, _eTime: Moment) => {
    let sTime;
    let eTime;
    if (_sTime <= _eTime) {
      sTime = _sTime;
      eTime = _eTime;
    } else {
      sTime = _eTime;
      eTime = _sTime;
    }
    kcSetState(this, { sTime: sTime, eTime: eTime });
    this.UpdateHistoryRange(this.mlHistoryTradedata, sTime, _eTime);
  };

  /* ------------------------------------------------------------- */
  // Render
  private Render_HeaderTitle = () => {
    return (
      <View
        style={{
          backgroundColor: "#00000000",
          //flex: 1,
          width: 300,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            backgroundColor: "#00000000",
            color: kcColor("Title"),
            fontSize: 18,
          }}
          selectable={false}
        >
          {"歷史"}
        </Text>
      </View>
    );
  };

  private Render_EmptyICon = () => {
    return (
      <View
        style={{
          backgroundColor: "#00000000",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <MaterialCommunityIcons
          selectable={false}
          name="file-clock-outline"
          size={100}
          color={kcColor("ListTitleBackground")}
        />
        <Text style={{ color: kcColor("ListIcon") }} selectable={false}>
          所選時間區間無交易紀錄
        </Text>
      </View>
    );
  };

  render() {
    const szSTime = this.state.sTime.format("YYYY-MM-DD");
    const szETime = this.state.eTime.format("YYYY-MM-DD");

    let bEmpty =
      this.state.mlHistoryTradedata_InView === undefined ||
      this.state.mlHistoryTradedata_InView.length == 0;

    return (
      <>
        <SafeAreaView
          style={{
            backgroundColor: kcColor("Background"),
            flex: 1,
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <KC_DateRangeModal
            ref={this.KC_DateRangeModal}
            OnTimeRangeChange={this.OnDateRangeChange}
          />
          <View
            style={{
              backgroundColor: kcColor("TopButtomBackground"),
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              height: 24,
            }}
          >
            <Text selectable={false} style={{ color: kcColor("Yellow") }}>
              {szSTime}
            </Text>
            <Text selectable={false} style={{ color: kcColor("SubTitle") }}>
              {" 至 "}
            </Text>
            <Text selectable={false} style={{ color: kcColor("Yellow") }}>
              {szETime}
            </Text>
            <Button
              style={{
                backgroundColor: "#00000000",
                marginLeft: 10,
                marginRight: 10,
              }}
              containerStyle={{
                alignItems: "center",
                justifyContent: "center",
              }}
              buttonStyle={{
                backgroundColor: kcColor("Blue"),
                borderRadius: 8,
                height: 20,
              }}
              onPress={() => {
                this.ShowDateRangeModal();
              }}
              titleStyle={{ fontSize: 14 }}
              title={"更換 "}
              icon={
                <Ionicons
                  name="ios-caret-down"
                  size={12}
                  color={kcColor("Icon")}
                  selectable={false}
                />
              }
              iconRight={true}
            />
          </View>
          <View style={{ height: 1, backgroundColor: kcColor("Border") }} />

          {bEmpty && [
            <KC_HistoryScreenAccInfo
              mlHistoryTrade={this.state.mlHistoryTradedata_InView}
              sTime={this.state.sTime}
              eTime={this.state.eTime}
              delCanUpdate={this.OnCanUpdate}
            />,
            <View
              style={{
                backgroundColor: kcColor("Background"),
                flexDirection: "column",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {this.Render_EmptyICon()}

              {/* <View style={{ height: 0, marginBottom: 80 }} /> */}
            </View>,
          ]}

          {!bEmpty && (
            <ScrollView style={{ backgroundColor: kcColor("Background") }}>
              <KC_HistoryScreenAccInfo
                mlHistoryTrade={this.state.mlHistoryTradedata_InView}
                sTime={this.state.sTime}
                eTime={this.state.eTime}
                delCanUpdate={this.OnCanUpdate}
              />

              {this.state.mlHistoryTradedata_InView.map((md) => {
                return (
                  <KC_HistoryScreenRow
                    mdHistoryTrade={md}
                    key={"HistroyRow" + md.TradeIdx}
                    delCanUpdate={this.OnCanUpdate}
                    OnAnimatedingChange={this.OnRowAnimatedingChange}
                  />
                );
              })}
              <View style={{ height: 0, marginBottom: 80 }} />
            </ScrollView>
          )}
        </SafeAreaView>
      </>
    );
  }
}

const styles = StyleSheet.create({});

export default Screen;
