import { CompressionExtensions, StorageHelper } from "../../kcExternal";
import TECPSetting from "./TECPSetting";
import { Buffer } from "buffer";

const StorageSaveKey = "TS";
const SecretData: boolean = true; // 加密Storage存檔的值(目前只有壓縮)

export default class TECPSettingHelper {
  public static CreateDefault() {
    let mdDefault = new TECPSetting({});

    mdDefault.KLine_Ma0.ToDefault(0);
    mdDefault.KLine_Ma1.ToDefault(1);
    mdDefault.KLine_Ma2.ToDefault(2);
    mdDefault.KLine_Ma3.ToDefault(3);

    return mdDefault;
  }

  public static SaveSetting(_Setting: TECPSetting) {
    this.Save(_Setting)
      .then(() => {})
      .catch(() => {})
      .finally(() => {});
  }
  public static async ReadSetting() {
    let mdDefault = this.CreateDefault();
    let mdRead = await this.Read();
    if (mdRead) {
      mdDefault.SetSetting(mdRead);
    }
    return mdDefault;
  }

  /* ------------------------------------------------------------------------- */
  // private
  private static async Save(_Setting: TECPSetting) {
    let Base64String = "";
    if (SecretData) {
      /* ------------------------------------------- */
      // 壓縮版本
      let szJson = JSON.stringify(_Setting); // AccountModel => JSON string
      let ZipBuffer = Buffer.from(
        CompressionExtensions.Zlib_Zip_byString(szJson)
      ); // JSON string => 壓縮Buffer
      Base64String = ZipBuffer.toString("base64"); // 壓縮Buffer => Base64編碼
    } else {
      /* ------------------------------------------- */
      // 純文字版本
      Base64String = JSON.stringify(_Setting);
    }
    return StorageHelper.SaveData(StorageSaveKey, Base64String);
  }
  private static async Read() {
    try {
      let Base64String = await StorageHelper.ReadData(StorageSaveKey); // 讀取 Base64編碼資料

      if (Base64String) {
        let mdSetting: TECPSetting;

        // 檢查檔案內容是否加密, 目前用JSon開頭 {" 這兩個字元簡單判斷
        let IsSecretData = true;
        if (
          Base64String.length >= 2 &&
          Base64String[0] === "{" &&
          Base64String[1] === '"'
        )
          IsSecretData = false;

        if (IsSecretData) {
          /* ------------------------------------------- */
          // 壓縮版本
          let ZipBuffer = Buffer.from(Base64String, "base64"); // Base64編碼 => 壓縮Buffer
          let szJson = CompressionExtensions.Zlib_UnZip_toString(ZipBuffer); // 壓縮Buffer => JSON string
          mdSetting = JSON.parse(szJson); // JSON string => Setting
        } else {
          /* ------------------------------------------- */
          // 純文字版本
          mdSetting = JSON.parse(Base64String);
        }
        return mdSetting;
      } else return undefined;
    } catch (e) {
      return undefined;
    }
  }
}
