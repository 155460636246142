import * as React from "react";
import {
  StyleSheet,
  ScrollView,
  View,
  Dimensions,
  ScaledSize,
  SafeAreaView,
} from "react-native";
import { Text } from "../components/Themed";
import { kcData } from "../kcData";
import { kcSetState, kcSetUnmount } from "../kcExternal";
import { Button, Icon, ListItem, SearchBar } from "react-native-elements";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { TabQuoteParamList } from "../types";
import { kcCommodityModel } from "../kcModel";
import { Ionicons } from "@expo/vector-icons";
import RowValue from "../components/kcRowValue";
import { kcColorObj, kcColor } from "../constants/Colors";

type IState = {
  Commodity?: kcCommodityModel;
  DimensionsWindow: ScaledSize;
};

type IProp = {
  route: RouteProp<TabQuoteParamList, "TabQuoteScreen_Info">;
  navigation: StackNavigationProp<TabQuoteParamList, "TabQuoteScreen_Info">;
};

class QuoteScreen_Info extends React.Component<IProp, IState> {
  state: IState = {
    DimensionsWindow: Dimensions.get("window"),
  };

  StockCode: string = "XAUUSD";

  componentDidMount() {
    this.props.navigation.addListener("focus", this.OnFocus);
    this.props.navigation.addListener("blur", this.OnBlur);
    Dimensions.addEventListener("change", this.OnDimensionsChange);
    this.props.navigation.setOptions({
      headerTitle: this.Render_HeaderTitle,
      headerLeft: this.Render_HeaderLeft,
      // headerTitleContainerStyle: {
      //   marginLeft: 50,
      //   marginRight: 5,
      //   flex: 1,
      // },
    });
  }

  componentWillUnmount() {
    this.props.navigation.removeListener("focus", this.OnFocus);
    this.props.navigation.removeListener("blur", this.OnBlur);
    Dimensions.removeEventListener("change", this.OnDimensionsChange);
    kcSetUnmount(this, true);
  }
  shouldComponentUpdate(nextProps: IProp, nextState: IState) {
    return true;
  }

  private OnFocus = () => {
    let szCommodity = "XAUUSD";
    let Params = this.props.route.params;
    if (Params && Params.StockCode) szCommodity = Params.StockCode;

    this.StockCode = szCommodity;

    let Commodity = kcData.GetCommodity(szCommodity);
    if (!Commodity) {
      setTimeout(() => {
        kcSetState(this, { Commodity: kcData.GetCommodity(szCommodity) });
      }, 500);
    } else kcSetState(this, { Commodity: kcData.GetCommodity(szCommodity) });
  };

  private OnBlur = () => {};

  private OnDimensionsChange = ({
    window,
    screen,
  }: {
    window: ScaledSize;
    screen: ScaledSize;
  }) => {
    this.setState({ DimensionsWindow: window });
  };

  private Render_HeaderLeft = () => {
    return (
      <Button
        // style={{ width: 48, height: 48 }}
        icon={
          <Ionicons
            selectable={false}
            name="ios-chevron-back"
            size={24}
            color={kcColor("Icon")}
          />
        }
        iconContainerStyle={{ alignSelf: "center" }}
        buttonStyle={styles.button}
        onPress={() => {
          this.props.navigation.pop();
          // if (this.props.navigation.canGoBack()) this.props.navigation.goBack();
          // else this.props.navigation.navigate("TabQuoteScreen");
        }}
      />
    );
  };

  private Render_HeaderTitle = () => {
    return (
      <View
        style={{
          backgroundColor: "#00000000",
          //flex: 1,
          width: 300,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            backgroundColor: "#00000000",
            color: kcColor("Title"),
            fontSize: 18,
          }}
          selectable={false}
        >
          {"商品規格"}
        </Text>
      </View>
    );
  };

  render() {
    let { Commodity } = this.state;
    if (!Commodity) return <></>;

    const EffectiveHeigh = this.state.DimensionsWindow.height - 60;

    return (
      <SafeAreaView
        style={{
          backgroundColor: kcColor("Background"),
          height: EffectiveHeigh,
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <ScrollView
          style={{
            backgroundColor: "#00000000",
            paddingRight: 6,
            flex: 1,
          }}
        >
          <View style={[styles.SubView, { backgroundColor: "#00000000" }]}>
            {/* <Text style={[styles.ViewTitle, Colorstyles("Title")]}>商品</Text> */}
            <RowValue
              TitleStyles={[styles.SubTitle, kcColorObj("Title")]}
              ValueStyles={[styles.SubValue, kcColorObj("Value")]}
              Title=""
              Value={Commodity.StockCode}
            ></RowValue>
            <View style={styles.Border} />
            <RowValue
              TitleStyles={[styles.SubTitle, kcColorObj("Title")]}
              ValueStyles={[styles.SubValue, kcColorObj("Value")]}
              Title=""
              Value={Commodity.StockName}
            ></RowValue>
          </View>

          <Text
            selectable={false}
            style={[styles.ViewTitle, kcColorObj("Value")]}
          >
            合約
          </Text>
          <View style={[styles.SubView]}>
            <RowValue
              TitleStyles={[styles.SubTitle, kcColorObj("Title")]}
              ValueStyles={[styles.SubValue, kcColorObj("Value")]}
              Title="合約大小"
              Value={Commodity.ContractSize}
            ></RowValue>
            <View style={styles.Border} />
            <RowValue
              TitleStyles={[styles.SubTitle, kcColorObj("Title")]}
              ValueStyles={[styles.SubValue, kcColorObj("Value")]}
              Title="點差"
              Value={"浮動"}
            ></RowValue>
            <View style={styles.Border} />
            <RowValue
              TitleStyles={[styles.SubTitle, kcColorObj("Title")]}
              ValueStyles={[styles.SubValue, kcColorObj("Value")]}
              Title="小數點位數"
              Value={Commodity.FloatNum}
            ></RowValue>
            <View style={styles.Border} />
            <RowValue
              TitleStyles={[styles.SubTitle, kcColorObj("Title")]}
              ValueStyles={[styles.SubValue, kcColorObj("Value")]}
              Title="最小跳動點數"
              Value={Commodity.PriceScale[0].TickPrice.toFixed(
                Commodity.FloatNum
              )}
            ></RowValue>
          </View>

          <Text
            selectable={false}
            style={[styles.ViewTitle, kcColorObj("Value")]}
          >
            交易
          </Text>
          <View style={[styles.SubView]}>
            <RowValue
              TitleStyles={[styles.SubTitle, kcColorObj("Title")]}
              ValueStyles={[styles.SubValue, kcColorObj("Value")]}
              Title="最少交易手數"
              Value="0.01"
            ></RowValue>
            <View style={styles.Border} />
            <RowValue
              TitleStyles={[styles.SubTitle, kcColorObj("Title")]}
              ValueStyles={[styles.SubValue, kcColorObj("Value")]}
              Title="最大交易手數"
              Value="無限制"
            ></RowValue>
            <View style={styles.Border} />
            <RowValue
              TitleStyles={[styles.SubTitle, kcColorObj("Title")]}
              ValueStyles={[styles.SubValue, kcColorObj("Value")]}
              Title="最大持倉手數"
              Value="無限制"
            ></RowValue>
            <View style={styles.Border} />
            <RowValue
              TitleStyles={[styles.SubTitle, kcColorObj("Title")]}
              ValueStyles={[styles.SubValue, kcColorObj("Value")]}
              Title="買入庫存費 :"
              Value={Commodity.SwapLong}
            ></RowValue>
            <View style={styles.Border} />
            <RowValue
              TitleStyles={[styles.SubTitle, kcColorObj("Title")]}
              ValueStyles={[styles.SubValue, kcColorObj("Value")]}
              Title="賣出庫存費 :"
              Value={Commodity.SwapShort}
            ></RowValue>
          </View>
        </ScrollView>
        <View style={{ backgroundColor: "#00000000", height: 40 }} />
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: "#00000000",
    borderWidth: 0,
    borderRadius: 15,
  },
  MainTitle: {
    fontSize: 16,
    margin: 5,
  },
  MainSubTitle: {
    fontSize: 14,
    margin: 5,
    //textAlign: "right",
  },
  ViewTitle: {
    fontSize: 14,
    marginLeft: 25,
    marginBottom: 0,
  },
  SubView: {
    margin: 15,
    marginTop: 3,
    flexDirection: "column",
    //alignItems: "center",
    borderRadius: 10,
    backgroundColor: kcColor("ListTitleBackground"),
  },
  SubTitle: {
    fontSize: 16,
    margin: 15,
    backgroundColor: "#00000000",
  },
  SubValue: {
    fontSize: 16,
    margin: 15,
    backgroundColor: "#00000000",
    textAlign: "right",
  },
  Border: {
    height: 1,
    backgroundColor: kcColor("Border"),
  },
});

export default QuoteScreen_Info;
