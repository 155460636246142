import {
  kcTickModel,
  kcLastInfoModel,
  kcCommodityModel,
  kcHistoryOHLCModel,
} from "../kcModel";
import kcHistoryCollection, {
  delOnHistoryChanged,
} from "./kcHistoryCollection";
import { HistoryDataType } from "../kcTransfer/InternalDefine";

export const MaxTickBufferSize = 100;
// export type HistoryCallback = (
//   _mdkcUnit: kcUnitModel,
//   _HistoryCollection: kcHistoryCollection
// ) => void;

export default class kcUnitModel {
  constructor(
    _StockCode: string,
    _Commodity?: kcCommodityModel,
    _LastInfo?: kcLastInfoModel,
    _PointDiff?: number
  ) {
    this.StockCode = _StockCode;
    this.Commodity = _Commodity;
    this.m_LastInfo_Pure = _LastInfo;
    this.PointDiff = _PointDiff ? _PointDiff : 0;

    if (this.m_LastInfo_Pure)
      this.LastInfo = this.m_LastInfo_Pure.CloneAddDiff(this.PointDiff);

    this.m_HistoryTick = new kcHistoryCollection(this, HistoryDataType.Tick);
    this.m_HistoryMin = new kcHistoryCollection(this, HistoryDataType.Minute);
    this.m_HistoryDay = new kcHistoryCollection(this, HistoryDataType.Day);
  }

  StockCode: string;
  Commodity?: kcCommodityModel;
  private m_LastInfo_Pure?: kcLastInfoModel;
  private m_TickBuffer_Pure: kcTickModel[] = [];
  PointDiff: number;

  private m_HistoryTick;
  private m_HistoryMin;
  private m_HistoryDay;

  public get LastInfo_Pure() {
    return this.m_LastInfo_Pure;
  }

  public LastInfo?: kcLastInfoModel; // 有加點差
  public TickBuffer: kcTickModel[] = []; // 有加點差

  public OnLastInfo(_mdLastInfo: kcLastInfoModel) {
    // Update Pure
    if (this.m_LastInfo_Pure)
      this.m_LastInfo_Pure.Update_ByLastInfo(_mdLastInfo);
    else this.m_LastInfo_Pure = _mdLastInfo;

    // Update Add PointDiff
    if (this.LastInfo)
      this.LastInfo.Update_ByLastInfo(_mdLastInfo.CloneAddDiff(this.PointDiff));
    else this.LastInfo = _mdLastInfo.CloneAddDiff(this.PointDiff);
  }

  public OnTick(_mdTick: kcTickModel) {
    // Update Pure
    this.m_LastInfo_Pure?.Update_ByTick(_mdTick);

    // TickBuffer, 留最新1000筆
    if (this.m_TickBuffer_Pure.length >= MaxTickBufferSize)
      this.m_TickBuffer_Pure.shift();
    this.m_TickBuffer_Pure.push(_mdTick);

    // Update Add PointDiff
    let mdTickWithDiff = _mdTick.CloneAddDiff(this.PointDiff);
    this.LastInfo?.Update_ByTick(mdTickWithDiff);

    // TickBuffer, 留最新1000筆
    if (this.TickBuffer.length >= MaxTickBufferSize) this.TickBuffer.shift();
    this.TickBuffer.push(mdTickWithDiff);

    // Update History
    this.m_HistoryTick.Update(_mdTick);
    this.m_HistoryMin.Update(_mdTick);
    this.m_HistoryDay.Update(_mdTick);
  }

  public PointDiffChange(_PointDiff: number) {
    if (!this.Commodity) return;

    let PointDiffValue = _PointDiff * this.Commodity.PriceScale[0].TickPrice;
    if (this.PointDiff === PointDiffValue) return;

    this.PointDiff = PointDiffValue;

    if (this.m_LastInfo_Pure)
      this.LastInfo = this.m_LastInfo_Pure.CloneAddDiff(this.PointDiff);

    this.TickBuffer.length = 0;
    this.TickBuffer.push(
      ...this.m_TickBuffer_Pure.map((v) => v.CloneAddDiff(this.PointDiff))
    );
  }

  public GetAskBidSubString() {
    let BidString = "";
    let BidString_Left = "";
    let BidString_Right = "";
    let AskString = "";
    let AskString_Left = "";
    let AskString_Right = "";
    let FloatNum = 0;

    if (!this.LastInfo || !this.Commodity) {
      return {
        BidString,
        BidString_Left,
        BidString_Right,
        AskString,
        AskString_Left,
        AskString_Right,
        FloatNum,
      };
    }

    let LastInfo = this.LastInfo;
    // let Split = this.Commodity.PriceScale[0].TickPrice.toString().split(".");
    // if (Split.length == 2) FloatNum = Split[1].length;
    FloatNum = this.Commodity.FloatNum;

    BidString = LastInfo.BidPrice.toFixed(FloatNum).toString();
    BidString_Left = BidString.substring(0, BidString.length - 2);
    BidString_Right = BidString.substring(
      BidString.length - 2,
      BidString.length
    );

    AskString = LastInfo.AskPrice.toFixed(FloatNum).toString();
    AskString_Left = AskString.substring(0, AskString.length - 2);
    AskString_Right = AskString.substring(
      AskString.length - 2,
      AskString.length
    );

    return {
      BidString,
      BidString_Left,
      BidString_Right,
      AskString,
      AskString_Left,
      AskString_Right,
      FloatNum,
    };
  }

  public Clear() {
    this.m_TickBuffer_Pure.length = 0;
    this.TickBuffer.length = 0;
    this.ClearHistory();
  }
  /* ----------------------------------------------------------------------------------- */
  // History
  // private m_fHistoryCallback: HistoryCallback | undefined = undefined;
  // private m_GettingHType: HistoryDataType = HistoryDataType.UnDef;
  // public GetHistory(
  //   _HType: HistoryDataType | "Tick" | "Minute" | "Day" | undefined,
  //   _fCallback?: HistoryCallback
  // ): boolean {
  //   if (_fCallback) this.m_fHistoryCallback = _fCallback;

  //   let bRet = true;
  //   if (_HType !== undefined) {
  //     if (_HType === HistoryDataType.Tick || _HType === "Tick") {
  //       this.m_GettingHType = HistoryDataType.Tick;
  //       this.m_HistoryTick.Get();
  //     } else if (_HType === HistoryDataType.Minute || _HType === "Minute") {
  //       this.m_GettingHType = HistoryDataType.Minute;
  //       this.m_HistoryMin.Get();
  //     } else if (_HType === HistoryDataType.Day || _HType === "Day") {
  //       this.m_GettingHType = HistoryDataType.Day;
  //       this.m_HistoryDay.Get();
  //     } else {
  //       bRet = false;
  //     }
  //   }
  //   return bRet;
  // }
  public GetHistory(
    _HType: HistoryDataType | "Tick" | "Minute" | "Day" | undefined
    // _fCallback?: delOnHistoryChanged
  ): kcHistoryCollection | undefined {
    //if (_fCallback) this.m_fHistoryCallback = _fCallback;

    let mdHistoryCollection: kcHistoryCollection | undefined = undefined;
    let bRet = true;
    if (_HType !== undefined) {
      if (_HType === HistoryDataType.Tick || _HType === "Tick") {
        //this.m_GettingHType = HistoryDataType.Tick;
        //this.m_HistoryTick.Get();
        mdHistoryCollection = this.m_HistoryTick;
      } else if (_HType === HistoryDataType.Minute || _HType === "Minute") {
        // this.m_GettingHType = HistoryDataType.Minute;
        // this.m_HistoryMin.Get();
        mdHistoryCollection = this.m_HistoryMin;
      } else if (_HType === HistoryDataType.Day || _HType === "Day") {
        // this.m_GettingHType = HistoryDataType.Day;
        // this.m_HistoryDay.Get();
        mdHistoryCollection = this.m_HistoryDay;
      }
    }
    // if (mdHistoryCollection) mdHistoryCollection.Get(_fCallback);

    return mdHistoryCollection;
  }
  public OnHistory(_HType: HistoryDataType, _mlOHLC: kcHistoryOHLCModel[]) {
    switch (_HType) {
      case HistoryDataType.Tick:
        return this.m_HistoryTick.OnHistoryOHLC(_mlOHLC);
      case HistoryDataType.Minute:
        return this.m_HistoryMin.OnHistoryOHLC(_mlOHLC);
      case HistoryDataType.Day:
        return this.m_HistoryDay.OnHistoryOHLC(_mlOHLC);
    }
  }
  public ClearHistory() {
    this.m_HistoryTick.Clear();
    this.m_HistoryMin.Clear();
    this.m_HistoryDay.Clear();
  }

  // public OnHistoryCollationReady(_HistoryCollection: kcHistoryCollection) {
  //   if (_HistoryCollection.HType === this.m_GettingHType)
  //     this.m_fHistoryCallback?.call(this, this, _HistoryCollection);
  // }
}
