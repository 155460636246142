import React from "react";
import { StyleSheet } from "react-native";
import { kcSetState, kcSetUnmount } from "../kcExternal";
import { kcData, kcUnitModel, MaxTickBufferSize } from "../kcData";
import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryZoomContainer,
  VictoryLabel,
} from "victory";
import { kcColorObj, kcColor } from "../constants/Colors";

import Svg, { Circle, Rect } from "react-native-svg";
import { ObjectExtensions } from "../kcExternal";

type DomainTuple = [number, number] | [Date, Date];
type brushDomain = { x?: DomainTuple; y?: DomainTuple };

type IState = {
  selectedDomain: brushDomain;
  zoomDomain: brushDomain;
  mlAskPrice: { x: number | Date; y: number }[];
  mlBidPrice: { x: number | Date; y: number }[];
};

type IProp = {
  delCanUpdate: () => boolean;
  StockCode: string;
  ChartHeight: number;
  ChartWidth: number;
  InPrice?: number;
  StopLossPrice?: number;
  StopProfitPrice?: number;
};

export default class KC_QuotePB extends React.Component<IProp, IState> {
  state: IState = {
    selectedDomain: {},
    zoomDomain: {},
    mlAskPrice: [],
    mlBidPrice: [],
  };

  /* ------------------------------------------------------------- */
  // Member
  StockCode: string = "";
  mdUnit?: kcUnitModel = undefined;
  PreCanUpdate: boolean = true;

  /* ------------------------------------------------------------- */
  // HookFunc
  componentDidMount() {
    this.ChangeFocusStock(this.props.StockCode);
    kcData.UnitEvent_Add(this.OnUnitDataChanged);
  }

  componentWillUnmount() {
    kcData.UnitEvent_Remove(this.OnUnitDataChanged);
    kcSetUnmount(this, true);
  }

  shouldComponentUpdate(nextProps: IProp, nextState: IState) {
    const CanUpdate = nextProps.delCanUpdate();

    let NeedChangeStock = false;
    if (this.PreCanUpdate != CanUpdate) {
      this.PreCanUpdate = CanUpdate;
      NeedChangeStock = CanUpdate;
    }

    if (this.StockCode != nextProps.StockCode) NeedChangeStock = true;
    if (NeedChangeStock) {
      this.ChangeFocusStock(nextProps.StockCode);
      return false;
    }

    if (!CanUpdate) return false;
    if (!ObjectExtensions.ObjectsEqual(nextState, this.state)) return true;
    if (!ObjectExtensions.ObjectsEqual(nextProps, this.props)) return true;
    return false;
  }

  /* ------------------------------------------------------------- */
  //MainFunc
  ChangeFocusStock = (StockCode: string) => {
    this.StockCode = StockCode;
    this.mdUnit = kcData.GetUnit(StockCode);
    kcSetState(this, { mlAskPrice: [], mlBidPrice: [] });
    if (this.mdUnit) this.OnUnitDataChanged(this.mdUnit);
  };

  /* ------------------------------------------------------------- */
  //On
  OnUnitDataChanged = (_mdUnit: kcUnitModel) => {
    if (this.StockCode != _mdUnit.StockCode) return;
    if (this.mdUnit == undefined || this.mdUnit.StockCode != _mdUnit.StockCode)
      this.mdUnit = _mdUnit;

    if (_mdUnit.TickBuffer) {
      let aAsk: { x: number | Date; y: number }[] = [];
      let aBid: { x: number | Date; y: number }[] = [];

      _mdUnit.TickBuffer.forEach((md, idx) => {
        aAsk.push({ x: idx, y: md.AskPrice });
        aBid.push({ x: idx, y: md.BidPrice });
      });

      if (_mdUnit.LastInfo)
        aAsk.push({ x: MaxTickBufferSize + 1, y: _mdUnit.LastInfo.AskPrice });
      if (_mdUnit.LastInfo)
        aBid.push({ x: MaxTickBufferSize + 1, y: _mdUnit.LastInfo.BidPrice });

      kcSetState(this, { mlAskPrice: aAsk, mlBidPrice: aBid });
    }
  };

  OnhandleZoom(domain: brushDomain) {
    this.setState({ selectedDomain: domain });
  }

  OnhandleBrush(domain: brushDomain) {
    this.setState({ zoomDomain: domain });
  }

  render() {
    if (this.StockCode == "") return <></>;
    if (!this.mdUnit) return <></>;
    if (!this.mdUnit.LastInfo) return <></>;

    let SpecTickValues = [];
    SpecTickValues.push(this.mdUnit.LastInfo.AskPrice);
    SpecTickValues.push(this.mdUnit.LastInfo.BidPrice);
    if (this.props.InPrice) SpecTickValues.push(this.props.InPrice);
    if (this.props.StopLossPrice) SpecTickValues.push(this.props.StopLossPrice);
    if (this.props.StopProfitPrice)
      SpecTickValues.push(this.props.StopProfitPrice);

    return (
      <VictoryChart
        width={this.props.ChartWidth}
        height={this.props.ChartHeight}
        scale={{ x: "time" }}
        containerComponent={
          <VictoryZoomContainer
            disable={true}
            responsive={false}
            zoomDimension="x"
            zoomDomain={this.state.zoomDomain}
            onZoomDomainChange={this.OnhandleZoom.bind(this)}
          />
        }
        domainPadding={{ x: [0, 0], y: 5 }}
        padding={{ top: 5, bottom: 5, left: 5, right: 60 }}
      >
        <VictoryAxis
          dependentAxis // 垂直
          orientation="left"
          style={Borderstyles} // 左邊框顏色
        />

        <VictoryAxis
          orientation="top"
          style={Borderstyles} // 上邊框顏色
        />

        <VictoryAxis
          dependentAxis // 垂直
          crossAxis
          orientation="right"
          style={{
            ...Borderstyles, // 右邊框顏色
            ...BorderToLabelstyles, //右邊框 連線到 label之間一小段的顏色 與長度
            ...Labelstyles,
            ...Gridstyles, // 內部水平格線顏色
          }}
          tickFormat={(y) => y}
          tickValues={[]}
        />

        <VictoryAxis
          dependentAxis // 垂直
          orientation="right"
          style={{
            ...Borderstyles, // 右邊框顏色
            ...BorderToLabelstyles, //右邊框 連線到 label之間一小段的顏色 與長度
          }}
          tickValues={SpecTickValues}
          tickLabelComponent={
            <CustomLabel
              style={{ fontSize: 14 }}
              AskPrice={this.mdUnit.LastInfo.AskPrice}
              BidPrice={this.mdUnit.LastInfo.BidPrice}
              InPrice={this.props.InPrice}
              StopLossPrice={this.props.StopLossPrice}
              StopProfitPrice={this.props.StopProfitPrice}
            />
          }
        />

        <VictoryAxis
          orientation="bottom"
          style={{
            ...Borderstyles, // 下邊框顏色
            ...BorderToLabelstyles, // 下邊框 連線到 label之間一小段的顏色 與長度
            ...Labelstyles,
            //...Gridstyles, // 內部垂直格線顏色
          }}
          //tickFormat={(x) => new Date(x).getFullYear()}
          //tickValues={[]}
          tickLabelComponent={<></>} // 不顯示下tickLabel
        />
        <VictoryLine
          style={{
            data: { stroke: kcColor("BuySell", "Sell") },
          }}
          data={this.state.mlAskPrice}
        />
        <VictoryLine
          style={{
            data: { stroke: kcColor("BuySell", "Buy") },
          }}
          data={this.state.mlBidPrice}
        />
        {this.props.InPrice && (
          <VictoryLine
            style={{
              data: { stroke: kcColor("InPrice") },
            }}
            data={[
              { x: 0, y: this.props.InPrice },
              { x: MaxTickBufferSize + 1, y: this.props.InPrice },
            ]}
          />
        )}
        {this.props.StopLossPrice && (
          <VictoryLine
            style={{
              data: { stroke: kcColor("StopLoss") },
            }}
            data={[
              { x: 0, y: this.props.StopLossPrice },
              { x: MaxTickBufferSize + 1, y: this.props.StopLossPrice },
            ]}
          />
        )}
        {this.props.StopProfitPrice && (
          <VictoryLine
            style={{
              data: { stroke: kcColor("StopProfit") },
            }}
            data={[
              { x: 0, y: this.props.StopProfitPrice },
              { x: MaxTickBufferSize + 1, y: this.props.StopProfitPrice },
            ]}
          />
        )}
      </VictoryChart>
    );
  }
}

const Borderstyles = { axis: { stroke: "#00000000" } };
const BorderToLabelstyles = {
  ticks: { stroke: "#00000000", size: 0 },
};
const Labelstyles = {
  tickLabels: {
    stroke: "#00000000", // 邊框顏色 不能開 會糊糊的
    fill: kcColor("Border"), // 填滿顏色
    padding: 5,
    fontSize: 14,
    //fontFamily:
  },
};
const Gridstyles = {
  grid: { stroke: kcColor("Border"), strokeDasharray: "3,3" },
};

const styles = StyleSheet.create({
  Row_Flex_1: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#00000000",
  },
  Col_Flex_1: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#00000000",
  },
});

const CustomLabel = (props: any) => {
  let BuySell = "Sell";
  if (props.AskPrice) {
    if (props.AskPrice != props.text) BuySell = "Buy";
  }

  let Color = kcColor("BuySell", BuySell);

  if (props.InPrice && props.InPrice == props.text) Color = kcColor("InPrice");
  if (props.StopLossPrice && props.StopLossPrice == props.text)
    Color = kcColor("StopLoss");
  if (props.StopProfitPrice && props.StopProfitPrice == props.text)
    Color = kcColor("StopProfit");

  return (
    <>
      <Rect
        x={props.x - 10}
        y={props.y - 7}
        width={59}
        height={props.style.fontSize}
        stroke={Color}
        fill={Color}
        strokeWidth={0}
      />
      <VictoryLabel
        x={props.x - 5}
        y={props.y}
        style={{
          fill: kcColor("Background"),
          //fontFamily:
          fontSize: props.style.fontSize,
        }}
        text={props.text}
      />
    </>
  );
};
