import * as React from "react";
import { StyleSheet, Pressable, Animated } from "react-native";
import { Text, View } from "./Themed";
import { kcSetState, kcSetUnmount } from "../kcExternal";
import { kcData, kcUnitModel } from "../kcData";
import { ObjectExtensions } from "../kcExternal";
import { kcAccountLDFModel, kcInventoryLDFModel } from "../kcData";
import {
  AccountLDFUpdateType,
  OnAccountLDFUpdate_Event,
  OnInventoryUpdate_Event,
} from "../kcData/kcAccountLDFModel";
import { kcColorObj, kcColor } from "../constants/Colors";
import { Ionicons } from "@expo/vector-icons";
import KC_TradeScreenRowItem from "../components/KC_TradeScreenRowItem";

import { MaterialIcons } from "@expo/vector-icons";
import { OrderScreenDisplayType } from "../kcData/OrderParamHelper";
type IState = {
  nFixedRedraw: number; // 笨方式 用來強制繪圖
  mlTradeIdx: number[];
  ThisStockProfit: number;
  ThisStockHVol: number;
};

type IProp = {
  StockCode: string;
  OnPress?: (_StockCode: string, _bIsLongPress: boolean) => void;
  OnOrder?: (
    _StockCode: string,
    _Type: OrderScreenDisplayType,
    _TraderIdx?: number
  ) => void;
  OnAnimatedingChange?: (_StockCode: string, _IsAnimateding: boolean) => void;
  delCanUpdate: () => boolean;
};

const DefaultHeigh = 0;
const EachItemHeigh = 80;
const DefaultTime = 200;
const EachItemTime = 0;

export default class KC_TradeScreenRow extends React.Component<IProp, IState> {
  state: IState = {
    nFixedRedraw: 0, // 笨方式 用來強制繪圖
    mlTradeIdx: [],
    ThisStockProfit: 0,
    ThisStockHVol: 0,
  };

  /* ------------------------------------------------------------- */
  // Member
  //mlInventory: kcInventoryLDFModel[] = [];
  nFixedRedraw: number = 0;
  StockCode: string = "";
  PreCanUpdate: boolean = true;
  bIsClose: boolean = true;
  Animateding: boolean = false;
  AnimatedValue: any = new Animated.Value(0);

  /* ------------------------------------------------------------- */
  // HookFunc
  componentDidMount() {
    this.ChangeFocusStock(this.props.StockCode);
    this.GetAccount_async();
    this.AnimatedValue.addListener(this.OnAnimatedValueChange);
    kcAccountLDFModel.AccountLDFUpdateEvent_Add(this.OnAccountLDFUpdate);
  }

  componentWillUnmount() {
    this.AnimatedValue.removeAllListeners();
    kcAccountLDFModel.AccountLDFUpdateEvent_Remove(this.OnAccountLDFUpdate);
    kcSetUnmount(this, true);
  }

  shouldComponentUpdate(nextProps: IProp, nextState: IState) {
    const CanUpdate = nextProps.delCanUpdate();

    if (this.PreCanUpdate != CanUpdate) {
      this.PreCanUpdate = CanUpdate;
      if (CanUpdate) {
        this.GetAccount_async();
        return false;
      }
    }

    if (!CanUpdate) return false;
    if (!ObjectExtensions.ObjectsEqual(nextState, this.state)) return true;
    if (!ObjectExtensions.ObjectsEqual(nextProps, this.props)) return true;
    return false;
  }

  /* ------------------------------------------------------------- */
  private ChangeFocusStock = (StockCode: string) => {
    this.StockCode = StockCode;
  };

  private GetAccount_async = async () => {
    let mdAccount = kcData.AccountLDF();
    if (!mdAccount) {
      setTimeout(this.GetAccount_async, 100);
      return;
    }

    this.OnAccountLDFUpdate(
      mdAccount,
      mdAccount.Account,
      AccountLDFUpdateType.Inventory,
      mdAccount.mlInventory,
      undefined
    );
    this.FixedRedrawUI();
  };

  private FixedRedrawUI = () => {
    this.nFixedRedraw = this.nFixedRedraw + 1;
    kcSetState(this, { nFixedRedraw: this.nFixedRedraw }); // 強制更動畫面
  };

  private AnimatedZoomIn = () => {
    this.AnimatedStateChange(false);
    const Cnt = this.state.mlTradeIdx.length;
    const ItemsTime = Cnt * EachItemTime;
    Animated.timing(this.AnimatedValue, {
      toValue: 0,
      duration: DefaultTime + ItemsTime,
      useNativeDriver: false,
    }).start();
  };

  private AnimatedZoomOut = () => {
    this.AnimatedStateChange(false);
    const Cnt = this.state.mlTradeIdx.length;
    const ItemsTime = Cnt * EachItemTime;
    Animated.timing(this.AnimatedValue, {
      toValue: 100,
      duration: DefaultTime + ItemsTime,
      useNativeDriver: false,
    }).start();
  };

  private AnimatedStateChange = (_bEnd: boolean) => {
    if (_bEnd) {
      this.Animateding = false;
    } else {
      if (this.Animateding) return;
      this.Animateding = true;
    }
    if (!this.props.OnAnimatedingChange) return;
    this.props.OnAnimatedingChange(this.StockCode, this.Animateding);
  };

  /* ------------------------------------------------------------- */
  // On
  private OnAccountLDFUpdate: OnAccountLDFUpdate_Event = (
    _mdSender: kcAccountLDFModel,
    _szAccount: string,
    _Type: AccountLDFUpdateType,
    _Param1: any,
    _Param2: any
  ) => {
    let mlInventory_Param: kcInventoryLDFModel[];

    switch (_Type) {
      case AccountLDFUpdateType.Quote: {
        const StockCode: string = _Param2 as string;
        if (StockCode != this.StockCode) return;
        mlInventory_Param = _Param1 as kcInventoryLDFModel[];
        break;
      }
      case AccountLDFUpdateType.Inventory: {
        mlInventory_Param = _mdSender.mlInventory as kcInventoryLDFModel[];
        break;
      }
      default:
        return;
    }

    let mlTradeIdx: number[] = [];
    let mlTradeIdx_Try: number[] = [];
    let ThisStockProfit: number = 0;
    let ThisStockHVol: number = 0;

    mlInventory_Param.forEach((md) => {
      if (md.StockCode != this.StockCode) return;
      if (md.BuySellType == "Buy" || md.BuySellType == "Sell") {
        mlTradeIdx.push(md.TradeIdx);
        ThisStockProfit = ThisStockProfit + md.ProfitMoney;
        ThisStockHVol = ThisStockHVol + md.VolHand;
      } else mlTradeIdx_Try.push(md.TradeIdx);
    });

    // TryIn放置下面
    mlTradeIdx_Try.forEach((n) => {
      mlTradeIdx.push(n);
    });

    kcSetState(this, {
      mlTradeIdx: mlTradeIdx,
      ThisStockProfit: ThisStockProfit,
      ThisStockHVol: ThisStockHVol,
    });
  };

  private OnPress = (_bIsLong: boolean) => {
    if (this.bIsClose) this.AnimatedZoomOut();
    else this.AnimatedZoomIn();

    this.bIsClose = !this.bIsClose;
  };

  private OnAddPress = () => {
    if (!this.props.OnOrder) return;
    this.props.OnOrder(this.StockCode, "New", undefined);
  };

  private OnSubItemPress = (
    _TradeIdx: number,
    _Type: OrderScreenDisplayType
  ) => {
    //console.log("OnSubItemPress");
    if (!this.props.OnOrder) return;
    this.props.OnOrder(this.StockCode, _Type, _TradeIdx);
  };

  private OnAnimatedValueChange = ({ value }: any) => {
    if (this.bIsClose && value != 0) return;
    if (!this.bIsClose && value != 100) return;

    this.AnimatedStateChange(true);
  };

  /* ------------------------------------------------------------- */
  render() {
    if (this.StockCode == "" || this.state.mlTradeIdx.length == 0) return <></>;

    const Cnt = this.state.mlTradeIdx.length;
    const ItemsHeigh = Cnt * EachItemHeigh;
    let AnimatedItemsHeigh = this.AnimatedValue.interpolate({
      inputRange: [0, 100],
      outputRange: [DefaultHeigh, DefaultHeigh + ItemsHeigh],
    });
    let AnimatedItemsOpacity = this.AnimatedValue.interpolate({
      inputRange: [0, 100],
      outputRange: [0, 1],
    });
    let AnimatedIconRotate = this.AnimatedValue.interpolate({
      inputRange: [0, 100],
      outputRange: ["0deg", "-180deg"],
    });
    let Comm = kcData.GetCommodity(this.StockCode);

    return (
      <View
        style={{
          flexDirection: "column",
          backgroundColor: kcColor("ListTitleBackground"),
        }}
      >
        <View
          style={{
            flexDirection: "row",
            flex: 1,
            alignItems: "center",
            backgroundColor: "#00000000",
          }}
        >
          <Pressable
            style={styles.MainView}
            onLongPress={() => this.OnPress(true)}
            onPress={() => this.OnPress(false)}
          >
            <Animated.View
              style={{
                marginLeft: 10,
                marginRight: 10,
                transform: [{ rotate: AnimatedIconRotate }],
              }}
            >
              <MaterialIcons
                name="arrow-drop-down"
                size={24}
                color={kcColor("Icon")}
                selectable={false}
              />
            </Animated.View>

            <View style={styles.LeftView}>
              <Text
                selectable={false}
                style={[styles.TextXs, kcColorObj("Yellow")]}
              >
                {this.props.StockCode}
              </Text>
              <Text
                selectable={false}
                style={[styles.TextSmall, kcColorObj("Title")]}
                numberOfLines={1}
              >
                {Comm?.StockName}
              </Text>
            </View>
            <View style={styles.RightView}>
              <Text
                selectable={false}
                style={[
                  styles.TextNormal,
                  kcColorObj("Value", this.state.ThisStockProfit),
                ]}
              >
                {"$" + this.state.ThisStockProfit.toFixed(2)}
              </Text>
              <Text
                selectable={false}
                style={[styles.TextSmall, kcColorObj("Value")]}
              >
                總手數: {this.state.ThisStockHVol.toFixed(2)}
              </Text>
            </View>
          </Pressable>
          <Ionicons
            name="ios-add"
            size={24}
            color={kcColor("Icon")}
            style={{ marginLeft: 10, marginRight: 10 }}
            onPress={() => this.OnAddPress()}
            selectable={false}
          />
        </View>
        <Animated.View
          style={{
            height: AnimatedItemsHeigh,
            overflow: "hidden",
            opacity: AnimatedItemsOpacity,
          }}
        >
          {this.state.mlTradeIdx.map((_TradeIdx) => {
            return (
              <KC_TradeScreenRowItem
                TradeIdx={_TradeIdx}
                key={"TradeRow" + _TradeIdx}
                delCanUpdate={this.props.delCanUpdate}
                OnItemPress={this.OnSubItemPress}
              />
            );
          })}
        </Animated.View>

        <View
          style={{
            height: 1,
            backgroundColor: kcColor("Border"),
            marginTop: -1, // 故意蓋住最後一條線
          }}
        ></View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  MainView: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: kcColor("ListTitleBackground"),
    //justifyContent: "center",
    alignItems: "center",
  },
  LeftView: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#00000000",
    justifyContent: "center",
    alignItems: "flex-start",
    height: 50,
  },
  RightView: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#00000000",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  ButtonBorder: {
    height: 1,
    backgroundColor: "#303030",
  },
  TextXs: {
    fontSize: 12,
  },
  TextSmall: {
    fontSize: 14,
  },
  TextNormal: {
    fontSize: 16,
    fontWeight: "bold",
  },
  TextBig: {
    fontSize: 24,
    fontWeight: "bold",
  },
});
