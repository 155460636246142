import * as React from "react";
import {
  StyleSheet,
  Dimensions,
  ScaledSize,
  SafeAreaView,
  ScrollView,
  Pressable,
} from "react-native";
import { Text, View } from "../components/Themed";
import { kcSetState, kcSetUnmount } from "../kcExternal";
import { FontAwesome, Ionicons } from "@expo/vector-icons";
import { Button } from "react-native-elements";
import kcData, { RegListHelper } from "../kcData";
import { RegListUpdateParam } from "../kcData/RegListHelper";
import { kcColorObj, kcColor } from "../constants/Colors";
import KC_DraggableList from "../components/KC_DraggableList";
import { RouteProp } from "@react-navigation/native";
import { TabQuoteParamList } from "../types";
import { StackNavigationProp } from "@react-navigation/stack";

type IState = {
  mlStockCode: string[];
  mlCheckStockCode: string[];
  DimensionsWindow: ScaledSize;
  FixedUpdate: number;
  SubItemWidth: number;
  EnableScroll: boolean;
};

type IProp = {
  // route: any;
  // navigation: any;
  route: RouteProp<TabQuoteParamList, "TabQuoteScreen_Modify">;
  navigation: StackNavigationProp<TabQuoteParamList, "TabQuoteScreen_Modify">;
};

class TabQuoteScreen_Modify extends React.Component<IProp, IState> {
  constructor(props: any) {
    super(props);
  }
  state: IState = {
    mlStockCode: [],
    mlCheckStockCode: [],
    DimensionsWindow: Dimensions.get("window"),
    FixedUpdate: 0,
    SubItemWidth: 10,
    EnableScroll: true,
  };

  /* ------------------------------------------------------------- */
  // HookFunc
  componentDidMount() {
    Dimensions.addEventListener("change", this.OnDimensionsChange);
    RegListHelper.RegListUpdateEvent_Add(this.OnRegListChanged);

    let mlStockCode = RegListHelper.GetRegList();
    kcSetState(this, { mlStockCode: mlStockCode });

    this.props.navigation.setOptions({
      headerLeft: this.Render_HeaderLeft,
      headerRight: this.Render_HeaderRight.bind(this, false),
      headerTitle: this.Render_HeaderTitle,
    });
  }

  componentWillUnmount() {
    Dimensions.removeEventListener("change", this.OnDimensionsChange);
    RegListHelper.RegListUpdateEvent_Remove(this.OnRegListChanged);
    kcSetUnmount(this, true);
  }

  shouldComponentUpdate(nextProps: IProp, nextState: IState) {
    return true;
  }
  private FixedUpdateUI = () => {
    kcSetState(this, { FixedUpdate: this.state.FixedUpdate++ });
  };
  /* ------------------------------------------------------------- */
  //On
  private OnDimensionsChange = ({
    window,
    screen,
  }: {
    window: ScaledSize;
    screen: ScaledSize;
  }) => {
    kcSetState(this, { DimensionsWindow: window });
  };

  private OnRegListChanged = (_Param: RegListUpdateParam) => {
    kcSetState(this, {
      mlStockCode: _Param.RegList,
      mlCheckStockCode: [],
    });
    this.props.navigation.setOptions({
      headerRight: this.Render_HeaderRight.bind(this, false),
    });
  };

  private OnBtnGobackClick = () => {
    this.props.navigation.navigate("TabQuoteScreen");
  };

  private OnBtnRemoveClick = () => {
    var aFilter = this.state.mlStockCode.filter(
      (el) => !this.state.mlCheckStockCode.includes(el)
    );
    RegListHelper.UpdateRegList(aFilter);
  };

  private OnListDataChange = (_Datas: string[]) => {
    RegListHelper.UpdateRegList(_Datas);
  };

  private OnCheckBoxClick = (StockCode: string) => {
    let mlCheck = this.state.mlCheckStockCode;
    if (mlCheck.includes(StockCode)) {
      const index = mlCheck.indexOf(StockCode);
      if (index > -1) {
        mlCheck.splice(index, 1);

        if (mlCheck.length == 0) {
          this.props.navigation.setOptions({
            headerRight: this.Render_HeaderRight.bind(this, false),
          });
        }
      }
    } else {
      if (mlCheck.length == 0) {
        this.props.navigation.setOptions({
          headerRight: this.Render_HeaderRight.bind(this, true),
        });
      }
      mlCheck.push(StockCode);
    }
    kcSetState(this, { mlCheckStockCode: mlCheck });
  };

  /* ------------------------------------------------------------- */
  //Render
  private Render_HeaderLeft = () => {
    return (
      <Button
        icon={<Ionicons name="ios-chevron-back" size={24} color="#ffffff" />}
        buttonStyle={styles.button}
        onPress={this.OnBtnGobackClick}
      />
    );
  };
  private Render_HeaderRight = (visable?: boolean) => {
    if (!visable) return <></>;
    return (
      <Button
        icon={<Ionicons name="ios-trash-outline" size={24} color="#ffffff" />}
        buttonStyle={styles.button}
        onPress={this.OnBtnRemoveClick}
      />
    );
  };
  private Render_HeaderTitle = () => {
    return (
      <View
        style={{
          backgroundColor: "#00000000",
          //flex: 1,
          width: 300,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            backgroundColor: "#00000000",
            color: kcColor("Title"),
            fontSize: 18,
          }}
          selectable={false}
        >
          {"編輯"}
        </Text>
      </View>
    );
  };
  SubItemHeight = 60;
  Render_SubItem = (item: string, index: number) => {
    let Com = kcData.GetCommodity(item);
    let bChecked = this.state.mlCheckStockCode.includes(item);
    return (
      <>
        <View
          style={{
            flex: 1,
            backgroundColor: "#00000000",

            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            paddingRight: 20,
          }}
          pointerEvents="box-none"
        >
          <Pressable
            style={{
              width: 50,
              height: 50,
              alignItems: "center",
              justifyContent: "center",
            }}
            onPress={() => {
              this.OnCheckBoxClick(item);
            }}
          >
            <FontAwesome
              selectable={false}
              name={bChecked ? "dot-circle-o" : "circle-o"}
              size={24}
              //color={bChecked ? "#207CC5" : "#B0B0B0"}
              color={bChecked ? "#207CC5" : kcColor("ListIcon")}
            />
          </Pressable>

          <View
            style={{
              flexDirection: "column",
              flex: 1,
              marginLeft: 10,
              padding: 3,
              backgroundColor: "#00000000",
            }}
          >
            {/* <Text selectable={false} style={[styles.Text, kcColorObj("Title")]}>
              {item}
            </Text> */}

            <Text
              selectable={false}
              style={[styles.Text, kcColorObj("Yellow")]}
            >
              {item}
            </Text>
            <Text
              selectable={false}
              style={[styles.Text, kcColorObj("Title")]}
              numberOfLines={1}
            >
              {Com?.StockName}
            </Text>
          </View>
        </View>
        {/* <View
          style={{
            height: 1,
            //backgroundColor: kcColor("Border"),
            backgroundColor: "yellow",
            width: this.state.DimensionsWindow.width,
          }}
        ></View> */}
      </>
    );
  };
  ScrollViewRef = React.createRef<ScrollView>();

  render() {
    const EffectiveHeigh = this.state.DimensionsWindow.height - 60;
    return (
      <SafeAreaView
        style={{
          backgroundColor: kcColor("Background"),
          height: EffectiveHeigh,
          flexDirection: "column",
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <View
          style={{ backgroundColor: "#00000000", height: 1 }}
          onLayout={(e) => {
            kcSetState(this, { SubItemWidth: e.nativeEvent.layout.width });
          }}
        ></View>

        <KC_DraggableList
          ItemHeight={60}
          onStartDrop={() => {
            //this.setState({ EnableScroll: false });
          }}
          onEndDrop={() => {
            //this.setState({ EnableScroll: true });
          }}
          ItemWrapStyle={{
            borderBottomColor: kcColor("Border"),
            borderBottomWidth: 1,
          }}
          Source={this.state.mlStockCode}
          renderItem={this.Render_SubItem}
          onDataChanged={this.OnListDataChange}
        />
        <View
          style={{
            backgroundColor: "#00000000",
            height: 40,
          }}
        ></View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  Text: {
    fontSize: 14,
    //fontWeight: "bold",
    textAlign: "left",
    flex: 1,
  },
  button: {
    backgroundColor: "#00000000",
    borderWidth: 0,
    borderRadius: 15,
  },
});
export default TabQuoteScreen_Modify;
