import { kcInventoryLDFModel } from ".";
import { CompressionExtensions, StorageHelper } from "../kcExternal";
import ObjectExtenstions from "../kcExternal/ObjectExtenstions";
import { Buffer } from "buffer";

const UseLocalStorage = true;
const StorageKey: string = "OSP";
const SecretData: boolean = true; // 加密Storage存檔的值(目前只有壓縮)

const _OrderScreenDisplayType = ["New", "Balance", "Update", "Delete"] as const;
export type OrderScreenDisplayType = typeof _OrderScreenDisplayType[number];
export type TradeOrderParam = {
  StockCode: string;
  FocusTrade?: kcInventoryLDFModel;
  DisplayType: OrderScreenDisplayType;
};

type ReadStorageResault = {
  KeyOK: boolean;
  ReadOK: boolean;
  Resault: TradeOrderParam;
};

declare type delOrderParamChange = (_Param: TradeOrderParam) => void;

export default class OrderParamHelper {
  private static StaticInitialized = false;
  private static StaticInitializing = false;

  // 實體資料
  private static m_mdParams: TradeOrderParam = {
    StockCode: "XAUUSD",
    DisplayType: "New",
  };

  /* -------------------------------- static initialize ----------------------------------------- */
  public static StaticInitialize() {
    if (
      OrderParamHelper.StaticInitialized ||
      OrderParamHelper.StaticInitializing
    )
      return;

    OrderParamHelper.StaticInitializing = true;

    OrderParamHelper.ReadStorage()
      .then((value) => {
        if (value.KeyOK && value.ReadOK && value.Resault)
          OrderParamHelper.UpdateParamsCore(value.Resault, false);
      })
      .catch((reason) => {})
      .finally(() => {
        OrderParamHelper.StaticInitializing = false;
        OrderParamHelper.StaticInitialized = true;
      });
  }
  public static isReady() {
    return OrderParamHelper.StaticInitialized;
  }

  /* -------------------------------- 公開function ----------------------------------------- */
  public static GetOrderParams(): TradeOrderParam {
    return { ...OrderParamHelper.m_mdParams };
  }
  public static NewOrder(_szStockCode: string): boolean {
    let mdParams: TradeOrderParam = {
      StockCode: _szStockCode,
      DisplayType: "New",
      FocusTrade: undefined,
    };
    return OrderParamHelper.SetOrderParams(mdParams);
  }
  public static ChangeNewOrderCommodity(_szStockCode: string): boolean {
    if (
      OrderParamHelper.m_mdParams.FocusTrade !== undefined ||
      OrderParamHelper.m_mdParams.DisplayType !== "New"
    )
      return false;

    let mdParams: TradeOrderParam = {
      ...OrderParamHelper.m_mdParams,
      StockCode: _szStockCode,
    };
    return OrderParamHelper.SetOrderParams(mdParams);
  }
  public static ChangeOrder(_mdFocusTrade: kcInventoryLDFModel): boolean {
    if (!_mdFocusTrade) return false;

    let mdParams: TradeOrderParam = {
      StockCode: _mdFocusTrade.StockCode,
      FocusTrade: _mdFocusTrade,
      DisplayType: "Update",
    };
    return OrderParamHelper.SetOrderParams(mdParams);
  }
  public static BalanceOrder(_mdFocusTrade: kcInventoryLDFModel): boolean {
    if (!_mdFocusTrade) return false;

    let mdParams: TradeOrderParam = {
      StockCode: _mdFocusTrade.StockCode,
      FocusTrade: _mdFocusTrade,
      DisplayType: "Balance",
    };
    return OrderParamHelper.SetOrderParams(mdParams);
  }
  public static SetOrderParams(_Params: TradeOrderParam): boolean {
    // 檢查參數, 不合理的狀態false
    if (!OrderParamHelper.ValidateParams(_Params)) return false;

    // Params一樣的話flase
    if (ObjectExtenstions.ObjectsEqual(OrderParamHelper.m_mdParams, _Params))
      return false;

    OrderParamHelper.UpdateParamsCore(_Params, true);
    return true;
  }

  /* --------------------------------Update Event----------------------------------------- */

  private static ParamsChange_Event: delOrderParamChange[] = [];
  public static ParamChangeEvent_Add = (
    ParamChangeCallback: delOrderParamChange
  ) => {
    if (ParamChangeCallback)
      OrderParamHelper.ParamsChange_Event.push(ParamChangeCallback);
  };
  public static ParamChangeEvent_Remove = (
    ParamChangeCallback: delOrderParamChange
  ) => {
    if (ParamChangeCallback) {
      let nIdx =
        OrderParamHelper.ParamsChange_Event.indexOf(ParamChangeCallback);
      if (nIdx >= 0) OrderParamHelper.ParamsChange_Event.splice(nIdx, 1);
    }
  };
  private static SendParamChangeEvent = (_Params: TradeOrderParam): void => {
    let mdSend: TradeOrderParam = { ..._Params };
    for (let i = 0; i < OrderParamHelper.ParamsChange_Event.length; i++) {
      OrderParamHelper.ParamsChange_Event[i](mdSend);
    }
  };

  /* ----------------------------核心function-------------------------------- */
  // 參數檢查
  private static ValidateParams(_Params: TradeOrderParam): boolean {
    if (!_Params) return false;
    if (typeof _Params.StockCode !== "string") return false;
    if (!_OrderScreenDisplayType.includes(_Params.DisplayType)) return false;

    // 新單 只能接受FocusTrade === undefined
    if (_Params.DisplayType === "New") {
      if (_Params.FocusTrade !== undefined) return false;
    }
    // 改單平倉 只能接受FocusTrade !== undefined
    else {
      if (_Params.FocusTrade === undefined) return false;
    }

    // FocusTrade, 只有在有值時才需要判, 暫時用TradeIdx判
    if (_Params.FocusTrade !== undefined) {
      if (!Number.isSafeInteger(_Params.FocusTrade.TradeIdx)) return false;
    }
    return true;
  }
  // Update
  private static UpdateParamsCore(
    _Params: TradeOrderParam,
    _bSendEvent: boolean
  ) {
    // 更新member狀態
    OrderParamHelper.UpdateParamsValueCore(_Params);

    // 存檔
    OrderParamHelper.SaveStorage(_Params);

    // 發送Event
    if (_bSendEvent) {
      OrderParamHelper.SendParamChangeEvent(_Params);
    }
  }
  // 更新member參數
  private static UpdateParamsValueCore(_Params: TradeOrderParam) {
    OrderParamHelper.m_mdParams.StockCode = _Params.StockCode;
    OrderParamHelper.m_mdParams.FocusTrade = _Params.FocusTrade;
    OrderParamHelper.m_mdParams.DisplayType = _Params.DisplayType;
  }

  /* ---------------------------- 存讀檔案 -------------------------------- */
  // Storage用的Key, 寫成function方便支援不同帳戶
  private static ToAccountStorageKey(): string {
    return StorageKey;
  }
  // Storage 讀取
  private static async ReadStorage(): Promise<ReadStorageResault> {
    let Resault: ReadStorageResault = {
      KeyOK: false,
      ReadOK: false,
      Resault: { StockCode: "", DisplayType: "New" },
    };

    if (!UseLocalStorage) return Resault;

    let szKey = OrderParamHelper.ToAccountStorageKey();
    if (!szKey) return Resault;
    Resault.KeyOK = true;

    let AllKeys = await StorageHelper.GetAllKeys();
    if (AllKeys.indexOf(szKey) < 0) return Resault;
    Resault.ReadOK = true;

    try {
      let Base64String = await StorageHelper.ReadData(szKey); // 讀取 Base64編碼資料
      if (Base64String) {
        let aRegList: TradeOrderParam;
        if (SecretData) {
          /* ------------------------------------------- */
          // 壓縮版本
          let ZipBuffer = Buffer.from(Base64String, "base64"); // Base64編碼 => 壓縮Buffer
          let szJson = CompressionExtensions.Zlib_UnZip_toString(ZipBuffer); // 壓縮Buffer => JSON string
          aRegList = JSON.parse(szJson); // JSON string => RegList
        } else {
          /* ------------------------------------------- */
          // 純文字版本
          aRegList = JSON.parse(Base64String);
        }

        Resault.Resault = aRegList;
      }
    } catch (e) {}
    return Resault;
  }
  // Storage 存檔
  private static async SaveStorage(_Params?: TradeOrderParam) {
    // if (!UseLocalStorage) return;

    let szKey = OrderParamHelper.ToAccountStorageKey();
    if (!szKey) return;

    let aData: TradeOrderParam | undefined = _Params;

    if (aData === undefined || !UseLocalStorage) {
      // 沒資料的話 清掉Storage
      StorageHelper.RemoveValue(szKey);
      return;
    }

    let Base64String: string;
    if (SecretData) {
      /* ------------------------------------------- */
      // 壓縮版本
      let szJson = JSON.stringify(aData); // RegList => JSON string
      let ZipBuffer = Buffer.from(
        CompressionExtensions.Zlib_Zip_byString(szJson)
      ); // JSON string => 壓縮Buffer
      Base64String = ZipBuffer.toString("base64"); // 壓縮Buffer => Base64編碼
    } else {
      /* ------------------------------------------- */
      // 純文字版本
      Base64String = JSON.stringify(aData);
    }

    return await StorageHelper.SaveData(szKey, Base64String);
  }
}

OrderParamHelper.StaticInitialize();
