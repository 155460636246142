import React from "react";
import {
  StyleSheet,
  Dimensions,
  ScaledSize,
  LayoutChangeEvent,
  SafeAreaView,
} from "react-native";
import { kcSetState, kcSetUnmount, MathEx } from "../kcExternal";
import { Text, View } from "../components/Themed";
import { Button } from "react-native-elements";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import moment, { Moment } from "moment";
import { kcData, kcUnitModel, OrderParamHelper } from "../kcData";
import KC_QuotePB from "../components/kcQuotePB";
import Accordion from "react-native-collapsible/Accordion";
import KC_RowNumberControl from "../components/kcRowNumberControl";
import KC_RowDateTimeControl from "../components/kcRowDateTimeControl";
import kcHistoryTrade, { BuySellType } from "../kcModel/kcHistoryTrade";
import { CompositeNavigationProp } from "@react-navigation/native";
import {
  BottomTabParamList,
  RootStackParamList,
  TabQuoteParamList,
  TabTradeParamList,
} from "../types";
import { StackNavigationProp } from "@react-navigation/stack";
import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs";
import { kcInventoryLDFModel } from "../kcData";
import KC_OrderModal, { OrderModalType } from "../components/kcOrderModal";
import { kcColorObj, kcColor } from "../constants/Colors";
import { OrderScreenDisplayType } from "../kcData/OrderParamHelper";
import { kcIsUnmount } from "../kcExternal/kcGlobalFunc";

type IState = {
  nFixedRedraw: number; // 笨方式 用來強制繪圖
  DisplayType: OrderScreenDisplayType;
  StockCode: string;
  BuySellType: BuySellType;
  FocusTrade?: kcInventoryLDFModel;

  mdUnit: kcUnitModel;
  bIsScreenFocus: boolean;
  ChartHeight: number;
  ChartWidth: number;
  mlActiveIdx: number[];
  DimensionsWindow: ScaledSize;
};

type StackNavigationProp_ByQuote = StackNavigationProp<
  TabQuoteParamList,
  "TabQuoteScreen_Order"
>;
type StackNavigationProp_ByTrade = StackNavigationProp<
  TabTradeParamList,
  "TabTradeScreen_Order"
>;

type IProp = {
  route: any;
  navigation: CompositeNavigationProp<
    StackNavigationProp_ByQuote | StackNavigationProp_ByTrade,
    CompositeNavigationProp<
      BottomTabNavigationProp<BottomTabParamList>,
      StackNavigationProp<RootStackParamList>
    >
  >;
};

export default class App extends React.Component<IProp, IState> {
  state: IState = {
    nFixedRedraw: 0, // 笨方式 用來強制繪圖
    DisplayType: "New",
    StockCode: "XAUUSD",
    mdUnit: new kcUnitModel("XAUUSD"),
    bIsScreenFocus: true,
    ChartHeight: 500,
    ChartWidth: 500,
    mlActiveIdx: [],
    BuySellType: "Non",
    DimensionsWindow: Dimensions.get("window"),
  };

  /* ------------------------------------------------------------- */
  // Member

  nFixedRedraw: number = 0;
  bFirstIn: boolean = true;
  PendPrice: string = "";
  VolHand: string = "0.01";
  StopLossPrice: string = "";
  StopProfitPrice: string = "";
  PendDateline: string = "";

  KC_OrderModal: React.RefObject<KC_OrderModal>;
  KC_RowNumberControl_PendPrice: any = undefined;
  KC_RowNumberControl_VolHand: any = undefined;
  KC_RowNumberControl_StopLoss: any = undefined;
  KC_RowNumberControl_StopProfit: any = undefined;
  KC_RowDateTimeControl_PendDateline: any = undefined;

  /* ------------------------------------------------------------- */
  // HookFunc

  constructor(props: IProp) {
    super(props);

    this.KC_OrderModal = React.createRef<KC_OrderModal>();
    this.KC_RowNumberControl_PendPrice = React.createRef();
    this.KC_RowNumberControl_VolHand = React.createRef();
    this.KC_RowNumberControl_StopLoss = React.createRef();
    this.KC_RowNumberControl_StopProfit = React.createRef();
    this.KC_RowDateTimeControl_PendDateline = React.createRef();
  }

  componentDidMount() {
    //console.log("[Tab Order] DidMount - ", this.props.route.key);
    Dimensions.addEventListener("change", this.OnDimensionsChange);
    this.props.navigation.addListener("focus", this.OnFocus);
    this.props.navigation.addListener("blur", this.OnBlur);
    kcData.UnitEvent_Add(this.OnUnitDataChanged);

    this.props.navigation.setOptions({
      headerLeft: this.Render_HeaderLeft,
      headerRight: this.Render_HeaderRight,
    });
  }

  componentWillUnmount() {
    //console.log("[Tab Order] WillUnmount - ", this.props.route.key);
    Dimensions.removeEventListener("change", this.OnDimensionsChange);
    this.props.navigation.removeListener("focus", this.OnFocus);
    this.props.navigation.removeListener("blur", this.OnBlur);
    kcData.UnitEvent_Remove(this.OnUnitDataChanged);

    kcSetUnmount(this, true);
  }

  shouldComponentUpdate(nextProps: IProp, nextState: IState) {
    if (!nextState.bIsScreenFocus) return false;
    return true;
  }

  /* ------------------------------------------------------------- */
  // Main Func
  private ChangeFocusStock = (
    _StockCode: string,
    _DisplayType: OrderScreenDisplayType,
    _FocusTrade?: kcInventoryLDFModel
  ) => {
    const Unit = kcData.GetUnit(_StockCode);
    if (Unit) this.OnUnitDataChanged(Unit, true);

    this.InitRefUI(_FocusTrade);

    const StockCode = _FocusTrade ? _FocusTrade.StockCode : _StockCode;
    const FocusTrade = _FocusTrade as kcInventoryLDFModel;
    const BuySellType = _FocusTrade ? _FocusTrade.BuySellType : "Non";
    const DisplayType = _FocusTrade ? _DisplayType : "New";
    kcSetState(this, {
      StockCode: StockCode,
      FocusTrade: FocusTrade,
      BuySellType: BuySellType,
      DisplayType: DisplayType,
    });

    let hBind;
    if (!_FocusTrade)
      hBind = this.Render_HeaderTitle.bind(this, _StockCode, "New");
    else hBind = this.Render_HeaderTitle.bind(this, _FocusTrade, _DisplayType);
    this.props.navigation.setOptions({ headerTitle: hBind });
  };

  private InitRefUI = (_FocusTrade: kcInventoryLDFModel | undefined) => {
    this.RowNumberToDefaultValue();
    if (!_FocusTrade) return;

    if (_FocusTrade.Price) {
      let Value = _FocusTrade.Price.toFixed(_FocusTrade.FloatNum);
      this.KC_RowNumberControl_PendPrice.current.SetValue(Value);
    }

    if (_FocusTrade.StopLossPrice && _FocusTrade.StopLossPrice != -1) {
      let Value = _FocusTrade.StopLossPrice.toFixed(_FocusTrade.FloatNum);
      this.KC_RowNumberControl_StopLoss.current.SetValue(Value);
    }

    if (_FocusTrade.StopProfitPrice && _FocusTrade.StopProfitPrice != -1) {
      let Value = _FocusTrade.StopProfitPrice.toFixed(_FocusTrade.FloatNum);
      this.KC_RowNumberControl_StopProfit.current.SetValue(Value);
    }

    if (_FocusTrade.DeadlineTime) {
      this.KC_RowDateTimeControl_PendDateline.current.SetValue(
        _FocusTrade.DeadlineTime
      );
    }
  };

  private FixedRedrawUI = () => {
    this.nFixedRedraw = this.nFixedRedraw + 1;
    kcSetState(this, { nFixedRedraw: this.nFixedRedraw }); // 強制更動畫面
  };

  private RowNumberToDefaultValue = () => {
    this.KC_RowNumberControl_PendPrice.current.ToDefaultValue();
    this.KC_RowNumberControl_VolHand.current.ToDefaultValue();
    this.KC_RowNumberControl_StopLoss.current.ToDefaultValue();
    this.KC_RowNumberControl_StopProfit.current.ToDefaultValue();
    this.KC_RowDateTimeControl_PendDateline.current.SetValue(null);
  };

  private Order_New = (BuySellType: BuySellType) => {
    //console.log(`-------On Order ${BuySellType}-------`);
    /* ------------------------------ */
    // BuySellType防呆
    if (!kcHistoryTrade.Validate_BuySellType(BuySellType)) {
      console.log(`BuySellType(${BuySellType})錯誤`);
      return;
    }
    /* ------------------------------ */
    // 商品
    let StockCode = this.state.StockCode;
    let mdCommodity = kcData.GetCommodity(StockCode);
    if (!mdCommodity) {
      console.log(`找不到商品(${StockCode})`);
      return;
    }
    let ContractSize = mdCommodity.ContractSize;

    /* ------------------------------ */
    // 手數
    let nHVol = Number(this.VolHand);
    if (!nHVol) {
      console.log(`下單手數(${this.VolHand})錯誤`);
      return;
    }
    let RoundNum = 0;
    if (ContractSize >= 100) RoundNum = 0;
    else if (ContractSize >= 10) RoundNum = 1;
    else RoundNum = 2;

    let Vol = MathEx.kcRound(nHVol * ContractSize, RoundNum);

    /* ------------------------------ */
    // 委託價 (非市價)
    let price: number | undefined = undefined;
    if (BuySellType != "Buy" && BuySellType != "Sell") {
      price = Number(this.PendPrice);
      if (!price) {
        console.log(`下單類型 ${BuySellType} 委託價(${price})錯誤`);
        return;
      }
    }
    /* ------------------------------ */
    // 停損停利
    let nStopProfitPrice: number | undefined = Number(this.StopProfitPrice);
    if (!nStopProfitPrice) nStopProfitPrice = undefined;
    let nStopLossPrice: number | undefined = Number(this.StopLossPrice);
    if (!nStopLossPrice) nStopLossPrice = undefined;

    /* ------------------------------ */
    // DeadLine Time
    let DeadLineTime: Moment | undefined = undefined;
    DeadLineTime = moment.utc(this.PendDateline);

    let HTrade: kcHistoryTrade = new kcHistoryTrade();
    HTrade.StockCode = StockCode;
    HTrade.BuySellType = BuySellType;
    HTrade.Vol = Vol;
    if (price) HTrade.Price = price;
    if (nStopProfitPrice) HTrade.StopProfitPrice = nStopProfitPrice;
    if (nStopLossPrice) HTrade.StopLossPrice = nStopLossPrice;
    HTrade.DeadlineTime = DeadLineTime;

    this.KC_OrderModal.current?.Order(HTrade, OrderModalType.New);
  };

  private Order_Update = () => {
    if (!this.state.FocusTrade) return;
    if (!this.state.FocusTrade.HTradeIn) return;

    let HTradeClone = this.state.FocusTrade.HTradeIn.Clone();

    HTradeClone.Type = "改單";
    /* ------------------------------ */
    // 委託價 (非市價)
    let price: number | undefined = undefined;
    if (HTradeClone.BuySellType != "Buy" && HTradeClone.BuySellType != "Sell") {
      price = Number(this.PendPrice);
      if (!price) {
        console.log(`下單類型 ${HTradeClone.BuySellType} 委託價(${price})錯誤`);
        return;
      }
      HTradeClone.Price = price;
    }

    /* ------------------------------ */
    // 停損停利
    let nStopProfitPrice: number | undefined = Number(this.StopProfitPrice);
    if (!nStopProfitPrice) nStopProfitPrice = -1;
    let nStopLossPrice: number | undefined = Number(this.StopLossPrice);
    if (!nStopLossPrice) nStopLossPrice = -1;

    HTradeClone.StopLossPrice = nStopLossPrice;
    HTradeClone.StopProfitPrice = nStopProfitPrice;

    /* ------------------------------ */
    // DeadLine Time
    if (HTradeClone.BuySellType != "Buy" && HTradeClone.BuySellType != "Sell") {
      let DeadLineTime: Moment | undefined = undefined;
      if (this.PendDateline != "") DeadLineTime = moment.utc(this.PendDateline);
      HTradeClone.DeadlineTime = DeadLineTime;
    }

    /* ------------------------------ */
    // 下單
    this.KC_OrderModal.current?.Order(HTradeClone, OrderModalType.Update);
  };

  private Order_BalanceOrDelete = () => {
    if (!this.state.FocusTrade) return;
    if (!this.state.FocusTrade.HTradeIn) return;

    let HTradeClone = this.state.FocusTrade.HTradeIn.Clone();
    if (HTradeClone.BuySellType != "Buy" && HTradeClone.BuySellType != "Sell")
      this.KC_OrderModal.current?.Order(HTradeClone, OrderModalType.Delete);
    else this.KC_OrderModal.current?.Order(HTradeClone, OrderModalType.Balance);
  };

  /* ------------------------------------------------------------- */
  // On
  private OnFocus = () => {
    kcSetState(this, { bIsScreenFocus: true });

    let Params = OrderParamHelper.GetOrderParams();
    const { StockCode, DisplayType, FocusTrade } = Params;
    if (FocusTrade && !kcInventoryLDFModel.IsInventoryLDFModel(FocusTrade)) {
      // 檢查是不是真正的Model, 普通的object要重新上去拿(目前會在重新整理從檔案讀出來發生)
      let nTradeIdx = kcInventoryLDFModel.GetTradeIdx(FocusTrade);
      kcData.AccountLDF()?.GetInventoryAsync(nTradeIdx, (_mdTrade) => {
        if (kcIsUnmount(this)) {
          return;
        }
        if (_mdTrade) {
          this.ChangeFocusStock(StockCode, DisplayType, _mdTrade);
        } else this.ChangeFocusStock(StockCode, "New", undefined);
      });
    } else this.ChangeFocusStock(StockCode, DisplayType, FocusTrade);
  };
  // private OnFocus = () => {
  //   kcSetState(this, { bIsScreenFocus: true });

  //   const RouteParams = this.props.route.params;
  //   const Param = RouteParams ? RouteParams.kcParam : undefined;

  //   const bFirstIn = this.bFirstIn;
  //   if (bFirstIn) this.bFirstIn = false;

  //   if (!Param) {
  //     if (bFirstIn) {
  //       /* ---------------------------------------------*/
  //       //重新整理相關
  //       this.ChangeFocusStock(this.state.StockCode, "New", undefined);
  //     } else {
  //       /* ---------------------------------------------*/
  //       // 透過其他頁面切換回來 不用做事
  //     }
  //     return;
  //   }

  //   const StockCode = Param.StockCode;
  //   const DisplayType = Param.DisplayType ? Param.DisplayType : "New";
  //   const FocusTrade = Param.FocusTrade as kcInventoryLDFModel;
  //   this.ChangeFocusStock(StockCode, DisplayType, FocusTrade);

  //   RouteParams.kcParam = undefined; // 額外包裝kcParam 就是為了清除
  // };

  private OnBlur = () => {
    kcSetState(this, { bIsScreenFocus: false });
  };

  private OnDimensionsChange = ({
    window,
    screen,
  }: {
    window: ScaledSize;
    screen: ScaledSize;
  }) => {
    kcSetState(this, { windowsWidth: window.width });
  };

  private OnUnitDataChanged = (
    _mdUnit: kcUnitModel,
    _bAlwaysUpdate?: boolean
  ) => {
    if (!_bAlwaysUpdate) if (this.state.StockCode != _mdUnit.StockCode) return;
    kcSetState(this, { mdUnit: _mdUnit });
  };

  private OnBtnGobackClick = () => {
    this.props.navigation.pop();
    // if (this.props.navigation.canGoBack()) {
    //   this.props.navigation.goBack();
    // } else {
    //   console.log("!!!!!Can't goBack()");
    //   let RootNavigation = this.props.navigation
    //     .dangerouslyGetParent()
    //     ?.dangerouslyGetParent() as StackNavigationProp<
    //     RootStackParamList,
    //     "Root"
    //   >;
    //   if (RootNavigation) {
    //     RootNavigation.replace("Root", { screen: "TabTrade" });
    //   } else {
    //     this.props.navigation.navigate("TabTrade");
    //   }
    // }
  };
  private OnBtnGoSelectCommodity = () => {
    let NowScreen = this.props.route?.name;
    if (
      NowScreen !== "TabQuoteScreen_Order" &&
      NowScreen !== "TabTradeScreen_Order"
    )
      return;

    let RootNavigation = this.props.navigation;
    if (NowScreen === "TabQuoteScreen_Order") {
      let QuoteNavigation = RootNavigation as CompositeNavigationProp<
        StackNavigationProp_ByQuote,
        CompositeNavigationProp<
          BottomTabNavigationProp<BottomTabParamList>,
          StackNavigationProp<RootStackParamList>
        >
      >;
      QuoteNavigation.push("TabQuoteScreen_Select");
    } else if (NowScreen === "TabTradeScreen_Order") {
      let TradeNavigation = RootNavigation as CompositeNavigationProp<
        StackNavigationProp_ByTrade,
        CompositeNavigationProp<
          BottomTabNavigationProp<BottomTabParamList>,
          StackNavigationProp<RootStackParamList>
        >
      >;
      TradeNavigation.push("TabTradeScreen_Select");
    }
  };

  private OnVictoryChartViewLayout = (event: LayoutChangeEvent) => {
    const { x, y, height, width } = event.nativeEvent.layout;
    kcSetState(this, { ChartHeight: height, ChartWidth: width });
  };

  private OnListSectionsChange = (_mlActiveIdx: number[]) => {
    this.RowNumberToDefaultValue();
    kcSetState(this, { mlActiveIdx: _mlActiveIdx });
  };

  private OnPendPriceValueChange = (text: string) => {
    this.PendPrice = text;
    this.FixedRedrawUI();
  };

  private OnVolHandValueChange = (text: string) => {
    this.VolHand = text;
  };

  private OnStopLossValueChange = (text: string) => {
    this.StopLossPrice = text;
    this.FixedRedrawUI();
  };

  private OnStopProfitValueChange = (text: string) => {
    this.StopProfitPrice = text;
    this.FixedRedrawUI();
  };

  private OnPendDatelineValueChange = (
    value: Moment | null,
    dateString: string
  ) => {
    this.PendDateline = dateString;
  };

  /* ------------------------------------------------------------- */
  //Render

  Render_HeaderLeft = () => {
    return (
      <Button
        // style={{ width: 48, height: 48 }}
        icon={
          <Ionicons name="ios-chevron-back" size={24} color={kcColor("Icon")} />
        }
        iconContainerStyle={{ alignSelf: "center" }}
        buttonStyle={styles.button}
        onPress={this.OnBtnGobackClick}
      />
    );
  };

  Render_HeaderRight = () => {
    /* ------------------------- */
    // Call進來的Screen不正確的話, 不顯示icon
    let NowScreen = this.props.route?.name;
    if (
      NowScreen !== "TabQuoteScreen_Order" &&
      NowScreen !== "TabTradeScreen_Order"
    )
      return <></>;

    /* ------------------------- */
    // 不是新單, 不顯示icon
    if (this.state.DisplayType !== "New") return <></>;

    return (
      <Button
        // style={{ width: 48, height: 48 }}
        style={{ backgroundColor: "#00000000" }}
        buttonStyle={{ backgroundColor: "#00000000" }}
        onPress={this.OnBtnGoSelectCommodity}
        titleStyle={{ fontSize: 16 }}
        // title={"其他"}
        // icon={<Ionicons name="ios-chevron-forward" size={20} color="#ffffff" />}
        icon={
          <MaterialCommunityIcons
            name="text-search"
            size={28}
            color={"#ffffff"}
          />
        }
        // iconPosition="right"
        //iconRight={true}
        iconContainerStyle={{ alignSelf: "center" }}
      />
    );
  };

  Render_HeaderTitle = (
    _Param: string | kcInventoryLDFModel,
    _DisplayType: string
  ) => {
    let Inventory = _Param as kcInventoryLDFModel;
    if (!Inventory.TradeIdx) {
      let StockCode = _Param as string;
      let Com;
      if (StockCode) Com = kcData.GetCommodity(StockCode);

      return (
        <View
          style={{
            backgroundColor: "#00000000",
            //flex: 1,
            width: 300,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            selectable={false}
            style={{
              backgroundColor: "#00000000",
              color: kcColor("Yellow"),
              fontSize: 12,
            }}
          >
            {StockCode}
          </Text>
          <Text
            selectable={false}
            style={{
              backgroundColor: "#00000000",
              color: "#ffffff",
              fontSize: 16,
            }}
          >
            {Com?.StockName}
          </Text>
        </View>
        // <Button
        //   style={{ backgroundColor: "#00000000" }}
        //   buttonStyle={{ backgroundColor: "#00000000" }}
        //   onPress={() => {
        //     if (this.state.StockCode == "XAUUSD")
        //       this.OnStockCodeChanged("EURUSD", "New", undefined);
        //     else this.OnStockCodeChanged("XAUUSD", "New", undefined);
        //   }}
        //   titleStyle={{ fontSize: 18 }}
        //   title={StockCode}
        //   icon={<Ionicons name="ios-caret-down" size={16} color="#ffffff" />}
        //   iconRight={true}
        // />
      );
    } else {
      let szDisplayType = "";
      if (_DisplayType == "Update") szDisplayType = "修改";
      else if (_DisplayType == "Balance") {
        if (Inventory.BuySellType == "Buy" || Inventory.BuySellType == "Sell")
          szDisplayType = "平倉";
        else szDisplayType = "取消";
      }
      return (
        <View
          style={{
            backgroundColor: "#00000000",
            //flex: 1,
            width: 300,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            selectable={false}
            style={{
              backgroundColor: "#00000000",
              color: kcColor("Title"),
              fontSize: 16,
            }}
          >
            {szDisplayType +
              " #" +
              Inventory.TradeIdx +
              " " +
              Inventory.StockCode +
              " " +
              Inventory.BuySellType +
              " " +
              Inventory.VolHand.toFixed(2)}
          </Text>
        </View>
      );
    }
  };

  Render_ListHeader = (_md: any) => {
    let szBuySellType = "市價單";
    if (this.state.BuySellType == "BuyLimit") szBuySellType = "Buy Limit";
    if (this.state.BuySellType == "SellLimit") szBuySellType = "Sell Limit";
    if (this.state.BuySellType == "BuyStop") szBuySellType = "Buy Stop";
    if (this.state.BuySellType == "SellStop") szBuySellType = "Sell Stop";

    return (
      <View
        style={{
          margin: 10,
          backgroundColor: "#00000000",
          alignItems: "center",
        }}
      >
        <Text
          selectable={false}
          style={{
            backgroundColor: "#00000000",
            alignItems: "center",
          }}
        >
          <Text
            selectable={false}
            style={[
              styles.BuySellBtn_Text,
              kcColorObj("Title"),
              { textAlign: "center" },
            ]}
          >
            {szBuySellType + " "}
          </Text>
          <Ionicons
            selectable={false}
            name="ios-caret-down"
            size={16}
            color={kcColor("Icon")}
          />
        </Text>
      </View>
    );
  };

  Render_ListContent = (_md: any) => {
    return (
      <View
        style={{
          backgroundColor: "#00000000",
          height: 130,
          marginTop: 3,
        }}
      >
        <Button
          buttonStyle={styles.BuySellBtn}
          titleStyle={[styles.BuySellBtn_Text, kcColorObj("Value")]}
          onPress={() =>
            kcSetState(this, { BuySellType: "Non", mlActiveIdx: [] })
          }
          title={"市價單"}
        />
        <Button
          buttonStyle={styles.BuySellBtn}
          titleStyle={[styles.BuySellBtn_Text, kcColorObj("BuySell", "Buy")]}
          onPress={() =>
            kcSetState(this, { BuySellType: "BuyLimit", mlActiveIdx: [] })
          }
          title={"Buy Limit"}
        />
        <Button
          buttonStyle={styles.BuySellBtn}
          titleStyle={[styles.BuySellBtn_Text, kcColorObj("BuySell", "Sell")]}
          onPress={() =>
            kcSetState(this, { BuySellType: "SellLimit", mlActiveIdx: [] })
          }
          title={"Sell Limit"}
        />
        <Button
          buttonStyle={styles.BuySellBtn}
          titleStyle={[styles.BuySellBtn_Text, kcColorObj("BuySell", "Buy")]}
          onPress={() =>
            kcSetState(this, { BuySellType: "BuyStop", mlActiveIdx: [] })
          }
          title={"Buy Stop"}
        />
        <Button
          buttonStyle={styles.BuySellBtn}
          titleStyle={[styles.BuySellBtn_Text, kcColorObj("BuySell", "Sell")]}
          onPress={() =>
            kcSetState(this, { BuySellType: "SellStop", mlActiveIdx: [] })
          }
          title={"Sell Stop"}
        />
      </View>
    );
  };

  render() {
    const mdCommodity = this.state.mdUnit.Commodity;
    const FloatNumber = mdCommodity ? mdCommodity.FloatNum : 2;
    const PriceScale = mdCommodity ? mdCommodity.PriceScale[0].TickPrice : 0.01;

    let ABString = this.state.mdUnit.GetAskBidSubString();

    let nInPrice: number | undefined = undefined;
    let nStopLossPrice: number | undefined = undefined;
    let nStopProfitPrice: number | undefined = undefined;
    const IsMarket =
      this.state.BuySellType == "" ||
      this.state.BuySellType == "Non" ||
      this.state.BuySellType == "Buy" ||
      this.state.BuySellType == "Sell";

    if (this.PendPrice) {
      let Value = Number(this.PendPrice);
      if (Value) nInPrice = Value;
    }
    if (this.StopLossPrice) {
      let Value = Number(this.StopLossPrice);
      if (Value) nStopLossPrice = Value;
    }
    if (this.StopProfitPrice) {
      let Value = Number(this.StopProfitPrice);
      if (Value) nStopProfitPrice = Value;
    }

    const EffectiveHeigh = this.state.DimensionsWindow.height - 60;
    return (
      <SafeAreaView
        style={{
          backgroundColor: kcColor("Background"),
          height: EffectiveHeigh,
          flex: 1,
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <KC_OrderModal ref={this.KC_OrderModal} />

        <View
          style={{
            backgroundColor: "#00000000",
            paddingLeft: 10,
            paddingRight: 10,
            flex: 1,
          }}
        >
          <View style={[{ backgroundColor: "#00000000" }]}>
            {this.state.DisplayType == "New" && (
              <Accordion
                containerStyle={{
                  backgroundColor: kcColor("ListTitleBackground"),
                  marginTop: 10,
                  marginBottom: 10,
                }}
                sections={[{}]}
                activeSections={this.state.mlActiveIdx}
                renderSectionTitle={() => <></>}
                renderHeader={this.Render_ListHeader}
                renderFooter={() => <></>}
                renderContent={this.Render_ListContent}
                onChange={this.OnListSectionsChange}
              />
            )}

            <KC_RowNumberControl
              ref={this.KC_RowNumberControl_PendPrice}
              Title="開倉價格"
              DefaultValue=""
              BtnClickDefaultValue={ABString.BidString}
              FloatNumber={FloatNumber}
              PriceScale={PriceScale}
              OnValueChange={this.OnPendPriceValueChange}
              Disable={
                IsMarket ||
                this.state.DisplayType == "Balance" ||
                this.state.DisplayType == "Delete"
              }
              Visable={!(IsMarket && this.state.DisplayType == "New")}
            />

            <KC_RowNumberControl
              ref={this.KC_RowNumberControl_VolHand}
              Title="手數"
              DefaultValue="0.01"
              BtnClickDefaultValue="0.01"
              FloatNumber={2}
              PriceScale={0.01}
              OnValueChange={this.OnVolHandValueChange}
              Visable={this.state.DisplayType == "New"}
            />

            <KC_RowNumberControl
              ref={this.KC_RowNumberControl_StopLoss}
              Title="停損"
              DefaultValue=""
              BtnClickDefaultValue={ABString.BidString}
              FloatNumber={FloatNumber}
              PriceScale={PriceScale}
              OnValueChange={this.OnStopLossValueChange}
              Disable={this.state.DisplayType == "Balance"}
            />

            <KC_RowNumberControl
              ref={this.KC_RowNumberControl_StopProfit}
              Title="停利"
              DefaultValue=""
              BtnClickDefaultValue={ABString.AskString}
              FloatNumber={FloatNumber}
              PriceScale={PriceScale}
              OnValueChange={this.OnStopProfitValueChange}
              Disable={this.state.DisplayType == "Balance"}
            />

            <KC_RowDateTimeControl
              ref={this.KC_RowDateTimeControl_PendDateline}
              Title="期限"
              OnValueChange={this.OnPendDatelineValueChange}
              Disable={this.state.DisplayType == "Balance"}
              Visable={!IsMarket}
            />

            <View
              style={[
                {
                  backgroundColor: "#00000000",
                  height: 34,
                  flexDirection: "row",
                },
              ]}
            >
              <Text selectable={false} style={{ flex: 1, textAlign: "center" }}>
                <Text
                  selectable={false}
                  style={[styles.TextNormal, kcColorObj("Title")]}
                >
                  {ABString.BidString_Left}
                </Text>
                <Text
                  selectable={false}
                  style={[styles.TextBig, kcColorObj("Title")]}
                >
                  {ABString.BidString_Right}
                </Text>
              </Text>
              <Text selectable={false} style={{ flex: 1, textAlign: "center" }}>
                <Text
                  selectable={false}
                  style={[styles.TextNormal, kcColorObj("Title")]}
                >
                  {ABString.AskString_Left}
                </Text>
                <Text
                  selectable={false}
                  style={[styles.TextBig, kcColorObj("Title")]}
                >
                  {ABString.AskString_Right}
                </Text>
              </Text>
            </View>

            {IsMarket && this.state.DisplayType == "New" && (
              <View
                style={[
                  {
                    backgroundColor: "#00000000",
                    flexDirection: "row",
                    margin: 3,
                  },
                ]}
              >
                <Button
                  containerStyle={{ flex: 1 }}
                  buttonStyle={{
                    backgroundColor: kcColor("BuySell", "Sell"),
                    height: 40,
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                  titleStyle={{ fontSize: 16, color: "#000000" }}
                  onPress={() => {
                    this.Order_New("Sell");
                  }}
                  title={"Sell"}
                />

                <Button
                  containerStyle={{ flex: 1 }}
                  buttonStyle={{
                    backgroundColor: kcColor("BuySell", "Buy"),
                    height: 40,
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                  titleStyle={{ fontSize: 16, color: "#000000" }}
                  onPress={() => {
                    this.Order_New("Buy");
                  }}
                  title={"Buy"}
                />
              </View>
            )}

            {!IsMarket && this.state.DisplayType == "New" && (
              <View
                style={[
                  {
                    backgroundColor: "#00000000",
                    flexDirection: "row",
                    margin: 3,
                  },
                ]}
              >
                <Button
                  containerStyle={{ flex: 1 }}
                  buttonStyle={{
                    backgroundColor: kcColor("BuySell", this.state.BuySellType),
                    height: 40,
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                  titleStyle={{ fontSize: 16, color: "#000000" }}
                  onPress={() => {
                    this.Order_New(this.state.BuySellType);
                  }}
                  title={"掛單交易"}
                />
              </View>
            )}

            {this.state.DisplayType == "Update" && (
              <View
                style={[
                  {
                    backgroundColor: "#00000000",
                    flexDirection: "row",
                    margin: 3,
                  },
                ]}
              >
                <Button
                  containerStyle={{ flex: 1 }}
                  buttonStyle={{
                    backgroundColor: kcColor("BuySell", this.state.BuySellType),
                    height: 40,
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                  titleStyle={{ fontSize: 16, color: "#000000" }}
                  onPress={() => {
                    this.Order_Update();
                  }}
                  title={"改單"}
                />
              </View>
            )}

            {this.state.DisplayType == "Balance" && (
              <View
                style={[
                  {
                    backgroundColor: "#00000000",
                    flexDirection: "row",
                    margin: 3,
                  },
                ]}
              >
                <Button
                  containerStyle={{ flex: 1 }}
                  buttonStyle={{
                    backgroundColor: kcColor("Yellow"),
                    height: 40,
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                  titleStyle={{ fontSize: 16, color: "#000000" }}
                  onPress={() => {
                    this.Order_BalanceOrDelete();
                  }}
                  title={
                    this.state.FocusTrade?.HTradeIn?.Type === "成交"
                      ? "平倉"
                      : "刪單"
                  }
                />
              </View>
            )}
          </View>

          <View
            onLayout={this.OnVictoryChartViewLayout}
            style={{
              flex: 1,
              flexDirection: "row",
              backgroundColor: "#00000000",
            }}
          >
            <KC_QuotePB
              ChartWidth={this.state.ChartWidth}
              ChartHeight={this.state.ChartHeight}
              StockCode={this.state.StockCode}
              delCanUpdate={() => this.state.bIsScreenFocus}
              InPrice={nInPrice}
              StopLossPrice={nStopLossPrice}
              StopProfitPrice={nStopProfitPrice}
            ></KC_QuotePB>
          </View>
        </View>
        <View
          style={{
            height: 40,
            backgroundColor: "#00000000",
            marginLeft: -10,
            marginRight: -10,
          }}
        ></View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  TextSmall: {
    fontSize: 14,
  },
  TextNormal: {
    fontSize: 20,
    fontWeight: "bold",
  },
  TextBig: {
    fontSize: 28,
    fontWeight: "bold",
  },
  BuySellBtn: {
    //backgroundColor: "#505050",
    backgroundColor: "#00000000",
    height: 25,
    borderRadius: 0,
    borderWidth: 0,
    padding: 2,
  },
  BuySellBtn_Text: {
    fontSize: 16,
  },
  button: {
    backgroundColor: "#00000000",
    borderColor: "red",
    borderWidth: 0,
    borderRadius: 15,
  },
});
