import kcUnitModel from "./kcUnitModel";
import * as kcQuote from "../kcTransfer/kcQuote";
import * as kcTrade from "../kcTransfer/kcTrade";
import { JWTLoginState } from "../kcTransfer/JWTPayloadModel";
import {
  CommodityChangeType,
  HistoryDataType,
} from "../kcTransfer/InternalDefine";
import {
  kcTickModel,
  kcLastInfoModel,
  kcCommodityModel,
  kcAccountInformationModel,
  kcInventoryModel,
  kcHistoryTrade,
  kcAccountModel,
  kcHistoryOHLCModel,
} from "../kcModel";
import { BuySellType } from "../kcModel/kcHistoryTrade";
import kcAccountLDFModel from "./kcAccountLDFModel";
import kcInventoryLDFModel from "./kcInventoryLDFModel";
import moment, { Moment } from "moment";
import RegListHelper, { RegListUpdateParam } from "./RegListHelper";
import { ArrayExtensions } from "../kcExternal";

// console.log(
//   "-----------------------------kcData In-----------------------------"
// );

export type UnitEventCallback = (_mdUnit: kcUnitModel) => void;
var OnUnit_Event: UnitEventCallback[] = [];

export var m_mdAccountLDF: kcAccountLDFModel;

var dicCommodityList: Map<string, kcCommodityModel> = new Map<
  string,
  kcCommodityModel
>();
var dicUnit: Map<string, kcUnitModel> = new Map<string, kcUnitModel>();

var dicToUSD: Map<string, number> = new Map<string, number>();
dicToUSD.set("USD", 1);

var Init = () => {
  kcTrade.InitializeCallback(
    OnAccount,
    OnAccountInfoChange,
    OnInventory,
    OnTradeHistory,
    OnTradeResault
  );
  kcQuote.InitializeCallback(
    OnRecvTick,
    OnRecvLastInfo,
    OnRecvCommodityList,
    OnRecvConnodityChange,
    OnHistory
  );
  RegListHelper.RegListUpdateEvent_Add(OnRegListUpdate);
};

/* --------------------------------------------------------------------------------- */
// kcQuote CallBack
var OnRecvTick = (_szStockCode: string, _mdTick: kcTickModel) => {
  let mdUnit = dicUnit.get(_szStockCode);
  if (!mdUnit) return;

  // Update ToUSD
  UpdateToUSD(_szStockCode, _mdTick.AskPrice, _mdTick.BidPrice);
  mdUnit.OnTick(_mdTick); // Update Unit

  if (m_mdAccountLDF) m_mdAccountLDF.UpdateQuote(mdUnit);

  // 發送Event
  _SendUnitEvent(mdUnit);
};

var OnRecvLastInfo = (_mlLastInfos: kcLastInfoModel[]) => {
  // console.log(
  //   "OnRecvLastInfo: ",
  //   _mlLastInfos.map((q) => q.StockCode)
  // );
  _mlLastInfos.forEach((_mdLastInfo) => {
    // Update ToUSD
    UpdateToUSD(
      _mdLastInfo.StockCode,
      _mdLastInfo.AskPrice,
      _mdLastInfo.BidPrice
    );

    // Update Unit
    let mdUnit = dicUnit.get(_mdLastInfo.StockCode);
    if (!mdUnit) {
      let mdComm = dicCommodityList.get(_mdLastInfo.StockCode);
      if (!mdComm) return;
      mdUnit = new kcUnitModel(mdComm.StockCode, mdComm, _mdLastInfo);
      dicUnit.set(_mdLastInfo.StockCode, mdUnit);

      if (m_mdAccountLDF)
        mdUnit.PointDiffChange(m_mdAccountLDF.Information.PointDiff);
    }
    mdUnit.OnLastInfo(_mdLastInfo);

    if (m_mdAccountLDF) m_mdAccountLDF.UpdateQuote(mdUnit);

    // 發送Event
    _SendUnitEvent(mdUnit);
  });
};

var OnRecvCommodityList = (_mlCommodityList: kcCommodityModel[]): void => {
  //console.log("OnRecvCommodityList");
  let RegCommodity: string[] = [];
  _mlCommodityList.forEach((_mdCommodity) => {
    // 更新Commodity
    let mdCommodity = dicCommodityList.get(_mdCommodity.StockCode);
    if (mdCommodity) mdCommodity.Update(_mdCommodity);
    else dicCommodityList.set(_mdCommodity.StockCode, _mdCommodity);

    // 創造Unit
    let mdUnit = dicUnit.get(_mdCommodity.StockCode);
    if (!mdUnit) {
      mdUnit = new kcUnitModel(_mdCommodity.StockCode, _mdCommodity);
      dicUnit.set(_mdCommodity.StockCode, mdUnit);

      if (m_mdAccountLDF)
        mdUnit.PointDiffChange(m_mdAccountLDF.Information.PointDiff);
    }

    // 若是含有USD的商品, 固定要註冊
    if (IsUSDCommodity(_mdCommodity.StockCode) >= 0)
      RegCommodity.push(_mdCommodity.StockCode);
  });

  /* ------------------------------- */
  // Init需要註冊的商品
  // 1.所有含有USD的商品, 固定要註冊
  RegCommodity.push(
    ..._mlCommodityList
      .filter((q) => IsUSDCommodity(q.StockCode) >= 0)
      .map((q) => q.StockCode)
  );

  // 2. 註冊列表裡面有的
  let RegStocks = RegListHelper.GetRegList();
  RegCommodity.push(...RegStocks);

  // 3. 有庫存了的話, 把庫存內有的加進去
  if (m_mdAccountLDF) {
    // 若AccountLDF裡有尚未設Commodity的商品, 更新進去
    m_mdAccountLDF.UpdateCommodity(_mlCommodityList);

    // 拿到OnAccount去做, 併入註冊列表
    // // 固定要註冊的商品 加入庫存有的商品
    // let mlInvCommoditys = m_mdAccountLDF.GetInventoryCommoditys();
    // RegCommodity.push(...mlInvCommoditys);
  }

  // 4. Set過濾重複項目
  RegCommodity = [...new Set(RegCommodity).values()];
  /* ------------------------------- */

  if (RegCommodity.length > 0) kcQuote.Register(RegCommodity);
};

var OnRecvConnodityChange: kcQuote.CommodityChangeCallback = (
  _mdCommodity: kcCommodityModel,
  _ChangeType: CommodityChangeType
) => {
  switch (_ChangeType) {
    case CommodityChangeType.Create: {
      let mdCommodity = dicCommodityList.get(_mdCommodity.StockCode);
      if (mdCommodity) mdCommodity.Update(_mdCommodity);
      else dicCommodityList.set(_mdCommodity.StockCode, _mdCommodity);
      if (IsUSDCommodity(_mdCommodity.StockCode) >= 0)
        kcQuote.Register(_mdCommodity.StockCode);
      break;
    }
    case CommodityChangeType.Modify: {
      let mdCommodity = dicCommodityList.get(_mdCommodity.StockCode);
      if (mdCommodity) mdCommodity.Update(_mdCommodity);
      else {
        dicCommodityList.set(_mdCommodity.StockCode, _mdCommodity);
        if (IsUSDCommodity(_mdCommodity.StockCode) >= 0)
          kcQuote.Register(_mdCommodity.StockCode);
      }
      break;
    }
    case CommodityChangeType.Delete: {
      dicCommodityList.delete(_mdCommodity.StockCode);
      if (IsUSDCommodity(_mdCommodity.StockCode) >= 0)
        kcQuote.UnRegister(_mdCommodity.StockCode);
      break;
    }
  }
};

var OnHistory: kcQuote.HistoryCallback = (
  _StockCode: string,
  _HType: HistoryDataType,
  _DayNumber: number,
  _mlOHLC: kcHistoryOHLCModel[]
) => {
  let mdUnit = dicUnit.get(_StockCode);
  if (!mdUnit) return;

  mdUnit.OnHistory(_HType, _mlOHLC);
};

/* --------------------------------------------------------------------------------- */
// kcTrade CallBack
var OnAccount = (
  _Account: kcAccountModel,
  LoginState: JWTLoginState // 登入狀態 (下單密碼, 觀摩密碼, admin)
) => {
  //console.log("OnAccount");

  let NeedUpdateRegList;
  if (!m_mdAccountLDF) {
    m_mdAccountLDF = new kcAccountLDFModel(
      _Account,
      GetUnit,
      GetToUSD,
      GetCommodity
    );
  } else {
    m_mdAccountLDF.ReSetAccount(_Account);
  }

  dicUnit.forEach((value) =>
    value.PointDiffChange(_Account.Information.PointDiff)
  );

  // 讀取註冊列表
  RegListHelper.ChangeAccount(_Account.Information.Account, (args) => {
    // 庫存有的東西全部加進註冊列表
    let mlInvComms = m_mdAccountLDF.GetInventoryCommoditys();
    RegListHelper.AppendRegListItem(mlInvComms, false);

    // 啟動Quote並RequestCommodity
    kcQuote.InitializeSocket();
  });
};
var OnAccountInfoChange = (_mdAccountInfo: kcAccountInformationModel) => {
  dicUnit.forEach((value) => value.PointDiffChange(_mdAccountInfo.PointDiff));
};
var OnInventory: kcTrade.InventoryCallback = (Inventory: kcInventoryModel) => {
  if (m_mdAccountLDF) m_mdAccountLDF.UpdateInventory(Inventory);
};

var OnTradeHistory: kcTrade.TradeHistoryCallback = (
  Trades: kcHistoryTrade[]
): void => {
  //console.log("OnTradeHistory");
  if (m_mdAccountLDF) m_mdAccountLDF.UpdateHistoryTrade(Trades);
};

var OnTradeResault = (
  _bSucceed: boolean,
  _szMsg: string,
  _HTrade: kcHistoryTrade
): void => {
  _SendTradeEvent(_bSucceed, _szMsg, _HTrade);
};

/* --------------------------------------------------------------------------------- */
// 註冊列表Event
var OnRegListUpdate = (_Param: RegListUpdateParam) => {
  if (_Param.Added && _Param.Added.length > 0) Register(_Param.Added);
  if (_Param.Removed && _Param.Removed.length > 0) {
    UnRegister(_Param.Removed);
    // 反註冊時清除Tick資料
    for (let szStockCode of _Param.Removed) GetUnit(szStockCode)?.Clear();
  }
};

/* --------------------------------------------------------------------------------- */
// Quote Function
var IsUSDCommodity = (_szStockCode: string): number => {
  return _szStockCode.indexOf("USD");
};

export var GetUnit = (_szStockCode: string): kcUnitModel | undefined => {
  if (!dicUnit.has(_szStockCode)) return undefined;
  return dicUnit.get(_szStockCode);
};
export var GetUnit_Async = async (
  _szStockCode: string
): Promise<kcUnitModel | undefined> => {
  let kcUnit = GetUnit(_szStockCode);
  if (kcUnit !== undefined) return kcUnit;

  if (
    dicCommodityList.size != 0 &&
    dicCommodityList.get(_szStockCode) === undefined
  )
    return undefined;

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(GetUnit_Async(_szStockCode));
    }, 50);
  });
};
var UpdateToUSD = (
  _StickCode: string,
  _AskPrice: number,
  _BidPrice: number
): void => {
  if (_StickCode.length != 6) return;

  let nIdx: number = IsUSDCommodity(_StickCode);
  if (nIdx == -1) return;

  let Price: number = (_AskPrice + _BidPrice) / 2;
  let Key: string;
  if (nIdx == 3) {
    // Ex: EURUSD
    Key = _StickCode.substring(0, 3);
  } else {
    Key = _StickCode.substring(3);
    Price = 1 / Price;
  }

  dicToUSD.set(Key, Price);
};
export var GetToUSD = (_szStockCode: string): number | undefined => {
  if (_szStockCode.length != 6) return undefined;

  let Key: string = _szStockCode.substring(3);
  return dicToUSD.get(Key);
};

/* --------------------------------------------------------------------------------- */
// Unit Event
export var UnitEvent_Add = (UnitEventCallback: UnitEventCallback) => {
  if (UnitEventCallback) OnUnit_Event.push(UnitEventCallback);
};
export var UnitEvent_Remove = (UnitEventCallback: UnitEventCallback) => {
  if (UnitEventCallback) {
    let nIdx = OnUnit_Event.indexOf(UnitEventCallback);
    if (nIdx >= 0) OnUnit_Event.splice(nIdx, 1);
  }
};
var _SendUnitEvent = (mdUnit: kcUnitModel): void => {
  for (let i = 0; i < OnUnit_Event.length; i++) OnUnit_Event[i](mdUnit);
};

/* ------------------------------------------------------------------------- */
// Trade Event
var OnTrade_Event: kcTrade.delTradeCallback[] = [];
export var TradeEvent_Add = (TradeEventCallback: kcTrade.delTradeCallback) => {
  if (TradeEventCallback) OnTrade_Event.push(TradeEventCallback);
};
export var TradeEvent_Remove = (
  TradeEventCallback: kcTrade.delTradeCallback
) => {
  if (TradeEventCallback) {
    let nIdx = OnTrade_Event.indexOf(TradeEventCallback);
    if (nIdx >= 0) OnTrade_Event.splice(nIdx, 1);
  }
};
var _SendTradeEvent = (
  _bSucceed: boolean,
  _szMsg: string,
  _HTrade: kcHistoryTrade
): void => {
  for (let i = 0; i < OnTrade_Event.length; i++)
    OnTrade_Event[i](_bSucceed, _szMsg, _HTrade);
};

// 註冊(訂閱)Tick
export var Register = (Stocks?: string | string[]) => {
  if (Stocks === undefined) {
    kcQuote.Register(Stocks);
    return;
  }

  // 含有"USD"的商品為固定必須註冊的, 所以在這邊排除掉
  let Units: kcUnitModel[] = []; // 所有註冊要求的商品, 有的話要先往下發一次
  let aRegList: string[] = []; // 不含"USD" 必須註冊的商品
  if (typeof Stocks === "string") {
    if (IsUSDCommodity(Stocks) < 0) aRegList.push(Stocks);

    // 嘗試尋找已經存在的Unit
    let mdUnit = dicUnit.get(Stocks);
    if (mdUnit && mdUnit.LastInfo) Units.push(mdUnit);
  } else if (Array.isArray(Stocks)) {
    Stocks.forEach((szStock) => {
      if (IsUSDCommodity(szStock) < 0) aRegList.push(szStock);

      // 嘗試尋找已經存在的Unit
      let mdUnit = dicUnit.get(szStock);
      if (mdUnit && mdUnit.LastInfo) Units.push(mdUnit);
    });
  }

  if (aRegList.length > 0) kcQuote.Register(aRegList); // 註冊

  // 先往下發一次註冊的Unit
  if (Units.length > 0) {
    Units.forEach((mdUnit) => _SendUnitEvent(mdUnit));
  }
};
// 反註冊(反訂閱)Tick
export var UnRegister = (Stocks?: string | string[]) => {
  if (Stocks === undefined) {
    kcQuote.UnRegister(Stocks);
    return;
  }

  let aRegList: string[] = [];
  if (typeof Stocks === "string") {
    if (IsUSDCommodity(Stocks) < 0) aRegList.push(Stocks);
  } else if (Array.isArray(Stocks)) {
    Stocks.forEach((szStock) => {
      if (IsUSDCommodity(szStock) < 0) aRegList.push(szStock);
    });
  }

  if (aRegList.length > 0) kcQuote.UnRegister(aRegList);
};
// 註冊(訂閱)Tick, 先以註冊列表篩選過
export var Register_CheckRegList = (StockCode: string) => {
  if (!StockCode) {
    return;
  }
  if (RegListHelper.ContainsItem(StockCode)) return;
  Register(StockCode);
};
// 反註冊(反訂閱)Tick, 先以註冊列表篩選過
export var UnRegister_CheckRegList = (StockCode: string) => {
  if (!StockCode) {
    return;
  }
  if (RegListHelper.ContainsItem(StockCode)) return;
  UnRegister(StockCode);
};

export var GetCommodity = (_StockCode: string) => {
  return dicCommodityList.get(_StockCode);
};
export var GetCommodityList = () => {
  return [...dicCommodityList.values()];
};

export var AccountLDF = () => {
  return m_mdAccountLDF;
};
export var AccountLDF_GetInventoryList = (): kcInventoryLDFModel[] => {
  if (m_mdAccountLDF) return m_mdAccountLDF.GetInventoryList();
  return [];
};

export var Trade_NewOrder = (
  _szStockCode: string,
  _ButSellType: BuySellType,
  _Vol: number,
  _Price: number = 0,
  _StopProfitPrice: number = -1,
  _StopLossPrice: number = -1,
  _DeadlineTime?: Moment
) => {
  let mdTrade = new kcHistoryTrade();
  mdTrade.StockCode = _szStockCode;
  mdTrade.Time = moment().utc();
  mdTrade.Type = "委託";
  mdTrade.BuySellType = _ButSellType;
  mdTrade.Vol = _Vol;

  mdTrade.Price = _Price;
  mdTrade.StopProfitPrice = _StopProfitPrice;
  mdTrade.StopLossPrice = _StopLossPrice;
  mdTrade.DeadlineTime = _DeadlineTime;
  Trade(mdTrade);
};
export var Trade_Balance = (_mdTrade: kcHistoryTrade) => {
  if (!_mdTrade) return;
  let mdBalance = _mdTrade.Clone();
  mdBalance.Time = moment().utc();
  mdBalance.Type = "平倉";
  Trade(mdBalance);
};
export var Trade_Delete = (_mdTrade: kcHistoryTrade) => {
  if (!_mdTrade) return;
  let mdBalance = _mdTrade.Clone();
  mdBalance.Time = moment().utc();
  mdBalance.Price = -1;
  mdBalance.Type = "改單";
  Trade(mdBalance);
};
export var Trade = (_mdTrade: kcHistoryTrade) => {
  kcTrade.Trade_TradeOrder(_mdTrade);
};

Init();
