import { kcColor } from "../constants/Colors";
import kcData, { kcUnitModel } from "../kcData";
import kcHistoryCollection, {
  delOnHistoryChanged,
  HistoryChangedType,
} from "../kcData/kcHistoryCollection";
import { MathEx } from "../kcExternal";
import { kcHistoryOHLCModel, kcTickModel } from "../kcModel";
import { HistoryDataType } from "../kcTransfer/InternalDefine";
import { kcTECPExtensions } from "./kcTECPExtensions";
import {
  kcBBandData,
  kcKDData,
  kcMACDData,
  kcMaData,
  kcRSIData,
} from "./TECPData";

import TECPSetting, { MaSetting, TECPSettingHelper } from "./TECPSetting";
import { XUnit } from "./XInfo";
import { YUnit } from "./YInfo";

export type TECPDataParam = {
  StockCode?: string;
  HType?: HistoryDataType | "Tick" | "Minute" | "Day";
  HBase?: number;
  Recover?: boolean;
};

export type HistoryParams = {
  HistoryType: HistoryDataType;
  HistoryBase: number;
  RecoverMode: boolean;
};

export type delOnTECPChanged = (
  TECP: kcTECPCollection,
  ChangedType: HistoryChangedType
) => void;

export default class kcTECPCollection {
  constructor(_Params?: TECPDataParam, _fOnTECPChanged?: delOnTECPChanged) {
    this.m_fOnTECPChanged = _fOnTECPChanged;
    if (_Params) this.UpdateTECPDataParam_Core(_Params);

    this.m_Setting = TECPSettingHelper.CreateDefault();
    this.m_KLine_MA0 = new kcMaData<kcHistoryOHLCModel>(
      this,
      this.m_mlOHLC,
      this.m_Setting.KLine_Ma0,
      (_md) => _md.ClosePrice
    );

    this.m_KLine_MA1 = new kcMaData<kcHistoryOHLCModel>(
      this,
      this.m_mlOHLC,
      this.m_Setting.KLine_Ma1,
      (_md) => _md.ClosePrice
    );

    this.m_KLine_MA2 = new kcMaData<kcHistoryOHLCModel>(
      this,
      this.m_mlOHLC,
      this.m_Setting.KLine_Ma2,
      (_md) => _md.ClosePrice
    );

    this.m_KLine_MA3 = new kcMaData<kcHistoryOHLCModel>(
      this,
      this.m_mlOHLC,
      this.m_Setting.KLine_Ma3,
      (_md) => _md.ClosePrice
    );

    this.m_BBand = new kcBBandData(this, this.m_Setting.BBand);
    this.m_KD = new kcKDData(this, this.m_Setting.KD);
    this.m_RSI = new kcRSIData(this, this.m_Setting.RSI);
    this.m_MACD = new kcMACDData(this, this.m_Setting.MACD);

    TECPSettingHelper.ReadSetting()
      .then((_Setting) => {
        this.UpdateSetting(_Setting);
        this.m_Setting.KLine.SetUpDownStyle(this.m_Setting.KLine.UpDownStyle);
      })
      .catch(() => {})
      .finally(() => {
        this.m_bSettingReady = true;
      });
  }

  private m_fOnTECPChanged?: delOnTECPChanged = undefined;
  private m_szStockCode: string = "";
  private m_FocusHistory?: kcHistoryCollection = undefined;
  private m_HType: HistoryDataType = HistoryDataType.Minute;
  private m_nHistoryBase: number = 1;
  private m_bRecover: boolean = false;

  private m_bSettingReady: boolean = false;
  private m_Setting: TECPSetting;

  private m_kcUnit?: kcUnitModel = undefined;
  private m_mlOHLC: kcHistoryOHLCModel[] = []; // 加點差 回補
  private m_KLine_MA0: kcMaData<kcHistoryOHLCModel>;
  private m_KLine_MA1: kcMaData<kcHistoryOHLCModel>;
  private m_KLine_MA2: kcMaData<kcHistoryOHLCModel>;
  private m_KLine_MA3: kcMaData<kcHistoryOHLCModel>;
  private m_BBand: kcBBandData;
  private m_KD: kcKDData;
  private m_RSI: kcRSIData;
  private m_MACD: kcMACDData;

  public get kcUnit() {
    return this.m_kcUnit;
  }
  public get Commodity() {
    return this.m_kcUnit?.Commodity;
  }
  public get HistoryParams(): HistoryParams {
    return {
      HistoryType: this.m_HType,
      HistoryBase: this.m_nHistoryBase,
      RecoverMode: this.m_bRecover,
    };
  }
  public get SettingReady() {
    return this.m_bSettingReady;
  }
  public get Setting() {
    return this.m_Setting;
  }
  public get mlOHLC() {
    return this.m_mlOHLC;
  }
  public get KLine_MA0() {
    return this.m_KLine_MA0;
  }
  public get KLine_MA1() {
    return this.m_KLine_MA1;
  }
  public get KLine_MA2() {
    return this.m_KLine_MA2;
  }
  public get KLine_MA3() {
    return this.m_KLine_MA3;
  }
  public get BBand() {
    return this.m_BBand;
  }
  public get KD() {
    return this.m_KD;
  }
  public get RSI() {
    return this.m_RSI;
  }
  public get MACD() {
    return this.m_MACD;
  }

  public SetCallback(_fOnTECPChanged?: delOnTECPChanged) {
    this.m_fOnTECPChanged = _fOnTECPChanged;
  }
  public ChangeTECP(_Params: TECPDataParam) {
    this.UpdateTECPDataParam_Core(_Params);
  }
  public Clear() {
    this.m_FocusHistory?.Clear();
    this.m_fOnTECPChanged = undefined;
  }

  private UpdateTECPDataParam_Core(_Params: TECPDataParam) {
    let bChangeUnit = this.ChangeStock(_Params.StockCode);
    let bChangeHtype = this.ChangeHType(_Params.HType);
    let bChangeHBase = this.ChangeHBase(_Params.HBase);
    let bChangeRecover = this.ChangeRecover(_Params.Recover);

    if (!bChangeUnit && !bChangeHtype && !bChangeHBase && !bChangeRecover)
      return;

    if (bChangeUnit || bChangeHtype) {
      this.m_FocusHistory?.Clear();
      //this.m_FocusHistory = this.m_kcUnit?.GetHistory(this.m_HType);
    }
    //this.m_FocusHistory?.Get(this.OnHistoryCollectionUpdate);

    // 等到取得Unit => 註冊 => Request
    kcData.GetUnit_Async(this.m_szStockCode).then((_kcUnit) => {
      this.m_kcUnit = _kcUnit;
      if (this.m_kcUnit) {
        kcData.Register_CheckRegList(this.m_kcUnit.StockCode);
        this.m_FocusHistory = this.m_kcUnit?.GetHistory(this.m_HType);
        this.m_FocusHistory?.Get(this.OnHistoryCollectionUpdate);
      }
    });
  }
  private ChangeStock(_szStockCode?: string): boolean {
    let bChanged = false;
    if (_szStockCode) {
      if (!this.m_szStockCode || this.m_szStockCode !== _szStockCode) {
        // 嘗試反註冊
        if (this.m_szStockCode && this.m_szStockCode.length > 0)
          kcData.UnRegister_CheckRegList(this.m_szStockCode);
        this.m_szStockCode = _szStockCode;
        bChanged = true;
      }
    }

    if (bChanged) {
      this.m_kcUnit = undefined;
    }

    return bChanged;
  }
  private ChangeHType(_HType?: HistoryDataType | "Tick" | "Minute" | "Day") {
    if (_HType) {
      let NewHType = HistoryDataType.UnDef;

      if (_HType === HistoryDataType.Tick || _HType === "Tick")
        NewHType = HistoryDataType.Tick;
      else if (_HType === HistoryDataType.Minute || _HType === "Minute")
        NewHType = HistoryDataType.Minute;
      else if (_HType === HistoryDataType.Day || _HType === "Day")
        NewHType = HistoryDataType.Day;

      if (NewHType !== HistoryDataType.UnDef && this.m_HType !== NewHType) {
        this.m_HType = NewHType;

        // Setting存檔
        if (this.m_Setting.Global.DataType !== NewHType) {
          this.m_Setting.Global.DataType = NewHType;
          this.UpdateSettingCore(this.m_Setting);
        }
        return true;
      }
    }
    return false;
  }
  private ChangeHBase(_HBase?: number): boolean {
    if (_HBase !== undefined) {
      _HBase = Math.floor(_HBase);
      if (this.m_nHistoryBase !== _HBase) {
        this.m_nHistoryBase = _HBase;

        // Setting存檔
        if (this.m_Setting.Global.DataMultiple !== _HBase) {
          this.m_Setting.Global.DataMultiple = _HBase;
          this.UpdateSettingCore(this.m_Setting);
        }

        return true;
      }
    }
    return false;
  }
  private ChangeRecover(_bRecover?: boolean): boolean {
    if (_bRecover !== undefined) {
      if (this.m_bRecover !== _bRecover) {
        this.m_bRecover = _bRecover;

        // Setting存檔
        if (this.m_Setting.Global.RecoverMode !== _bRecover) {
          this.m_Setting.Global.RecoverMode = _bRecover;
          this.UpdateSettingCore(this.m_Setting);
        }

        return true;
      }
    }
    return false;
  }

  // Setting
  public UpdateSetting(_TECPSetting: TECPSetting) {
    if (!_TECPSetting) return;
    this.UpdateTECPDataParam_Core({
      HType: _TECPSetting.Global.DataType,
      HBase: _TECPSetting.Global.DataMultiple,
      Recover: _TECPSetting.Global.RecoverMode,
    });
    this.UpdateSettingCore(_TECPSetting);
  }
  private UpdateSettingCore(_TECPSetting: TECPSetting) {
    if (!_TECPSetting) return;

    // Setting存檔
    this.m_Setting.Global.SetSetting(_TECPSetting.Global);
    this.m_Setting.KLine.SetSetting(_TECPSetting.KLine);
    this.m_KLine_MA0.UpdateSetting(_TECPSetting.KLine_Ma0);
    this.m_KLine_MA1.UpdateSetting(_TECPSetting.KLine_Ma1);
    this.m_KLine_MA2.UpdateSetting(_TECPSetting.KLine_Ma2);
    this.m_KLine_MA3.UpdateSetting(_TECPSetting.KLine_Ma3);
    this.m_BBand.UpdateSetting(_TECPSetting.BBand);
    this.m_KD.UpdateSetting(_TECPSetting.KD);
    this.m_RSI.UpdateSetting(_TECPSetting.RSI);
    this.m_MACD.UpdateSetting(_TECPSetting.MACD);
    this.m_Setting.Order.SetSetting(_TECPSetting.Order);
    TECPSettingHelper.SaveSetting(this.m_Setting);
  }
  public UpdateSetting_XDataDis(_XDataDis: number) {
    if (!this.m_bSettingReady) return;
    if (this.m_Setting.Global.XDataDis === _XDataDis) return;

    this.m_Setting.Global.XDataDis = _XDataDis;
    TECPSettingHelper.SaveSetting(this.m_Setting);
  }

  private OnHistoryCollectionUpdate: delOnHistoryChanged = (
    _HistoryCollection,
    _ChangedType,
    _Data
  ) => {
    switch (_ChangedType) {
      case "Init":
        let mlOHLC: kcHistoryOHLCModel[] =
          _HistoryCollection.GetPureOHLC_WithPointDiff();
        if (mlOHLC) this.InitTECP(mlOHLC);
        break;
      case "Update":
        let mdTick: kcTickModel = _Data as kcTickModel;
        if (mdTick) this.UpdateTECP(mdTick);
        break;
      case "Clear":
        this.ClearTECP();
        break;
    }
  };

  private InitTECP(_mlOHLC: kcHistoryOHLCModel[]) {
    this.m_mlOHLC.length = 0; // Clear

    if (!this.m_kcUnit || !this.m_kcUnit.Commodity) return;

    this.m_mlOHLC.push(
      ...kcTECPExtensions.InitOHLCExData(
        _mlOHLC,
        this.m_kcUnit.Commodity,
        this.HistoryParams
      )
    );

    this.m_KLine_MA0.Init();
    this.m_KLine_MA1.Init();
    this.m_KLine_MA2.Init();
    this.m_KLine_MA3.Init();
    this.m_BBand.Init();
    this.m_KD.Init();
    this.m_RSI.Init();
    this.m_MACD.Init();
    this.m_fOnTECPChanged?.call(this, this, "Init");
  }
  private UpdateTECP(_mdTick: kcTickModel) {
    if (!this.m_kcUnit || !this.m_kcUnit.Commodity) return;

    let mdTickClone = kcTickModel.CloneAddDiff(
      _mdTick,
      this.m_kcUnit.PointDiff
    );
    let UpdateResault = kcTECPExtensions.UpdateOHLCExData(
      this.m_mlOHLC,
      [mdTickClone],
      this.m_kcUnit.Commodity,
      this.HistoryParams
    );

    if (UpdateResault.HasUpdate) {
      this.m_KLine_MA0.Update(UpdateResault.StartIdx, UpdateResault.EndIdx);
      this.m_KLine_MA1.Update(UpdateResault.StartIdx, UpdateResault.EndIdx);
      this.m_KLine_MA2.Update(UpdateResault.StartIdx, UpdateResault.EndIdx);
      this.m_KLine_MA3.Update(UpdateResault.StartIdx, UpdateResault.EndIdx);
      this.m_BBand.Update(UpdateResault.StartIdx, UpdateResault.EndIdx);
      this.m_KD.Update(UpdateResault.StartIdx, UpdateResault.EndIdx);
      this.m_RSI.Update(UpdateResault.StartIdx, UpdateResault.EndIdx);
      this.m_MACD.Update(UpdateResault.StartIdx, UpdateResault.EndIdx);
    }

    this.m_fOnTECPChanged?.call(this, this, "Update");
  }
  private ClearTECP() {
    this.m_fOnTECPChanged?.call(this, this, "Clear");
  }

  public GetOHLCEx(_nIndex: number) {
    if (_nIndex < 0 || _nIndex >= this.m_mlOHLC.length) return undefined;
    return this.m_mlOHLC[_nIndex];
  }
  public TopInfo_KLine(_bMa: boolean, _bBBand: boolean) {
    let TopInfoData: TopInfo[] = [];
    let aData = this.m_mlOHLC;
    if (aData.length == 0) return TopInfoData;

    let { OpenPrice, HighPrice, LowPrice, ClosePrice } = {
      ...aData[aData.length - 1],
    };
    let FlowNumber = this.m_kcUnit?.Commodity?.FloatNum ?? 5;

    let KLineSetting = this.m_Setting.KLine;

    let mdOpen: TopInfo = {
      Text: "開盤",
      Value: MathEx.kcRound(OpenPrice, FlowNumber).toString(),
      Color: KLineSetting.EqualKColor,
    };
    let mdHigh: TopInfo = {
      Text: "最高",
      Value: MathEx.kcRound(HighPrice, FlowNumber).toString(),
      Color: KLineSetting.EqualKColor,
    };
    let mdLow: TopInfo = {
      Text: "最低",
      Value: MathEx.kcRound(LowPrice, FlowNumber).toString(),
      Color: KLineSetting.EqualKColor,
    };
    let mdClose: TopInfo = {
      Text: "收盤",
      Value: MathEx.kcRound(ClosePrice, FlowNumber).toString(),
      Color: KLineSetting.EqualKColor,
    };

    if (aData.length > 1) {
      let fColor = (_dPrePrice: number, _nNowPrice: number) => {
        if (_nNowPrice > _dPrePrice) return KLineSetting.UpKColor;
        else if (_nNowPrice < _dPrePrice) return KLineSetting.DownKColor;
        else return KLineSetting.EqualKColor;
      };
      let dPreClose = aData[aData.length - 2].ClosePrice;
      mdOpen.Color = fColor(dPreClose, OpenPrice);
      mdHigh.Color = fColor(dPreClose, HighPrice);
      mdLow.Color = fColor(dPreClose, LowPrice);
      mdClose.Color = fColor(dPreClose, ClosePrice);
    }
    TopInfoData.push(mdOpen, mdHigh, mdLow, mdClose);

    // KLine MA
    if (_bMa) {
      this.m_KLine_MA0.AddTopInfo(TopInfoData);
      this.m_KLine_MA1.AddTopInfo(TopInfoData);
      this.m_KLine_MA2.AddTopInfo(TopInfoData);
      this.m_KLine_MA3.AddTopInfo(TopInfoData);
    }
    // BBand
    if (_bBBand) this.m_BBand.AddTopInfo(TopInfoData);

    return TopInfoData;
  }
  public TopInfo_MA() {
    let TopInfoData: TopInfo[] = [];
    this.m_KLine_MA0.AddTopInfo(TopInfoData);
    this.m_KLine_MA1.AddTopInfo(TopInfoData);
    this.m_KLine_MA2.AddTopInfo(TopInfoData);
    this.m_KLine_MA3.AddTopInfo(TopInfoData);
    return TopInfoData;
  }
  public TopInfo_BBand() {
    let TopInfoData: TopInfo[] = [];
    this.m_BBand.AddTopInfo(TopInfoData);
    return TopInfoData;
  }

  public LeftInfo_Time(_nDataIndex: number) {
    let TopInfoData: TopInfo[] = [];
    let aData = this.m_mlOHLC;
    if (_nDataIndex < 0 || _nDataIndex >= aData.length) return TopInfoData;

    let Time = aData[_nDataIndex].Time;
    let mdDate: TopInfo = {
      Text: "日期",
      Value: Time.format("YYYY/MM/DD"),
      Color: kcColor("Yellow"),
    };
    TopInfoData.push(mdDate);

    let szTimeFormat = "";
    if (this.m_HType == HistoryDataType.Tick) szTimeFormat = "HH:mm:ss";
    else if (this.m_HType == HistoryDataType.Minute) szTimeFormat = "HH:mm";
    else return TopInfoData;

    let mdTime: TopInfo = {
      Text: "時間",
      Value: Time.format(szTimeFormat),
      Color: kcColor("Yellow"),
    };
    TopInfoData.push(mdTime);
    return TopInfoData;
  }
  public LeftInfo_KLine(_nDataIndex: number, _bMa: boolean, _bBBand: boolean) {
    let TopInfoData: TopInfo[] = [];
    let aData = this.m_mlOHLC;
    if (_nDataIndex < 0 || _nDataIndex >= aData.length) return TopInfoData;

    let { OpenPrice, HighPrice, LowPrice, ClosePrice } = {
      ...aData[_nDataIndex],
    };
    let FlowNumber = this.m_kcUnit?.Commodity?.FloatNum ?? 5;

    let KLineSetting = this.m_Setting.KLine;

    let mdOpen: TopInfo = {
      Text: "開盤",
      Value: MathEx.kcRound(OpenPrice, FlowNumber).toString(),
      Color: KLineSetting.EqualKColor,
    };
    let mdHigh: TopInfo = {
      Text: "最高",
      Value: MathEx.kcRound(HighPrice, FlowNumber).toString(),
      Color: KLineSetting.EqualKColor,
    };
    let mdLow: TopInfo = {
      Text: "最低",
      Value: MathEx.kcRound(LowPrice, FlowNumber).toString(),
      Color: KLineSetting.EqualKColor,
    };
    let mdClose: TopInfo = {
      Text: "收盤",
      Value: MathEx.kcRound(ClosePrice, FlowNumber).toString(),
      Color: KLineSetting.EqualKColor,
    };

    let nPreDataIndex = _nDataIndex - 1;
    if (nPreDataIndex >= 0 && nPreDataIndex < aData.length) {
      let fColor = (_dPrePrice: number, _nNowPrice: number) => {
        if (_nNowPrice > _dPrePrice) return KLineSetting.UpKColor;
        else if (_nNowPrice < _dPrePrice) return KLineSetting.DownKColor;
        else return KLineSetting.EqualKColor;
      };
      let dPreClose = aData[nPreDataIndex].ClosePrice;
      mdOpen.Color = fColor(dPreClose, OpenPrice);
      mdHigh.Color = fColor(dPreClose, HighPrice);
      mdLow.Color = fColor(dPreClose, LowPrice);
      mdClose.Color = fColor(dPreClose, ClosePrice);
    }
    TopInfoData.push(mdOpen, mdHigh, mdLow, mdClose);

    // KLine MA
    if (_bMa) {
      this.m_KLine_MA0.AddLeftInfo(_nDataIndex, TopInfoData);
      this.m_KLine_MA1.AddLeftInfo(_nDataIndex, TopInfoData);
      this.m_KLine_MA2.AddLeftInfo(_nDataIndex, TopInfoData);
      this.m_KLine_MA3.AddLeftInfo(_nDataIndex, TopInfoData);
    }
    // BBand
    if (_bBBand) this.m_BBand.AddLeftInfo(_nDataIndex, TopInfoData);

    return TopInfoData;
  }
  public TopInfo_Vol() {
    // TopInfo
    let TopInfoData: TopInfo[] = [];
    let aData = this.m_mlOHLC;
    let VolSetting = this.m_Setting.Vol;

    if (this.m_mlOHLC.length > 0) {
      let dVol = aData[aData.length - 1].Vol;
      let szColor = VolSetting.EqualKColor;
      if (aData.length > 1) {
        let dPreVol = aData[aData.length - 2].Vol;
        if (dVol > dPreVol) szColor = VolSetting.UpKColor;
        if (dVol < dPreVol) szColor = VolSetting.DownKColor;
      }
      TopInfoData.push({
        Text: "成交量",
        Value: dVol.toString(),
        Color: szColor,
      });
    }
    return TopInfoData;
  }
  public LeftInfo_Vol(_nDataIndex: number) {
    // TopInfo
    let TopInfoData: TopInfo[] = [];
    let aData = this.m_mlOHLC;
    if (_nDataIndex < 0 || _nDataIndex >= aData.length) return TopInfoData;

    let VolSetting = this.m_Setting.Vol;

    let dVol = aData[_nDataIndex].Vol;
    let szColor = VolSetting.EqualKColor;

    let nPreDataIndex = _nDataIndex - 1;
    if (nPreDataIndex >= 0 && nPreDataIndex < aData.length) {
      let dPreVol = aData[nPreDataIndex].Vol;
      if (dVol > dPreVol) szColor = VolSetting.UpKColor;
      if (dVol < dPreVol) szColor = VolSetting.DownKColor;
    }
    TopInfoData.push({
      Text: "成交量",
      Value: dVol.toString(),
      Color: szColor,
    });

    return TopInfoData;
  }

  ToString() {
    let sz = "";
    sz += `[${this.m_kcUnit?.StockCode}] `;
    sz += `${this.m_nHistoryBase} - `;
    sz += HistoryDataType[this.m_HType];

    return sz;
  }
}

export type SvgLinePath = {
  PathColor: string;
  PathWidth: number;
  SvgPath: string;
};

export type TopInfo = {
  Text: string;
  TextColor?: string;
  Value: string;
  Color?: string;
};
