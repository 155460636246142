import React from "react";
import {
  ActivityIndicator,
  LayoutChangeEvent,
  SafeAreaView,
  StyleSheet,
} from "react-native";
import { View, Text } from "../components/Themed";
import { Button } from "react-native-elements";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { kcColor } from "../constants/Colors";
import {
  ArrayExtensions,
  kcSetState,
  kcSetUnmount,
  MathEx,
} from "../kcExternal";

import Svg, {
  Rect,
  Line,
  Text as SVGText,
  Path,
  Defs,
  ClipPath,
  TSpan,
} from "react-native-svg";
import {
  PanGestureHandler,
  PinchGestureHandler,
  TapGestureHandler,
  GestureEvent,
  GestureEventPayload,
  PanGestureHandlerEventPayload,
  State,
  PinchGestureHandlerEventPayload,
} from "react-native-gesture-handler";
import { kcHistoryTrade } from "../kcModel";
import YInfo, { YUnit } from "../kcTECP/YInfo";
import XInfo, { eXUnitDisplay } from "../kcTECP/XInfo";
import kcDrawHelper, { BasicTimeModel } from "../kcTECP/kcDrawHelper";
import { kcTECPCollection, TECPSettingEditHelper } from "../kcTECP";
import {
  delOnTECPChanged,
  SvgLinePath,
  TopInfo,
} from "../kcTECP/kcTECPCollection";
import kcData, {
  kcAccountLDFModel,
  kcInventoryLDFModel,
  OrderParamHelper,
} from "../kcData";
import kcAnimated, {
  kcAnimatedDecay,
  kcAnimatedOneTime,
} from "../kcExternal/kcAnimated";
import { CompositeNavigationProp, RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { BottomTabParamList, TabTECPParamList } from "../types";
import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs";
import { HistoryDataType } from "../kcTransfer/InternalDefine";
import KC_HTypeModal, { cHType, FindcHType } from "../components/kcHTypeModal";
import KC_TECPScreenTopInfo, {
  TopItemClickType,
} from "../components/KC_TECPScreenTopInfo";
import {
  AccountLDFUpdateType,
  OnAccountLDFUpdate_Event,
} from "../kcData/kcAccountLDFModel";
import KC_OrderModal, { OrderModalType } from "../components/kcOrderModal";
import { KLineDispalyType } from "../kcTECP/TECPSetting/TECPSettingDefine";
import {
  IsBottomTabHieghtToLow,
  ToLowCallback,
} from "../navigation/BottomTabNavigator";

enum DeputyTECP {
  Vol = 0,
  KD = 1,
  RSI = 2,
  MACD = 3,
}
enum SubTECP {
  None = 0,
  MA = 1,
  BBand = 2,
}
const DeputyTECPArray = Object.keys(DeputyTECP)
  .filter((value) => isNaN(Number(value)) === false)
  .map((value) => Number(value));
const SubTECPArray = Object.keys(SubTECP)
  .filter((value) => isNaN(Number(value)) === false)
  .map((value) => Number(value));

type IState = {
  nFixedRedraw: number; // 笨方式 用來強制繪圖
  bIsScreenFocus: boolean;
  loading: boolean;
};

type IProp = {
  route: RouteProp<TabTECPParamList, "TabTECPScreen">;
  navigation: CompositeNavigationProp<
    StackNavigationProp<TabTECPParamList, "TabTECPScreen">,
    BottomTabNavigationProp<BottomTabParamList, "TabTECP">
  >;
};

export default class MoveDemo extends React.Component<IProp, IState> {
  state: IState = {
    loading: false,
    nFixedRedraw: 0, // 笨方式 用來強制繪圖
    bIsScreenFocus: true,
  };

  KC_OrderModal: React.RefObject<KC_OrderModal> =
    React.createRef<KC_OrderModal>();

  nFixedRedraw: number = 0;
  m_StockCode: string = "";
  m_HType: HistoryDataType = HistoryDataType.Minute;
  m_HBase: number = 1;
  m_cHType: cHType = new cHType();

  m_TECPS: any = (<></>);
  m_SubTECP: SubTECP = SubTECP.None; // 主圖指標
  m_DeputyTECP: DeputyTECP = DeputyTECP.Vol; // 副圖指標

  m_XInfo: XInfo = new XInfo();
  m_YInfo: YInfo = new YInfo();

  doubleTapRef: any = undefined;
  KC_HTypeModal;
  KC_TECPScreenTopInfo: any = undefined;
  m_AnimatedDecay_Pan: kcAnimatedDecay = new kcAnimatedDecay();
  m_AnimatedDecay_Pinch: kcAnimatedDecay = new kcAnimatedDecay();
  m_AnimatedDecay_Wheel: kcAnimatedOneTime = new kcAnimatedOneTime();
  m_Animated_PanLimit: kcAnimated = new kcAnimated();
  private AnimatedPanLimit_0_Value = 0;
  private AnimatedPanLimit_1_Value = 0;

  m_TECPData: kcTECPCollection;
  m_Inventorys: kcInventoryLDFModel[] = [];
  m_Init = false;
  m_Height: number = 0;
  m_Width: number = 0;

  m_BeginDataPos_Pan?: number = undefined;
  m_pDown_Pan?: { X: number; Y: number } = undefined;

  m_BeginDataDis_Pinch?: number = undefined;
  m_pDown_Pinch?: { X: number; Y: number } = undefined;

  m_bCheckLine: boolean = false;
  m_BeginCheckLinePos?: { X: number; Y: number } = undefined;
  m_pCheckLinePos?: { X: number; Y: number } = undefined;

  constructor(props: any) {
    super(props);

    this.KC_HTypeModal = React.createRef<KC_HTypeModal>();
    this.doubleTapRef = React.createRef();
    this.m_TECPData = new kcTECPCollection(undefined, this.OnTECPCallback);
    this.m_Animated_PanLimit = new kcAnimated(0, 300);
    TECPSettingEditHelper.BeginEdit(this.m_TECPData);
  }
  componentDidMount() {
    window.onwheel = this.OnWindowsMouseWheel;

    ToLowCallback((_ToLow) => {
      this.ChangeHeaderVisable(!_ToLow);
    });

    this.m_AnimatedDecay_Pan.SetCallback(this.OnAnimatedPanValueChange);
    this.m_AnimatedDecay_Pinch.SetCallback(this.OnAnimatedPinchValueChange);
    this.m_AnimatedDecay_Wheel.SetCallback(this.OnAnimatedWheelValueChange);
    this.m_Animated_PanLimit.SetCallback(this.OnAnimatedPanLimitValueChange);

    this.props.navigation.addListener("focus", this.OnFocus);
    this.props.navigation.addListener("blur", this.OnBlur);

    kcAccountLDFModel.AccountLDFUpdateEvent_Add(this.OnAccountLDFUpdate);

    this.props.navigation.setOptions({
      headerLeft: this.Render_HeaderLeft.bind(this, this.m_cHType),
      headerRight: this.Render_HeaderRight,
    });

    this.ChangeHeaderVisable(!IsBottomTabHieghtToLow());

    TECPSettingEditHelper.SettingBuffer?.GetSettingAsync(false)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        this.m_cHType = FindcHType(
          this.m_TECPData.Setting.Global.DataType,
          this.m_TECPData.Setting.Global.DataMultiple
        );

        this.props.navigation.setOptions({
          headerLeft: this.Render_HeaderLeft.bind(this, this.m_cHType),
        });
      });
  }

  componentWillUnmount() {
    this.props.navigation.removeListener("focus", this.OnFocus);
    this.props.navigation.removeListener("blur", this.OnBlur);
    kcAccountLDFModel.AccountLDFUpdateEvent_Remove(this.OnAccountLDFUpdate);
    kcSetUnmount(this, true);
  }

  private FixedRedrawUI = () => {
    // this.m_TECPS = this.Render_TECPs();

    this.nFixedRedraw = this.nFixedRedraw + 1;
    kcSetState(this, { nFixedRedraw: this.nFixedRedraw }); // 強制更動畫面
  };

  private ChangeFocusTECP = (_StockCode: string, _cHType: cHType) => {
    if (this.m_StockCode == _StockCode && this.m_cHType == _cHType) return;

    kcSetState(this, { loading: true });

    this.m_StockCode = _StockCode;
    this.m_cHType = _cHType;
    let TitleBind = this.Render_HeaderTitle.bind(this, _StockCode);
    let LeftBind = this.Render_HeaderLeft.bind(this, _cHType);
    this.props.navigation.setOptions({
      headerTitle: TitleBind,
      headerLeft: LeftBind,
    });

    this.StopAllAnimation();
    this.m_TECPData.ChangeTECP({
      StockCode: _StockCode,
      HType: _cHType.HType,
      HBase: _cHType.HBase,
      Recover: false,
    });

    // if (Unit) this.OnUnitDataChanged(Unit);
  };

  private GetAccount_async = async () => {
    let mdAccount = kcData.AccountLDF();
    if (!mdAccount) {
      setTimeout(this.GetAccount_async, 100);
      return;
    }

    this.OnAccountLDFUpdate(
      mdAccount,
      mdAccount.Account,
      AccountLDFUpdateType.Inventory,
      mdAccount.mlInventory,
      undefined
    );
    this.FixedRedrawUI();
  };

  private StopAllAnimation = () => {
    this.m_AnimatedDecay_Pan.Stop();
    this.m_AnimatedDecay_Pinch.Stop();
    this.m_AnimatedDecay_Wheel.Stop();
    this.m_Animated_PanLimit.Stop();
  };

  private CheckingLineChange = (x?: number, y?: number) => {
    if (!x) x = (this.m_Width * 2) / 3;
    if (!y) y = (this.m_Height * 1) / 3;
    this.m_bCheckLine = !this.m_bCheckLine;
    this.m_pCheckLinePos = {
      X: x,
      Y: y,
    };
    this.FixedRedrawUI();
  };

  private ChangeSubTECP() {
    this.m_SubTECP = (this.m_SubTECP + 1) % SubTECPArray.length;
    this.FixedRedrawUI();
  }
  private ChangeDeputyTECP() {
    this.m_DeputyTECP = (this.m_DeputyTECP + 1) % DeputyTECPArray.length;
    this.FixedRedrawUI();
  }
  private ChangeHeaderVisable = (_Visable: boolean) => {
    this.props.navigation.setOptions({
      header: _Visable ? undefined : () => <></>,
    });
    //ChangeBottomTabVisable(_Visable);
    // this.props.navigation.dangerouslyGetParent()?.setOptions({
    //   tabBarVisible: _Visable,
    // });
  };

  /*---------------------------------------------------------------------------- */
  private OnFocus = () => {
    kcSetState(this, { bIsScreenFocus: true });

    let StockCode = "XAUUSD";
    if (this.props.route.params) StockCode = this.props.route.params.StockCode;
    this.ChangeFocusTECP(StockCode, this.m_cHType);
    this.GetAccount_async();
    //this.FixedRedrawUI();
  };
  private OnBlur = () => {
    kcSetState(this, { bIsScreenFocus: false });
    this.KC_HTypeModal.current?.Close();
  };

  private OnTECPCallback: delOnTECPChanged = (_TECP, _ChangedType) => {
    if (_ChangedType == "Init") {
      this.m_XInfo.ChangeTotalDataNum(_TECP.mlOHLC.length);
      this.m_XInfo.ChangeDisplayIndex(_TECP.mlOHLC.length - 1);
      kcSetState(this, { loading: false });
    } else if (_ChangedType == "Update") {
      this.m_XInfo.ChangeTotalDataNum(_TECP.mlOHLC.length);
      kcSetState(this, { loading: false });
    } else if (_ChangedType == "Clear") {
      kcSetState(this, { loading: true });
    }

    this.FixedRedrawUI();
  };

  private OnAccountLDFUpdate: OnAccountLDFUpdate_Event = (
    _mdSender: kcAccountLDFModel,
    _szAccount: string,
    _Type: AccountLDFUpdateType,
    _Param1: any,
    _Param2: any
  ) => {
    switch (_Type) {
      case AccountLDFUpdateType.Quote: {
        break;
      }
      case AccountLDFUpdateType.Inventory: {
        let mlInventory_Param: kcInventoryLDFModel[];
        mlInventory_Param = _Param1 as kcInventoryLDFModel[];
        // let mlFocusInventory: kcInventoryLDFModel[] = [];
        // mlInventory_Param.forEach((md) => {
        //   if (md.StockCode == this.m_StockCode) mlFocusInventory.push(md);
        // });
        this.m_Inventorys = mlInventory_Param;
        this.FixedRedrawUI();
        break;
      }
      default:
        return;
    }
  };

  /*---------------------------------------------------------------------------- */
  // Wheel
  private OnWindowsMouseWheel = (e: WheelEvent) => {
    this.StopAllAnimation();

    this.m_pDown_Pinch = { X: e.clientX, Y: e.clientY };
    let EndDataDis =
      e.deltaY < 0
        ? this.m_XInfo.DataDis * XInfo.DefaultZoomRate
        : this.m_XInfo.DataDis / XInfo.DefaultZoomRate;

    this.m_AnimatedDecay_Wheel.Start(
      this.m_XInfo.DataDis,
      EndDataDis,
      780,
      0.987
    );
  };

  private OnAnimatedWheelValueChange = (value: number, _bEnd: boolean) => {
    if (!this.m_pDown_Pinch) return;
    this.m_XInfo.Zoom_DataDis(value, this.m_pDown_Pinch.X);
    this.FixedRedrawUI();
  };

  /*---------------------------------------------------------------------------- */
  // Tap
  private OnTapHandlerStateChange = (event: any) => {
    let e = event.nativeEvent;
    this.StopAllAnimation();
    if (e.state == State.END) {
      if (this.m_bCheckLine) {
        this.m_pCheckLinePos = {
          X: e.x,
          Y: e.y,
        };
        this.FixedRedrawUI();
      } else {
        let nIdx = this.m_YInfo.GetFocusYUnitIndex(e.y);
        if (nIdx == 0) this.ChangeSubTECP();
        if (nIdx == 1) this.ChangeDeputyTECP();
      }
    }
  };
  private OnDoubleTapHandlerStateChange = (event: any) => {
    let e = event.nativeEvent;
    if (e.state != State.END) return;
    this.StopAllAnimation();
    this.CheckingLineChange(e.x, e.y);
  };

  /*---------------------------------------------------------------------------- */
  // Pan
  private OnPanGestureEvent = (
    event: GestureEvent<GestureEventPayload & PanGestureHandlerEventPayload> // 事實上裡面還有 target PointInside 可以使用
  ) => {
    let e = event.nativeEvent;
    if (e.state == State.BEGAN || e.state == State.END) {
      return;
    }

    // 影響到Zoom 關掉
    if (e.numberOfPointers == 2) return;

    if (!this.m_pDown_Pan || this.m_BeginDataPos_Pan === undefined) {
      this.m_pDown_Pan = { X: e.x, Y: e.y };
      this.m_BeginDataPos_Pan = this.m_XInfo.ScreenDataPos;
      this.m_BeginCheckLinePos = this.m_pCheckLinePos;
    }

    if (!this.m_bCheckLine) {
      this.m_AnimatedDecay_Pan.UpateAutoVelocity(e.x);
      let XDiff = this.m_pDown_Pan.X - e.x;
      let AnimatedValue = this.m_BeginDataPos_Pan + XDiff;
      this.m_XInfo.ChangeDisplayPos(AnimatedValue);
    } else {
      if (!this.m_BeginCheckLinePos) return;
      let CheckX = this.m_BeginCheckLinePos.X + e.translationX;
      let CheckY = this.m_BeginCheckLinePos.Y + e.translationY;
      if (CheckX < 2) CheckX = 2;
      if (CheckX > this.m_Width) CheckX = this.m_Width;

      if (CheckY < 0) CheckY = 0;
      if (CheckY > this.m_Height) CheckY = this.m_Height;

      this.m_pCheckLinePos = { X: CheckX, Y: CheckY };
    }
    this.FixedRedrawUI();
  };
  private OnPanGestureStateChange = (event: any) => {
    let e = event.nativeEvent;
    if (e.numberOfPointers == 2) {
      if (e.state == State.BEGAN) {
        //this.StopAllAnimation();
      }
      return;
    }

    if (e.state == State.BEGAN) {
      //this.StopAllAnimation();
    }
    if (e.state == State.END) {
      let XInfoScreenDataPos = this.m_XInfo.ScreenDataPos;
      this.m_AnimatedDecay_Pan.Start_AutoVelocity(
        0,
        XInfoScreenDataPos,
        0.998,
        true
      );
      this.m_BeginDataPos_Pan = undefined;
    }
  };
  private OnAnimatedPanValueChange = (
    BaseValue: number,
    value: number,
    _bEnd: boolean
  ) => {
    this.m_XInfo.ChangeDisplayPos(value);
    if (
      this.m_XInfo.ScreenEndDataPos == this.m_XInfo.m_fTotalDataWidth ||
      this.m_XInfo.ScreenDataPos == 0
    ) {
      _bEnd = true;
    }

    if (_bEnd) {
      this.StopAllAnimation();
      this.CheckRunNeedPanLimit();
    }
    this.FixedRedrawUI();
  };
  /*---------------------------------------------------------------------------- */
  // Pan Limit
  private CheckRunNeedPanLimit() {
    let bOverRight = this.m_XInfo.IsOverRightBuffer();
    let bOverLeft = this.m_XInfo.IsOverLeftBuffer();

    if (bOverRight) {
      this.AnimatedPanLimit_0_Value = this.m_XInfo.ScreenDataPos;
      this.AnimatedPanLimit_1_Value =
        this.m_XInfo.m_fTotalDataWidth -
        this.m_XInfo.TECPWidth -
        XInfo.OverLimitBuffer;
      this.m_Animated_PanLimit.RunUp();
    } else if (bOverLeft) {
      this.AnimatedPanLimit_0_Value = this.m_XInfo.ScreenDataPos;
      this.AnimatedPanLimit_1_Value = XInfo.OverLimitBuffer;
      this.m_Animated_PanLimit.RunUp();
    }
  }
  private OnAnimatedPanLimitValueChange = (value: any) => {
    let Pos = kcAnimated.TranferValue(
      value,
      this.AnimatedPanLimit_0_Value,
      this.AnimatedPanLimit_1_Value
    );
    this.m_XInfo.ChangeDisplayPos(Pos);
    this.FixedRedrawUI();
  };

  /*---------------------------------------------------------------------------- */
  // Pinch
  private OnPinchGestureEvent = (
    event: GestureEvent<PinchGestureHandlerEventPayload>
  ) => {
    let e = event.nativeEvent;
    if (e.state == State.BEGAN || e.state == State.END) {
      return;
    }

    if (!this.m_BeginDataDis_Pinch || !this.m_pDown_Pinch) {
      this.m_BeginDataDis_Pinch = this.m_XInfo.DataDis;
      this.m_pDown_Pinch = { X: e.focalX, Y: e.focalY };
    }

    let NewDataDis = this.m_BeginDataDis_Pinch * e.scale;
    this.m_XInfo.Zoom_DataDis(NewDataDis, this.m_pDown_Pinch.X);
    this.m_AnimatedDecay_Pinch.UpateAutoVelocity(NewDataDis);
    this.FixedRedrawUI();
  };
  private OnPinchGestureStateChange = (event: any) => {
    let e = event.nativeEvent;

    if (e.state == State.BEGAN) {
      //this.StopAllAnimation();
      // IosWeb會從PanGesture的2指進去
    }
    if (e.state == State.END) {
      let XInfoDataDis = this.m_XInfo.DataDis;
      this.m_AnimatedDecay_Pinch.Start_AutoVelocity(0, XInfoDataDis, 0.995);
      this.m_BeginDataDis_Pinch = undefined;
    }
  };
  private OnAnimatedPinchValueChange = (BaseValue: number, value: number) => {
    if (!this.m_pDown_Pinch) return;
    this.m_XInfo.Zoom_DataDis(value, this.m_pDown_Pinch.X);
    this.FixedRedrawUI();
  };

  /*---------------------------------------------------------------------------- */
  // Left Right
  private OnBtnChangeHTypeClick = () => {
    this.KC_HTypeModal.current?.Show();
  };
  private OnModalSelectHType = (_cHype: cHType) => {
    this.ChangeFocusTECP(this.m_StockCode, _cHype);
  };
  private OnBtnGoSelectCommodity = () => {
    let RootNavigation = this.props.navigation;
    RootNavigation.push("TabTECPScreen_Select");
  };

  /*---------------------------------------------------------------------------- */
  private onLayout = (e: LayoutChangeEvent) => {
    let nWidth = e.nativeEvent.layout.width;
    let nHeight = e.nativeEvent.layout.height;

    if (nWidth === 0 || nHeight === 0) return; // 頁面切走的時候會是0, state.bIsScreenFocus可能來不及防

    if (!this.m_Init) {
      this.m_Init = true;
      this.m_XInfo.Init(
        100,
        this.m_TECPData.Setting.Global.XDataDis,
        e.nativeEvent.layout.width
      );
      if (this.m_TECPData && this.m_TECPData.mlOHLC.length != 0)
        this.m_XInfo.ChangeDisplayIndex(this.m_TECPData.mlOHLC.length - 1);
      this.m_YInfo.Init(
        e.nativeEvent.layout.height,
        this.m_TECPData.Setting.Global.ShowDeputyTECP
      );
    }

    this.m_Width = e.nativeEvent.layout.width;
    this.m_Height = e.nativeEvent.layout.height;

    this.m_XInfo.ChangePictureWidth(this.m_Width);
    this.m_YInfo.ChangeHeight(
      this.m_Height,
      this.m_TECPData.Setting.Global.ShowDeputyTECP
    );

    //console.log("onLayout", this.m_Width, this.m_Height);

    if (!this.state.bIsScreenFocus) return;
    // 延遲100ms重畫, 減少切子頁面回來時高度會閃的問題
    setTimeout(() => {
      this.FixedRedrawUI();
    }, 100);
  };

  private OnTopInfoItmeClick = (
    _ClickItem: TopItemClickType,
    _Param0?: any
  ) => {
    switch (_ClickItem) {
      case "BuyOrder":
      case "SellOrder": {
        if (this.m_TECPData.Setting.Order.FastOrder) {
          /* ------------------------------ */
          // 商品
          let mdCommodity = this.m_TECPData?.kcUnit?.Commodity;
          if (!mdCommodity) return;
          let StockCode = mdCommodity.StockCode;
          let ContractSize = mdCommodity.ContractSize;

          /* ------------------------------ */
          // 手數
          // let VolHand = _Param0 as string;
          // let nHVol = Number(VolHand);
          // if (!nHVol) {
          //   console.log(`下單手數(${VolHand})錯誤`);
          //   return;
          // }
          let nHVol = this.m_TECPData.Setting.Order.DefaultOrderHandVol;
          let Vol = MathEx.kcRound(nHVol * ContractSize, 0);

          /* ------------------------------ */
          // kcHistoryTrade
          let HTrade: kcHistoryTrade = new kcHistoryTrade();
          HTrade.StockCode = StockCode;
          HTrade.BuySellType = _ClickItem === "BuyOrder" ? "Buy" : "Sell";
          HTrade.Vol = Vol;

          // 下單
          this.KC_OrderModal.current?.Order(HTrade, OrderModalType.New);
        } else {
          OrderParamHelper.NewOrder(this.m_StockCode);
          this.props.navigation.navigate("TabTECPScreen_Order");
        }
        break;
      }
      case "Checking":
        this.CheckingLineChange();
        break;
      case "Setting":
        TECPSettingEditHelper.BeginEdit(this.m_TECPData);
        this.props.navigation.push("TabTECPScreen_Setting");
        break;
      case "cHType":
        this.OnBtnChangeHTypeClick();
        break;
      case "SelectCommodity":
        this.OnBtnGoSelectCommodity();
        break;
    }
  };

  private Render_HeaderLeft = (_Param: cHType) => {
    return (
      <Button
        iconContainerStyle={{ alignSelf: "center" }}
        buttonStyle={styles.button}
        titleStyle={{ fontSize: 18 }}
        title={_Param.sz}
        onPress={this.OnBtnChangeHTypeClick}
      />
    );
  };

  private Render_HeaderRight = () => {
    return (
      <Button
        // style={{ width: 48, height: 48 }}
        style={{ backgroundColor: "#00000000" }}
        buttonStyle={{ backgroundColor: "#00000000" }}
        onPress={this.OnBtnGoSelectCommodity}
        titleStyle={{ fontSize: 16 }}
        // title={"其他"}
        // icon={<Ionicons name="ios-chevron-forward" size={20} color="#ffffff" />}
        icon={
          <MaterialCommunityIcons
            name="text-search"
            size={28}
            color={"#ffffff"}
          />
        }
        // iconPosition="right"
        //iconRight={true}
        iconContainerStyle={{ alignSelf: "center" }}
      />
    );
  };

  private Render_HeaderTitle = (_Param: string) => {
    let Comm = kcData.GetCommodity(_Param);
    if (Comm)
      return (
        <View
          style={{
            backgroundColor: "#00000000",
            //flex: 1,
            width: 300,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              backgroundColor: "#00000000",
              color: kcColor("Yellow"),
              fontSize: 12,
            }}
            selectable={false}
          >
            {_Param}
          </Text>
          <Text
            style={{
              backgroundColor: "#00000000",
              color: kcColor("Title"),
              fontSize: 16,
            }}
            selectable={false}
          >
            {Comm.StockName}
          </Text>
        </View>
      );
    return (
      <View
        style={{
          backgroundColor: "#00000000",
          //flex: 1,
          width: 300,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            backgroundColor: "#00000000",
            color: kcColor("Yellow"),
            fontSize: 12,
          }}
          selectable={false}
        >
          {_Param}
        </Text>
      </View>
    );
  };
  private Render_TECPs = () => {
    if (
      !this.m_TECPData ||
      !this.m_TECPData.mlOHLC ||
      !this.m_TECPData.mlOHLC.length
    )
      return <></>;

    let TimeLines = kcDrawHelper.GetBasicInfo_Time(
      this.m_TECPData.mlOHLC,
      this.m_XInfo,
      this.m_TECPData.HistoryParams
    );
    let DailyLines = kcDrawHelper.GetDailyLines(
      this.m_TECPData.mlOHLC,
      this.m_XInfo,
      this.m_TECPData.HistoryParams,
      this.m_TECPData.Commodity
    );
    let bShowDeputyTECP = this.m_TECPData.Setting.Global.ShowDeputyTECP;
    // SVG座標實測起來比較像是(1,1)開始, Line在(0,0)時會畫不出來
    // 關閉反鋸齒:在元素內加入 shape-rendering="crispEdges"
    return (
      <>
        {this.Render_KLine(TimeLines, DailyLines)}
        {bShowDeputyTECP &&
          this.m_DeputyTECP === DeputyTECP.Vol &&
          this.Render_Vol(TimeLines, DailyLines)}
        {bShowDeputyTECP &&
          this.m_DeputyTECP === DeputyTECP.KD &&
          this.Render_KD(TimeLines, DailyLines)}
        {bShowDeputyTECP &&
          this.m_DeputyTECP === DeputyTECP.RSI &&
          this.Render_RSI(TimeLines, DailyLines)}
        {bShowDeputyTECP &&
          this.m_DeputyTECP === DeputyTECP.MACD &&
          this.Render_MACD(TimeLines, DailyLines)}
        {this.Render_Time(TimeLines, DailyLines)}
        {this.Render_SpecHLine(TimeLines, DailyLines)}
        {this.Render_CheckLine()}
      </>
    );
  };
  private Render_KLine = (
    _TimeLines: BasicTimeModel[],
    _DailyLines: BasicTimeModel[]
  ) => {
    let YUnit = this.m_YInfo.YUnits[0];
    if (!YUnit) return <></>;
    if (this.m_XInfo.lXUnit.length == 0) return <></>;
    let FloatNum = 2;

    if (this.m_TECPData?.kcUnit?.Commodity) {
      let Com = this.m_TECPData.kcUnit.Commodity;
      FloatNum = Com.FloatNum;
      YUnit.SetTickBase(Com.PriceScale[0].TickPrice);
    }

    let GSetting = this.m_TECPData.Setting.Global;
    let KLineSetting = this.m_TECPData.Setting.KLine;

    let Max: { Value: number; DataIdx: number; PosX: number } = {
      Value: Number.MIN_VALUE,
      DataIdx: -1,
      PosX: 0,
    };
    let Min: { Value: number; DataIdx: number; PosX: number } = {
      Value: Number.MAX_VALUE,
      DataIdx: -1,
      PosX: 0,
    };

    this.m_XInfo.lXUnit.map((md) => {
      if (md.eXUnitDisplay == eXUnitDisplay.Empty) return;
      let nDataIdx = md.nDataIndex;
      let Data = this.m_TECPData.mlOHLC[nDataIdx];
      if (!Data) return;
      if (Max.Value < Data.HighPrice)
        Max = { Value: Data.HighPrice, DataIdx: nDataIdx, PosX: md.fXMidPos };
      if (Min.Value > Data.LowPrice)
        Min = { Value: Data.LowPrice, DataIdx: nDataIdx, PosX: md.fXMidPos };
    });

    if (
      Min.DataIdx === -1 &&
      Max.DataIdx === -1 &&
      this.m_XInfo.lXUnit.length > 0
    ) {
      let md = this.m_XInfo.lXUnit[0];
      let nDataIdx = md.nDataIndex;
      if (nDataIdx > -1 && nDataIdx < this.m_TECPData.mlOHLC.length) {
        let Data = this.m_TECPData.mlOHLC[nDataIdx];
        Max = { Value: Data.HighPrice, DataIdx: nDataIdx, PosX: md.fXMidPos };
        Min = { Value: Data.LowPrice, DataIdx: nDataIdx, PosX: md.fXMidPos };
      }
    }

    // 考慮AskPrice的最高最低價
    // if (
    //   this.m_XInfo.lXUnit.findIndex(
    //     (q) => q.nDataIndex === this.m_XInfo.TotalDataNum - 1
    //   ) >= 0
    // ) {
    //   if (this.m_TECPData.kcUnit?.LastInfo?.AskPrice)
    //     Max.Value = Math.max(
    //       Max.Value,
    //       this.m_TECPData.kcUnit?.LastInfo?.AskPrice
    //     );
    //   if (this.m_TECPData.kcUnit?.LastInfo?.AskPrice)
    //     Min.Value = Math.min(
    //       Min.Value,
    //       this.m_TECPData.kcUnit?.LastInfo?.AskPrice
    //     );
    // }
    let KLineMax = { ...Max };
    let KLineMin = { ...Min };
    if (this.m_XInfo.lXUnit.length > 0)
      YUnit.ChangeYValue(Min.Value, Max.Value);

    let SubPaths: SvgLinePath[] = []; // 疊圖Path
    // Ma疊圖
    if (this.m_SubTECP === SubTECP.MA) {
      let SubDatas = [
        this.m_TECPData.KLine_MA0,
        this.m_TECPData.KLine_MA1,
        this.m_TECPData.KLine_MA2,
        this.m_TECPData.KLine_MA3,
      ];
      let SubValues = SubDatas.map((_Data) =>
        _Data.GetValues(this.m_XInfo.lXUnit)
      );
      SubValues.forEach((_SubValue, idx) => {
        if (!_SubValue) return;
        if (!SubDatas[idx].Setting.UpdateMaxMinLimit) return;
        if (_SubValue.Max !== undefined)
          Max.Value = Math.max(Max.Value, _SubValue.Max);
        if (_SubValue.Min !== undefined)
          Min.Value = Math.min(Min.Value, _SubValue.Min);
      });

      if (this.m_XInfo.lXUnit.length > 0)
        YUnit.ChangeYValue(Min.Value, Max.Value);

      for (let i = 0; i < SubValues.length; i++) {
        let SubValue = SubValues[i];
        if (SubValue) {
          let Path = SubDatas[i].GetSVGPath(SubValue, YUnit);
          if (Path) SubPaths.push(Path);
        }
      }
    }
    // BBand疊圖
    if (this.m_SubTECP === SubTECP.BBand) {
      let SubData = this.m_TECPData.BBand;
      let SubValue = SubData.GetValues(this.m_XInfo.lXUnit);
      if (SubValue) {
        if (SubValue.Max !== undefined)
          Max.Value = Math.max(Max.Value, SubValue.Max);
        if (SubValue.Min !== undefined)
          Min.Value = Math.min(Min.Value, SubValue.Min);
        YUnit.ChangeYValue(Min.Value, Max.Value);

        let Path = SubData.GetSVGPath(SubValue, YUnit);
        if (Path) SubPaths.push(...Path);
      }
    }

    let nlGridLines = kcDrawHelper.GetDefaultHorizontaGridLineBase(YUnit);
    let XStartCoord = this.m_XInfo.TECPStartLocation; // 水平線左邊
    let XEndCoord = this.m_XInfo.TECPEndLocation; // 水平線右邊
    let XFontMid = (XEndCoord + this.m_XInfo.PictureWidth) / 2;

    let TopInfos = [this.m_TECPData.TopInfo_KLine(false, false)];
    if (this.m_SubTECP === SubTECP.MA)
      TopInfos.push(this.m_TECPData.TopInfo_MA());
    if (this.m_SubTECP === SubTECP.BBand)
      TopInfos.push(this.m_TECPData.TopInfo_BBand());

    return (
      <>
        {/* ClipPath */}
        <Defs>
          <ClipPath id="KLine">
            <Rect
              x={XStartCoord}
              y={YUnit.nEffectStartY}
              width={XEndCoord - XStartCoord}
              height={YUnit.nEffectHigh}
            />
          </ClipPath>
        </Defs>

        {/* BackGround*/}
        <Rect
          x={0}
          y={YUnit.StartY + 1}
          width={this.m_XInfo.PictureWidth}
          height={YUnit.High - 1}
          fill="#00000000"
          stroke={kcColor("Border")}
          strokeWidth="1"
        />
        {/* <Line
          x1={XEndCoord}
          y1={YUnit.StartY}
          x2={XEndCoord}
          y2={YUnit.EndY}
          stroke={kcColor("Border")}
          strokeWidth="1"
        /> */}

        {/* 垂直格線 */}
        {GSetting.EnableVGridLine &&
          _TimeLines.map((_mdTime, index) => {
            return (
              <Line
                key={`VGrid_${index}`}
                x1={_mdTime.XMidPos}
                y1={YUnit.StartY}
                x2={_mdTime.XMidPos}
                y2={YUnit.EndY}
                stroke={kcColor("Border")}
                strokeDasharray={[5, 5]}
                strokeWidth="1"
              />
            );
          })}
        {/* 水平格線 */}
        {nlGridLines.map((_Value, index) => {
          let YCoord = YUnit.ValueToCoord(_Value);
          return [
            GSetting.EnableHGridLine && (
              <Line
                key={`HGrid_Line_${index}`}
                x1={XStartCoord}
                y1={YCoord}
                x2={XEndCoord}
                y2={YCoord}
                stroke={kcColor("Border")}
                strokeDasharray={[5, 5]}
                strokeWidth="1"
              />
            ),
            <SVGText
              key={`HGrid_Value_${index}`}
              fontSize="12"
              x={XFontMid}
              y={YCoord + 4}
              textAnchor="middle"
              fill={kcColor("SubTitle")}
              fontFamily="Arial"
            >
              {_Value.toFixed(FloatNum)}
            </SVGText>,
          ];
        })}
        {/* 換日線 */}
        {GSetting.EnableDailyVGridLine &&
          _DailyLines.map((_mdTime, index) => {
            return (
              <Line
                key={`DailyV_${index}`}
                x1={_mdTime.XMidPos}
                y1={YUnit.StartY}
                x2={_mdTime.XMidPos}
                y2={YUnit.EndY}
                stroke={kcColor("Yellow_3")}
                strokeDasharray={[5, 5]}
                strokeWidth="1"
              />
            );
          })}

        {/* Kline */}
        {KLineSetting.DispalyType === KLineDispalyType.NormalKLine &&
          this.Render_KLine_NormalKLine(YUnit)}
        {KLineSetting.DispalyType === KLineDispalyType.USKLine &&
          this.Render_KLine_USKLine(YUnit)}
        {KLineSetting.DispalyType === KLineDispalyType.CloseKLine &&
          this.Render_KLine_CloseKLine(YUnit)}

        {/* Kline 最高最低價*/}
        {this.Render_KLine_MaxMin(YUnit, KLineMax, KLineMin)}

        {/* 疊圖 */}
        {SubPaths &&
          SubPaths.map((_Line, index) => {
            return (
              <Path
                key={`SubPaths_${index}`}
                clipPath="url(#KLine)"
                d={_Line.SvgPath}
                strokeWidth={_Line.PathWidth}
                stroke={_Line.PathColor}
                fill="none"
              />
            );
          })}

        {/* Top Info */}
        {this.Render_TopInfo(YUnit, "K線圖", TopInfos)}
        {/* MaxMin */}
      </>
    );
  };
  private Render_Vol = (
    _TimeLines: BasicTimeModel[],
    _DailyLines: BasicTimeModel[]
  ) => {
    let YUnit = this.m_YInfo.YUnits[1];
    if (!YUnit) return;
    if (this.m_XInfo.lXUnit.length == 0) return;
    YUnit.SetTickBase(1);

    let Max: { Value: number; DataIdx: number } = {
      Value: Number.MIN_VALUE,
      DataIdx: -1,
    };
    let Min: { Value: number; DataIdx: number } = {
      Value: Number.MAX_VALUE,
      DataIdx: -1,
    };
    this.m_XInfo.lXUnit.map((md) => {
      if (md.eXUnitDisplay == eXUnitDisplay.Empty) return;
      let nDataIdx = md.nDataIndex;
      let Data = this.m_TECPData.mlOHLC[nDataIdx];
      if (!Data) return;
      if (Max.Value < Data.Vol) Max = { Value: Data.Vol, DataIdx: nDataIdx };
      if (Min.Value > Data.Vol) Min = { Value: Data.Vol, DataIdx: nDataIdx };
    });
    YUnit.ChangeYValue(0, Max.Value);

    let GSetting = this.m_TECPData.Setting.Global;
    let VolSetting = this.m_TECPData.Setting.Vol;

    let nlGridLines = kcDrawHelper.GetDefaultHorizontaGridLineBase(YUnit);
    let XStartCoord = this.m_XInfo.TECPStartLocation; // 水平線左邊
    let XEndCoord = this.m_XInfo.TECPEndLocation; // 水平線右邊
    let XFontMid = (XEndCoord + this.m_XInfo.PictureWidth) / 2;

    // TopInfo
    let TopInfoData = this.m_TECPData.TopInfo_Vol();

    return (
      <>
        {/* ClipPath */}
        <Defs>
          <ClipPath id="Vol">
            <Rect
              x={XStartCoord}
              y={YUnit.nEffectStartY}
              width={XEndCoord - XStartCoord}
              height={YUnit.nEffectHigh}
            />
          </ClipPath>
        </Defs>

        {/* BackGround*/}
        <Rect
          x={0}
          y={YUnit.StartY + 1}
          width={this.m_XInfo.PictureWidth}
          height={YUnit.High - 1}
          fill="#00000000"
          stroke={kcColor("Border")}
          strokeWidth="1"
        />

        {/* <Line
          x1={XEndCoord}
          y1={YUnit.StartY}
          x2={XEndCoord}
          y2={YUnit.EndY}
          stroke={kcColor("Border")}
          strokeWidth="1"
        /> */}

        {/* 垂直格線 */}
        {GSetting.EnableVGridLine &&
          _TimeLines.map((_mdTime, index) => {
            return (
              <Line
                key={`Vol_VGrid_${index}`}
                x1={_mdTime.XMidPos}
                y1={YUnit.StartY}
                x2={_mdTime.XMidPos}
                y2={YUnit.EndY}
                stroke={kcColor("Border")}
                strokeDasharray={[5, 5]}
                strokeWidth="1"
              />
            );
          })}
        {/* 水平格線 */}
        {nlGridLines.map((_Value, index) => {
          let YCoord = YUnit.ValueToCoord(_Value);
          return [
            GSetting.EnableHGridLine && (
              <Line
                key={`Vol_HGrid_${index}`}
                x1={XStartCoord}
                y1={YCoord}
                x2={XEndCoord}
                y2={YCoord}
                stroke={kcColor("Border")}
                strokeDasharray={[5, 5]}
                strokeWidth="1"
              />
            ),
            <SVGText
              key={`Vol_HGrid_Value_${index}`}
              fontSize="12"
              x={XFontMid}
              y={YCoord + 4}
              textAnchor="middle"
              fill={kcColor("SubTitle")}
              fontFamily="Arial"
            >
              {_Value.toFixed(YUnit.YTickBaseFloatNum)}
            </SVGText>,
          ];
        })}
        {/* 換日線 */}
        {GSetting.EnableDailyVGridLine &&
          _DailyLines.map((_mdTime, index) => {
            return (
              <Line
                key={`Vol_DailyVGrid_${index}`}
                x1={_mdTime.XMidPos}
                y1={YUnit.StartY}
                x2={_mdTime.XMidPos}
                y2={YUnit.EndY}
                stroke={kcColor("Yellow_3")}
                strokeDasharray={[5, 5]}
                strokeWidth="1"
              />
            );
          })}
        {/* Kline */}
        {this.m_XInfo.lXUnit.map((md, idx) => {
          let Data = this.m_TECPData.mlOHLC[md.nDataIndex];
          if (!Data) return;
          let Y0 = YUnit.EndY; //YUnit.ValueToCoord(0); 會誤差2pix不知道為什麼
          let YVol = YUnit.ValueToCoord(Data.Vol);
          let HDiffOC = Math.abs(YVol - Y0);
          let PreData =
            md.nDataIndex == 0
              ? Data
              : this.m_TECPData.mlOHLC[md.nDataIndex - 1];

          let szFillColor = VolSetting.EqualKColor;
          if (PreData.ClosePrice < Data.ClosePrice)
            szFillColor = VolSetting.UpKColor;
          else if (PreData.ClosePrice > Data.ClosePrice)
            szFillColor = VolSetting.DownKColor;

          return (
            <Rect
              key={`Vol_${idx}`}
              clipPath="url(#Vol)"
              x={md.fXLeftPos}
              y={YVol}
              width={md.fXWidth}
              height={HDiffOC}
              fill={szFillColor}
            />
          );
        })}
        {/* Top Info */}
        {this.Render_TopInfo(YUnit, "成交量", [TopInfoData])}
        {/* MaxMin */}
      </>
    );
  };
  private Render_KD = (
    _TimeLines: BasicTimeModel[],
    _DailyLines: BasicTimeModel[]
  ) => {
    let YUnit = this.m_YInfo.YUnits[1];
    if (!YUnit) return;
    if (this.m_XInfo.lXUnit.length == 0) return;
    YUnit.SetTickBase(0.01);
    YUnit.ChangeYValue(0, 100);

    let GSetting = this.m_TECPData.Setting.Global;
    let KDSetting = this.m_TECPData.Setting.KD;

    let XStartCoord = this.m_XInfo.TECPStartLocation; // 水平線左邊
    let XEndCoord = this.m_XInfo.TECPEndLocation; // 水平線右邊
    let XFontMid = (XEndCoord + this.m_XInfo.PictureWidth) / 2;

    let nlGridLines: {
      Value: number;
      LineColor: string;
      LineWidth: number;
    }[] = [];
    if (KDSetting.BHL_0_Enable) {
      nlGridLines.push({
        Value: KDSetting.BHL_0_Value,
        LineColor: KDSetting.BHL_0_LineColor,
        LineWidth: KDSetting.BHL_0_LineWidth,
      });
    }
    if (KDSetting.BHL_1_Enable) {
      nlGridLines.push({
        Value: KDSetting.BHL_1_Value,
        LineColor: KDSetting.BHL_1_LineColor,
        LineWidth: KDSetting.BHL_1_LineWidth,
      });
    }
    if (KDSetting.BHL_2_Enable) {
      nlGridLines.push({
        Value: KDSetting.BHL_2_Value,
        LineColor: KDSetting.BHL_2_LineColor,
        LineWidth: KDSetting.BHL_2_LineWidth,
      });
    }
    let KDValues = this.m_TECPData.KD.GetValues(this.m_XInfo.lXUnit);
    let KDLinePath = this.m_TECPData.KD.GetSVGPath(KDValues, YUnit);

    // TopInfo
    let TopInfoData = this.m_TECPData.KD.GetTopInfo();

    return (
      <>
        {/* ClipPath */}
        <Defs>
          <ClipPath id="KD">
            <Rect
              x={XStartCoord}
              y={YUnit.nEffectStartY}
              width={Math.max(0, XEndCoord - XStartCoord)}
              height={YUnit.nEffectHigh}
            />
          </ClipPath>
        </Defs>

        {/* BackGround*/}
        <Rect
          x={0}
          y={YUnit.StartY + 1}
          width={this.m_XInfo.PictureWidth}
          height={YUnit.High - 1}
          fill="#00000000"
          stroke={kcColor("Border")}
          strokeWidth="1"
        />

        {/* <Line
          x1={XEndCoord}
          y1={YUnit.StartY}
          x2={XEndCoord}
          y2={YUnit.EndY}
          stroke={kcColor("Border")}
          strokeWidth="1"
        /> */}

        {/* 垂直格線 */}
        {GSetting.EnableVGridLine &&
          _TimeLines.map((_mdTime, index) => {
            return (
              <Line
                key={`KD_VGrid_${index}`}
                x1={_mdTime.XMidPos}
                y1={YUnit.StartY}
                x2={_mdTime.XMidPos}
                y2={YUnit.EndY}
                stroke={kcColor("Border")}
                strokeDasharray={[5, 5]}
                strokeWidth="1"
              />
            );
          })}
        {/* 水平格線 */}
        {nlGridLines.map((_Line, index) => {
          let YCoord = YUnit.ValueToCoord(_Line.Value);
          return [
            <Line
              key={`KD_HGrid_Line_${index}`}
              x1={XStartCoord}
              y1={YCoord}
              x2={XEndCoord}
              y2={YCoord}
              stroke={_Line.LineColor}
              strokeDasharray={[5, 5]}
              strokeWidth={_Line.LineWidth}
            />,
            <SVGText
              key={`KD_HGrid_Text_${index}`}
              fontSize="12"
              x={XFontMid}
              y={YCoord + 4}
              textAnchor="middle"
              fill={kcColor("SubTitle")}
              fontFamily="Arial"
            >
              {_Line.Value.toFixed(YUnit.YTickBaseFloatNum)}
            </SVGText>,
          ];
        })}
        {/* 換日線 */}
        {GSetting.EnableDailyVGridLine &&
          _DailyLines.map((_mdTime, index) => {
            return (
              <Line
                key={`KD_DailyVGrid_${index}`}
                x1={_mdTime.XMidPos}
                y1={YUnit.StartY}
                x2={_mdTime.XMidPos}
                y2={YUnit.EndY}
                stroke={kcColor("Yellow_3")}
                strokeDasharray={[5, 5]}
                strokeWidth="1"
              />
            );
          })}
        {/* KD */}
        {KDLinePath &&
          KDLinePath.map((md, idx) => {
            return (
              <Path
                key={`KD_${idx}`}
                clipPath="url(#KD)"
                d={md.SvgPath}
                strokeWidth={md.PathWidth}
                stroke={md.PathColor}
                fill="none"
              />
            );
          })}

        {/* Top Info */}
        {TopInfoData && this.Render_TopInfo(YUnit, "KD", [TopInfoData])}
        {/* MaxMin */}
      </>
    );
  };
  private Render_RSI = (
    _TimeLines: BasicTimeModel[],
    _DailyLines: BasicTimeModel[]
  ) => {
    let YUnit = this.m_YInfo.YUnits[1];
    if (!YUnit) return;
    if (this.m_XInfo.lXUnit.length == 0) return;
    YUnit.SetTickBase(0.01);
    YUnit.ChangeYValue(0, 100);

    let GSetting = this.m_TECPData.Setting.Global;
    let RSISetting = this.m_TECPData.Setting.RSI;

    // let nlGridLines = kcDrawHelper.GetDefaultHorizontaGridLineBase(YUnit);
    let nlGridLines = [25, 50, 75];
    let XStartCoord = this.m_XInfo.TECPStartLocation; // 水平線左邊
    let XEndCoord = this.m_XInfo.TECPEndLocation; // 水平線右邊
    let XFontMid = (XEndCoord + this.m_XInfo.PictureWidth) / 2;

    let RSIValues = this.m_TECPData.RSI.GetValues(this.m_XInfo.lXUnit);
    let RSILinePath = this.m_TECPData.RSI.GetSVGPath(RSIValues, YUnit);

    // TopInfo
    let TopInfoData = this.m_TECPData.RSI.GetTopInfo();

    return (
      <>
        {/* ClipPath */}
        <Defs>
          <ClipPath id="RSI">
            <Rect
              x={XStartCoord}
              y={YUnit.nEffectStartY}
              width={Math.max(0, XEndCoord - XStartCoord)}
              height={YUnit.nEffectHigh}
            />
          </ClipPath>
        </Defs>

        {/* BackGround*/}
        <Rect
          x={0}
          y={YUnit.StartY + 1}
          width={this.m_XInfo.PictureWidth}
          height={YUnit.High - 1}
          fill="#00000000"
          stroke={kcColor("Border")}
          strokeWidth="1"
        />

        {/* <Line
          x1={XEndCoord}
          y1={YUnit.StartY}
          x2={XEndCoord}
          y2={YUnit.EndY}
          stroke={kcColor("Border")}
          strokeWidth="1"
        /> */}

        {/* 垂直格線 */}
        {GSetting.EnableVGridLine &&
          _TimeLines.map((_mdTime, index) => {
            return (
              <Line
                key={`RSI_VGrid_${index}`}
                x1={_mdTime.XMidPos}
                y1={YUnit.StartY}
                x2={_mdTime.XMidPos}
                y2={YUnit.EndY}
                stroke={kcColor("Border")}
                strokeDasharray={[5, 5]}
                strokeWidth="1"
              />
            );
          })}
        {/* 水平格線 */}
        {nlGridLines.map((_Value, index) => {
          let YCoord = YUnit.ValueToCoord(_Value);
          return [
            GSetting.EnableHGridLine && (
              <Line
                key={`RSI_HGrid_Line_${index}`}
                x1={XStartCoord}
                y1={YCoord}
                x2={XEndCoord}
                y2={YCoord}
                stroke={kcColor("Border")}
                strokeDasharray={[5, 5]}
                strokeWidth="1"
              />
            ),
            <SVGText
              key={`RSI_HGrid_Text_${index}`}
              fontSize="12"
              x={XFontMid}
              y={YCoord + 4}
              textAnchor="middle"
              fill={kcColor("SubTitle")}
              fontFamily="Arial"
            >
              {_Value.toFixed(YUnit.YTickBaseFloatNum)}
            </SVGText>,
          ];
        })}
        {/* 換日線 */}
        {GSetting.EnableDailyVGridLine &&
          _DailyLines.map((_mdTime, index) => {
            return (
              <Line
                key={`RSI_DailyVGrid_${index}`}
                x1={_mdTime.XMidPos}
                y1={YUnit.StartY}
                x2={_mdTime.XMidPos}
                y2={YUnit.EndY}
                stroke={kcColor("Yellow_3")}
                strokeDasharray={[5, 5]}
                strokeWidth="1"
              />
            );
          })}
        {/* RSI */}
        {RSILinePath &&
          RSILinePath.map((md, idx) => {
            return (
              <Path
                key={`RSI_${idx}`}
                clipPath="url(#RSI)"
                d={md.SvgPath}
                strokeWidth={md.PathWidth}
                stroke={md.PathColor}
                fill="none"
              />
            );
          })}

        {/* Top Info */}
        {TopInfoData && this.Render_TopInfo(YUnit, "RSI", [TopInfoData])}
        {/* MaxMin */}
      </>
    );
  };
  private Render_MACD = (
    _TimeLines: BasicTimeModel[],
    _DailyLines: BasicTimeModel[]
  ) => {
    let YUnit = this.m_YInfo.YUnits[1];
    if (!YUnit) return;
    if (this.m_XInfo.lXUnit.length == 0) return;
    YUnit.SetTickBase(0.0001);

    let TECPData = this.m_TECPData.MACD;
    let MACDValues = TECPData.GetValues(this.m_XInfo.lXUnit);
    if (MACDValues && MACDValues.Min && MACDValues.Max)
      YUnit.ChangeYValue(MACDValues.Min, MACDValues.Max);

    let LinePaths = TECPData.GetSVGPath(MACDValues, YUnit);

    let GSetting = this.m_TECPData.Setting.Global;
    let MACDSetting = this.m_TECPData.Setting.MACD;

    let nlGridLines = kcDrawHelper.GetDefaultHorizontaGridLineBase(YUnit);
    let XStartCoord = this.m_XInfo.TECPStartLocation; // 水平線左邊
    let XEndCoord = this.m_XInfo.TECPEndLocation; // 水平線右邊
    let XFontMid = (XEndCoord + this.m_XInfo.PictureWidth) / 2;

    // TopInfo
    let TopInfoData = TECPData.GetTopInfo();

    return (
      <>
        {/* ClipPath */}
        <Defs>
          <ClipPath id="MACD">
            <Rect
              x={XStartCoord}
              y={YUnit.nEffectStartY}
              width={Math.max(0, XEndCoord - XStartCoord)}
              height={YUnit.nEffectHigh}
            />
          </ClipPath>
        </Defs>

        {/* BackGround*/}
        <Rect
          x={0}
          y={YUnit.StartY + 1}
          width={this.m_XInfo.PictureWidth}
          height={YUnit.High - 1}
          fill="#00000000"
          stroke={kcColor("Border")}
          strokeWidth="1"
        />

        {/* <Line
          x1={XEndCoord}
          y1={YUnit.StartY}
          x2={XEndCoord}
          y2={YUnit.EndY}
          stroke={kcColor("Border")}
          strokeWidth="1"
        /> */}

        {/* 垂直格線 */}
        {GSetting.EnableVGridLine &&
          _TimeLines.map((_mdTime, index) => {
            return (
              <Line
                key={`MACD_VGrid_${index}`}
                x1={_mdTime.XMidPos}
                y1={YUnit.StartY}
                x2={_mdTime.XMidPos}
                y2={YUnit.EndY}
                stroke={kcColor("Border")}
                strokeDasharray={[5, 5]}
                strokeWidth="1"
              />
            );
          })}
        {/* 水平格線 */}
        {nlGridLines.map((_Value, index) => {
          let YCoord = YUnit.ValueToCoord(_Value);
          return [
            GSetting.EnableHGridLine && (
              <Line
                key={`MACD_HGrid_Line_${index}`}
                x1={XStartCoord}
                y1={YCoord}
                x2={XEndCoord}
                y2={YCoord}
                stroke={kcColor("Border")}
                strokeDasharray={[5, 5]}
                strokeWidth="1"
              />
            ),
            <SVGText
              key={`MACD_HGrid_Text_${index}`}
              fontSize="12"
              x={XFontMid}
              y={YCoord + 4}
              textAnchor="middle"
              fill={kcColor("SubTitle")}
              fontFamily="Arial"
            >
              {_Value.toFixed(YUnit.YTickBaseFloatNum)}
            </SVGText>,
          ];
        })}
        {/* 換日線 */}
        {GSetting.EnableDailyVGridLine &&
          _DailyLines.map((_mdTime, index) => {
            return (
              <Line
                key={`MACD_DailyVGrid_${index}`}
                x1={_mdTime.XMidPos}
                y1={YUnit.StartY}
                x2={_mdTime.XMidPos}
                y2={YUnit.EndY}
                stroke={kcColor("Yellow_3")}
                strokeDasharray={[5, 5]}
                strokeWidth="1"
              />
            );
          })}
        {/* MACD */}
        {MACDValues &&
          MACDValues.XUnitValues.map((md, idx) => {
            let Data = md.Value;
            if (!Data || !Data.DIF_MACD) return;
            let Y0 = YUnit.ValueToCoord(0);
            let YMACD = YUnit.ValueToCoord(Data.DIF_MACD);
            let HDiffOC = Math.abs(YMACD - Y0);
            HDiffOC = Math.max(HDiffOC, 1);

            return [
              Data.DIF_MACD && Data.DIF_MACD >= 0 && (
                <Rect
                  key={`DifMACD_${idx}`}
                  clipPath="url(#MACD)"
                  x={md.XUnit.fXLeftPos}
                  y={YMACD}
                  width={md.XUnit.fXWidth}
                  height={HDiffOC}
                  fill={MACDSetting.Dif_MACD_UpColor}
                />
              ),

              Data.DIF_MACD && Data.DIF_MACD < 0 && (
                <Rect
                  key={`DifMACD_${idx}`}
                  clipPath="url(#MACD)"
                  x={md.XUnit.fXLeftPos}
                  y={Y0}
                  width={md.XUnit.fXWidth}
                  height={HDiffOC}
                  fill={MACDSetting.Dif_MACD_DownColor}
                />
              ),
            ];
          })}

        {LinePaths &&
          LinePaths.map((md, idx) => {
            return (
              <Path
                key={`MACDLine_${idx}`}
                clipPath="url(#MACD)"
                d={md.SvgPath}
                strokeWidth={md.PathWidth}
                stroke={md.PathColor}
                fill="none"
              />
            );
          })}

        {/* Top Info */}
        {TopInfoData && this.Render_TopInfo(YUnit, "MACD", [TopInfoData])}
        {/* MaxMin */}
      </>
    );
  };
  private Render_Time = (
    _TimeLines: BasicTimeModel[],
    _DailyLines: BasicTimeModel[]
  ) => {
    let YUnit = this.m_TECPData.Setting.Global.ShowDeputyTECP
      ? this.m_YInfo.YUnits[2]
      : this.m_YInfo.YUnits[1];
    if (!YUnit) return;
    if (this.m_XInfo.lXUnit.length == 0) return;

    let XStartCoord = this.m_XInfo.TECPStartLocation; // 水平線左邊
    let XEndCoord = this.m_XInfo.TECPEndLocation; // 水平線右邊

    return (
      <>
        {/* BackGround*/}
        <Rect
          x={0}
          y={YUnit.StartY + 1}
          width={this.m_XInfo.PictureWidth}
          height={YUnit.High - 1}
          fill="#00000000"
          stroke={kcColor("Border")}
          strokeWidth="1"
        />
        {_TimeLines.map((_mdTime, index) => {
          return [
            <Line
              key={`TimeRow_${index}`}
              x1={_mdTime.XMidPos}
              y1={YUnit.StartY}
              x2={_mdTime.XMidPos}
              y2={YUnit.EndY}
              stroke={kcColor("Border")}
              strokeWidth="2"
            />,
            <SVGText
              key={`TimeRow_Text_${index}`}
              fontSize="12"
              x={_mdTime.XMidPos + 4}
              y={YUnit.EndY - 2}
              textAnchor="start"
              fill={kcColor("SubTitle")}
              fontFamily="Arial"
            >
              {_mdTime.Time.format(_mdTime.TimeFormat)}
            </SVGText>,
          ];
        })}

        {/* 換日線 */}
        {/* {_DailyLines.map((_mdTime) => {
          return (
            <>
              <Line
                x1={_mdTime.XMidPos}
                y1={YUnit.StartY}
                x2={_mdTime.XMidPos}
                y2={YUnit.EndY}
                stroke={kcColor("Yellow_3")}
                strokeWidth="2"
              />
            </>
          );
        })} */}

        {/* <Line
          x1={XEndCoord}
          y1={YUnit.StartY}
          x2={XEndCoord}
          y2={YUnit.EndY}
          stroke={kcColor("Border")}
          strokeWidth="1"
        /> */}
      </>
    );
  };
  private Render_TopInfo = (
    _YUnit: YUnit,
    _Title: string,
    _aInfos: TopInfo[][]
  ) => {
    let nStartY = _YUnit.StartY + 3;
    let nEndY = _YUnit.nEffectStartY - 1;
    let nHeight = _YUnit.YLeftInfoHeight * _aInfos.length;
    // let nEndY = _YUnit.nEffectStartY - 1;
    // let nStartY = nEndY - _YUnit.YLeftInfoHeight;
    // let nHeight = _YUnit.YLeftInfoHeight * _aInfos.length;

    let Id = `TopInfo${_Title}`;
    let clipPath = `url(#${Id})`;
    return (
      <>
        {/* ClipPath */}
        <Defs>
          <ClipPath id={Id}>
            <Rect x={0} y={nStartY} width="100%" height={nHeight + 1} />
          </ClipPath>
        </Defs>
        <SVGText
          clipPath={clipPath}
          x="5"
          y={nStartY + _YUnit.YLeftInfoHeight}
          fill={kcColor("SubTitle")}
          fontFamily="Arial"
          fontSize={12}
        >
          {/* // Title */}
          <TSpan fontSize={14}>{_Title}</TSpan>

          {_aInfos.map((Row, Idx) => {
            return (
              <TSpan key={`${Id}_${Idx}`}>
                <TSpan x={Idx == 0 ? 40 : -5} dy={_YUnit.YLeftInfoHeight * Idx}>
                  {" "}
                </TSpan>
                {Row.map((v, ConIdx) => {
                  return (
                    <TSpan key={`${Id}_${Idx}_${ConIdx}`}>
                      <TSpan dx="6" fill={v.TextColor}>
                        {v.Text}
                      </TSpan>
                      <TSpan dx="3" fill={v.Color}>
                        {v.Value}
                      </TSpan>
                    </TSpan>
                  );
                })}
              </TSpan>
            );
          })}
        </SVGText>
      </>
    );
  };
  private Render_CheckLine = () => {
    if (!this.m_bCheckLine) return;
    if (
      !this.m_TECPData ||
      !this.m_pCheckLinePos ||
      !this.m_YInfo.YUnits ||
      this.m_YInfo.YUnits.length == 0
    )
      return;

    // 共用參數
    let PosX = this.m_pCheckLinePos.X;
    let PosY = this.m_pCheckLinePos.Y;
    PosX = Math.min(this.m_XInfo.TECPEndLocation, Math.max(PosX, 0));
    PosY = Math.min(this.m_YInfo.PictureHeight - 1, Math.max(PosY, 0));
    //console.log(this.m_XInfo.lXUnit.map((q) => q.nDataIndex));
    //console.log(this.m_XInfo.lXUnit);
    // X定位
    let nDataIndex = this.m_XInfo.DrawPosToDataIndex(PosX);
    let FirstXUnit = ArrayExtensions.FindLast(
      this.m_XInfo.lXUnit,
      (q) =>
        q.eXUnitDisplay == eXUnitDisplay.OverHalf ||
        q.eXUnitDisplay == eXUnitDisplay.All
    );
    let LastXUnit = this.m_XInfo.lXUnit.find(
      (q) =>
        q.eXUnitDisplay == eXUnitDisplay.OverHalf ||
        q.eXUnitDisplay == eXUnitDisplay.All
    );
    nDataIndex = Math.min(
      nDataIndex,
      LastXUnit?.nDataIndex ?? this.m_TECPData.mlOHLC.length - 1
    ); // DataIndex最大值在最後一筆OHLC
    nDataIndex = Math.max(nDataIndex, FirstXUnit?.nDataIndex ?? 0);
    if (nDataIndex < 0) return;
    PosX = this.m_XInfo.DataIndexToDrawCenterPos(nDataIndex);
    // if (PosX < 0) {
    //   nDataIndex++;
    //   PosX = this.m_XInfo.DataIndexToDrawCenterPos(nDataIndex);
    // }
    // if (PosX >= this.m_XInfo.TECPWidth) {
    //   nDataIndex--;
    //   PosX = this.m_XInfo.DataIndexToDrawCenterPos(nDataIndex);
    // }

    // Y定位
    let FocusYUnit = this.m_YInfo.GetFocusYUnit(PosY);
    if (!FocusYUnit) return;
    let FixYValue = FocusYUnit.CoordToFixedValue(PosY);
    if (!FocusYUnit.FixedHeight) PosY = FixYValue.fFixedCoord; // 檔FixedHeight解DateTimeRow時Y會在0的問題

    // CkeckLine 價位 文字
    let bDrawPrice =
      PosY > FocusYUnit.nEffectStartY && PosY < FocusYUnit.nEffectEndY;
    let FloatNum = FocusYUnit.YTickBaseFloatNum;
    let rectPrice: { x: number; y: number; width: number; height: number } = {
      x: this.m_XInfo.TECPWidth + 1,
      y: PosY - 10,
      width: this.m_XInfo.PictureWidth - this.m_XInfo.TECPWidth - 1,
      height: 20,
    };

    // CkeckLine 時間 文字
    let LastYUnit = this.m_YInfo.YUnits[this.m_YInfo.YUnits.length - 1]; // 最後一個YUnit, Time的那個
    let bDrawTime =
      PosX >= this.m_XInfo.TECPStartLocation &&
      PosX <= this.m_XInfo.TECPEndLocation; // 超出TECP範圍時(價位文字區域)不畫
    let TimeValue =
      this.m_TECPData.mlOHLC[nDataIndex]?.Time?.format("YYYY/MM/DD HH:mm:ss") ??
      "";
    let nTimeFontSize = 14;
    let TimeRangeWidth = TimeValue !== "" ? 130 : 0;
    let rectTime: { x: number; y: number; width: number; height: number } = {
      x: PosX - TimeRangeWidth / 2,
      y: LastYUnit.StartY,
      width: TimeRangeWidth,
      height: LastYUnit.High,
    };

    // LeftInfo參數
    let aLeftInfo: TopInfo[] = [];
    let TimeLeft = this.m_TECPData.LeftInfo_Time(nDataIndex);
    let KLineLeft = this.m_TECPData.LeftInfo_KLine(
      nDataIndex,
      this.m_SubTECP === SubTECP.MA,
      this.m_SubTECP === SubTECP.BBand
    );
    //let VolLeft = this.m_TECPData.LeftInfo_Vol(nDataIndex);
    aLeftInfo.push(...TimeLeft, ...KLineLeft);

    if (this.m_DeputyTECP === DeputyTECP.Vol)
      aLeftInfo.push(...this.m_TECPData.LeftInfo_Vol(nDataIndex));
    if (this.m_DeputyTECP === DeputyTECP.KD)
      this.m_TECPData.KD.AddLeftInfo(nDataIndex, aLeftInfo);
    if (this.m_DeputyTECP === DeputyTECP.RSI)
      this.m_TECPData.RSI.AddLeftInfo(nDataIndex, aLeftInfo);
    if (this.m_DeputyTECP === DeputyTECP.MACD)
      this.m_TECPData.MACD.AddLeftInfo(nDataIndex, aLeftInfo);

    const LeftInfoPosOffset = 10;
    const LeftInfoVPadding = 5;
    const HeightPerLine = 20;
    let rectLeft: { x: number; y: number; width: number; height: number } = {
      x: PosX + LeftInfoPosOffset,
      y: PosY + LeftInfoPosOffset,
      width: 150,
      height: aLeftInfo.length * HeightPerLine + LeftInfoVPadding * 2,
    };
    if (rectLeft.x + rectLeft.width >= this.m_XInfo.TECPEndLocation)
      rectLeft.x = Math.max(0, PosX - rectLeft.width - LeftInfoPosOffset);
    if (rectLeft.y + rectLeft.height >= this.m_YInfo.PictureHeight)
      rectLeft.y = Math.max(0, PosY - rectLeft.height - LeftInfoPosOffset);
    let LeftStrPosX = rectLeft.x + 10;
    let LeftStrPosY = rectLeft.y + LeftInfoVPadding;
    return (
      <>
        {/* // 水平查價線 */}
        {bDrawPrice && (
          <>
            <Line
              x1={0}
              y1={PosY}
              x2={this.m_XInfo.PictureWidth}
              y2={PosY}
              stroke="yellow"
            />
            <Rect {...rectPrice} fill={kcColor("Background")} />
            <SVGText
              x={rectPrice.x + rectPrice.width / 2}
              y={rectPrice.y + rectPrice.height / 2 + nTimeFontSize / 3}
              textAnchor="middle"
              fontFamily="Arial"
              fontSize={nTimeFontSize}
              fill="yellow"
              children={FixYValue.dFixedValue.toFixed(FloatNum)}
            />
          </>
        )}

        {/* // 垂直查價線 */}
        {bDrawTime && (
          <>
            <Line
              x1={PosX}
              y1={0}
              x2={PosX}
              y2={this.m_YInfo.PictureHeight}
              stroke="yellow"
            />
            <Rect {...rectTime} fill={kcColor("Background")} />
            <SVGText
              x={rectTime.x + rectTime.width / 2}
              y={rectTime.y + rectTime.height / 2 + nTimeFontSize / 3}
              textAnchor="middle"
              fontFamily="Arial"
              fontSize={nTimeFontSize}
              fill="yellow"
              children={TimeValue}
            />
          </>
        )}

        {bDrawTime && (
          <>
            <Rect
              {...rectLeft}
              fill="#00000080"
              stroke={kcColor("Border")}
              strokeWidth="1"
              rx={20}
            />
            <SVGText
              x={LeftStrPosX}
              y={LeftStrPosY}
              fill={kcColor("SubTitle")}
              fontFamily="Arial"
              fontSize={12}
            >
              {aLeftInfo.map((_md, _Idx) => {
                let ConterPosY = LeftStrPosY + (_Idx + 0.5) * HeightPerLine;
                return [
                  <TSpan
                    key={`Chicking_Text_${_Idx}`}
                    x={LeftStrPosX}
                    y={ConterPosY + 12 / 3}
                    fill={_md.TextColor}
                    children={_md.Text}
                  />,
                  <TSpan
                    key={`Chicking_Value_${_Idx}`}
                    x={LeftStrPosX + 55}
                    fill={_md.Color}
                    children={_md.Value}
                  />,
                ];
              })}
            </SVGText>
          </>
        )}
      </>
    );
  };
  private Render_SpecHLine = (
    _TimeLines: BasicTimeModel[],
    _DailyLines: BasicTimeModel[]
  ) => {
    let YUnit = this.m_YInfo.YUnits[0];
    if (!YUnit) return <></>;
    if (this.m_XInfo.lXUnit.length == 0) return <></>;
    let FloatNum = 2;

    if (this.m_TECPData?.kcUnit?.Commodity) {
      let Com = this.m_TECPData.kcUnit.Commodity;
      FloatNum = Com.FloatNum;
    }

    let XStartCoord = 0; // 水平線左邊
    let XEndCoord =
      this.m_XInfo.TECPWidth -
      this.m_XInfo.TECPStartLocation -
      XInfo.FrameLineWidth; // 水平線右邊
    let XFontMid = (XEndCoord + this.m_XInfo.PictureWidth) / 2;

    let SpecHLine: {
      sz: string;
      Value: number;
      szColor: string;
      dosh: number[];
    }[] = [];
    if (this.m_TECPData.Setting.Global.EnableBuyPriceHLine) {
      this.m_Inventorys.forEach((md) => {
        if (md.StockCode != this.m_StockCode) return;
        if (md.Type != "成交" && md.Type != "委託") return;

        if (md.StopProfitPrice && md.StopProfitPrice != -1) {
          let mdHLine: {
            sz: string;
            Value: number;
            szColor: string;
            dosh: number[];
          } = {
            sz: `#${md.TradeIdx} TP`,
            Value: md.StopProfitPrice,
            szColor: kcColor("Up_2"),
            dosh: [5, 5],
          };
          SpecHLine.push(mdHLine);
        }
        if (md.StopLossPrice && md.StopLossPrice != -1) {
          let mdHLine: {
            sz: string;
            Value: number;
            szColor: string;
            dosh: number[];
          } = {
            sz: `#${md.TradeIdx} SL`,
            Value: md.StopLossPrice,
            szColor: kcColor("Down_2"),
            dosh: [5, 5],
          };
          SpecHLine.push(mdHLine);
        }
        if (md.HTradeIn && md.HTradeIn.Price != -1) {
          let mdHLine: {
            sz: string;
            Value: number;
            szColor: string;
            dosh: number[];
          } = {
            sz: `#${md.TradeIdx} ${md.BuySellType} ${md.VolHand.toFixed(2)}`,
            Value: md.HTradeIn.Price,
            szColor: "#AAAAAA",
            dosh: [5, 5],
          };
          SpecHLine.push(mdHLine);
        }
      });
    }

    // if (
    //   this.m_TECPData.Setting.Global.EnableNowPriceHLine &&
    //   this.m_TECPData.mlOHLC.length != 0
    // ) {
    //   let mdHLine: { sz: string; Value: number; szColor: string } = {
    //     sz: "",
    //     Value:
    //       this.m_TECPData.mlOHLC[this.m_TECPData.mlOHLC.length - 1].ClosePrice,
    //     szColor: kcColor("Yellow_3"),
    //   };
    //   SpecHLine.push(mdHLine);
    // }
    if (
      this.m_TECPData.Setting.Global.EnableNowPriceHLine &&
      this.m_TECPData.mlOHLC.length != 0
    ) {
      if (this.m_TECPData.kcUnit?.LastInfo?.AskPrice) {
        let mdHLine_Ask: {
          sz: string;
          Value: number;
          szColor: string;
          dosh: number[];
        } = {
          sz: "",
          Value: this.m_TECPData.kcUnit.LastInfo.AskPrice,
          szColor: kcColor("Up"),
          dosh: [0],
        };
        SpecHLine.push(mdHLine_Ask);
      }
      if (this.m_TECPData.kcUnit?.LastInfo?.BidPrice) {
        let mdHLine_Bid: {
          sz: string;
          Value: number;
          szColor: string;
          dosh: number[];
        } = {
          sz: "",
          Value: this.m_TECPData.kcUnit.LastInfo.BidPrice,
          szColor: kcColor("Down"),
          dosh: [0],
        };
        SpecHLine.push(mdHLine_Bid);
      }
    }

    return (
      <>
        {/* ClipPath */}
        <Defs>
          <ClipPath id="SpecHLine">
            <Rect
              x={0}
              y={YUnit.nEffectStartY}
              width={this.m_Width}
              height={YUnit.nEffectHigh}
            />
          </ClipPath>
        </Defs>

        {/* 水平格線 */}
        {SpecHLine.map((_Value, index) => {
          let YCoord = YUnit.ValueToCoord(_Value.Value);
          if (
            //SVGText ClipPath 會失效 所以需要在防呆一次
            _Value.Value < YUnit.StartValue ||
            _Value.Value > YUnit.EndValue
          )
            return <></>;
          return [
            <SVGText
              key={`SpecHLine_Title_${index}`}
              clipPath="url(#SpecHLine)"
              fontSize="10"
              x={0}
              y={YCoord - 4}
              textAnchor="start"
              fill={_Value.szColor}
              //stroke={_Value.szColor}
              fontFamily="Arial"
            >
              {_Value.sz}
            </SVGText>,

            <Line
              key={`SpecHLine_Dash_${index}`}
              clipPath="url(#SpecHLine)"
              x1={XStartCoord}
              y1={YCoord}
              x2={XEndCoord}
              y2={YCoord}
              stroke={_Value.szColor}
              strokeDasharray={_Value.dosh}
              strokeWidth="1"
            />,

            <Rect
              key={`SpecHLine_Rect_${index}`}
              clipPath="url(#SpecHLine)"
              x={XEndCoord + 1}
              y={YCoord - 7}
              width={this.m_XInfo.PictureWidth - XEndCoord - 2}
              height={14}
              fill={_Value.szColor}
              strokeWidth="0"
            />,

            <SVGText
              key={`SpecHLine_Text_${index}`}
              clipPath="url(#SpecHLine)"
              fontSize="12"
              x={XFontMid}
              y={YCoord + 4}
              textAnchor="middle"
              fill="#000000"
              fontFamily="Arial"
            >
              {_Value.Value.toFixed(FloatNum)}
            </SVGText>,
          ];
        })}
      </>
    );
  };
  private Render_KLine_NormalKLine = (_YUnit: YUnit) => {
    let KLineSetting = this.m_TECPData.Setting.KLine;
    return this.m_XInfo.lXUnit.map((md, idx) => {
      let Data = this.m_TECPData.mlOHLC[md.nDataIndex];
      if (!Data) return;
      if (md.fXRightPos < 0) return;
      let YOpenPrice = _YUnit.ValueToCoord(Data.OpenPrice);
      let YHighPrice = _YUnit.ValueToCoord(Data.HighPrice);
      let YLowPrice = _YUnit.ValueToCoord(Data.LowPrice);
      let YClosePrice = _YUnit.ValueToCoord(Data.ClosePrice);
      let HDiffOC = Math.abs(YOpenPrice - YClosePrice);

      return [
        <Line
          key={`KLine_${idx}_V`}
          clipPath="url(#KLine)"
          x1={md.fXMidPos}
          y1={YHighPrice}
          x2={md.fXMidPos}
          y2={YLowPrice}
          stroke={KLineSetting.VerticalLineColor}
          strokeWidth="1"
        />,

        Data.OpenPrice < Data.ClosePrice && (
          <Rect
            key={`KLine_${idx}_Up`}
            clipPath="url(#KLine)"
            x={md.fXLeftPos}
            y={YClosePrice}
            width={md.fXWidth}
            height={HDiffOC}
            fill={KLineSetting.UpKColor}
          />
        ),

        Data.OpenPrice > Data.ClosePrice && (
          <Rect
            key={`KLine_${idx}_Down`}
            clipPath="url(#KLine)"
            x={md.fXLeftPos}
            y={YOpenPrice}
            width={md.fXWidth}
            height={HDiffOC}
            fill={KLineSetting.DownKColor}
          />
        ),

        Data.OpenPrice == Data.ClosePrice && (
          <Rect
            key={`KLine_${idx}_eq`}
            clipPath="url(#KLine)"
            x={md.fXLeftPos}
            y={YOpenPrice}
            width={md.fXWidth}
            height={1}
            fill={KLineSetting.EqualKColor}
          />
        ),
      ];
    });
  };
  private Render_KLine_USKLine = (_YUnit: YUnit) => {
    let KLineSetting = this.m_TECPData.Setting.KLine;
    return this.m_XInfo.lXUnit.map((md, idx) => {
      let Data = this.m_TECPData.mlOHLC[md.nDataIndex];
      if (!Data) return;
      let YOpenPrice = _YUnit.ValueToCoord(Data.OpenPrice);
      let YHighPrice = _YUnit.ValueToCoord(Data.HighPrice);
      let YLowPrice = _YUnit.ValueToCoord(Data.LowPrice);
      let YClosePrice = _YUnit.ValueToCoord(Data.ClosePrice);

      let szColor = KLineSetting.EqualKColor;
      if (Data.OpenPrice < Data.ClosePrice) szColor = KLineSetting.UpKColor;
      else if (Data.OpenPrice > Data.ClosePrice)
        szColor = KLineSetting.DownKColor;

      return [
        <Line
          key={`KLine_${idx}_O`}
          clipPath="url(#KLine)"
          x1={md.fXLeftPos}
          y1={YOpenPrice}
          x2={md.fXMidPos}
          y2={YOpenPrice}
          stroke={szColor}
          strokeWidth="1"
          {...SVG_shapeRendering}
        />,
        <Line
          key={`KLine_${idx}_V`}
          clipPath="url(#KLine)"
          x1={md.fXMidPos}
          y1={YHighPrice}
          x2={md.fXMidPos}
          y2={YLowPrice}
          stroke={szColor}
          strokeWidth="1"
          {...SVG_shapeRendering}
        />,
        <Line
          key={`KLine_${idx}_C`}
          clipPath="url(#KLine)"
          x1={md.fXMidPos}
          y1={YClosePrice}
          x2={md.fXRightPos}
          y2={YClosePrice}
          stroke={szColor}
          strokeWidth="1"
          {...SVG_shapeRendering}
        />,
      ];
    });
  };
  private Render_KLine_CloseKLine = (_YUnit: YUnit) => {
    let KLineSetting = this.m_TECPData.Setting.KLine;

    let szPath = "";
    for (let i = 0; i < this.m_XInfo.lXUnit.length; i++) {
      let md = this.m_XInfo.lXUnit[i];
      let Data = this.m_TECPData.mlOHLC[md.nDataIndex];
      let dValue = Data.ClosePrice;
      let XCoord = md.fXMidPos;
      let YCoord = _YUnit.ValueToCoord(dValue);

      szPath += `${i == 0 ? "M" : "L"} ${XCoord} ${YCoord}`;
    }
    return (
      <Path
        clipPath="url(#KLine)"
        d={szPath}
        strokeWidth={1}
        stroke={KLineSetting.EqualKColor}
        fill="none"
      />
    );
  };
  private Render_KLine_MaxMin = (
    _YUnit: YUnit,
    _Max: { Value: number; DataIdx: number; PosX: number },
    _Min: { Value: number; DataIdx: number; PosX: number }
  ) => {
    let fontFamily = "Arial";
    let fontSize = 12;
    let nStartY = _YUnit.StartY + 3;
    let nEndY = _YUnit.EndY - 3;
    let FloatNum = _YUnit.YTickBaseFloatNum;

    // Clip
    let XStartCoord = this.m_XInfo.TECPStartLocation; // 水平線左邊
    let XEndCoord = this.m_XInfo.TECPEndLocation; // 水平線右邊
    let Id = "KLine_MaxMin";
    let clipPath = `url(#${Id})`;

    // Max
    let MaxValue = _Max.Value.toFixed(FloatNum);
    let Max_Size = MeasureText(MaxValue, fontFamily, fontSize);
    let Max_PosY =
      nStartY + 2 * _YUnit.YLeftInfoHeight + _YUnit.YLeftInfoHeight;
    let Max_PosX = _Max.PosX - Max_Size.Width / 2;
    Max_PosX = Math.max(0, Math.min(XEndCoord - Max_Size.Width, Max_PosX));

    //Min
    let MinValue = _Min.Value.toFixed(FloatNum);
    let Min_Size = MeasureText(MinValue, fontFamily, fontSize);
    let Min_PosY = nEndY;
    let Min_PosX = _Min.PosX - Min_Size.Width / 2;
    Min_PosX = Math.max(0, Math.min(XEndCoord - Min_Size.Width, Min_PosX));

    return (
      <>
        <Defs>
          <ClipPath id={Id}>
            <Rect
              x={XStartCoord}
              y={nStartY}
              width={XEndCoord - XStartCoord}
              height={nEndY - nStartY + 1}
            />
          </ClipPath>
        </Defs>
        <SVGText
          clipPath={clipPath}
          x={Max_PosX}
          y={Max_PosY}
          fill={kcColor("Up")}
          fontFamily={fontFamily}
          fontSize={fontSize}
        >
          {MaxValue}
        </SVGText>

        <SVGText
          clipPath={clipPath}
          x={Min_PosX}
          y={Min_PosY}
          fill={kcColor("Down")}
          fontFamily={fontFamily}
          fontSize={fontSize}
        >
          {MinValue}
        </SVGText>
      </>
    );
  };

  /*-------------------------------------------------*/

  render() {
    // XInfo DataDis存檔
    if (this.m_Init)
      this.m_TECPData?.UpdateSetting_XDataDis(this.m_XInfo.DataDis);

    let IsHeightToLow = IsBottomTabHieghtToLow();

    return (
      <SafeAreaView
        style={{
          flex: 1,
          flexDirection: "column",
          backgroundColor: kcColor("Background"),
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <KC_OrderModal ref={this.KC_OrderModal} />

        <KC_HTypeModal
          ref={this.KC_HTypeModal}
          OnSelectHType={this.OnModalSelectHType}
        />
        <KC_TECPScreenTopInfo
          delCanUpdate={() => true}
          m_kcUnit={this.m_TECPData.kcUnit}
          OnItemClick={this.OnTopInfoItmeClick}
          Checking={this.m_bCheckLine}
          ShowOrder={true}
          ShowCommodity={IsHeightToLow}
          cHTypeValue={this.m_cHType.sz}
        />
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            backgroundColor: "#00000000",
            alignItems: "stretch",
            justifyContent: "space-around",
          }}
          onLayout={this.onLayout}
        >
          {this.state.loading && (
            <ActivityIndicator size="large" style={{ alignSelf: "center" }} />
          )}
          {!this.state.loading && (
            <PanGestureHandler
              onGestureEvent={this.OnPanGestureEvent}
              onHandlerStateChange={this.OnPanGestureStateChange}
            >
              <PinchGestureHandler
                onGestureEvent={this.OnPinchGestureEvent}
                onHandlerStateChange={this.OnPinchGestureStateChange}
              >
                <TapGestureHandler
                  //waitFor={this.doubleTapRef}
                  onHandlerStateChange={this.OnTapHandlerStateChange}
                >
                  {/* <TapGestureHandler
                    ref={this.doubleTapRef}
                    onHandlerStateChange={this.OnDoubleTapHandlerStateChange}
                    numberOfTaps={2}
                  > */}
                  <Svg style={{ flex: 1 }}>
                    {this.Render_TECPs()}
                    {/* {this.m_TECPS} */}
                    {this.Render_Log()}
                  </Svg>
                  {/* </TapGestureHandler> */}
                </TapGestureHandler>
              </PinchGestureHandler>
            </PanGestureHandler>
          )}
        </View>
      </SafeAreaView>
    );
  }

  /* ------------------------------------------------- */
  // Svg Log
  m_bShowSvgLog = true;
  m_aViewLog: string[] = [];
  m_LogFlowInx = 0;
  PushLog = (_Msg: string) => {
    //this.m_aViewLog.push(_Msg);
    let szMsg = `(${this.m_LogFlowInx++}) ${_Msg}`;
    this.m_aViewLog.splice(0, 0, szMsg);
    if (this.m_aViewLog.length > 10) {
      this.m_aViewLog.length = 10;
      //this.m_aViewLog.splice(0, 1);
    }
  };
  private Render_Log = () => {
    if (!this.m_bShowSvgLog) return <></>;

    return (
      <SVGText
        x={20}
        y={20}
        fill={"#80FF0080"}
        fontFamily="Arial"
        fontSize={12}
      >
        {this.m_aViewLog.map((_sz) => {
          return <TSpan x={20} dy="18" children={_sz} />;
        })}
      </SVGText>
    );
  };
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: "#00000000",
    borderColor: "red",
    borderWidth: 0,
    borderRadius: 15,
  },
});

const SVG_shapeRendering = { shapeRendering: "crispEdges" };

const MeasureText = (
  _szValue: string,
  _fontFamily: string,
  fontSize: string | number
): { Width: number; Height: number } => {
  let mdRet = { Width: 0, Height: 0 };

  let newText = document.createElement("text");
  document.body.appendChild(newText);

  // append text data
  if (newText) {
    newText.innerHTML += _szValue;

    // append text font / size, bc might as well be fancy
    newText.style.fontFamily = _fontFamily;
    newText.style.fontSize = `${fontSize}px`;

    // measure element
    let width = newText.getBoundingClientRect().width;
    let height = newText.getBoundingClientRect().height;

    // delete element
    newText.parentNode?.removeChild(newText);

    mdRet.Width = width;
    mdRet.Height = height;
  }
  return mdRet;
};
