import { MathEx } from "../kcExternal";
import cTECPDef from "../kcTECP/cTECPDef";

export enum XInfoZoomDatum {
  PosBase = 0x00,
  IndexBase = 0x01,
  LeftPos = 0x10,
  LeftIndex = 0x11,
  CenterPos = 0x20,
  CenterIndex = 0x21,
  RightPos = 0x40,
  RightIndex = 0x41,
  FixedPos = 0x80,
  FixedIndex = 0x81,
  DefaultPos = RightPos,
  DefaultIndex = RightIndex,
}

export enum eXUnitDisplay {
  All,
  OverHalf,
  NonHalf,
  Empty,
}

export class XUnit {
  constructor(_nDataIndex: number, _fXMidPos: number, _fXWidth: number) {
    this.nDataIndex = _nDataIndex;
    this.fXMidPos = _fXMidPos;
    this.fXWidth = MathEx.kcRound(_fXWidth, 6);
  }

  public nDataIndex: number = 0;
  public fXMidPos: number = 0;
  public fXWidth: number = 0;
  public eXUnitDisplay: eXUnitDisplay = eXUnitDisplay.All;
  public get fXLeftPos() {
    return this.fXMidPos - (this.fXWidth - 1) / 2;
  }
  public get fXRightPos() {
    return this.fXMidPos + (this.fXWidth - 1) / 2;
  }
}

export default class XInfo {
  constructor() {}

  /* --------------------------------------------- */
  // Define
  public static BorderLineWidth: number = 1; // 外框線寬度
  public static FrameLineWidth: number = 1; // 圖與值分隔線寬度
  public static ValueAreaWidth: number = 50; // 右邊寫值的固定寬度
  //public static LeftBufferWidth: number = 0; // 左邊Buffer寬 pixel
  //public static RightBufferWidth: number = 0; // 右邊Buffer寬 pixel
  public static DrawingMinWidth: number = 100; // 扣掉Buffer的畫圖區域最小寬度 pixel
  public static m_Rate: number = 0.8; // 不能超過 0-1
  public static MinDataNum: number = 10; // 畫面上最少DataNum
  public static DefaultDataNum: number = 100;
  public static DefaultZoomRate: number = 1.6; // Zoom時DataNum調整固定比例, 目前給滑鼠滾輪使用
  public static OverLimitBuffer: number = 50; // 左右需要回彈的距離

  /* --------------------------------------------- */
  // 畫面顯示 Member
  private m_nPictureWidth: number = 0;
  private m_nDrawPos: number = 0;
  private m_nDrawWidth: number = 0;
  private m_nValuePos: number = 0;
  private m_nValueWidth: number = XInfo.ValueAreaWidth;
  private m_nXDataNum: number = XInfo.MinDataNum; //一個頁面 可以顯示幾筆Data
  private m_nDataWidth = 0; //一個data 總寬度        2 * m_nXBase + 1;
  private m_nXBase = 0; //一個data 左右寬度
  private m_nLeftBlockWidth: number = XInfo.OverLimitBuffer; // 左邊空白的區域程式可以控制區域大小
  private m_nRightBlockWidth: number = XInfo.OverLimitBuffer + 100; // 右邊空白的區域程式可以控制區域大小
  private m_lXUnit: XUnit[] = [];
  private m_DefultBestDataWidth: number = 10; //一個 data 最佳總寬
  private m_nDefutBestDataCount: number = 0; //data 最佳數量
  /* --------------------------------------------- */

  private m_nTotalDataNum: number = 0;
  private m_fDataDis: number = 0; //兩筆data 中間 距離多少  (m_nXBase > 0) -> 2 * m_nXBase + 3 間隙2 ; (m_nXBase = 0) -> 1 間隙0
  public m_fTotalDataWidth: number = 0;
  private m_fScreenDataPos: number = 0;

  public get DefutBestDataCount() {
    return this.m_nDefutBestDataCount;
  }
  public get PictureWidth() {
    return this.m_nPictureWidth;
  }
  public get TECPStartLocation() {
    return this.m_nDrawPos;
  }
  public get TECPEndLocation() {
    return this.m_nDrawPos + this.m_nDrawWidth;
  }
  public get TECPWidth() {
    return this.m_nDrawWidth;
  }
  public get nXDataNum() {
    return this.m_nXDataNum;
  }
  public get nDataWidth() {
    return this.m_nDataWidth;
  }
  public get lXUnit() {
    return this.m_lXUnit;
  }
  public get TotalDataNum() {
    return this.m_nTotalDataNum;
  }
  public get DataDis() {
    return this.m_fDataDis;
  }
  public get ScreenDataPos() {
    return this.m_fScreenDataPos;
  }
  public get ScreenEndDataPos() {
    return this.m_fScreenDataPos + this.m_nDrawWidth;
  }
  public get ScreenDataIndex() {
    return this.DataPosToFloatDataIndex(this.m_fScreenDataPos);
  }
  public get ScreenEndDataIndex() {
    return this.DataPosToFloatDataIndex(
      this.m_fScreenDataPos + this.m_nDrawWidth
    );
  }

  public Clear() {
    this.m_lXUnit = [];
    this.ChangeTotalDataNum(0);
  }
  public Init(
    _nTotalNum: number,
    _dDataDis: number,
    _nPictureBoxWidth: number
  ) {
    this.ChangeTotalDataNum(_nTotalNum, false, false);
    this.ChangePictureWidth(_nPictureBoxWidth, true);
    this._ChangeDataDis(_dDataDis);
  }

  /* --------------------------------------------- */
  //Bool
  public IsOverRightBuffer() {
    if (this.ScreenEndDataPos > this.m_fTotalDataWidth - XInfo.OverLimitBuffer)
      return true;
    return false;
  }
  public IsOverLeftBuffer() {
    if (this.ScreenDataPos < XInfo.OverLimitBuffer) return true;
    return false;
  }
  /* --------------------------------------------- */
  // Data相關
  public ChangePictureWidth(
    _nPictureBoxWidth: number,
    _bSetMore: boolean = false
  ) {
    let dLPos = this.m_fScreenDataPos;
    let lRPos = this.m_fScreenDataPos + this.m_nDrawWidth;
    let nWidthDif = this.m_nPictureWidth - _nPictureBoxWidth;

    this.m_nPictureWidth = _nPictureBoxWidth;
    this.m_nDrawPos = XInfo.BorderLineWidth;

    // 可畫圖寬度 : 總寬 - 左外框 - 右外框 - 分隔線 - 值區域寬
    this.m_nDrawWidth =
      this.m_nPictureWidth -
      XInfo.BorderLineWidth -
      XInfo.BorderLineWidth -
      XInfo.FrameLineWidth -
      XInfo.ValueAreaWidth;
    if (this.m_nDrawWidth <= 0) return;

    // 寫字區域 : 總寬 - 右外框 - 值區域寬
    this.m_nValuePos =
      this.m_nPictureWidth - XInfo.BorderLineWidth - XInfo.ValueAreaWidth;

    /* --------------------------------------------------- */
    // 固定畫面上的K棒
    //Zoom_PosRange(dLPos, lRPos);

    /* --------------------------------------------------- */
    // 固定K棒大小

    if (!_bSetMore) {
      this._ChangeDataDis(this.m_fDataDis);
      //m_nXDataNum = GetXDataNum(m_nDrawWidth, m_fDataDis); // 畫面上的K棒數量
      //if (m_fScreenDataPos != 0)

      this.MoveDisplayPos(nWidthDif);
    }
  }
  public ChangeTotalDataNum(
    _nTotalDataNum: number,
    _bKeepDisplayPos: boolean = false,
    _bUpdateXUnit: boolean = true
  ) {
    // 觀察畫面在前面時, 新加入K棒也會把畫面右移, 暫時先把KeepRight_FixedBuffer參數改成False
    //decimal dDisplayPos = KeepRight_FixedBuffer(_nTotalDataNum, _bKeepDisplayPos);
    let dDisplayPos = this.KeepRight_FixedBuffer(_nTotalDataNum, false);
    this.m_nTotalDataNum = _nTotalDataNum;
    this.UpdateTotalDataWidth();
    this._ChangeDisplayPos(dDisplayPos);

    if (_bUpdateXUnit) this.UpdateXUnit();
  }
  public ChangeXDataNum(_nXDataNum: number, _bSetMore: boolean = false) {
    this.ChangeDataDis_WidthAndNum(this.m_nDrawWidth, _nXDataNum);
    if (_bSetMore) return;
    this.UpdateXUnit();
  }
  private UpdateTotalDataWidth() {
    this.m_fTotalDataWidth =
      this.m_nLeftBlockWidth +
      this.m_nRightBlockWidth +
      this.m_nTotalDataNum * this.m_fDataDis;

    // 資料量不夠時, K棒貼齊左邊
    if (this.m_fTotalDataWidth < this.m_nDrawWidth)
      this.m_fTotalDataWidth = this.m_nDrawWidth;
  }
  public ChangeDataDis(_fDataDis: number) {
    this._ChangeDataDis(_fDataDis);
  }
  public ChangeDataDis_WidthAndNum(_nDrawWidth: number, _dXDataNum: number) {
    if (_nDrawWidth <= 0) return;
    if (_dXDataNum <= 0) return;

    this._ChangeDataDis(XInfo.GetDataDis(_nDrawWidth, _dXDataNum));
  }
  private _ChangeDataDis(_fDataDis: number) {
    // 卡住DataDis極限值
    let dMaxDis = this.GetMaxDataDis();
    let dMinDis = this.GetMinDataDis();
    if (_fDataDis < dMinDis) _fDataDis = dMinDis;
    if (_fDataDis > dMaxDis) _fDataDis = dMaxDis;

    let bDataDisChange = this.m_fDataDis != _fDataDis;
    this.m_fDataDis = _fDataDis; // 兩筆Data間的距離

    // Data顯示
    this.m_nXBase = XInfo.GetXBase(this.m_fDataDis, XInfo.m_Rate); // X中心向左右的額外寬度
    this.m_nDataWidth = 2 * this.m_nXBase + 1; // K棒總寬度
    this.m_nXDataNum = XInfo.GetXDataNum(this.m_nDrawWidth, this.m_fDataDis); // 畫面上的K棒數量

    this.UpdateTotalDataWidth();
    return bDataDisChange;
  }
  /* --------------------------------------------- */
  // Zoom
  public Zoom_PosRange(
    _fLeftPos: number,
    _fRightPos: number // 目前狀態的左右Pos Zoom到畫面上
  ) {
    /* ------------------------------------- */
    // 比例計算:
    //
    //   (舊的總長度[畫面寬度] - 左Buffer值 - 右Buffer值)
    // ------------------------------------------
    //   (新的總長度[ Input ] - 左Buffer值 - 右Buffer值)
    //
    // 左右Buffer值: Input佔了多少Pixel的值
    /* ------------------------------------- */

    /* ------------------------------------- */
    // 防呆處理
    _fRightPos = this.RightDataPos(_fRightPos);
    _fLeftPos = this.LeftDataPos(_fLeftPos);

    /* ------------------------------------- */
    // 左右佔Buffef值
    let dLeftBufferWidth = 0; // 新左Buffer寬
    if (_fLeftPos < this.m_nLeftBlockWidth)
      dLeftBufferWidth = this.m_nLeftBlockWidth - _fLeftPos;
    let dRightBufferWidth = 0; // 新右Buffer寬
    if (_fRightPos > this.m_fTotalDataWidth - this.m_nRightBlockWidth)
      dRightBufferWidth =
        _fRightPos - (this.m_fTotalDataWidth - this.m_nRightBlockWidth);
    if (dRightBufferWidth > this.m_nRightBlockWidth)
      // 右Buffer過大處理
      dRightBufferWidth = this.m_nRightBlockWidth;

    /* ------------------------------------- */
    // 新座標計算, 先找左邊緣DataIndex
    let dDataIndex = this.DataPosToFloatDataIndex(_fLeftPos);
    if (dDataIndex < 0) dDataIndex = 0;

    /* ------------------------------------- */
    // 比例計算
    let dWidthFrom = this.m_nDrawWidth - dLeftBufferWidth - dRightBufferWidth;
    let dWidthTo =
      _fRightPos - _fLeftPos - dLeftBufferWidth - dRightBufferWidth;
    if (dWidthTo <= 0) return;
    let fRate = dWidthFrom / dWidthTo;
    let fNewDataDis = this.m_fDataDis * fRate;
    if (!this._ChangeDataDis(fNewDataDis)) return;

    /* ------------------------------------- */
    // 新座標設定
    let fNewPos = this.DataIndexToDataPos(dDataIndex) - dLeftBufferWidth;
    this.ChangeDisplayPos(fNewPos);
  }
  public Zoom_DataIndex(
    _dDataIndex: number,
    _dDataNum: number // LeftDataIndex + DataNumber
  ) {
    this.ChangeXDataNum(_dDataNum, true);
    this.ChangeDisplayIndex(_dDataIndex);
  }
  public Zoom_DefaultRate(
    bZoomIn: boolean,
    _Datum: XInfoZoomDatum,
    _dDrawPosX: number
  ) {
    let dNowDataNum = this.GetDataNum(this.m_nDrawWidth);
    let dNewDataNum;

    if (bZoomIn) dNewDataNum = dNowDataNum / XInfo.DefaultZoomRate;
    else dNewDataNum = dNowDataNum * XInfo.DefaultZoomRate;

    this.Zoom_Datum(dNewDataNum, _Datum, _dDrawPosX);
  }
  public Zoom_Datum(
    _dDataNum: number,
    _Datum: XInfoZoomDatum,
    _dDrawPosX: number
  ) {
    let dDataPos = this.DatumToDataPos(_Datum, _dDrawPosX);
    this.Zoom_PosDatum(_dDataNum, dDataPos);
  }
  public Zoom_DatumRate(
    _Rate: number,
    _Datum: XInfoZoomDatum,
    _dDrawPosX: number
  ) {
    let newDataNume = this.m_nXDataNum * _Rate;
    this.Zoom_Datum(newDataNume, _Datum, _dDrawPosX);
  }
  private Zoom_PosDatum(
    _dDataNum: number,
    _dDisplayDataPos: number // 以座標為基準點Zoom
  ) {
    let dDisplayDif = _dDisplayDataPos - this.m_fScreenDataPos; // 基準點跟左邊座標差
    let dDisplayDataIndex = this.DataPosToFloatDataIndex(_dDisplayDataPos); // 基準點的DataIndex
    if (_dDisplayDataPos < this.m_nLeftBlockWidth) {
      // 基準點在左邊Buffer上
      dDisplayDif = this.m_nLeftBlockWidth - _dDisplayDataPos;
      dDisplayDataIndex = 0;
    }
    if (_dDisplayDataPos > this.m_fTotalDataWidth - this.m_nRightBlockWidth) {
      // 基準點在右邊Buffer上
      let dOtherDif =
        _dDisplayDataPos - (this.m_fTotalDataWidth - this.m_nRightBlockWidth);
      dDisplayDif -= dOtherDif;
      dDisplayDataIndex = this.DataPosToFloatDataIndex(
        this.m_fTotalDataWidth - this.m_nRightBlockWidth
      );
    }

    this.ChangeXDataNum(_dDataNum, true);

    /* ------------------------------------- */
    // 新座標設定
    let fNewPos = this.DataIndexToDataPos(dDisplayDataIndex) - dDisplayDif;
    this.ChangeDisplayPos(fNewPos);
  }

  public Zoom_DataDis(
    _DataDis: number,
    _dDisplayDataPos: number // 以座標為基準點Zoom
  ) {
    _dDisplayDataPos = _dDisplayDataPos + this.m_fScreenDataPos;
    let dDisplayDif = _dDisplayDataPos - this.m_fScreenDataPos; // 基準點跟左邊座標差
    let dDisplayDataIndex = this.DataPosToFloatDataIndex(_dDisplayDataPos); // 基準點的DataIndex

    if (_dDisplayDataPos < this.m_nLeftBlockWidth) {
      // 基準點在左邊Buffer上
      dDisplayDif = this.m_nLeftBlockWidth - _dDisplayDataPos;
      dDisplayDataIndex = 0;
    }
    if (_dDisplayDataPos > this.m_fTotalDataWidth - this.m_nRightBlockWidth) {
      // 基準點在右邊Buffer上
      let dOtherDif =
        _dDisplayDataPos - (this.m_fTotalDataWidth - this.m_nRightBlockWidth);
      dDisplayDif -= dOtherDif;
      dDisplayDataIndex = this.DataPosToFloatDataIndex(
        this.m_fTotalDataWidth - this.m_nRightBlockWidth
      );
    }

    this.ChangeDataDis(_DataDis);

    /* ------------------------------------- */
    //新座標設定
    let fNewPos = this.DataIndexToDataPos(dDisplayDataIndex) - dDisplayDif;
    this.ChangeDisplayPos(fNewPos);
  }
  private DatumToDataPos(_Datum: XInfoZoomDatum, _dDrawPos: number) {
    let dDataPos;
    switch (_Datum) {
      case XInfoZoomDatum.PosBase:
      case XInfoZoomDatum.RightPos:
      default:
        dDataPos = this.m_fScreenDataPos + this.m_nDrawWidth;
        break;
      case XInfoZoomDatum.IndexBase:
      case XInfoZoomDatum.RightIndex:
        dDataPos = this.DataIndexToCenterDataPos(
          this.DataPosToFloatDataIndex(
            this.m_fScreenDataPos + this.m_nDrawWidth
          )
        );
        if (dDataPos > this.m_fScreenDataPos + this.m_nDrawWidth)
          dDataPos -= this.m_fDataDis;
        break;
      case XInfoZoomDatum.LeftPos:
        dDataPos = this.m_fScreenDataPos;
        break;
      case XInfoZoomDatum.LeftIndex:
        dDataPos = this.DataIndexToCenterDataPos(
          this.DataPosToFloatDataIndex(this.m_fScreenDataPos)
        );
        if (dDataPos < this.m_fScreenDataPos) dDataPos += this.m_fDataDis;
        break;
      case XInfoZoomDatum.CenterPos:
        dDataPos = this.m_fScreenDataPos + this.m_nDrawWidth / 2;
        break;
      case XInfoZoomDatum.CenterIndex:
        dDataPos = this.DataIndexToCenterDataPos(
          this.DataPosToFloatDataIndex(
            (this.m_fScreenDataPos + this.m_nDrawWidth) / 2
          )
        );
        break;

      case XInfoZoomDatum.FixedPos:
        dDataPos = this.DrawPosToDataPos(_dDrawPos);
        break;
      case XInfoZoomDatum.FixedIndex:
        dDataPos = this.DataIndexToCenterDataPos(
          this.DataPosToFloatDataIndex(this.DrawPosToDataPos(_dDrawPos))
        );
        break;
    }
    return dDataPos;
  }

  /* -------------------------------------- */
  // 移動畫面
  private _ChangeDisplayPos(
    _fDataPos: number // 底層使用, 不會Update XUnit
  ) {
    if (_fDataPos < 0) _fDataPos = 0;
    if (_fDataPos > this.m_fTotalDataWidth - this.m_nDrawWidth)
      _fDataPos = this.m_fTotalDataWidth - this.m_nDrawWidth;

    this.m_fScreenDataPos = _fDataPos;
  }
  public ChangeDisplayPos(_fDataPos: number) {
    this._ChangeDisplayPos(_fDataPos);
    this.UpdateXUnit();
  }
  public MoveDisplayPos(_fPosDif: number) {
    /* ----------------------------------------------- */
    // 排除掉OverLimitBuffer的座標範圍
    let LeftLimit = XInfo.OverLimitBuffer;
    let RightLimit =
      this.m_fTotalDataWidth - this.m_nDrawWidth - XInfo.OverLimitBuffer;
    let dPos = Math.max(
      LeftLimit,
      Math.min(RightLimit, this.m_fScreenDataPos + _fPosDif)
    );
    /* ----------------------------------------------- */

    this.ChangeDisplayPos(dPos);
  }
  public MoveDisplayIndex(_nIndexDif: number) {
    this.MoveDisplayPos(_nIndexDif * this.m_fDataDis);
  }
  public ChangeDisplayIndex(_nIndex: number) {
    /* ----------------------------------------------- */
    // 排除掉OverLimitBuffer的座標範圍
    let LeftLimit = XInfo.OverLimitBuffer;
    let RightLimit =
      this.m_fTotalDataWidth - this.m_nDrawWidth - XInfo.OverLimitBuffer;
    let dPos = Math.max(
      LeftLimit,
      Math.min(RightLimit, this.DataIndexToDataPos(_nIndex))
    );
    /* ----------------------------------------------- */

    this.ChangeDisplayPos(dPos);
  }
  public DisplayMove(bLeft: boolean, _nMoveBase: number) {
    let Def;
    let MaxDef = this.m_nXDataNum / 2;

    if (_nMoveBase < 5) Def = 1;
    else Def = _nMoveBase / 2;

    if (Def > MaxDef) Def = MaxDef;

    if (Def < 1) Def = 1;

    if (bLeft) Def = -Def;
    this.MoveDisplayIndex(Def);
  }
  private UpdateXUnit() {
    // 要觀察是否會連續進來
    // 可能之後需要透過是否EndDayIndex change防呆
    let mlXUnit = [];

    //List<string> mlDebug = new List<string>();
    //for (int i = m_nXDataNum - 1; i >= 0; i--)
    let nStartDataIndex = this.DataPosToDataIndex(this.m_fScreenDataPos);

    let nIStart = this.m_nXDataNum + 1;
    let nIEnd = -2;
    if (nStartDataIndex >= this.m_nTotalDataNum) {
      nStartDataIndex = this.m_nTotalDataNum - 1;
      nIStart = 0;
      nIEnd = -1;
    }

    for (
      let i = nIStart;
      i >= nIEnd;
      i-- // 前後各兩筆Buffer
    ) {
      // i 就是 RunningIndex
      let nDataIndex = nStartDataIndex + i;

      if (nDataIndex >= this.m_nTotalDataNum) continue;

      if (nDataIndex < 0) continue;

      //string Debug = string.Format("({0}) ", nDataIndex);
      //mlDebug.Add(Debug);

      let fMidDataPos = this.DataIndexToCenterDataPos(nDataIndex); // 對應所有Data的座標
      let fMidDrawPos = this.DataPosToDrawPos(fMidDataPos); // 顯示在畫面上的座標
      let xUnit = new XUnit(nDataIndex, fMidDrawPos, this.m_nDataWidth);

      /* ------------------------------------------------ */
      // 判斷超出畫圖區域的Flag

      let eDisplay = eXUnitDisplay.All;
      //if (nDataIndex <= m_nStartDateIndex || nDataIndex >= m_nEndDateIndex)
      if (
        fMidDrawPos <= this.m_nDrawPos ||
        fMidDrawPos >= this.m_nDrawPos + this.m_nDrawWidth
      ) {
        if (xUnit.fXLeftPos < this.m_nDrawPos) {
          eDisplay = eXUnitDisplay.OverHalf;
          if (fMidDrawPos < this.m_nDrawPos) {
            eDisplay = eXUnitDisplay.NonHalf;
            if (xUnit.fXRightPos < this.m_nDrawPos) {
              eDisplay = eXUnitDisplay.Empty;
            }
          }
        }

        //int nCanDrawWidth = GetCanDrawWidth(m_nPictureBoxWidth); // 可以畫圖的寬度(區域)
        if (xUnit.fXRightPos > this.m_nDrawPos + this.m_nDrawWidth) {
          eDisplay = eXUnitDisplay.OverHalf;
          if (fMidDrawPos > this.m_nDrawPos + this.m_nDrawWidth) {
            eDisplay = eXUnitDisplay.NonHalf;
            if (xUnit.fXLeftPos > this.m_nDrawPos + this.m_nDrawWidth) {
              eDisplay = eXUnitDisplay.Empty;
            }
          }
        }

        xUnit.eXUnitDisplay = eDisplay;
      }
      /* ------------------------------------------------ */

      mlXUnit.push(xUnit);
    }

    this.m_lXUnit = mlXUnit;
    // if (this.m_lXUnit.length != 0) {
    //   console.log(
    //     "XUnit",
    //     this.m_lXUnit[0].nDataIndex,
    //     this.m_lXUnit[this.m_lXUnit.length - 1].nDataIndex
    //   );
    // }

    //Debug.WriteLine("\r\n---------------------------------------------------\r\n");
    //for (int i = 0; i < mlDebug.Count - 1; i++)
    //{
    //    Debug.Write(mlDebug[i]);
    //    if (i % 10 == 0)
    //        Debug.WriteLine("\r\n");

    //    if (i > 100)
    //        break;
    //}
    //Debug.WriteLine("\r\n---------------------------------------------------\r\n");
  }
  public ChangeRightBufferWidth(_nBufferWidth: number) {
    this.m_nRightBlockWidth = _nBufferWidth;
    this.UpdateTotalDataWidth();
  }
  public MoveToRight() {
    this.ChangeDisplayPos(this.m_fTotalDataWidth);
    this.UpdateXUnit();
  }

  /* -------------------------------------- */
  // Pos To DataIndex
  private DataPosToFloatDataIndex(
    _fPos: number // 小於0無意義, 防掉
  ) {
    if (this.m_fDataDis == 0) return 0;
    let dRealPos = _fPos - this.m_nLeftBlockWidth;
    if (dRealPos < 0) dRealPos = 0;
    return dRealPos / this.m_fDataDis;
  }
  private DataPosToDataIndex(_fPos: number) {
    return Math.floor(this.DataPosToFloatDataIndex(_fPos));
  }
  private DrawPosToFloatDataIndex(_fPos: number) {
    return this.DataPosToFloatDataIndex(this.DrawPosToDataPos(_fPos));
  }
  public DrawPosToDataIndex(_fPos: number) {
    return Math.floor(this.DrawPosToFloatDataIndex(_fPos));
  }
  /* -------------------------------------- */
  // DataIndex To Pos
  private DataIndexToDataPos(_nDataIndex: number) {
    return this.m_nLeftBlockWidth + _nDataIndex * this.m_fDataDis;
  }
  public DataIndexToDrawPos(_nDataIndex: number) {
    return this.DataPosToDrawPos(this.DataIndexToDataPos(_nDataIndex));
  }
  private DataIndexToCenterDataPos(
    _nDataIndex: number // Data中心位置 (K棒中心)
  ) {
    // 該跟K棒中心 所以要先無條件捨去
    _nDataIndex = Math.floor(_nDataIndex);
    return (
      this.m_nLeftBlockWidth +
      _nDataIndex * this.m_fDataDis +
      this.m_fDataDis / 2
    );
  }
  public DataIndexToDrawCenterPos(
    _nDataIndex: number // Data中心位置 (K棒中心) 的畫面座標
  ) {
    return this.DataPosToDrawPos(this.DataIndexToCenterDataPos(_nDataIndex));
  }

  /* -------------------------------------- */
  // 螢幕畫面與實際Pos互轉
  public DataPosToDrawPos(_fDataPos: number) {
    return _fDataPos - this.m_fScreenDataPos + this.m_nDrawPos;
  }
  public DrawPosToDataPos(_fDrawPos: number) {
    return _fDrawPos + this.m_fScreenDataPos - this.m_nDrawPos;
  }

  /* -------------------------------------- */
  // DataDis極限值
  private GetMinDataDis() {
    /*
            // 畫面寬與畫面上現有的DataNum
            decimal dMinWidth = m_nDrawWidth - LeftBufferWidth - m_nRightBlockWidth - RightBufferWidth;
            if (dMinWidth < DrawingMinWidth)
                dMinWidth = DrawingMinWidth;
            decimal dDataNum = m_nTotalDataNum;
            if (dDataNum == 0) // 沒有Data時, 走預設DataNum
                dDataNum = DefaultDataNum;
            return dMinWidth / dDataNum;
            */
    //return 0.2m;
    return 3;
    /*
            if (m_nTotalDataNum != 0 && m_nTotalDataNum < cTECPDef.DATA_NUM_MAX)
                return (decimal)(m_nDrawWidth - LeftBufferWidth - RightBufferWidth) / m_nTotalDataNum;
            else
                return (decimal)m_nDrawWidth / cTECPDef.DATA_NUM_MAX;
            */
  }
  private GetMaxDataDis() {
    //return this.m_nDrawWidth / XInfo.MinDataNum;
    return 100;
  }

  /* -------------------------------------- */
  // 防呆輔助
  public GetDataNum(_dWidth: number) {
    if (this.m_fDataDis == 0) return 0;
    return _dWidth / this.m_fDataDis;
  }
  public LeftDataPos(_dLeftDataPos: number) {
    //if (_dLeftDataPos < 0)
    //    _dLeftDataPos = 0;
    if (_dLeftDataPos < 0) {
      if (this.m_fScreenDataPos >= 0) _dLeftDataPos = 0;
      else {
        if (_dLeftDataPos < this.m_fScreenDataPos)
          _dLeftDataPos = this.m_fScreenDataPos;
      }
    }
    return _dLeftDataPos;
  }
  public RightDataPos(_dRightDataPos: number) {
    if (_dRightDataPos > this.m_fTotalDataWidth)
      _dRightDataPos = this.m_fTotalDataWidth;
    return _dRightDataPos;
  }

  /* -------------------------------------- */
  // 額外輔助Func
  public RightBufferAtScreenStartPos() {
    // 右邊Buffer相對於畫面上的起始座標
    let dRightBufferStartDataPos =
      this.m_nLeftBlockWidth + this.m_nTotalDataNum * this.m_fDataDis;
    let dAtScreenPos = this.DataPosToDrawPos(dRightBufferStartDataPos);
    return dAtScreenPos;
  }

  private KeepRight_FixedBuffer(
    _nNewTotalNum: number,
    _bKeepDisplay: boolean = false // Keep Right 保留Buffer空間
  ) {
    let dRightDataPos = this.m_fTotalDataWidth - this.m_nRightBlockWidth; // Data最右邊的DataPos
    let dRightDisplayDataPos = this.m_fScreenDataPos + this.m_nDrawWidth; // 畫面上最右邊的DataPos
    let bNeedKeepRight = dRightDisplayDataPos >= dRightDataPos;
    let dDisplayPosRet = this.m_fScreenDataPos;
    if (bNeedKeepRight || _bKeepDisplay) {
      let dDataNumDif = _nNewTotalNum - this.m_nTotalDataNum;
      let dPosDif = dDataNumDif * this.m_fDataDis;
      dDisplayPosRet += dPosDif;
    }
    return dDisplayPosRet;
  }
  // static Fun
  public static GetCanDrawWidth(_nPictureBoxWidth: number) {
    return (
      _nPictureBoxWidth -
      cTECPDef.FIXED_RIGHT_SPACE -
      cTECPDef.FIXED_FIRST_KLINE_SPACE
    );
  }
  private static GetDataDis(_dCanDrawWidth: number, _dXDataNum: number) {
    return _dCanDrawWidth / _dXDataNum;
  }
  private static GetXBase(_fDataDis: number, _fRate: number) {
    let nXBase;
    if (_fDataDis > 1) Math.floor((nXBase = ((_fDataDis - 1) * _fRate) / 2));
    else nXBase = 0;
    return nXBase;
  }
  private static GetXDataNum(_nCanDrawWidth: number, _fDataDis: number) {
    let fXDataNum = _nCanDrawWidth / _fDataDis;
    return Math.floor(MathEx.kcRound(fXDataNum, 1));
  }
}
