import moment, { Moment } from "moment";
import { MomentExtensions } from "../kcExternal";

export default class kcHistoryOHLCModel {
  constructor(props: object) {
    Object.assign(this, { ...props });

    this.Time = MomentExtensions.Parse(this.Time);
  }

  Time: Moment = moment(0);
  OpenPrice: number = 0;
  HighPrice: number = 0;
  LowPrice: number = 0;
  ClosePrice: number = 0;
  Vol: number = 0;

  // 尚未擴OHLCEx, 暫存變數
  StartTickIndex: number = -1;
  EndTickIndex: number = -1;

  ToString() {
    let szRet = "";
    szRet += this.Time.utc().format("YYYY/MM/DD HH:mm:ss.SSS") + ", ";
    szRet += this.OpenPrice + ", ";
    szRet += this.HighPrice + ", ";
    szRet += this.LowPrice + ", ";
    szRet += this.ClosePrice + ", ";
    szRet += this.Vol + ", ";
    return szRet;
  }

  /* ---------------------------static Function--------------------------- */
  static FixedBufferSize: number = 44;
  static FromListBuffer(bytes: Uint8Array): kcHistoryOHLCModel[] {
    let mdRet: kcHistoryOHLCModel[] = [];

    try {
      let nDataCount = bytes.length / kcHistoryOHLCModel.FixedBufferSize;
      for (let i = 0; i < nDataCount; i++) {
        let md = kcHistoryOHLCModel.CreateFromBuffer(
          bytes,
          i * kcHistoryOHLCModel.FixedBufferSize
        );
        if (md) mdRet.push(md);
      }
    } catch {}

    return mdRet;
  }

  static CreateFromBuffer(
    bytes: Uint8Array,
    offset: number
  ): kcHistoryOHLCModel | undefined {
    if (offset + kcHistoryOHLCModel.FixedBufferSize > bytes.length)
      return undefined;

    let dv = new DataView(bytes.buffer, offset);

    let nIdx = 0;

    let dOADate: number = dv.getFloat64(nIdx, true);
    let tTime: Moment = MomentExtensions.FromOADate(dOADate).utc();
    nIdx += 8;

    let dOpenPrice = dv.getFloat64(nIdx, true);
    nIdx += 8;

    let dHighPrice = dv.getFloat64(nIdx, true);
    nIdx += 8;

    let dLowPrice = dv.getFloat64(nIdx, true);
    nIdx += 8;

    let dClosePrice = dv.getFloat64(nIdx, true);
    nIdx += 8;

    let nVol = dv.getUint32(nIdx, true);
    nIdx += 4;

    let mdTick: kcHistoryOHLCModel = new kcHistoryOHLCModel({
      Time: tTime,
      OpenPrice: dOpenPrice,
      HighPrice: dHighPrice,
      LowPrice: dLowPrice,
      ClosePrice: dClosePrice,
      Vol: nVol,
    });

    return mdTick;
  }

  static CloneAddDiff(
    _mdOri: kcHistoryOHLCModel,
    _dPointDiff: number
  ): kcHistoryOHLCModel {
    let mdClone = new kcHistoryOHLCModel(_mdOri);
    mdClone.Time = _mdOri.Time.clone();
    mdClone.OpenPrice = mdClone.OpenPrice - _dPointDiff;
    mdClone.HighPrice = mdClone.HighPrice - _dPointDiff;
    mdClone.LowPrice = mdClone.LowPrice - _dPointDiff;
    mdClone.ClosePrice = mdClone.ClosePrice - _dPointDiff;
    return mdClone;
  }
  static IsHistoryOHLCModel(_Input: any): boolean {
    if (
      !_Input["Time"] ||
      !_Input["OpenPrice"] ||
      !_Input["HighPrice"] ||
      !_Input["LowPrice"] ||
      !_Input["ClosePrice"] ||
      !_Input["Vol"]
    )
      return false;
    return true;
  }
}

var tmp = Object.keys(new kcHistoryOHLCModel({}));
