import * as React from "react";
import { Fragment, useState } from "react";
import { StyleSheet, Platform } from "react-native";
import { Text, View } from "../components/Themed";
import { kcSetState, kcSetUnmount } from "../kcExternal";
import moment, { Moment } from "moment";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import * as Device from "expo-device";

type IState = {
  szDateTime: string;
};

type IProp = {
  defaultValue?: Moment;
  EnableTime?: boolean;
  Disable?: boolean;
  onChange?: (value: Moment | null, dateString: string) => void;
};

export default function KC_DatePicker(props: IProp) {
  let defaultValue: Moment = moment.utc();
  if (props.defaultValue) {
    defaultValue = props.defaultValue;
  }

  const classes = useStyles();

  let sztype;
  let szFormat;
  let TextClass;
  let InputClass;
  if (props.EnableTime) {
    sztype = "datetime-local";
    szFormat = "YYYY-MM-DDTHH:mm";
    TextClass =
      Device.osName == "Windows"
        ? classes.textField_DateTime_Windows
        : classes.textField_DateTime;

    InputClass =
      Device.osName == "Windows"
        ? classes.input_DateTime_Windwos
        : classes.input_DateTime;
  } else {
    sztype = "date";
    szFormat = "YYYY-MM-DD";
    TextClass =
      Device.osName == "Windows"
        ? classes.textField_Windows
        : classes.textField;

    InputClass =
      Device.osName == "Windows" ? classes.input_Windwos : classes.input;
  }

  let szdefaultValue = defaultValue.format(szFormat);
  const [szDateTime, setszDateTime] = useState(szdefaultValue);

  const OnValueChange = (szValue: string) => {
    setszDateTime(szValue);
    if (!props.onChange) return;
    let mom = moment.utc(szValue);
    props.onChange(mom, szValue);
  };

  return (
    <form className={classes.container} noValidate>
      <TextField
        type={sztype}
        onChange={(values) => OnValueChange(values.target.value)}
        value={szDateTime}
        className={TextClass}
        InputProps={{
          className: InputClass,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        size="small"
        disabled={props.Disable}
        rowsMax={1}
      />
    </form>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },

    /*--------------------------------------*/
    textField: {
      backgroundColor: "#292B2A",
      width: 140,
      height: 25,
    },
    input: {
      color: "#4D84EB",
      width: 140,
    },

    /*--------------------------------------*/
    textField_Windows: {
      backgroundColor: "#292B2A",
      width: 140,
      height: 25,
    },
    input_Windwos: {
      color: "#4D84EB",
      width: 140,
    },

    /*--------------------------------------*/
    textField_DateTime: {
      backgroundColor: "#00000000",
      width: 250,
      height: 25,
      alignItems: "flex-end",
    },
    input_DateTime: {
      color: "#4D84EB",
      width: 200,
    },

    /*--------------------------------------*/
    textField_DateTime_Windows: {
      backgroundColor: "#292B2A",
      width: 225,
      height: 25,
      //alignItems: "center",
    },
    input_DateTime_Windwos: {
      color: "#4D84EB",
      width: 225,
    },
    /*--------------------------------------*/
  })
);
