export enum kcExchangeCode {
  UnDef = 0,
  Forex = 1, // 外匯交易
  SpotMetals = 2, // 金屬現貨
  SpotEnergies = 3, // 能源
  Indices = 4, // 指標
  CryptoCurrency = 5, // 加密貨幣
}
export enum kcCommodityCategory {
  Unknow = 0x0,
  Index = 0x10,
  Stock = 0x20,
  StockWarrant = 0x21,
  CommodityFuture = 0x30,
  IndexFuture = 0x31,
  StockFuture = 0x32,
  InterestRateFuture = 0x33,
  BondFuture = 0x34,
  CurrFuture = 0x35,
  IndexOption_Call = 0x40,
  IndexOption_Put = 0x48,
  StockOption_Call = 0x41,
  StockOption_Put = 0x49,
  FutureOption_Call = 0x42,
  FutureOption_Put = 0x4a,
  CurrOption_Call = 0x44,
  CurrOption_Put = 0x4c,
}
export enum TradeTimeState {
  UnDef = 0,
  //Stop = 0,
  //Start = 1,
  //Clear = 2,
  Open = 4,
  Close = 5,
  //PreOpen = 5,
  //Suspend = 6,
  //Remove = 9
}
export enum DayKLineType {
  Day = 1,
  Week = 5,
  Month = 20,
  Season = 60,
  HalfYear = 120,
  Year = 240,
}
export enum CurrencyType { // 貨幣
  UnKnow = 0,
  CNH = 1,
  CNY = 2,
  EUR = 3,
  GBP = 4,
  JPY = 5,
  NTD = 6,
  SGD = 7,
  USD = 8,

  TWD = NTD,
}
