import moment, { Moment } from "moment";
import {
  CompressionExtensions,
  MathEx,
  MomentExtensions,
  StringConverter,
} from "../kcExternal";
import kcTickModel from "./kcTickModel";

export default class kcLastInfoModel {
  constructor(props: object) {
    Object.assign(this, { ...props });
    this.FixedTime();
  }

  /* ---------------------------Member--------------------------- */
  StockCode: string = "";
  Time: Moment = moment(0); // DateTime (8 bytes double OADate)
  PreClosePrice: number = 0;
  BasePrice: number = 0;
  OpenPrice: number = 0;
  HighPrice: number = 0;
  LowPrice: number = 0;
  ClosePrice: number = 0;
  AskPrice: number = 0; // double (8 bytes double)
  BidPrice: number = 0; // double (8 bytes double)
  Vol: number = 0;
  TotalVol: number = 0;

  ToString() {
    let szRet = "";
    szRet += this.Time.utc().format("YYYY/MM/DD HH:mm:ss.SSS") + ", ";
    szRet += this.AskPrice + ", ";
    szRet += this.BidPrice + ", ";
    szRet += this.Vol + ", ";
    szRet += this.TotalVol + ", ";
    return szRet;
  }

  Update_ByLastInfo(_mdLastInfo: kcLastInfoModel) {
    Object.assign(this, { ..._mdLastInfo });
    this.FixedTime();
  }

  Update_ByTick(_mdTick: kcTickModel) {
    Object.assign(this, { ..._mdTick });
    this.FixedTime();

    let Price: number = _mdTick.BidPrice; // 成交價
    if (this.OpenPrice === 0) this.OpenPrice = Price;
    if (this.HighPrice < Price) this.HighPrice = Price;
    if (this.LowPrice > Price) this.LowPrice = Price;
    this.ClosePrice = Price;
  }

  CloneAddDiff(_PointDiff: number) {
    var md: kcLastInfoModel = new kcLastInfoModel(this);

    if (this.PreClosePrice != 0)
      md.PreClosePrice = MathEx.kcRound(this.PreClosePrice - _PointDiff, 10);
    if (this.BasePrice != 0)
      md.BasePrice = MathEx.kcRound(this.BasePrice - _PointDiff, 10);
    if (this.OpenPrice != 0)
      md.OpenPrice = MathEx.kcRound(this.OpenPrice - _PointDiff, 10);
    if (this.HighPrice != 0)
      md.HighPrice = MathEx.kcRound(this.HighPrice - _PointDiff, 10);
    if (this.LowPrice != 0)
      md.LowPrice = MathEx.kcRound(this.LowPrice - _PointDiff, 10);
    if (this.ClosePrice != 0)
      md.ClosePrice = MathEx.kcRound(this.ClosePrice - _PointDiff, 10);
    if (this.AskPrice != 0)
      md.AskPrice = MathEx.kcRound(this.AskPrice + _PointDiff, 10);
    if (this.BidPrice != 0)
      md.BidPrice = MathEx.kcRound(this.BidPrice - _PointDiff, 10);

    return md;
  }

  private FixedTime() {
    this.Time = MomentExtensions.Parse(this.Time);
  }

  /* ---------------------------static Function--------------------------- */
  static CreateList_FromJSon(ZipJSonBuffer: Uint8Array): kcLastInfoModel[] {
    let JSonBuffer = CompressionExtensions.Zlib_UnZip(ZipJSonBuffer);
    let szJSon: string = StringConverter.UTF8_GetString(JSonBuffer);
    let Obj = JSON.parse(szJSon);

    let mlLastInfo: kcLastInfoModel[] = [];
    if (Obj) {
      if (Array.isArray(Obj))
        mlLastInfo = Obj.map((q) => new kcLastInfoModel(q));
      else {
        mlLastInfo.push(new kcLastInfoModel(Obj));
      }
    }
    return mlLastInfo;
  }
}
