import moment, { Moment } from "moment";
import { MomentExtensions } from "../kcExternal";

export enum LDFAccountType {
  未定義,
  直戶,
  跟單戶,
  經理人,
}
export enum LDFGroupType { // 群組 幾乎只用再點差
  未定義,
  LDF_A1,
  LDF_A2,
  LDF_A3,
  LDF_B1,
  LDF_B2,
  LDF_B3,
}

export default class kcAccountInformationModel {
  constructor(props: object) {
    Object.assign(this, { ...props });
    this.RegTime = MomentExtensions.Parse(this.RegTime);
  }

  // Deleted:boolean = false;
  // IsConfirm:"未開通"|"已開通" ="未開通"; // 是否認證
  // AccIdx:number = -1; // 序號
  RegTime: Moment = moment(0); // 註冊日期
  Account: string = ""; // 帳號
  // Password:string = ""; // 密碼
  // PasswordFollow:string = ""; // 觀摩密碼
  Name: string = ""; // 姓名
  EMail: string = ""; // EMail
  Multiple: number = 100; // 交易倍數
  PointDiff: number = 29; // 點差
  AccountType: LDFAccountType = LDFAccountType.未定義;
  GroupType: LDFGroupType = LDFGroupType.未定義;
  // CommissionTimeType:"每周"|"每月"|"每季" ="每月";
  // CommissionMode:"未定義"|"獲利比例" = "獲利比例"; // 利潤分成方式
  CommissionRate: number = 30; // 利潤分成比例
  Ex: string = ""; // 註釋
  // SubAccountIdxs: number[] = []; // 跟單戶Hidx列表
  // ParentAccountIdx: number = -1; // 經理戶 Hidx
  // StopInPrepaidRate: number = 100; // 阻擋進場報倉比例
  // AutoOutPrepaidRate: number = 50; // 強制出場報倉比例
  // EnableRealOrder: boolean = false; // 真實下單
  // RootAccount_UserIdx: number = -1; // 資金帳戶Idx

  public Update(_mdInfo: kcAccountInformationModel) {
    Object.assign(this, { ..._mdInfo });
    this.RegTime = MomentExtensions.Parse(this.RegTime);
  }
}
