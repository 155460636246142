import * as React from "react";
import {
  StyleProp,
  StyleSheet,
  TextStyle,
  ViewStyle,
  TextInput,
} from "react-native";
import { Text, View } from "../components/Themed";
import { Button } from "react-native-elements";
import { Ionicons } from "@expo/vector-icons";
import { kcSetState, kcSetUnmount } from "../kcExternal";
import { kcColor } from "../constants/Colors";

type IState = {
  Value: string;
};

type IProp = {
  Title: string;
  DefaultValue: string;
  BtnClickDefaultValue: string;
  FloatNumber: number;
  PriceScale: number;
  Disable?: boolean;
  Visable?: boolean;
  OnValueChange: (Value: string) => void;
};

class KC_RowNumberControl extends React.Component<IProp, IState> {
  state: IState = {
    Value: "",
  };
  componentDidMount() {
    kcSetState(this, { Value: this.props.DefaultValue });
  }

  onChangeText = (text: string) => {
    kcSetState(this, { Value: text });
  };

  onTextBlur = () => {
    let nValue = parseFloat(this.state.Value);
    let szValue;
    if (!nValue) {
      szValue = this.props.DefaultValue;
    } else {
      szValue = nValue.toFixed(this.props.FloatNumber);
    }
    kcSetState(this, { Value: szValue });
    this.props.OnValueChange(szValue);
  };

  onBtnClick = (n: number) => {
    let szValue;
    if (this.state.Value == "") {
      szValue = this.props.BtnClickDefaultValue;
    } else {
      let nValue = parseFloat(this.state.Value);
      if (!nValue) {
        szValue = this.props.BtnClickDefaultValue;
      } else {
        nValue = nValue + n;
        szValue = nValue.toFixed(this.props.FloatNumber);
      }
    }
    kcSetState(this, { Value: szValue });
    this.props.OnValueChange(szValue);
  };

  ToDefaultValue = () => {
    kcSetState(this, { Value: this.props.DefaultValue });
    this.props.OnValueChange(this.props.DefaultValue);
  };

  SetValue = (_Value: string) => {
    kcSetState(this, { Value: _Value });
    this.props.OnValueChange(_Value);
  };

  render() {
    if (this.props.Visable == false) return <></>;
    return (
      <View
        style={[
          {
            //height: 30,
            //margin: 3,
            backgroundColor: "#00000000",
          },
        ]}
      >
        {!this.props.Disable && (
          <View
            style={[
              {
                height: 30,
                backgroundColor: "#00000000",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              },
            ]}
          >
            <Text
              selectable={false}
              style={{ flex: 1, color: kcColor("Title") }}
            >
              {this.props.Title}
            </Text>

            <Button
              containerStyle={styles.NumberBtnContainer}
              buttonStyle={styles.NumberBtn}
              onPress={this.onBtnClick.bind(this, this.props.PriceScale * -1)}
              icon={
                <Ionicons
                  selectable={false}
                  name="ios-remove"
                  size={20}
                  color={kcColor("Blue")}
                />
              }
            />
            <TextInput
              style={{
                flex: 1,
                color: kcColor("Title"),
                textAlign: "center",
                fontSize: 16,
                minWidth: 0, // 設定0是因為 ios和web的寬差距很大
              }}
              keyboardType="decimal-pad"
              value={this.state.Value}
              onChangeText={this.onChangeText}
              onBlur={this.onTextBlur}
              returnKeyLabel="Done"
              returnKeyType="done"
              maxLength={7}
              placeholder="未設定"
            />
            <Button
              containerStyle={styles.NumberBtnContainer}
              buttonStyle={styles.NumberBtn}
              onPress={this.onBtnClick.bind(this, this.props.PriceScale)}
              icon={
                <Ionicons
                  selectable={false}
                  name="ios-add"
                  size={20}
                  color={kcColor("Blue")}
                />
              }
            />
          </View>
        )}
        {this.props.Disable && (
          <View
            style={[
              {
                height: 30,
                backgroundColor: "#00000000",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              },
            ]}
          >
            <Text
              selectable={false}
              style={{ flex: 1, color: kcColor("Title") }}
            >
              {this.props.Title}
            </Text>
            <Text
              selectable={false}
              style={{
                marginLeft: -100,
                flex: 1,
                color: kcColor("Title"),
                textAlign: "center",
                fontSize: 16,
              }}
            >
              {this.state.Value != "" ? this.state.Value : "未設定"}
            </Text>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  NumberBtnContainer: {
    //flex: 1,
    backgroundColor: "#00000000",
    justifyContent: "center",
  },
  NumberBtn: {
    //backgroundColor: "#505050",
    backgroundColor: "#00000000",
    height: 20,
    borderRadius: 0,
    borderWidth: 0,
    padding: 0,
    width: 50,
  },
});

export default KC_RowNumberControl;
