import {
  IP_Quote_Dealer,
  IP_Quote_Subscriber,
  CommandType,
  CommodityChangeType,
  SystemSubscriberTopic_CommodityChange,
  SystemSubscriberTopic_LastInfoUpdate,
  SystemSubscriberTopic_ServerMessage,
  HistoryDataType,
  QuoteHistoryRequestMode,
} from "./InternalDefine";
import { Dealer, Message, Subscriber } from "../TSMQ";
import {
  kcTickModel,
  kcLastInfoModel,
  kcCommodityModel,
  kcHistoryTickModel,
  kcHistoryOHLCModel,
} from "../kcModel";
import * as kcTransModel from "./kcTransModel";
import { CompressionExtensions } from "../kcExternal";

export type TickCallback = (_szStockCode: string, _mdTick: kcTickModel) => void;
export type LastInfoCallback = (_mlLastInfos: kcLastInfoModel[]) => void;
export type CommodityListCallback = (
  _mlCommodityList: kcCommodityModel[]
) => void;
export type CommodityChangeCallback = (
  _mdCommodity: kcCommodityModel,
  _ChangeType: CommodityChangeType
) => void;
export type HistoryCallback = (
  StockCode: string,
  HType: HistoryDataType,
  DayNumber: number,
  mlOHLC: kcHistoryOHLCModel[]
) => void;

var m_bDealerInited: boolean = false;
var m_bSubscriberInited: boolean = false;
var sQDealer: Dealer | undefined = undefined;
var sQSubscriber: Subscriber | undefined = undefined;

var OnTickCallback: TickCallback | undefined;
var OnLastInfoCallback: LastInfoCallback | undefined;
var OnCommodityListCallback: CommodityListCallback | undefined;
var OnCommodityChangeCallback: CommodityChangeCallback | undefined;
var OnHistoryCallback: HistoryCallback | undefined;
var OnQuoteReadyCallback: () => void | undefined;

// console.log(
//   "-----------------------------kcQuote In-----------------------------"
// );

export var InitializeCallback = (
  _OnTickCallback?: TickCallback,
  _OnLastInfoCallback?: LastInfoCallback,
  _OnCommodityListCallback?: CommodityListCallback,
  _OnCommodityChangeCallback?: CommodityChangeCallback,
  _OnHistoryCallback?: HistoryCallback
) => {
  OnTickCallback = _OnTickCallback;
  OnLastInfoCallback = _OnLastInfoCallback;
  OnCommodityListCallback = _OnCommodityListCallback;
  OnCommodityChangeCallback = _OnCommodityChangeCallback;
  OnHistoryCallback = _OnHistoryCallback;
};

export var SetQuoteReadyCallback = (
  _OnQuoteReadyCallback: () => void | undefined
) => {
  OnQuoteReadyCallback = _OnQuoteReadyCallback;
};

export var InitializeSocket = () => {
  InitializeDealer();
  if (!m_bSubscriberInited) InitializeSubscriber();
};

/* ------------------------------------------------------------------------- */
// Dealer
var InitializeDealer = () => {
  //console.log("Initialize Dealer() In");
  if (!m_bDealerInited) {
    sQDealer = new Dealer(On_Dealer_Receive);
    sQDealer.connect(IP_Quote_Dealer);
    m_bDealerInited = true;
  }

  Quote_Request_CommodityList();
};

var On_Dealer_Receive = (msg: Uint8Array[]) => {
  if (msg.length < 2) return;

  let CTypeBuffer: Uint8Array | undefined = msg.shift(); // 回傳並移除陣列第一個元素
  if (CTypeBuffer == undefined) return;

  let dv = new DataView(CTypeBuffer.buffer);
  let nCType = dv.getUint32(0, true) as CommandType;

  switch (nCType) {
    case CommandType.CommodityList:
      return On_Dealer_Receive_CommodityList(msg);
    case CommandType.LastInfo:
      return On_Dealer_Receive_LastInfo(msg);
    case CommandType.HistoryData:
      return On_Dealer_Receive_HistoryData(msg);
  }
};
var On_Dealer_Receive_CommodityList = (msg: Uint8Array[]) => {
  let mlCommodity = kcCommodityModel.CreateList_FromJSon(msg[0]);
  if (!mlCommodity) return;
  if (OnCommodityListCallback) OnCommodityListCallback(mlCommodity);
  if (OnQuoteReadyCallback) OnQuoteReadyCallback();
};
var On_Dealer_Receive_LastInfo = (msg: Uint8Array[]) => {
  let mlLastInfo = kcLastInfoModel.CreateList_FromJSon(msg[0]);
  if (!mlLastInfo) return;
  if (OnLastInfoCallback) OnLastInfoCallback(mlLastInfo);
};
var On_Dealer_Receive_HistoryData = (msg: Uint8Array[]) => {
  if (!msg || msg.length < 1) return;
  let szJson = CompressionExtensions.Zlib_UnZip_toString(msg[0]);
  let mdResault: kcTransModel.Quote_QuoteHistoryResault = JSON.parse(szJson);

  if (!mdResault) return;

  let StockCode = mdResault.StockCode;
  let HType = mdResault.HType;
  let DayNumber = mdResault.DayNumber ? mdResault.DayNumber : -1;
  let mlOHLC: kcHistoryOHLCModel[] = [];

  let bState = false;
  if (mdResault.State === kcTransModel.ResaultState.Ok && msg.length > 1) {
    let DataBuffer = CompressionExtensions.Zlib_UnZip(msg[1]);

    if (mdResault.HType === HistoryDataType.Tick) {
      let mlTick = kcHistoryTickModel.FromListBuffer(DataBuffer);
      mlOHLC = kcHistoryTickModel.ToOHLCList(mlTick);
      bState = true;

      console.log(mlTick.map((v) => v.ToString()));
    } else if (
      mdResault.HType === HistoryDataType.Minute ||
      mdResault.HType === HistoryDataType.Day
    ) {
      mlOHLC = kcHistoryOHLCModel.FromListBuffer(DataBuffer);
      bState = true;
      //console.log(mlOHLC);
      // console.log(mlOHLC.map((v) => v.ToString()));
    }
  }

  if (bState) {
    // console.log("Request History OK: ", mdResault.Message);
    OnHistoryCallback?.call(this, StockCode, HType, DayNumber, mlOHLC);
    // 送出去
  } else {
    // Request錯誤訊息
    console.log("Request History Fail: ", mdResault.Message);
  }
};

export var Quote_Request_CommodityList = () => {
  if (!sQDealer) return;
  let CType: CommandType = CommandType.CommodityList;
  let mdMsg: Message = new Message();

  mdMsg.addBuffer(_ToCommandTypeBuffer(CType));
  sQDealer.send(mdMsg);
};
export var Quote_Request_LastInfo = (StockCode?: string | string[]) => {
  if (!sQDealer) return;
  let CType: CommandType = CommandType.LastInfo;
  let szStockCodes: string = ""; // XAUUSD|AUDCAD
  if (typeof StockCode === "undefined") szStockCodes = "*";
  else if (typeof StockCode === "string") szStockCodes = StockCode;
  else if (Array.isArray(StockCode)) {
    StockCode.forEach((szStockCode, Idx) => {
      if (Idx > 0) szStockCodes += "|";
      szStockCodes += szStockCode;
    });
  }

  let mdMsg: Message = new Message();
  mdMsg.addBuffer(_ToCommandTypeBuffer(CType));
  mdMsg.addString(szStockCodes);
  sQDealer.send(mdMsg);
};
export var Quote_Request_History = (
  StockCode: string,
  HType: HistoryDataType,
  nDayNumber: number
) => {
  if (!sQDealer) return;

  let CType: CommandType = CommandType.HistoryData;
  let mdMsg: Message = new Message();

  let mdParma: kcTransModel.Quote_QuoteHistoryParam = {
    RequestKey: _GetRequestKey(),
    StockCode: StockCode,
    HType: HType,
    RequestMode: QuoteHistoryRequestMode.DayNumber,
    DayNumber: nDayNumber,
  };
  let szJson = JSON.stringify(mdParma);
  let ZipBuffer = CompressionExtensions.Zlib_Zip_byString(szJson);

  mdMsg.addBuffer(_ToCommandTypeBuffer(CType));
  mdMsg.addBuffer(ZipBuffer);
  sQDealer.send(mdMsg);
};
export var Quote_Request_History_ByRange = (
  StockCode: string,
  HType: HistoryDataType,
  ST: string,
  ET: string
) => {
  if (!sQDealer) return;

  let CType: CommandType = CommandType.HistoryData;
  let mdMsg: Message = new Message();

  let mdParma: kcTransModel.Quote_QuoteHistoryParam = {
    RequestKey: _GetRequestKey(),
    StockCode: StockCode,
    HType: HType,
    RequestMode: QuoteHistoryRequestMode.TimeRange,
    StartTime: ST,
    EndTime: ET,
  };
  let szJson = JSON.stringify(mdParma);
  let ZipBuffer = CompressionExtensions.Zlib_Zip_byString(szJson);

  mdMsg.addBuffer(_ToCommandTypeBuffer(CType));
  mdMsg.addBuffer(ZipBuffer);
  sQDealer.send(mdMsg);
};

var _ToCommandTypeBuffer = (_CType: CommandType): Uint8Array => {
  let Buf: Uint8Array = new Uint8Array(4);
  let dv = new DataView(Buf.buffer);
  dv.setUint32(0, _CType, true);
  return Buf;
};

/* ------------------------------------------------------------------------- */
// Subscriber
var InitializeSubscriber = () => {
  //console.log("Initialize Subscriber() In");
  if (m_bSubscriberInited) return;

  sQSubscriber = new Subscriber(On_Subscriber_Receive);
  sQSubscriber.connect(IP_Quote_Subscriber);
  m_bSubscriberInited = true;

  // 訂閱系統資訊
  sQSubscriber.subscribe(SystemSubscriberTopic_CommodityChange);
  sQSubscriber.subscribe(SystemSubscriberTopic_LastInfoUpdate);
  sQSubscriber.subscribe(SystemSubscriberTopic_ServerMessage);
};
var On_Subscriber_Receive = (Topic: string, msg: Uint8Array[]) => {
  if (msg.length < 2) return;

  let CTypeBuffer: Uint8Array | undefined = msg.shift(); // 回傳並移除陣列第一個元素
  if (CTypeBuffer == undefined) return;

  let dv = new DataView(CTypeBuffer.buffer);
  let nCType = dv.getUint32(0, true) as CommandType;

  switch (nCType) {
    case CommandType.Tick:
      return On_Subscriber_On_Tick(Topic, msg);
    case CommandType.Best5:
      return On_Subscriber_On_Best5(Topic, msg);
    case CommandType.LastInfo:
      return On_Subscriber_On_LastInfo(Topic, msg);
    case CommandType.CommodityChange_Create:
      return On_Subscriber_On_CommodityCreate(Topic, msg);
    case CommandType.CommodityChange_Modify:
      return On_Subscriber_On_CommodityModify(Topic, msg);
    case CommandType.CommodityChange_Delete:
      return On_Subscriber_On_CommodityDelete(Topic, msg);
    default:
      return;
  }
};

var On_Subscriber_On_Tick = (Topic: string, msg: Uint8Array[]) => {
  let mdTick = kcTickModel.CreateFromBuffer(msg[0]);
  if (mdTick && OnTickCallback) {
    OnTickCallback(Topic, mdTick);
  }
};
var On_Subscriber_On_Best5 = (Topic: string, msg: Uint8Array[]) => {};
var On_Subscriber_On_LastInfo = (Topic: string, msg: Uint8Array[]) => {
  let mlLastInfo = kcLastInfoModel.CreateList_FromJSon(msg[0]);
  if (OnLastInfoCallback) OnLastInfoCallback(mlLastInfo);
};
var On_Subscriber_On_CommodityCreate = (Topic: string, msg: Uint8Array[]) => {
  let mdCommodity = kcCommodityModel.Create_FromJSon(msg[0]);
  if (!mdCommodity) return;
  if (OnCommodityChangeCallback)
    OnCommodityChangeCallback(mdCommodity, CommodityChangeType.Create);
};
var On_Subscriber_On_CommodityModify = (Topic: string, msg: Uint8Array[]) => {
  let mdCommodity = kcCommodityModel.Create_FromJSon(msg[0]);
  if (!mdCommodity) return;
  if (OnCommodityChangeCallback)
    OnCommodityChangeCallback(mdCommodity, CommodityChangeType.Modify);
};
var On_Subscriber_On_CommodityDelete = (Topic: string, msg: Uint8Array[]) => {
  let mdCommodity = kcCommodityModel.Create_FromJSon(msg[0]);
  if (!mdCommodity) return;
  if (OnCommodityChangeCallback)
    OnCommodityChangeCallback(mdCommodity, CommodityChangeType.Delete);
};

var SubscriberTick = (Stocks?: string | string[]) => {
  if (sQSubscriber) {
    if (typeof Stocks === "undefined") sQSubscriber.subscribe("");
    else if (typeof Stocks === "string") sQSubscriber.subscribe(Stocks);
    else if (Array.isArray(Stocks))
      Stocks.forEach((Stock: string) => sQSubscriber?.subscribe(Stock));
  }
};
var UnSubscriberTick = (Stocks?: string | string[]) => {
  if (sQSubscriber) {
    if (typeof Stocks === "undefined") sQSubscriber.unsubscribe("");
    else if (typeof Stocks === "string") sQSubscriber.unsubscribe(Stocks);
    else if (Array.isArray(Stocks))
      Stocks.forEach((Stock: string) => sQSubscriber?.unsubscribe(Stock));
  }
};

// 訂閱Tick
export var Register = (Stocks?: string | string[]) => {
  if (sQDealer && sQSubscriber) {
    Quote_Request_LastInfo(Stocks);
    SubscriberTick(Stocks);
  } // 等到可以註冊時, 重新註冊
  else {
    setTimeout(Register, 1000, Stocks);
  }
};
// 反訂閱Tick
export var UnRegister = (Stocks?: string | string[]) => {
  UnSubscriberTick(Stocks);
};

var RequestKey = 20000;
var _GetRequestKey = () => {
  return RequestKey++;
};

//InitializeSocket();
