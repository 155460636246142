import kcAccountInformationModel from "./kcAccountInformationModel";
import kcInventoryModel from "./kcInventoryModel";

export default class kcAccountModel {
  constructor(
    _Information: kcAccountInformationModel,
    _Inventory: kcInventoryModel
  ) {
    this.Information = _Information;
    this.Inventory = _Inventory;
  }
  public Information: kcAccountInformationModel;
  public Inventory: kcInventoryModel;
}
