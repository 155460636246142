import moment, { Moment } from "moment";
import { MomentExtensions, SortedMap } from "../kcExternal";
import kcHistoryTradeLDFModel from "./kcHistoryTradeLDFModel";

export default class kcHistoryTradeLDFCollection {
  constructor(_szKey: string, _tStart?: Moment, _tEnd?: Moment) {
    this.m_szKey = _szKey;
    if (_tStart && _tEnd) {
      //if ( _tEnd > _tStart) {
      if (_tEnd.isAfter(_tStart)) {
        this.m_tStart = _tStart;
        this.m_tEnd = _tEnd;
      } else {
        this.m_tStart = _tEnd;
        this.m_tEnd = _tStart;
      }
      this.m_bNeedCheckTime = true;
    }
  }

  private ml: kcHistoryTradeLDFModel[] = [];
  private dicPureOneDate: SortedMap<Moment, kcHistoryTradeOneDayLDFModel> =
    new SortedMap<Moment, kcHistoryTradeOneDayLDFModel>();

  // 主要核心的時間
  private m_szKey: string = "";
  private m_InOutCnt: number = 0; // 總交易次數
  private m_ProfitCnt: number = 0; // 盈利次數
  private m_LossCnt: number = 0; // 虧損次數
  private m_BuyCnt: number = 0; // 買進次數
  private m_SellCnt: number = 0; // 賣出次數
  private m_ProfitCntRate: number = 0; // 盈利比例
  private m_LossCntRate: number = 0; // 虧損比例
  private m_BuyCntRate: number = 0; // 買進比例
  private m_SellCntRate: number = 0; // 賣出比例
  private m_HighestProfit: number = 0; // 最高利潤
  private m_LowestProfit: number = 0; // 最低利潤
  private m_HighestLoss: number = 0; // 最高虧損
  private m_LowestLoss: number = 0; // 最低虧損
  private m_Profit: number = 0; // 總利潤
  private m_Profit_Pure: number = 0; // 總利潤 不加入 庫存 分潤
  private m_AverageLifeTime: number = 0; // 平均交易時間(HR)
  private m_LifeTime: number = 0; // 總交易時間(HR)
  private m_VolHand: number = 0; // 總交易手數
  private m_Deposit: number = 0; // 總入金
  private m_Withdraw: number = 0; // 總出金
  private m_TranferMoney: number = 0; // 總出入金
  private m_CommissionMoney: number = 0; // 總分潤金額
  private m_CommissionMoney_Ready: number = 0; // 已分潤金額
  private m_CommissionMoney_Non: number = 0; // 未分潤金額
  private m_CommissionCnt: number = 0; // 總分潤筆數
  private m_CommissionCnt_Ready: number = 0; // 已分潤筆數
  private m_CommissionCnt_Non: number = 0; // 未分潤筆數

  // [DisplayName("分潤\r\n時間")]
  // public string szKey { get { return m_szKey; } }
  public get szKey() {
    return this.m_szKey;
  }
  // [DisplayName("交易\r\n次數")]
  // public int InOutCnt { get { return m_InOutCnt; } }
  public get InOutCnt() {
    return this.m_InOutCnt;
  }
  // [DisplayName("盈利\r\n次數")]
  // public int ProfitCnt { get { return m_ProfitCnt; } }
  public get ProfitCnt() {
    return this.m_ProfitCnt;
  }
  // [DisplayName("虧損\r\n次數")]
  // public int LossCnt { get { return m_LossCnt; } }
  public get LossCnt() {
    return this.m_LossCnt;
  }
  // [DisplayName("買進\r\n次數")]
  // public int BuyCnt { get { return m_BuyCnt; } }
  public get BuyCnt() {
    return this.m_BuyCnt;
  }
  // [DisplayName("賣出\r\n次數")]
  // public int SellCnt { get { return m_SellCnt; } }
  public get SellCnt() {
    return this.m_SellCnt;
  }
  // [DisplayName("盈利\r\n比例")]
  // public decimal ProfitCntRate { get { return m_ProfitCntRate; } }
  public get ProfitCntRate() {
    return this.m_ProfitCntRate;
  }
  // [DisplayName("虧損\r\n比例")]
  // public decimal LossCntRate { get { return m_LossCntRate; } }
  public get LossCntRate() {
    return this.m_LossCntRate;
  }
  // [DisplayName("買進\r\n比例")]
  // public decimal BuyCntRate { get { return m_BuyCntRate; } }
  public get BuyCntRate() {
    return this.m_BuyCntRate;
  }
  // [DisplayName("賣出\r\n比例")]
  // public decimal SellCntRate { get { return m_SellCntRate; } }
  public get SellCntRate() {
    return this.m_SellCntRate;
  }
  // [DisplayName("最高\r\n盈利")]
  // public decimal HighestProfit { get { return m_HighestProfit; } }
  public get HighestProfit() {
    return this.m_HighestProfit;
  }
  // [DisplayName("最低\r\n盈利")]
  // public decimal LowestProfit { get { return m_LowestProfit; } }
  public get LowestProfit() {
    return this.m_LowestProfit;
  }
  // [DisplayName("最高\r\n虧損")]
  // public decimal HighestLoss { get { return m_HighestLoss; } }
  public get HighestLoss() {
    return this.m_HighestLoss;
  }
  // [DisplayName("最低\r\n虧損")]
  // public decimal LowestLoss { get { return m_LowestLoss; } }
  public get LowestLoss() {
    return this.m_LowestLoss;
  }
  // [DisplayName("累積\r\n獲利")]
  // public decimal Profit { get { return m_Profit; } }
  public get Profit() {
    return this.m_Profit;
  }
  // [DisplayName("原始\r\n總利潤")]
  // public decimal Profit_Pure { get { return m_Profit_Pure; } }
  public get Profit_Pure() {
    return this.m_Profit_Pure;
  }
  // [DisplayName("平均\r\n交易時間(HR)")]
  // public decimal AverageLifeTime { get { return m_AverageLifeTime; } }
  public get AverageLifeTime() {
    return this.m_AverageLifeTime;
  }
  // [DisplayName("累積交易時間(HR)")]
  // public decimal LifeTime { get { return m_LifeTime; } }
  public get LifeTime() {
    return this.m_LifeTime;
  }
  // [DisplayName("交易\r\n手數")]
  // public decimal VolHand { get { return m_VolHand; } }
  public get VolHand() {
    return this.m_VolHand;
  }
  // [DisplayName("入金\r\n累計")]
  // public decimal Deposit { get { return m_Deposit; } }
  public get Deposit() {
    return this.m_Deposit;
  }
  // [DisplayName("出金\r\n累計")]
  // public decimal Withdraw { get { return m_Withdraw; } }
  public get Withdraw() {
    return this.m_Withdraw;
  }
  // [DisplayName("轉帳\r\n累計")]
  // public decimal TranferMoney { get { return m_TranferMoney; } }
  public get TranferMoney() {
    return this.m_TranferMoney;
  }
  // [DisplayName("應分潤\r\n金額")]
  // public decimal CommissionMoney { get { return m_CommissionMoney; } }
  public get CommissionMoney() {
    return this.m_CommissionMoney;
  }
  // [DisplayName("已分潤\r\n金額")]
  // public decimal CommissionMoney_Ready { get { return m_CommissionMoney_Ready; } }
  public get CommissionMoney_Ready() {
    return this.m_CommissionMoney_Ready;
  }
  // [DisplayName("未分潤\r\n金額")]
  // public decimal CommissionMoney_Non { get { return m_CommissionMoney_Non; } }
  public get CommissionMoney_Non() {
    return this.m_CommissionMoney_Non;
  }
  // [DisplayName("應分潤\r\n筆數")]
  // public int CommissionCnt { get { return m_CommissionCnt; } }
  public get CommissionCnt() {
    return this.m_CommissionCnt;
  }
  // [DisplayName("已分潤\r\n筆數")]
  // public int CommissionCnt_Ready { get { return m_CommissionCnt_Ready; } }
  public get CommissionCnt_Ready() {
    return this.m_CommissionCnt_Ready;
  }
  // [DisplayName("未分潤\r\n筆數")]
  // public int CommissionCnt_Non { get { return m_CommissionCnt_Non; } }
  public get CommissionCnt_Non() {
    return this.m_CommissionCnt_Non;
  }
  // /*-----------------------------------------------------------------------------------------*/
  // 時間分配機制
  private m_bNeedCheckTime: boolean = false;
  private m_tStart: Moment = moment(0);
  private m_tEnd: Moment = moment(0);
  public get AllDate() {
    return this.dicPureOneDate.keysArray();
  }

  /*-----------------------------------------------------------------------------------------*/
  public Clear(): void {
    this.dicPureOneDate.clear();
    this.ml.length = 0;
    this.m_InOutCnt = 0;
    this.m_ProfitCnt = 0;
    this.m_LossCnt = 0;
    this.m_BuyCnt = 0;
    this.m_SellCnt = 0;
    this.m_ProfitCntRate = 0;
    this.m_LossCntRate = 0;
    this.m_BuyCntRate = 0;
    this.m_SellCntRate = 0;
    this.m_HighestProfit = 0;
    this.m_LowestProfit = 0;
    this.m_HighestLoss = 0;
    this.m_LowestLoss = 0;
    this.m_Profit = 0;
    this.m_Profit_Pure = 0;
    this.m_AverageLifeTime = 0;
    this.m_LifeTime = 0;
    this.m_VolHand = 0;
    this.m_Deposit = 0;
    this.m_Withdraw = 0;
    this.m_TranferMoney = 0;
    this.m_CommissionMoney = 0;
    this.m_CommissionMoney_Ready = 0;
    this.m_CommissionMoney_Non = 0;
    this.m_CommissionCnt = 0;
    this.m_CommissionCnt_Ready = 0;
    this.m_CommissionCnt_Non = 0;
  }
  public AddRows(_mlHistory: kcHistoryTradeLDFModel[]): void {
    for (let md of _mlHistory) {
      if (this.m_bNeedCheckTime) {
        // if (md.TimeOut < this.m_tStart || md.TimeOut > this.m_tEnd)
        if (
          md.TimeOut.isBefore(this.m_tStart) ||
          md.TimeOut.isAfter(this.m_tEnd)
        )
          continue;
      }
      let Date = MomentExtensions.GetDate(md.TimeOut);
      this.ml.push(md);
      let mdOneDay: kcHistoryTradeOneDayLDFModel =
        this.dicPureOneDate.get_WithAppend(
          Date,
          () => new kcHistoryTradeOneDayLDFModel(Date)
        );

      mdOneDay.ProfitMoney +=
        md.ProfitMoney /*+ md.CommissionMoney*/ + md.SwapMoney;
      let ProfitMoney: number = md.ProfitMoney;
      if (md.Type == "出入金") {
        if (ProfitMoney > 0) this.m_Deposit += ProfitMoney;
        if (ProfitMoney < 0) this.m_Withdraw += -ProfitMoney;
        this.m_TranferMoney += ProfitMoney;
        continue;
      }
      this.m_InOutCnt++;
      if (md.BuySellType == "Buy") this.m_BuyCnt++;
      if (md.BuySellType == "Sell") this.m_SellCnt++;
      this.m_Profit_Pure += ProfitMoney;
      ProfitMoney += md.SwapMoney;
      //ProfitMoney += md.HTradeOut.CommissionMoney; 是否要加入分潤金可議
      this.m_Profit += ProfitMoney;

      this.m_LifeTime += MomentExtensions.Span(md.TimeOut, md.Time).asHours();
      if (ProfitMoney > 0) {
        this.m_ProfitCnt++;
        if (this.m_HighestProfit < ProfitMoney)
          this.m_HighestProfit = ProfitMoney;
        if (this.m_LowestProfit == 0) this.m_LowestProfit = ProfitMoney;
        if (this.m_LowestProfit > ProfitMoney)
          this.m_LowestProfit = ProfitMoney;
      }
      if (ProfitMoney < 0) {
        this.m_LossCnt++;
        if (this.m_HighestLoss > ProfitMoney) this.m_HighestLoss = ProfitMoney;
        if (this.m_LowestLoss == 0) this.m_LowestLoss = ProfitMoney;
        if (this.m_LowestLoss < ProfitMoney) this.m_LowestLoss = ProfitMoney;
      }
      this.m_VolHand += md.VolHand ? md.VolHand : 0;
      // 分潤金額
      if (md.TypeOut == "平倉") {
        this.m_CommissionMoney += md.CommissionMoney;
        this.m_CommissionCnt++;
        if (md.CommissionTime) {
          this.m_CommissionMoney_Ready += md.CommissionMoney;
          this.m_CommissionCnt_Ready++;
        } else {
          this.m_CommissionMoney_Non += md.CommissionMoney;
          this.m_CommissionCnt_Non++;
        }
      }
    }
    if (this.m_InOutCnt != 0) {
      this.m_ProfitCntRate = this.m_ProfitCnt / this.m_InOutCnt;
      this.m_LossCntRate = this.m_LossCnt / this.m_InOutCnt;
      this.m_BuyCntRate = this.m_BuyCnt / this.m_InOutCnt;
      this.m_SellCntRate = this.m_SellCnt / this.m_InOutCnt;
      this.m_AverageLifeTime = this.m_LifeTime / this.m_InOutCnt;
    }
    let mlPureOneDate: kcHistoryTradeOneDayLDFModel[] =
      this.dicPureOneDate.valuesArray();
    for (let i = 0; i < mlPureOneDate.length; i++) {
      let mdOneDay = mlPureOneDate[i];
      if (i == 0) {
        mdOneDay.AutoSecurityMoney = mdOneDay.ProfitMoney;
        continue;
      }
      mdOneDay.AutoSecurityMoney =
        mlPureOneDate[i - 1].AutoSecurityMoney + mdOneDay.ProfitMoney;
    }
  }
}
export class kcHistoryTradeOneDayLDFModel {
  constructor(_TimeDate: Moment) {
    // <= _TimeStart, < _TimeEnd
    this.TimeDate = _TimeDate;
  }
  public TimeDate: Moment;
  public ProfitMoney: number = 0;
  public AutoSecurityMoney: number = 0;
}
