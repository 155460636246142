import * as React from "react";
import {
  StyleProp,
  StyleSheet,
  TextStyle,
  ViewStyle,
  TextInput,
  Platform,
} from "react-native";
import { Text, View } from "../components/Themed";
import { Button } from "react-native-elements";
import { Ionicons } from "@expo/vector-icons";
import { kcSetState, kcSetUnmount } from "../kcExternal";
import moment, { Moment } from "moment";
import KC_DatePicker from "../components/kcDatePicker";
import Swipeable from "react-native-gesture-handler/Swipeable";
import { kcColorObj, kcColor } from "../constants/Colors";

type IState = {
  Value: Moment;
  ShowDateTime: boolean;
};

type IProp = {
  Title: string;
  Disable?: boolean;
  Visable?: boolean;
  OnValueChange: (value: Moment | null, dateString: string) => void;
};

class KC_RowDateTimeControl extends React.Component<IProp, IState> {
  state: IState = {
    Value: moment.utc(),
    ShowDateTime: false,
  };

  Swipeable_Ref: any = undefined;
  constructor(props: IProp) {
    super(props);
    this.Swipeable_Ref = React.createRef();
  }

  componentDidMount() {}

  onChangeDateTime = (value: Moment | null, dateString: string) => {
    kcSetState(this, { Value: value });
    this.SendUpdate(value);
  };

  onBtnClick = () => {
    kcSetState(this, { ShowDateTime: true });
    this.SendUpdate(this.state.Value);
  };

  SetValue = (_Value: Moment | null) => {
    if (_Value == null) {
      kcSetState(this, { ShowDateTime: false, Value: moment().utc() });
    } else {
      kcSetState(this, { ShowDateTime: true, Value: _Value });
    }
    this.SendUpdate(_Value);
  };

  SendUpdate = (Value: Moment | null) => {
    if (!this.props.OnValueChange) return;
    if (Value == null) this.props.OnValueChange(null, "");
    else this.props.OnValueChange(Value, Value.format("YYYY-MM-DDTHH:mm"));
  };

  _renderSwipeRight = () => {
    return (
      <Button
        containerStyle={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          padding: 3,
          width: 50,
          height: 30,
          backgroundColor: "#00000000",
        }}
        buttonStyle={{
          backgroundColor: kcColor("Red"),
          borderColor: kcColor("Red_2"),
          borderWidth: 0,
          borderRadius: 10,
          width: 50,
          height: 30,
        }}
        titleStyle={{ fontSize: 14 }}
        title="清除"
        //icon={<Ionicons name="ios-add" size={28} color="#ffffff" />}
        onPress={() => {
          this.Swipeable_Ref.current.close();
          this.SetValue(null);
        }}
      />
    );
  };

  OnSwipeRightOpen = () => {};

  render() {
    if (this.props.Visable == false) return <></>;
    return (
      <View
        style={[
          {
            //height: 30,
            //margin: 3,
            backgroundColor: "#00000000",
          },
        ]}
      >
        {!this.props.Disable && (
          <Swipeable
            ref={this.Swipeable_Ref}
            enabled={this.state.ShowDateTime}
            renderRightActions={this._renderSwipeRight}
            onSwipeableRightOpen={this.OnSwipeRightOpen}
          >
            <View
              style={[
                {
                  height: 30,
                  backgroundColor: "#00000000",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                },
              ]}
            >
              <Text style={{ flex: 1, color: kcColor("Title") }}>
                {this.props.Title}
              </Text>
              {!this.state.ShowDateTime && (
                <Button
                  containerStyle={{
                    marginLeft: -100,
                    flex: 1,
                    flexDirection: "column",
                    backgroundColor: "#00000000",
                    height: 30,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  buttonStyle={{
                    backgroundColor: "#00000000",
                    height: 26,
                  }}
                  title="未設定"
                  titleStyle={{
                    color: kcColor("TextNonSet"),
                    fontSize: 16,
                    height: 26,
                  }}
                  onPress={this.onBtnClick}
                />
              )}
              {this.state.ShowDateTime && (
                <View
                  style={{
                    marginLeft: -100,
                    flex: 1,
                    flexDirection: "row",
                    backgroundColor: kcColor("Background"),
                    justifyContent: "center",
                    marginRight: 3,
                  }}
                >
                  <KC_DatePicker
                    onChange={this.onChangeDateTime}
                    defaultValue={this.state.Value}
                    EnableTime={true}
                  />
                </View>
              )}
            </View>
          </Swipeable>
        )}
        {this.props.Disable && (
          <View
            style={[
              {
                height: 30,
                backgroundColor: kcColor("Background"),
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              },
            ]}
          >
            <Text style={{ flex: 1, color: kcColor("Title") }}>
              {this.props.Title}
            </Text>
            <Text
              style={{
                marginLeft: -100,
                flex: 1,
                color: kcColor("Title"),
                textAlign: "center",
                fontSize: 16,
              }}
            >
              {this.state.ShowDateTime
                ? this.state.Value.format("YYYY年MM月DD日 HH:mm")
                : "未設定"}
            </Text>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  NumberBtnContainer: {
    //flex: 1,
    backgroundColor: "#00000000",
    justifyContent: "center",
  },
  NumberBtn: {
    //backgroundColor: "#505050",
    backgroundColor: "#00000000",
    height: 0,
    borderRadius: 0,
    borderWidth: 0,
    padding: 0,
    width: 50,
  },
});

export default KC_RowDateTimeControl;
