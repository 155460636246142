// 因為不想多加一個檔案, 下單設定目前跟指標存在一起
export default class OrderSetting {
  constructor(props: object) {
    Object.assign(this, { ...props });
  }

  FastOrder: boolean = false; // 快速下單
  DefaultOrderHandVol: number = 0.01; // 快速下單手數

  public Clone(): OrderSetting {
    return new OrderSetting(this);
  }
  public SetSetting(_GSetting: OrderSetting) {
    Object.assign(this, _GSetting);
  }

  public FastOrderToString() {
    if (this.FastOrder) return `快速下單 (${this.DefaultOrderHandVol} 手)`;
    else return "兩步下單";
  }
}
