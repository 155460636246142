import moment, { Moment } from "moment";
import { MathEx, MomentExtensions } from "../kcExternal";

export default class kcTickModel {
  constructor(props: object) {
    Object.assign(this, { ...props });

    this.Time = MomentExtensions.Parse(this.Time);
  }

  /* ---------------------------Member--------------------------- */
  Time: Moment = moment(0); // DateTime (8 bytes double OADate)
  AskPrice: number = 0; // double (8 bytes double)
  BidPrice: number = 0; // double (8 bytes double)
  Vol: number = 0; // ushort (2 bytes ushout)
  TotalVol: number = 0; // uint (4 bytes uint)

  CloneAddDiff(_PointDiff: number) {
    var md: kcTickModel = new kcTickModel(this);

    if (this.AskPrice != 0)
      md.AskPrice = MathEx.kcRound(this.AskPrice + _PointDiff, 10);
    if (this.BidPrice != 0)
      md.BidPrice = MathEx.kcRound(this.BidPrice - _PointDiff, 10);

    return md;
  }
  ToString() {
    let szRet = "";
    szRet += this.Time.utc().format("YYYY/MM/DD HH:mm:ss.SSS") + ", ";
    szRet += this.AskPrice + ", ";
    szRet += this.BidPrice + ", ";
    szRet += this.Vol + ", ";
    szRet += this.TotalVol + ", ";
    return szRet;
  }

  /* ---------------------------static Function--------------------------- */
  static FixedBufferSize: number = 30;
  static CreateFromBuffer(bytes: Uint8Array): kcTickModel | undefined {
    if (bytes.length < kcTickModel.FixedBufferSize) return undefined;
    let dv = new DataView(bytes.buffer);

    let nIdx = 0;

    let dOADate: number = dv.getFloat64(nIdx, true);
    let tTime: Moment = MomentExtensions.FromOADate(dOADate).utc();
    nIdx += 8;

    let dAskPrice = dv.getFloat64(nIdx, true);
    nIdx += 8;

    let dBidPrice = dv.getFloat64(nIdx, true);
    nIdx += 8;

    let sVol = dv.getUint16(nIdx, true);
    nIdx += 2;

    let sTotalVol = dv.getUint32(nIdx, true);
    nIdx += 4;

    let mdTick: kcTickModel = new kcTickModel({
      Time: tTime,
      AskPrice: dAskPrice,
      BidPrice: dBidPrice,
      Vol: sVol,
      TotalVol: sTotalVol,
    });

    return mdTick;
  }

  static CloneAddDiff(_mdOri: kcTickModel, _dPointDiff: number): kcTickModel {
    let mdClone = new kcTickModel(_mdOri);
    mdClone.AskPrice = mdClone.AskPrice + _dPointDiff;
    mdClone.BidPrice = mdClone.BidPrice - _dPointDiff;
    return mdClone;
  }
}
