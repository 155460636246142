import {
  kcColor,
  kcColor_GetUpDownStyle,
  kcColor_SetUpDownStyle,
  kcColor_UpDownStyleToString,
} from "../../constants/Colors";
import {
  KLineDispalyType,
  KLineDispalyTypeToString,
} from "./TECPSettingDefine";

const Default_DispalyType = KLineDispalyType.NormalKLine;

export default class KLineSetting {
  constructor(props: object) {
    Object.assign(this, { ...props });
  }

  UpDownStyle: "RedUp" | "GreenUp" = kcColor_GetUpDownStyle();
  DispalyType: KLineDispalyType = Default_DispalyType;
  UpKColor: string = kcColor("Up");
  DownKColor: string = kcColor("Down");
  EqualKColor: string = kcColor("Yellow");
  VerticalLineColor: string = "white";

  public Clone(): KLineSetting {
    return new KLineSetting(this);
  }
  public SetSetting(_Setting: KLineSetting) {
    Object.assign(this, _Setting);
  }
  public ToDefault_DispalyType() {
    this.DispalyType = Default_DispalyType;
  }
  public ToDefault_KLineColor() {}
  public SetUpDownStyle(_Style: KLineSetting["UpDownStyle"]) {
    kcColor_SetUpDownStyle(_Style);

    this.UpDownStyle = kcColor_GetUpDownStyle();
    this.UpKColor = kcColor("Up");
    this.DownKColor = kcColor("Down");
  }
  public DispalyTypeToString() {
    return KLineDispalyTypeToString(this.DispalyType);
  }
  public UpDownStyleToString() {
    if (this.UpKColor === kcColor("Up") && this.DownKColor === kcColor("Down"))
      return kcColor_UpDownStyleToString(this.UpDownStyle);
    else return "自訂樣式";
  }
}
