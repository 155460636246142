import { IP_Trade_Dealer, CommandType, LdfApiAwakeURL } from "./InternalDefine";
import { Dealer, Message } from "../TSMQ";
import { StorageHelper, CompressionExtensions } from "../kcExternal";
import * as kcTransModel from "./kcTransModel";
import jwt_decode from "jwt-decode";
import JWTPayloadModel, { JWTLoginState } from "./JWTPayloadModel";
import {
  kcAccountInformationModel,
  kcAccountModel,
  kcHistoryTrade,
  kcInventoryModel,
} from "../kcModel";
import { GoLoginScreen } from "../navigation/RootNavigation";
import { SetQuoteReadyCallback } from "./kcQuote";
import {
  delOnConnection,
  delOnDealerMessage,
  ConnectionState,
} from "../TSMQ/Dealer";

const Key_SessionToken = "SessionToken";

var m_bDealerInited: boolean = false;
var sQDealer: Dealer | undefined = undefined;

var SessionToken: string | undefined = undefined;
var Account: string = "";
var State: JWTLoginState = "None";

var SendingAwake: boolean = false; // 喚醒後端用的flag

export enum LoginStatus {
  LoginSucceed,
  LoginFail,
  TraderReady,
}

export type AccountCallback = (
  AccountInfo: kcAccountModel,
  LoginState: JWTLoginState
) => void;
export type AccountInformationChange = (
  _mdAccountInfo: kcAccountInformationModel
) => void;
export type InventoryCallback = (Inventory: kcInventoryModel) => void;
export type TradeHistoryCallback = (Trades: kcHistoryTrade[]) => void;
export type LoginStateCallback = (_state: LoginStatus, _szMsg: string) => void;
export type delTradeCallback = (
  _bSucceed: boolean,
  _szMsg: string,
  _HTrade: kcHistoryTrade
) => void;

var OnAccountCallback: AccountCallback | undefined;
var OnAccountInfoChangeCallback: AccountInformationChange | undefined;
var OnInventoryCallback: InventoryCallback | undefined;
var OnTradeHistoryCallback: TradeHistoryCallback | undefined;
var OnLoginStateCallback: LoginStateCallback | undefined;
var OnTradeResault: delTradeCallback | undefined;

// console.log(
//   "-----------------------------kcTrade In-----------------------------"
// );

export var InitializeCallback = (
  _OnAccountCallback?: AccountCallback,
  _OnAccountInfoChangeCallback?: AccountInformationChange,
  _OnInventoryCallback?: InventoryCallback,
  _OnTradeHistoryCallback?: TradeHistoryCallback,
  _OnTradeResault?: delTradeCallback
) => {
  OnAccountCallback = _OnAccountCallback;
  OnAccountInfoChangeCallback = _OnAccountInfoChangeCallback;
  OnInventoryCallback = _OnInventoryCallback;
  OnTradeHistoryCallback = _OnTradeHistoryCallback;
  OnTradeResault = _OnTradeResault;
};
export var SetLoginStateCallback = (
  _OnLoginStateCallback?: LoginStateCallback
) => {
  OnLoginStateCallback = _OnLoginStateCallback;
};

var InitializeSocket = () => {
  if (m_bDealerInited) return;
  SetQuoteReadyCallback(Trade_Request_TradeHistory);

  StorageHelper.ReadData(Key_SessionToken).then((szToken) => {
    _TokenHandel(szToken);
    InitializeDealer();
  });
};

/* ------------------------------------------------------------------------- */
// Dealer
var InitializeDealer = () => {
  //console.log("Initialize kcTrade Dealer() In");
  if (m_bDealerInited) return;

  sQDealer = new Dealer(On_Dealer_Receive, On_Dealer_Connection);

  sQDealer.connect(IP_Trade_Dealer);
  m_bDealerInited = true;

  //Trade_Request_AccountInfo();
};
var On_Dealer_Connection: delOnConnection = (_State, _Address) => {
  // 連線失敗, 嘗試喚醒後端
  if (_State === ConnectionState.ConnectionFail) {
    if (SendingAwake) return;
    SendingAwake = true;

    fetch(LdfApiAwakeURL).finally(() => {
      SendingAwake = false;
    });
  } else if (_State === ConnectionState.Connected) {
    Trade_Request_AccountInfo();
  }
};

var On_Dealer_Receive: delOnDealerMessage = (msg) => {
  if (msg.length < 2) return;

  let CTypeBuffer: Uint8Array | undefined = msg.shift(); // 回傳並移除陣列第一個元素
  if (CTypeBuffer == undefined) return;

  let dv = new DataView(CTypeBuffer.buffer);
  let nCType = dv.getUint32(0, true) as CommandType;

  switch (nCType) {
    case CommandType.Login:
      return On_Dealer_Receive_Login(msg);
    case CommandType.Connection:
      return On_Dealer_Receive_Connection(msg);
    case CommandType.Account_GetAccount:
      return On_Dealer_Receive_Account_GetAccount(msg);
    case CommandType.Account_InformationUpdate:
      return On_Dealer_Receive_Account_InformationUpdate(msg);
    case CommandType.Account_TradeHistory:
      return On_Dealer_Receive_Account_TradeHistory(msg);
    case CommandType.TradeInventory:
      return On_Dealer_Receive_TradeInventory(msg);
    case CommandType.Trade:
      return On_Dealer_Receive_TradeResault(msg);
  }
};
var On_Dealer_Receive_Login = (msg: Uint8Array[]) => {
  if (!msg || msg.length != 1) return;
  let szJson = CompressionExtensions.Zlib_UnZip_toString(msg[0]);
  let mdResault: kcTransModel.LoginResault = JSON.parse(szJson);

  if (!mdResault) return;

  let bState = mdResault.State === kcTransModel.ResaultState.Ok;
  let bToken = _TokenHandel(mdResault.Token); // 處理Token(更新狀態,存檔)
  let StateOK: boolean = bState && bToken;

  if (StateOK) {
    if (OnLoginStateCallback)
      OnLoginStateCallback(LoginStatus.LoginSucceed, "登入成功");
    Trade_Request_AccountInfo();
  } else {
    if (OnLoginStateCallback)
      OnLoginStateCallback(LoginStatus.LoginFail, mdResault.Message);
    else GoLoginScreen();
  }
};
var On_Dealer_Receive_Connection = (msg: Uint8Array[]) => {
  if (!msg || msg.length != 1) return;
  let szJson = CompressionExtensions.Zlib_UnZip_toString(msg[0]);
  let mdResault: kcTransModel.ConnectionResault = JSON.parse(szJson);
  // console.log(mdResault);

  if (!mdResault) return;

  let bState = mdResault.State === kcTransModel.ResaultState.Ok;
  let bToken = _TokenHandel(mdResault.Token); // 處理Token(更新狀態,存檔)
  let StateOK: boolean = bState && bToken;

  if (StateOK) {
  } else {
    GoLoginScreen();
  }
};
var On_Dealer_Receive_Account_GetAccount = (msg: Uint8Array[]) => {
  if (!msg || msg.length != 1) return;
  let szJson = CompressionExtensions.Zlib_UnZip_toString(msg[0]);
  let mdResault: kcTransModel.GetAccountResault = JSON.parse(szJson);

  if (!mdResault) return;

  let PreAccount = Account;
  let bState = mdResault.State === kcTransModel.ResaultState.Ok;
  let bToken = _TokenHandel(mdResault.Token); // 處理Token(更新狀態,存檔)
  let StateOK: boolean = bState && bToken;

  if (StateOK) {
    if (OnAccountCallback) {
      let mdAccount = new kcAccountModel(
        new kcAccountInformationModel(mdResault.AccountInfo),
        new kcInventoryModel(mdResault.Inventory)
      );
      OnAccountCallback(mdAccount, State);
    }

    // // 啟動Quote並RequestCommodity
    // InitQuote();
  } else {
    GoLoginScreen();
  }
};

// Information Update 由Server主動推送
var On_Dealer_Receive_Account_InformationUpdate = (msg: Uint8Array[]) => {
  if (!msg || msg.length != 1) return;
  let szJson = CompressionExtensions.Zlib_UnZip_toString(msg[0]);
  let mdAccInfoRecv: kcAccountInformationModel = JSON.parse(szJson);

  if (!mdAccInfoRecv) return;

  if (OnAccountInfoChangeCallback)
    OnAccountInfoChangeCallback(new kcAccountInformationModel(mdAccInfoRecv));
};
var On_Dealer_Receive_Account_TradeHistory = (msg: Uint8Array[]) => {
  if (!msg || msg.length != 1) return;
  let szJson = CompressionExtensions.Zlib_UnZip_toString(msg[0]);
  let mdResault: kcTransModel.TradeHistoryResault = JSON.parse(szJson);

  if (!mdResault) return;

  let bState = mdResault.State === kcTransModel.ResaultState.Ok;
  let bToken = _TokenHandel(mdResault.Token); // 處理Token(更新狀態,存檔)
  let StateOK: boolean = bState && bToken;

  if (StateOK) {
    // 發送交易歷史到DataInfo
    if (OnTradeHistoryCallback) {
      let mlTradeHistory = mdResault.Trades.map((md) => new kcHistoryTrade(md));
      OnTradeHistoryCallback(mlTradeHistory);
    }

    // 讓Login頁面跳轉
    if (OnLoginStateCallback) OnLoginStateCallback(LoginStatus.TraderReady, "");
  } else {
    GoLoginScreen();
  }
};
// 庫存 Update 由Server主動推送
var On_Dealer_Receive_TradeInventory = (msg: Uint8Array[]) => {
  if (!msg || msg.length != 1) return;
  let szJson = CompressionExtensions.Zlib_UnZip_toString(msg[0]);
  let mdInv: kcInventoryModel = JSON.parse(szJson);

  if (!mdInv) return;
  if (OnInventoryCallback) OnInventoryCallback(new kcInventoryModel(mdInv));
};
// 下單狀態回傳
var On_Dealer_Receive_TradeResault = (msg: Uint8Array[]) => {
  if (!msg || msg.length != 1) return;
  let szJson = CompressionExtensions.Zlib_UnZip_toString(msg[0]);
  let mdResault: kcTransModel.Trade_TradeOrderResault = JSON.parse(szJson);

  if (!mdResault) return;

  let bState = mdResault.State === kcTransModel.ResaultState.Ok;
  let bToken = _TokenHandel(mdResault.Token); // 處理Token(更新狀態,存檔)
  let StateOK: boolean = bState && bToken;
  let HTrade = new kcHistoryTrade(mdResault.Order);

  // console.log(mdResault, HTrade);
  if (StateOK) {
    if (OnTradeResault) OnTradeResault(true, "", HTrade);
  } else {
    if (!bToken) {
      // Token失敗, 跳轉到Login
      if (OnTradeResault)
        OnTradeResault(false, "登入逾時, 回到登入畫面", HTrade);
      GoLoginScreen();
    } else {
      if (OnTradeResault) OnTradeResault(false, mdResault.Message, HTrade);
    }
  }
};

// 登入
export var GetAccount = () => {
  return Account;
};
export var GetAccountState = () => {
  return State;
};
export var LogOut = () => {
  StorageHelper.RemoveValue(Key_SessionToken);
  _TokenHandel(undefined);
  GoLoginScreen();
};
export var ClearStorage = () => {
  StorageHelper.ClearAll();
  _TokenHandel(undefined);
  GoLoginScreen();
};
export var Trade_Request_Login = (_szAccount: string, _szPW: string) => {
  // _CheckSendRequest() 會檢查sQDealer跟SessionToken, &&後面再帶一次只是為了好Call
  if (!_CheckSendRequest(false) || !sQDealer) return;

  let CType: CommandType = CommandType.Login;
  let mdMsg: Message = new Message();

  let mdParma: kcTransModel.LoginParam = {
    Account: _szAccount,
    PW: _szPW,
    RequestKey: _GetRequestKey(),
  };
  let szJson = JSON.stringify(mdParma);
  let ZipBuffer = CompressionExtensions.Zlib_Zip_byString(szJson);

  mdMsg.addBuffer(_ToCommandTypeBuffer(CType));
  mdMsg.addBuffer(ZipBuffer);
  sQDealer.send(mdMsg);
};
export var Trade_Request_AccountInfo = () => {
  // _CheckSendRequest() 會檢查sQDealer跟SessionToken, &&後面再帶一次只是為了好Call
  if (!_CheckSendRequest() || !sQDealer || !SessionToken) return;

  let CType: CommandType = CommandType.Account_GetAccount;
  let mdMsg: Message = new Message();

  let mdParma: kcTransModel.GetAccountParam = {
    Token: SessionToken,
    RequestKey: _GetRequestKey(),
  };

  let szJson = JSON.stringify(mdParma);
  let ZipBuffer = CompressionExtensions.Zlib_Zip_byString(szJson);

  mdMsg.addBuffer(_ToCommandTypeBuffer(CType));
  mdMsg.addBuffer(ZipBuffer);
  sQDealer.send(mdMsg);
};
export var Trade_Request_TradeHistory = () => {
  // _CheckSendRequest() 會檢查sQDealer跟SessionToken, &&後面再帶一次只是為了好Call
  if (!_CheckSendRequest() || !sQDealer || !SessionToken) return;

  let CType: CommandType = CommandType.Account_TradeHistory;
  let mdMsg: Message = new Message();

  let mdParma: kcTransModel.TradeHistoryParam = {
    Token: SessionToken,
    RequestKey: _GetRequestKey(),
  };

  let szJson = JSON.stringify(mdParma);
  let ZipBuffer = CompressionExtensions.Zlib_Zip_byString(szJson);

  mdMsg.addBuffer(_ToCommandTypeBuffer(CType));
  mdMsg.addBuffer(ZipBuffer);
  sQDealer.send(mdMsg);
};
export var Trade_TradeOrder = (_mdTrade: kcHistoryTrade) => {
  // _CheckSendRequest() 會檢查sQDealer跟SessionToken, &&後面再帶一次只是為了好Call
  if (!_CheckSendRequest() || !sQDealer || !SessionToken) return;

  _mdTrade.Account = Account;

  let CType: CommandType = CommandType.Trade;
  let mdMsg: Message = new Message();

  let mdParma: kcTransModel.Trade_TradeOrderParam = {
    Token: SessionToken,
    RequestKey: _GetRequestKey(),
    Order: _mdTrade,
  };

  let szJson = JSON.stringify(mdParma);
  let ZipBuffer = CompressionExtensions.Zlib_Zip_byString(szJson);

  mdMsg.addBuffer(_ToCommandTypeBuffer(CType));
  mdMsg.addBuffer(ZipBuffer);
  sQDealer.send(mdMsg);
};

// 檢查是否可以發送Request, 不能的話跳轉到Login頁面
var _CheckSendRequest = (_bCheckToken: boolean = true) => {
  let bTokenOK = _bCheckToken ? (SessionToken ? true : false) : true; // 不CheckToken的話就是true, CheckToken的話看SessionToken有沒有值
  let bCanSend = !!sQDealer && bTokenOK;

  if (!bCanSend) GoLoginScreen();
  return bCanSend;
};

var _ToCommandTypeBuffer = (_CType: CommandType): Uint8Array => {
  let Buf: Uint8Array = new Uint8Array(4);
  let dv = new DataView(Buf.buffer);
  dv.setUint32(0, _CType, true);
  return Buf;
};
var _TokenHandel = (_szJwtToken: string | undefined | null) => {
  let bRet: boolean = false;

  try {
    if (_szJwtToken) {
      SessionToken = _szJwtToken;
      StorageHelper.SaveData(Key_SessionToken, SessionToken);
      let JwtData: JWTPayloadModel = jwt_decode(_szJwtToken);

      if (JwtData.Account && JWTPayloadModel.Validate_State(JwtData.State)) {
        Account = JwtData.Account;
        State = JwtData.State;
        bRet = true;
      }
    }
  } catch {}

  if (!bRet) {
    SessionToken = undefined;
    Account = "";
    State = "None";
  }

  return bRet;
};

var RequestKey = 1000;
var _GetRequestKey = () => {
  return RequestKey++;
};

InitializeSocket();
