import { HistoryDataType } from "../../kcTransfer/InternalDefine";

export default class TECPGlobalSetting {
  constructor(props: object) {
    Object.assign(this, { ...props });
  }

  EnableGradient: boolean = false; // 啟用漸層
  EnableHGridLine: boolean = true; // 水平格線
  EnableVGridLine: boolean = true; // 垂直格線
  EnableDailyVGridLine: boolean = true; // 時段間格
  EnableNowPriceHLine: boolean = false; // 現價線
  EnableBuyPriceHLine: boolean = false; // 買價線
  EnableTopInfoOHLC: boolean = true; // 顯示開盤價, 最高價, 最低價, 收盤價
  BackgroundColor: string = "#000000FF"; // 背景顏色
  RecoverMode: boolean = false; // 回補模式
  DataType: HistoryDataType = HistoryDataType.Minute;
  DataMultiple: number = 1;
  XDataDis: number = 9; // Xinfo DataDis
  ShowDeputyTECP: boolean = true;

  public Clone(): TECPGlobalSetting {
    return new TECPGlobalSetting(this);
  }
  public SetSetting(_GSetting: TECPGlobalSetting) {
    Object.assign(this, _GSetting);
  }
}
