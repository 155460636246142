const tintColorLight = "#2f95dc";
const tintColorDark = "#fff";

export default {
  light: {
    text: "#000",
    background: "#fff",
    tint: tintColorLight,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: "#fff",
    background: "#000",
    tint: tintColorDark,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorDark,
  },
};

var m_UpDownStyle: "RedUp" | "GreenUp" = "GreenUp";
export const kcColor_GetUpDownStyle = () => {
  return m_UpDownStyle;
};
export const kcColor_SetUpDownStyle = (_UpDownStyle: typeof m_UpDownStyle) => {
  m_UpDownStyle = _UpDownStyle;
};
export const kcColor_UpDownStyleToString = (_Style: typeof m_UpDownStyle) => {
  if (_Style === "GreenUp") return "綠漲紅跌";
  else if (_Style === "RedUp") return "紅漲綠跌";
  else return "其他樣式";
};

export const kcColorObj = function (
  _ColName?: ColorName,
  _BaseValue?: BaseValueType
) {
  const RetColor = kcColor(_ColName, _BaseValue);
  return { color: RetColor };
};

// Iphone Back #000000
// Iphone List #1C1C1E
// Iphone Border #343436

export const kcColor = function (
  _ColName?: ColorName,
  _BaseValue?: BaseValueType
): string {
  switch (_ColName) {
    /* ------------------------------------------------ */
    // 不會動的部分
    case "TabBackground":
      return "#121212";

    case "TabBorder":
      return "#272729";

    case "TabCtrlFocus":
      return "#2F95DC"; //Blue

    case "TabCtrlNonFocus":
      return "#7C7C7D"; //灰

    case "TextNonSet":
      return "#B3B3B3"; //灰

    /* ------------------------------------------------ */
    // 基本
    case "TopButtomBackground":
      return "#202020";

    case "Background":
      return "#1C1C1C";

    case "ListTitleBackground":
      return "#373737";

    case "ListValueBackground":
      return "#2B2B2B";

    case "ListIcon":
      return "#B0B0B0";

    case "Border":
      return "#343436";

    case "Title":
      return "#FFFFFF";

    case "SubTitle":
      return "#DBDBDB";

    case "Icon":
      return "#FFFFFF";

    case "Yellow":
      return "#ECCA72";
    case "Yellow_2":
      return "#B68300";
    case "Yellow_3":
      return "#F0E68C";

    case "Blue":
      return "#208AB5";
    case "Blue_2":
      return "#0069B5";

    case "Red":
      return "#F97173";
    case "Red_2":
      return "#B71B1D";

    case "Green":
      return "#5ADE67";
    case "Green_2":
      return "#0C9500";

    case "DateTimeBackground":
      return "#292B2A";

    case "Up":
      if (m_UpDownStyle === "GreenUp") return kcColor("Green");
      //if( UpDownStyle === "RedUp")
      else return kcColor("Red");

    case "Up_2":
      if (m_UpDownStyle === "GreenUp") return kcColor("Green_2");
      //if( UpDownStyle === "RedUp")
      else return kcColor("Red_2");

    case "Down":
      if (m_UpDownStyle === "GreenUp") return kcColor("Red");
      //if( UpDownStyle === "RedUp")
      else return kcColor("Green");

    case "Down_2":
      if (m_UpDownStyle === "GreenUp") return kcColor("Red_2");
      //if( UpDownStyle === "RedUp")
      else return kcColor("Green_2");

    case "BuySell":
      if (
        _BaseValue === "Buy" ||
        _BaseValue === "BuyStop" ||
        _BaseValue === "BuyLimit"
      )
        return kcColor("Value", 1);
      return kcColor("Value", -1);

    case "Value":
      if (!_BaseValue) return kcColor("SubTitle");
      else if (_BaseValue == 0) return kcColor("SubTitle");
      else if (_BaseValue > 0) return kcColor("Up");
      else if (_BaseValue < 0) return kcColor("Down");

    case "StopProfit":
      return kcColor("Up_2");

    case "StopLoss":
      return kcColor("Down_2");

    case "InPrice":
      return kcColor("SubTitle");
  }
  return "#707070";
};

const ColorNamesList = [
  "TabBackground",
  "TabBorder",
  "TabCtrlFocus",
  "TabCtrlNonFocus",
  "TextNonSet",
  "TopButtomBackground",
  "Background",
  "ListTitleBackground",
  "ListValueBackground",
  "ListIcon",
  "Border",
  "Title",
  "SubTitle",
  "Icon",
  "Yellow",
  "Yellow_2",
  "Yellow_3",
  "Blue",
  "Blue_2",
  "Red",
  "Red_2",
  "Green",
  "Green_2",
  "DateTimeBackground",
  "Up",
  "Up_2",
  "Down",
  "Down_2",
  "BuySell",
  "Value",
  "StopProfit",
  "StopLoss",
  "InPrice",
] as const;
export type ColorName = typeof ColorNamesList[number];
type BaseValueType =
  | ""
  | "Buy"
  | "Sell"
  | "BuyStop"
  | "BuyLimit"
  | "Non"
  | "SellLimit"
  | "SellStop"
  | number
  | undefined;
