// 本機
//const ServerIP: string = "wss://localhost:44368";

// Server使用
const ServerIP: string = `wss://trader.lit-dit-fund.com`;
export const IP_Trade_Dealer: string = ServerIP + "/Trader/";
export const IP_Quote_Dealer: string = ServerIP + "/Quote/";
export const IP_Quote_Subscriber: string = ServerIP + "/Subscriber/";

// export const IP_Trade_Dealer: string = "wss://localhost:44368/Trader/";
// export const IP_Quote_Dealer: string = "wss://localhost:44368/Quote/";
// export const IP_Quote_Subscriber: string = "wss://localhost:44368/Subscriber/";

// export const LdfApiAwakeURL: string =
//   "https://secureApi.lit-dit-fund.com/PostApi/Awake";
export const LdfApiAwakeURL: string = "/PostApi/Awake";

export const SystemSubscriberTopic_CommodityChange: string = "CommodityChange";
export const SystemSubscriberTopic_LastInfoUpdate: string = "LastInfoUpdate";
export const SystemSubscriberTopic_ServerMessage: string = "ServerMessage";

export enum CommandType {
  UnDef = 0,
  ServerTime = 0x01,
  Connection = 0x02,
  Login = 0x03,
  //Heartbeat = 0x04,
  //Session = 0x08,
  CommodityList = 0x10,
  //CommodityListEx = 0x11,
  CommodityChange_Create = 0x21,
  CommodityChange_Modify = 0x22,
  CommodityChange_Delete = 0x24,
  Tick = 0x30,
  Best5 = 0x40,
  LastInfo = 0x50,
  //LastInfoEx = 0x51,

  HistoryData = 0x61,

  // HistoryFileList = 0x71,
  // HistoryFileData = 0x72,

  // Close = 0x0100, // 收盤發送

  Account = 0x00000200, // 帳戶相關
  Trade = 0x00000400, // 交易相關
  TradeInventory = 0x00000410, // 交易庫存, Server單向發送
  // RootAccount = 0x00000800, // 資金帳戶相關
  // Entrust = 0x00001000, // 委託相關

  /* ------------------------------------------------------------ */
  // 以下為WebServer Trade特殊Command
  Account_GetAccount = 0x00000210, // GetAccount
  Account_InformationUpdate = 0x00000211, // Account Information Update
  Account_TradeHistory = 0x00000220, // TradeHistory
}
export enum CommodityChangeType {
  UnDef = 0,
  Create = 1,
  Modify = 2,
  Delete = 3,
}

export enum QuoteHistoryRequestMode {
  DayNumber = 0x00,
  TimeRange = 0x01,
}
export enum HistoryDataType {
  UnDef = 0x00,
  Tick = 0x01,
  Depth = 0x02,
  Minute = 0x04,
  Day = 0x08,
}
