import { Moment } from "moment";
import {
  kcAccountInformationModel,
  kcInventoryModel,
  kcHistoryTrade,
} from "../kcModel";
import { HistoryDataType, QuoteHistoryRequestMode } from "./InternalDefine";

export enum ResaultState {
  Ok = 0x01,
  Fail = 0x02,
}

// Trade_Login
export type LoginParam = {
  Account: string;
  PW: string;
  RequestKey: number;
};
export type LoginResault = {
  State: ResaultState;
  Message: string;
  Token: string;
};

// Trade_Connection
export type ConnectionParam = {
  Token: string;
  RequestKey: number;
};
export type ConnectionResault = {
  State: ResaultState;
  Message: string;
  Token: string;
};

// Trade_Account
export type GetAccountParam = {
  Token: string;
  RequestKey: number;
};
export type GetAccountResault = {
  State: ResaultState;
  Message: string;
  Token: string;
  AccountInfo: kcAccountInformationModel;
  Inventory: kcInventoryModel;
};

// Trade_History
export type TradeHistoryParam = {
  Token: string;
  RequestKey: number;
};
export type TradeHistoryResault = {
  State: ResaultState;
  Message: string;
  Token: string;
  Trades: kcHistoryTrade[];
};

// Trade_Order
export type Trade_TradeOrderParam = {
  Token: string;
  RequestKey: number;
  Order: kcHistoryTrade;
};
export type Trade_TradeOrderResault = {
  State: ResaultState;
  Message: string;
  Token: string;
  RequestKey: number;
  Order: kcHistoryTrade;
};

// Quote_History
export type Quote_QuoteHistoryParam = {
  RequestKey: number;
  StockCode: string;
  HType: HistoryDataType;
  RequestMode: QuoteHistoryRequestMode;
  DayNumber?: undefined | number;
  StartTime?: undefined | string | Moment | Date;
  EndTime?: undefined | string | Moment | Date;
};
export type Quote_QuoteHistoryResault = {
  State: ResaultState;
  Message: string;
  RequestKey: number;
  StockCode: string;
  HType: HistoryDataType;
  RequestMode: QuoteHistoryRequestMode;
  DayNumber: undefined | number;
  StartTime: undefined | string | Moment | Date;
  EndTime: undefined | string | Moment | Date;
};
