import moment, { Moment } from "moment";

/* ---------------------------------------------------- */
// Moment 的 月份month() 回傳值為Index(0-11)
// 所以取得month時要+1
// moment([y,m,d]) 這個建構式month要-1
// moment("YYYY/MM/DD", "YYYY/MM/DD") 這個建構式要帶直觀數字(1-12)
/* ---------------------------------------------------- */

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export default class MomentExtensions {
  public static TicksPerMillisecond: number = 1; // 與C#差4個0
  public static TicksPerSecond: number = 1000;
  public static TicksPerMinute: number = 60000;
  public static TicksPerHour: number = 3600000;
  public static TicksPerDay: number = 86400000;

  static get MaxValue() {
    return moment(253402300800000).utc();
  }
  static get MinValue() {
    return moment(0).utc();
  }
  static UtcNow() {
    return moment().utc();
  }

  static ToOADate(date: Moment) {
    // let tzOffset =  moment().utcOffset(); // UTC+8 => 480
    var dOADate = date.valueOf() / MomentExtensions.TicksPerDay + 25569;
    return dOADate;
    /* -------------------------------Date����------------------------------- */
    // �t�e��UTC�p��
    // let tzOffset = -date.getTimezoneOffset(); // UTC+8 => -480
    // var timezoneOffset = tzOffset / (60 * 24);
    // var dOADate = date.getTime() / MomentExtensions.TicksPerDay + (25569 + timezoneOffset);
    // return dOADate;
    /* -------------------------------Date����------------------------------- */
  }
  static FromOADate(oadate: number) {
    //let tzOffset =  moment().utcOffset(); // UTC+8 => 480
    let date = moment(
      Math.round((oadate - 25569) * MomentExtensions.TicksPerDay)
    );
    return date;
    /* -------------------------------Date����------------------------------- */
    // let tzOffset = -date.getTimezoneOffset(); // UTC+8 => -480
    // var date = new Date((oadate - 25569) * MomentExtensions.TicksPerDay); // Input��ƭ쬰UTC+0
    // return date;

    // Input��Ʒ��DUTC+0, �B��e�ݬۦP
    // return new Date((oadate - 25569 - tzOffset / (60 * 24)) * MomentExtensions.TicksPerDay);
    /* -------------------------------Date����------------------------------- */
  }

  static MomentToDate(value: Moment) {
    return value.toDate();
  }

  static DateToMoment(value: Date) {
    return moment(value.valueOf()).utc();
  }

  // 捨去Hour以下 (保留年月日)
  static GetDate(value: Moment) {
    let Base = MomentExtensions.TicksPerDay;
    let TimeTick = value.valueOf();

    TimeTick = Math.floor(TimeTick / Base) * Base;
    return moment(TimeTick);
  }

  // 類似TimeSapn
  static Span(_vEnd: Moment, _vStart: Moment) {
    let SpanMS = _vEnd.valueOf() - _vStart.valueOf();
    return moment.duration(SpanMS);
  }

  static Parse_Generalized(_OriData: Moment | string | undefined | null) {
    if (_OriData === null || _OriData === undefined) return undefined;
    else if (typeof _OriData === "string") return moment(_OriData).utc();
    else if (moment.isMoment(_OriData)) return _OriData.utc();
    return _OriData;
  }

  static Parse(_OriData: Moment | string) {
    if (typeof _OriData === "string") return moment(_OriData).utc();
    else if (moment.isMoment(_OriData)) return _OriData.utc();
    return _OriData;
  }

  public static FloorToMinute(_Value: Moment): Moment {
    let timeRet = _Value.clone();
    let TickValue = _Value.valueOf();
    let lModBase = MomentExtensions.TicksPerMinute;
    let lModValue = TickValue % lModBase;
    if (lModValue > 0) timeRet = timeRet.add(-lModValue, "ms");
    return timeRet;
  }

  public static FloorToMinute_Base(_Value: Moment, _nBase: number): Moment {
    let timeRet = _Value.clone();
    let TickValue = _Value.valueOf();
    let lModBase = MomentExtensions.TicksPerMinute * _nBase;
    let lModValue = TickValue % lModBase;
    if (lModValue > 0) timeRet = timeRet.add(-lModValue, "ms");
    return timeRet;
  }

  public static FloorToMinute_Basic(
    _Value: Moment,
    _BasicTime: Moment,
    _nBase: number = 1
  ): Moment {
    let timeRet: Moment = _Value.clone();
    let lModBase = MomentExtensions.TicksPerMinute * _nBase;
    let lBasicShift = _BasicTime.valueOf() % lModBase;
    timeRet = timeRet.add(-lBasicShift, "ms");
    let lModValue = timeRet.valueOf() % lModBase;
    if (lModValue > 0) timeRet = timeRet.add(-lModValue, "ms");
    timeRet = timeRet.add(lBasicShift, "ms");
    return timeRet;
  }
  public static FloorToDay(_Value: Moment, _nBase: number = 1): Moment {
    let timeRet = _Value.clone();
    let lModBase = MomentExtensions.TicksPerDay * _nBase;
    let lModValue = _Value.valueOf() % lModBase;
    if (lModValue > 0) timeRet = timeRet.add(-lModValue, "ms");
    return timeRet;
  }
  public static FloorToDayOfWeak_ByNum(
    _Value: Moment,
    _nDayOfWeak: number
  ): Moment {
    return this.FloorToDayOfWeak(_Value, _nDayOfWeak);
  }
  public static FloorToDayOfWeak(
    _Value: Moment,
    _DayOfWeak: DayOfWeek = 0
  ): Moment {
    _DayOfWeak = _DayOfWeak % 7; // 防止Input超過6

    let timeRet = _Value.clone();
    let nNeedAdd = (_DayOfWeak - _Value.weekday() - 7) % 7;
    let lModBase = MomentExtensions.TicksPerDay;
    let lModValue = _Value.valueOf() % lModBase;
    if (lModValue > 0) timeRet = timeRet.add(-lModValue, "ms"); // 先捨去至Day單位
    timeRet = timeRet.add(lModBase * nNeedAdd, "ms"); // 加上需進位天數
    return timeRet;
  }
  public static FloorToMonth(_Value: Moment, _nBase: number = 1): Moment {
    let timeRet = _Value.clone();
    let ValueMonth = _Value.month() + 1;
    let nMonth = Math.floor(ValueMonth / _nBase);

    if (ValueMonth % _nBase != 0) nMonth += 1;
    nMonth = (nMonth - 1) * _nBase + 1;

    timeRet = timeRet.add({
      M: -(ValueMonth - nMonth),
      d: -(_Value.date() - 1),
      h: -_Value.hours(),
      m: -_Value.minutes(),
      s: -_Value.seconds(),
      ms: -_Value.milliseconds(),
    });
    return timeRet;
  }
  public static FloorToYear(_Value: Moment): Moment {
    let timeRet = _Value.clone();
    timeRet = timeRet.add({
      M: -_Value.month(),
      d: -(_Value.date() - 1),
      h: -_Value.hours(),
      m: -_Value.minutes(),
      s: -_Value.seconds(),
      ms: -_Value.milliseconds(),
    });
    return timeRet;
  }
}
