import * as React from "react";
import { StyleSheet, Pressable } from "react-native";
import { Text, View } from "./Themed";
import { kcSetState, kcSetUnmount } from "../kcExternal";
import { kcData, kcUnitModel } from "../kcData";
import { kcColorObj, kcColor } from "../constants/Colors";
import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";
import { Button } from "react-native-elements";
import Icon from "../components/KC_Icon";

const TopItemClickTypeItems = [
  "BuyOrder",
  "SellOrder",
  "Checking",
  "Setting",
  "cHType",
  "SelectCommodity",
] as const;
export type TopItemClickType = typeof TopItemClickTypeItems[number];

type IState = {
  nFixedRedraw: number; // 笨方式 用來強制繪圖
};

type IProp = {
  delCanUpdate: () => boolean;
  OnItemClick?: (_szItem: TopItemClickType, _Param0?: any) => void;
  m_kcUnit?: kcUnitModel;
  Checking?: boolean;
  ShowOrder?: boolean;
  ShowCommodity?: boolean;
  cHTypeValue?: string;
};

export default class KC_TECPScreenTopInfo extends React.Component<
  IProp,
  IState
> {
  state: IState = {
    nFixedRedraw: 0, // 笨方式 用來強制繪圖
  };

  /* ------------------------------------------------------------- */
  // Member
  nFixedRedraw: number = 0;
  PreCanUpdate: boolean = true;
  VolHand: string = "0.01"; // 快速下單手數 (UI設定)
  // m_StockCode: string = "";
  // m_kcUnit?: kcUnitModel = undefined;

  /* ------------------------------------------------------------- */
  // HookFunc
  componentDidMount() {
    kcData.UnitEvent_Add(this.OnUnitDataChanged);
  }

  componentWillUnmount() {
    kcSetUnmount(this, true);
  }

  // shouldComponentUpdate(nextProps: IProp, nextState: IState) {
  //   const CanUpdate = nextProps.delCanUpdate();

  //   // if (nextProps.StockCode != this.m_StockCode && CanUpdate)
  //   //   this.ChangeFocusStockCode(nextProps.StockCode);

  //   if (this.PreCanUpdate != CanUpdate) {
  //     this.PreCanUpdate = CanUpdate;
  //     if (CanUpdate) return true;
  //   }

  //   if (!ObjectExtensions.ObjectsEqual(nextState, this.state)) return true;
  //   if (!ObjectExtensions.ObjectsEqual(nextProps, this.props)) return true;
  //   return false;
  // }

  /* ------------------------------------------------------------- */
  // public ChangeFocusStockCode = (_StockCode: string) => {
  //   if (_StockCode == "") return;
  //   if (this.m_StockCode == _StockCode) return;
  //   this.m_StockCode = _StockCode;
  //   kcData.GetUnit_Async(this.m_StockCode).then((_kcUnit) => {
  //     if (!_kcUnit) return;
  //     this.OnUnitDataChanged(_kcUnit);
  //   });
  // };

  private FixedRedrawUI = () => {
    if (!this.props.delCanUpdate()) return;
    this.nFixedRedraw = this.nFixedRedraw + 1;
    kcSetState(this, { nFixedRedraw: this.nFixedRedraw }); // 強制更動畫面
  };

  /* ------------------------------------------------------------- */
  // On

  private OnUnitDataChanged = (_mdUnit: kcUnitModel) => {
    if (!this.props.m_kcUnit) return;
    if (this.props.m_kcUnit.StockCode != _mdUnit.StockCode) return;
    this.FixedRedrawUI();
  };

  private OnItemClick = (_ClickType: TopItemClickType) => {
    if (!this.props.OnItemClick) return;

    switch (_ClickType) {
      case "BuyOrder":
      case "SellOrder":
        if (this.props.m_kcUnit)
          this.props.OnItemClick(_ClickType, this.VolHand);
        break;
      default:
        this.props.OnItemClick(_ClickType);
        break;
    }
  };

  /* ------------------------------------------------------------- */
  render() {
    const ShowPrice =
      this.props.ShowOrder !== undefined ? this.props.ShowOrder : true;
    const ShowCommodity =
      this.props.ShowCommodity !== undefined ? this.props.ShowCommodity : false;

    const mdUnit = this.props.m_kcUnit;
    const LastInfo = mdUnit?.LastInfo;
    const Commodity = mdUnit?.Commodity;
    const ABString = mdUnit?.GetAskBidSubString();

    let BidString_Left = "0.";
    let BidString_Right = "00";
    let AskString_Left = "0.";
    let AskString_Right = "00";
    let High = "0.00";
    let Low = "0.00";

    if (mdUnit && LastInfo && Commodity && ABString) {
      Low = LastInfo.LowPrice.toFixed(ABString.FloatNum);
      High = LastInfo.HighPrice.toFixed(ABString.FloatNum);
      BidString_Left = ABString.BidString_Left;
      BidString_Right = ABString.BidString_Right;
      AskString_Left = ABString.AskString_Left;
      AskString_Right = ABString.AskString_Right;
    }
    return (
      <View
        style={{
          backgroundColor: kcColor("Background"),
          flexDirection: "row",
          justifyContent: "center",
          borderColor: kcColor("Border"),
          borderWidth: 2,
          margin: 10,
          padding: 3,
          borderRadius: 15,
        }}
      >
        <View style={styles.MainView}>
          {ShowCommodity && (
            <>
              <View style={styles.TECPInfoArea}>
                {this.props.cHTypeValue && (
                  <Button
                    iconContainerStyle={{ alignSelf: "center" }}
                    buttonStyle={{
                      backgroundColor: "#00000000",
                      // borderColor: kcColor("Border"),
                      // borderWidth: 1,
                      // borderRadius: 8,
                    }}
                    titleStyle={{ fontSize: 18 }}
                    title={this.props.cHTypeValue}
                    onPress={() => {
                      this.OnItemClick("cHType");
                    }}
                  />
                )}

                <Button
                  style={{ backgroundColor: "#00000000" }}
                  buttonStyle={{
                    backgroundColor: "#00000000",
                    // borderColor: kcColor("Border"),
                    // borderWidth: 1,
                    // borderRadius: 8,
                  }}
                  onPress={() => {
                    this.OnItemClick("SelectCommodity");
                  }}
                  titleStyle={{ fontSize: 16 }}
                  icon={
                    <MaterialCommunityIcons
                      name="text-search"
                      size={24}
                      color={"#ffffff"}
                    />
                  }
                  iconContainerStyle={{ alignSelf: "center" }}
                />
                <View
                  style={[
                    {
                      backgroundColor: "#00000000",
                      flexDirection: "column",
                    },
                  ]}
                >
                  <Text
                    style={[
                      {
                        color: kcColor("Yellow"),
                        fontSize: 12,
                        marginLeft: 15,
                        marginRight: 15,
                      },
                    ]}
                    selectable={false}
                  >
                    {this.props.m_kcUnit
                      ? this.props.m_kcUnit.StockCode
                      : "商品"}
                  </Text>
                  <Text
                    style={[
                      {
                        color: kcColor("Title"),
                        fontSize: 16,
                        marginLeft: 15,
                        marginRight: 15,
                      },
                    ]}
                    selectable={false}
                  >
                    {Commodity?.StockName}
                  </Text>
                </View>
              </View>
            </>
          )}

          {ShowPrice && (
            <>
              <View style={styles.LeftView}>
                <Text
                  onPress={() => this.OnItemClick("SellOrder")}
                  selectable={false}
                >
                  <Text style={[styles.TextNormal, kcColorObj("Down")]}>
                    {BidString_Left}
                  </Text>
                  <Text style={[styles.TextBig, kcColorObj("Down")]}>
                    {BidString_Right}
                  </Text>
                </Text>
                {/* <Text
                  style={[styles.TextSmall, kcColorObj("Value")]}
                  onPress={() => this.OnItemClick("SellOrder")}
                  selectable={false}
                >
                  最低: {Low}
                </Text> */}
                <Button
                  // style={{ width: 48, height: 48 }}
                  style={{
                    backgroundColor: "#00000000",
                    margin: 3,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  buttonStyle={{
                    backgroundColor: kcColor("Down"),
                    borderRadius: 4,
                    width: 80,
                    height: 14,
                  }}
                  onPress={() => {
                    this.OnItemClick("SellOrder");
                  }}
                  titleStyle={{
                    fontSize: 12,
                    color: "#000000",
                  }}
                  title={"Sell"}
                />
              </View>
              <View style={styles.LeftView}>
                <Text
                  onPress={() => this.OnItemClick("BuyOrder")}
                  selectable={false}
                >
                  <Text style={[styles.TextNormal, kcColorObj("Up")]}>
                    {AskString_Left}
                  </Text>
                  <Text style={[styles.TextBig, kcColorObj("Up")]}>
                    {AskString_Right}
                  </Text>
                </Text>
                {/* <Text
                  style={[styles.TextSmall, kcColorObj("Value")]}
                  onPress={() => this.OnItemClick("BuyOrder")}
                  selectable={false}
                >
                  最高: {High}
                </Text> */}
                <Button
                  // style={{ width: 48, height: 48 }}
                  style={{
                    backgroundColor: "#00000000",
                    margin: 3,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  buttonStyle={{
                    backgroundColor: kcColor("Up"),
                    borderRadius: 4,
                    width: 80,
                    height: 14,
                  }}
                  onPress={() => {
                    this.OnItemClick("BuyOrder");
                  }}
                  titleStyle={{
                    fontSize: 12,
                    color: "#000000",
                  }}
                  title={"Buy"}
                />
              </View>
            </>
          )}

          <View style={styles.RightView}>
            <Button
              // style={{ width: 48, height: 48 }}
              style={{
                backgroundColor: "#00000000",
                margin: 3,
                justifyContent: "center",
                alignItems: "center",
              }}
              buttonStyle={{
                backgroundColor: "#00000000",
                borderRadius: 8,
                width: 30,
                height: 30,
              }}
              onPress={() => {
                this.OnItemClick("Checking");
              }}
              titleStyle={{
                fontSize: 12,
              }}
              //title={"交易"}
              // iconPosition="right"
              //iconRight={true}
              icon={
                <Icon
                  name="Cross"
                  fill={this.props.Checking ? "gold" : "#FFFFFF"}
                  //stroke="#FF0000"
                  width={30}
                  height={30}
                />
              }
              iconContainerStyle={{ alignSelf: "center" }}
            />
            <Button
              // style={{ width: 48, height: 48 }}
              style={{
                backgroundColor: "#00000000",
                margin: 3,
                justifyContent: "center",
                alignItems: "center",
              }}
              buttonStyle={{
                backgroundColor: "#00000000",
                borderRadius: 8,
                width: 30,
                height: 30,
              }}
              onPress={() => {
                this.OnItemClick("Setting");
              }}
              titleStyle={{ fontSize: 16 }}
              // title={"其他"}
              icon={<AntDesign name="setting" color="#FFFFFF" size={20} />}
              // icon={
              //   <Icon
              //     name="CrossPre"
              //     fill="#FFFFFF"
              //     //stroke="#FF0000"
              //     width={30}
              //     height={30}
              //   />
              // }
              // iconPosition="right"
              //iconRight={true}
              iconContainerStyle={{ alignSelf: "center" }}
            />
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  MainView: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#00000000",
    justifyContent: "flex-start",
  },
  TECPInfoArea: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    alignItems: "center",
    marginLeft: 10,
    marginRight: 10,
  },
  LeftView: {
    //flex: 1,
    //paddingLeft: 32,
    width: 100,
    flexDirection: "column",
    backgroundColor: "#00000000",
    justifyContent: "center",
    alignItems: "center",
  },
  RightView: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#00000000",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  TextSmall: {
    fontSize: 14,
  },
  TextNormal: {
    fontSize: 16,
  },
  TextBig: {
    fontSize: 20,
    fontWeight: "bold",
  },
});
