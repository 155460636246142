import { MathEx } from "../kcExternal";
import {
  kcAccountInformationModel,
  kcCommodityModel,
  kcHistoryTrade,
} from "../kcModel";
import { LDFAccountType } from "../kcModel/kcAccountInformationModel";
import kcUnitModel from "./kcUnitModel";

export default class kcInventoryLDFModel {
  constructor(
    _HisTrade: kcHistoryTrade,
    _Account: kcAccountInformationModel,
    _mdCommodity?: kcCommodityModel
  ) {
    // 庫存使用
    this.m_HTradeIn = _HisTrade;
    this.m_mdAccount = _Account;

    if (!_Account) return;

    /* --------------------------------------------------------------------------------------- */
    // 最小跳動點數
    if (_mdCommodity) this.UpdateCommodity(_mdCommodity);

    if (_Account.AccountType == LDFAccountType.跟單戶)
      this.m_CommissionRate = this.m_mdAccount.CommissionRate;
    else this.m_CommissionRate = 0;
  }

  /* internal */ IsSpaceRow: boolean = false; // 特殊Row 目前用在Gview 最下方
  /* internal */ nSepcSortIdx: number = 0; // Keep Sort Idx

  private m_bCommodityInited: boolean = false;

  private m_HTradeIn: kcHistoryTrade;
  private m_mdAccount: kcAccountInformationModel;

  //internal kcHistoryTrade HTradeIn { get { return m_HTradeIn; } }
  public get HTradeIn() {
    return this.m_HTradeIn;
  }

  /* --------------------------------------------------------------------------------------------------- */
  // 動態計算相關
  private m_NowPrice: number = 0; // 目前價格
  private m_ProfitMoney: number = 0; // 獲利 USD
  private m_ProfitMoney_Local: number = 0; // 獲利 當地貨幣
  private m_ProfitMoney_Point: number = 0; // 獲利 點差
  private m_PointDiff: number = 0; // 點差 各Account不一樣
  private m_FloatNum: number = 0; // 小數點位數
  private m_CommissionRate: number = 0; // 傭金比例
  private m_AutoCommissionMoney: number = 0; // 動態傭金

  // [DisplayName("目前\r\n價格")]
  // public decimal NowPrice { get { return m_NowPrice; } }
  public get NowPrice() {
    return this.m_NowPrice;
  }
  // [DisplayName("獲利\r\n(USD)")]
  // public decimal ProfitMoney { get { return m_ProfitMoney; } set { m_ProfitMoney = value; } } // Spec Row會使用修改
  public get ProfitMoney() {
    return this.m_ProfitMoney;
  }
  public set ProfitMoney(_value: number) {
    this.m_ProfitMoney = _value;
  }
  // [DisplayName("獲利\r\n(商品幣種)")]
  // public decimal ProfitMoney_Local { get { return m_ProfitMoney_Local; } }
  public get ProfitMoney_Local() {
    return this.m_ProfitMoney_Local;
  }
  // [DisplayName("獲利\r\n(點數)")]
  // public decimal ProfitMoney_Point { get { return m_ProfitMoney_Point; } }
  public get ProfitMoney_Point() {
    return this.m_ProfitMoney_Point;
  }
  // [DisplayName("分潤\r\n金額")]
  // public decimal AutoCommissionMoney { get { return m_AutoCommissionMoney; } }
  public get AutoCommissionMoney() {
    return this.m_AutoCommissionMoney;
  }

  /* --------------------------------------------------------------------------------------------------- */
  // Account
  // [DisplayName("使用者\r\n序號")]
  // public int AccIdx { get { return m_mdAccount.Information.AccIdx; } }
  // [DisplayName("使用者\r\n帳號")]
  // public string Account { get { return m_mdAccount.Information.Account; } }
  public get Account() {
    return this.m_mdAccount.Account;
  }

  /* --------------------------------------------------------------------------------------------------- */
  // 進出共用
  // [DisplayName("商品")]
  // public string StockCode { get { return m_HTradeIn.StockCode; } }
  public get StockCode() {
    return this.m_HTradeIn.StockCode;
  }
  // [DisplayName("交易\r\n序號")]
  // public int TradeIdx { get { return m_HTradeIn.TradeIdx; } }
  public get TradeIdx() {
    return this.m_HTradeIn.TradeIdx;
  }
  // [DisplayName("交易\r\n序號")]
  // public kcImgString TradeIdxImg { get { return m_TradeIdxImg; } set { m_TradeIdxImg = value; } }
  // [DisplayName("帳單\r\n狀態")]
  // public TradeType Type { get { return m_HTradeIn.Type; } }
  public get Type() {
    return this.m_HTradeIn.Type;
  }
  // [DisplayName("成交量")]
  // public decimal Vol { get { return m_HTradeIn.Vol; } }
  public get Vol() {
    return this.m_HTradeIn.Vol;
  }
  // [DisplayName("手數")]
  // public decimal VolHand { get { return (decimal)m_HTradeIn.HVol; } }
  public get VolHand() {
    return this.m_HTradeIn.HVol;
  }
  // [DisplayName("庫存費")]
  // public decimal SwapMoney { get { return m_HTradeIn.SwapMoney; } }
  public get SwapMoney() {
    return this.m_HTradeIn.SwapMoney;
  }

  /* --------------------------------------------------------------------------------------------------- */
  // 進場
  // [DisplayName("類型")]
  // public BuySellType BuySellType { get { return m_HTradeIn.BuySellType; } }
  public get BuySellType() {
    return this.m_HTradeIn.BuySellType;
  }
  // [DisplayName("開倉\r\n時間")]
  // public DateTime Time { get { return m_HTradeIn.Time; } }
  public get Time() {
    return this.m_HTradeIn.Time;
  }
  // [DisplayName("開倉\r\n價格")]
  // public decimal Price { get { return m_HTradeIn.Price; } }
  public get Price() {
    return this.m_HTradeIn.Price;
  }
  // [DisplayName("停利價")]
  // public decimal? StopProfitPrice
  // { // Form Order 要使用 無法單純用 GviewHelper做
  //     get
  //     {
  //         if (m_HTradeIn.StopProfitPrice == -1)
  //             return null;
  //         return m_HTradeIn.StopProfitPrice;
  //     }
  // }
  public get StopProfitPrice(): number | undefined {
    if (this.m_HTradeIn.StopProfitPrice == -1) return undefined;
    return this.m_HTradeIn.StopProfitPrice;
  }
  // [DisplayName("停損價")]
  // public decimal? StopLossPrice
  // { // Form Order 要使用 無法單純用 GviewHelper做
  //     get
  //     {
  //         if (m_HTradeIn.StopLossPrice == -1)
  //             return null;
  //         return m_HTradeIn.StopLossPrice;
  //     }
  // }
  public get StopLossPrice(): number | undefined {
    if (this.m_HTradeIn.StopLossPrice == -1) return undefined;
    return this.m_HTradeIn.StopLossPrice;
  }
  // [DisplayName("開倉\r\n原因")]
  // public string Device { get { return m_HTradeIn.Device; } }
  // [DisplayName("到期\r\n時間")]
  // public DateTime DeadlineTime { get { return m_HTradeIn.DeadlineTime; } }
  public get DeadlineTime() {
    return this.m_HTradeIn.DeadlineTime;
  }
  // [DisplayName("預付款")]
  // public decimal PrepaidMoney { get { return m_HTradeIn.PrepaidMoney; } }
  public get PrepaidMoney() {
    return this.m_HTradeIn.PrepaidMoney;
  }
  // [DisplayName("備註")]
  // public string Ex { get { return m_HTradeIn.Ex; } }
  public get Ex() {
    return this.m_HTradeIn.Ex;
  }

  /* --------------------------------------------------------------------------------------------------- */
  // internal int FloatNum { get { return m_FloatNum; } }
  public get FloatNum() {
    return this.m_FloatNum;
  }
  // internal string StringFloatFormat { get { return "N" + m_FloatNum; } }
  public get StringFloatFormat() {
    return "N" + this.m_FloatNum;
  }

  public SepcRow(): boolean {
    return this.IsSpaceRow;
  }
  public SepcSortIdx(): number {
    return this.nSepcSortIdx;
  }
  public UpdateQuote(_Qoute: kcUnitModel, _dToUSD: number): void {
    if (!_Qoute.LastInfo_Pure || !_Qoute.Commodity) return;

    let m_AskPrice: number = _Qoute.LastInfo_Pure.AskPrice + this.m_PointDiff;
    let m_BidPrice: number = _Qoute.LastInfo_Pure.BidPrice - this.m_PointDiff;

    if (this.m_HTradeIn.BuySellType == "Sell") {
      m_BidPrice = this.m_HTradeIn.Price;
      this.m_NowPrice = m_AskPrice;
    } else if (this.m_HTradeIn.BuySellType == "Buy") {
      m_AskPrice = this.m_HTradeIn.Price;
      this.m_NowPrice = m_BidPrice;
    } else if (
      this.m_HTradeIn.BuySellType == "SellLimit" ||
      this.m_HTradeIn.BuySellType == "SellStop"
    ) {
      this.m_NowPrice = m_BidPrice;
    } else if (
      this.m_HTradeIn.BuySellType == "BuyLimit" ||
      this.m_HTradeIn.BuySellType == "BuyStop"
    ) {
      this.m_NowPrice = m_AskPrice;
    }

    let PriceDiff: number = m_BidPrice - m_AskPrice;
    this.m_ProfitMoney_Local = PriceDiff * this.m_HTradeIn.Vol;
    this.m_ProfitMoney = this.m_ProfitMoney_Local * _dToUSD;
    this.m_ProfitMoney_Point =
      PriceDiff / _Qoute.Commodity.PriceScale[0].TickPrice;

    if (this.m_ProfitMoney < 0) this.m_AutoCommissionMoney = 0;
    else
      this.m_AutoCommissionMoney =
        (-this.m_ProfitMoney * this.m_CommissionRate) / 100;
    //m_AutoCommissionMoney = m_ProfitMoney * m_CommissionRate / 100;
  }

  public UpdateCommodity(_mdCommodity: kcCommodityModel) {
    if (this.m_bCommodityInited) return;
    if (this.StockCode != _mdCommodity.StockCode) return;

    let PriceScale = 0.00001;
    this.m_FloatNum = 5;

    if (_mdCommodity.PriceScale.length != 0) {
      PriceScale = _mdCommodity.PriceScale[0].TickPrice;
      //this.m_FloatNum = PriceScaleExtensions.kcFloatNum(PriceScale,_mdCommodity.PriceScale);

      this.m_FloatNum = MathEx.GetDecimalNumber(PriceScale);
      this.m_bCommodityInited = true;
    } else {
      console.log("kcInventoryLDFModel _mdCommodity == null");
    }

    this.m_PointDiff = PriceScale * this.m_mdAccount.PointDiff;
  }

  public static IsInventoryLDFModel(_md: kcInventoryLDFModel) {
    if (_md.Account) return true;
    return false;
  }
  public static GetTradeIdx(_md: kcInventoryLDFModel) {
    if (
      _md.m_HTradeIn &&
      Number.isSafeInteger(_md.m_HTradeIn.TradeIdx) &&
      _md.m_HTradeIn.TradeIdx >= 0
    )
      return _md.m_HTradeIn.TradeIdx;
    return -1;
  }
}
