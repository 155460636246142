import * as React from "react";
import { StyleSheet, TextInput, View, Text, Pressable } from "react-native";
// import { Text, View } from "../components/Themed";
import { Button, CheckBox, Image } from "react-native-elements";
import {
  CompressionExtensions,
  kcSetState,
  kcSetUnmount,
  StorageHelper,
} from "../kcExternal";
import * as kcTrade from "../kcTransfer/kcTrade";
import { RootStackParamList } from "../types";
import { StackNavigationProp } from "@react-navigation/stack";
import { GoRootScreen } from "../navigation/RootNavigation";
import { Entypo, FontAwesome } from "@expo/vector-icons";
import { Buffer } from "buffer";
const Logo = require("../../assets/images/Login_Icon.png");

type IState = {
  open: boolean;
  SelectedIndex: number;
  AccountID: string;
  PassWord: string;
  RememberPW: boolean;
  ShowPW: boolean;
  ErrorMsg: string;
  ShowDropDown: boolean;
  SearchText: string;
};

type IProp = {
  route: any;
  navigation: StackNavigationProp<RootStackParamList, "Root">;
};

class LoginScreen extends React.Component<IProp, IState> {
  constructor(props: any) {
    super(props);
  }

  ThisRef: any = React.createRef();
  state: IState = {
    open: false,
    SelectedIndex: -1,
    AccountID: "",
    PassWord: "",
    RememberPW: false,
    ShowPW: false,
    ErrorMsg: "",
    ShowDropDown: false,
    SearchText: "",
  };

  DropDownFocus: "DropDownItem" | "DropDownIcon" | "none" = "none";
  Accounts: AccountLoginInfo[] = [];
  LoginInfoBuffer: AccountLoginInfo | undefined = undefined;
  AccountInputRef = React.createRef<TextInput>();

  componentDidMount() {
    //console.log("[Login Screen] DidMount -> ", this.props.route.key);
    this.props.navigation.addListener("focus", this.OnComponent_Focused);
    this.props.navigation.addListener("blur", this.OnComponent_blur);
  }

  componentWillUnmount() {
    //console.log("[Login Screen] WillUnmount -> ", this.props.route.key);
    kcSetUnmount(this, true);
  }

  OnLoginStateCallback = (_state: kcTrade.LoginStatus, _szMsg: string) => {
    switch (_state) {
      case kcTrade.LoginStatus.LoginSucceed:
        if (this.LoginInfoBuffer)
          AccountLoginInfoHelper.SaveAccount(this.LoginInfoBuffer, true);
        break;
      case kcTrade.LoginStatus.LoginFail:
        kcSetState(this, { ErrorMsg: _szMsg });
        break;
      case kcTrade.LoginStatus.TraderReady:
        GoRootScreen({
          screen: "TabQuote",
          params: { screen: "TabQuoteScreen" },
        });
        break;
    }
  };

  OnComponent_Focused = () => {
    //console.log("[Login Screen] Focused  -> ", this.props.route.key);
    kcTrade.SetLoginStateCallback(this.OnLoginStateCallback);
    this.ReadAccountLoginInfo(true);
  };
  OnComponent_blur = () => {
    kcTrade.SetLoginStateCallback(this.OnLoginStateCallback);
  };
  ReadAccountLoginInfo = async (_bAutoLogin: boolean) => {
    let ReadedList = await AccountLoginInfoHelper.GetAccountList();

    let mdLigonOnfo = {
      AccountID: "",
      PassWord: "",
      RememberPW: false,
      ShowPW: false,
    };
    if (ReadedList && ReadedList.SelectedIndex >= 0) {
      mdLigonOnfo = { ...ReadedList.Accounts[ReadedList.SelectedIndex] };
    }

    this.Accounts = ReadedList.Accounts;
    if (mdLigonOnfo)
      kcSetState(this, {
        ...mdLigonOnfo,
        SelectedIndex: ReadedList.SelectedIndex,
      });
    //if (_bAutoLogin) kcTrade.Trade_Request_AccountInfo();
  };

  Account_Changed = (_szAccount: string) => {
    kcSetState(this, { AccountID: _szAccount, SearchText: _szAccount });
    // this.state.AccountID = _szAccount;
  };
  PW_Changed = (_szPW: string) => {
    kcSetState(this, { PassWord: _szPW });
  };
  ShowPW_Change = () => {
    kcSetState(this, { ShowPW: !this.state.ShowPW });
  };
  RememberPW_Change = () => {
    kcSetState(this, { RememberPW: !this.state.RememberPW });
  };

  Login = () => {
    this.LoginInfoBuffer = {
      AccountID: this.state.AccountID,
      PassWord: this.state.PassWord,
      RememberPW: this.state.RememberPW,
      ShowPW: this.state.ShowPW,
    };

    if (this.state.ErrorMsg) kcSetState(this, { ErrorMsg: " " });
    kcTrade.Trade_Request_Login(this.state.AccountID, this.state.PassWord);
  };

  Render_DropDown = () => {
    let NowTest = this.state.SearchText;
    let DisplayList: AccountLoginInfo[] = [
      ...this.Accounts.filter((q) => q.AccountID.indexOf(NowTest) >= 0),
    ];
    if (DisplayList.length === 0) return <></>;

    return (
      <View style={styles.DropDownContainer}>
        {DisplayList.map((_v, _idx) => {
          let szkey = `Account-${_idx}`;
          return (
            <View key={szkey} style={styles.DropDownItemContainer}>
              <Pressable
                style={({ pressed }) => [
                  {
                    flex: 1,
                    height: 40,
                    justifyContent: "center",
                  },
                  {
                    backgroundColor: pressed ? "transparent" : "transparent",
                  },
                ]}
                onPress={(e) => {
                  this.AccountInputRef.current?.blur();
                  kcSetState(this, { ..._v, ShowDropDown: false });
                }}
                onPressIn={(e) => {
                  this.DropDownFocus = "DropDownItem";
                }}
                onPressOut={(e) => {
                  this.AccountInputRef.current?.focus();
                  this.DropDownFocus = "none";
                }}
              >
                <Text selectable={false} style={{ color: "#ffffff" }}>
                  {_v.AccountID}
                </Text>
              </Pressable>

              <View style={{ marginLeft: 8 }}>
                <Pressable
                  onPress={async (e) => {
                    this.DropDownFocus = "DropDownIcon";
                    await AccountLoginInfoHelper.RemoveAccount(
                      _v.AccountID
                    ).then(() => {
                      AccountLoginInfoHelper.GetAccountList().then(
                        (_mdRead) => {
                          this.Accounts = _mdRead.Accounts;
                          kcSetState(this, { ShowDropDown: true });
                        }
                      );
                    });
                  }}
                  onPressIn={(e) => {
                    this.DropDownFocus = "DropDownIcon";
                  }}
                  onPressOut={(e) => {
                    this.AccountInputRef.current?.focus();
                    this.DropDownFocus = "none";
                  }}
                >
                  <Entypo
                    selectable={false}
                    name="circle-with-cross"
                    size={16}
                    color="#666666"
                  />
                </Pressable>
              </View>
            </View>
          );
        })}
      </View>
    );
  };

  render() {
    let { AccountID, PassWord, ShowPW, RememberPW, ErrorMsg, ShowDropDown } =
      this.state;

    return (
      <View style={[styles.container]}>
        <View style={[styles.Main, {}]}>
          <View
            style={{
              backgroundColor: "trabsparent",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "flex-start",
              marginBottom: 20,
            }}
          >
            <Image
              source={Logo}
              style={{
                width: 127,
                height: 82,
              }}
            />
          </View>

          <View style={[styles.inputContainer, { zIndex: 10 }]}>
            <TextInput
              ref={this.AccountInputRef}
              style={[styles.input]}
              //defaultValue={AccountID}
              value={AccountID}
              autoCompleteType="off"
              textAlign="left"
              placeholder="帳號"
              placeholderTextColor="#AAAAAA"
              clearButtonMode="always"
              disableFullscreenUI={true}
              keyboardType="email-address"
              onChangeText={this.Account_Changed}
              onFocus={(e) => {
                this.DropDownFocus = "none";
                kcSetState(this, { ShowDropDown: true, SearchText: "" });
              }}
              onBlur={(e) => {
                this.DropDownFocus = "none";
                setTimeout(() => {
                  if (this.DropDownFocus == "none")
                    kcSetState(this, { ShowDropDown: false });
                }, 100);
              }}
            />
            {ShowDropDown && this.Render_DropDown()}
          </View>

          <View style={styles.inputContainer}>
            <TextInput
              secureTextEntry={!ShowPW}
              style={styles.input}
              //defaultValue={PassWord}
              value={PassWord}
              autoCompleteType="off"
              textAlign="left"
              placeholder="密碼"
              placeholderTextColor="#AAAAAA"
              clearButtonMode="always"
              disableFullscreenUI={true}
              onChangeText={this.PW_Changed}
            />
          </View>

          <Text selectable={false} style={{ color: "red" }}>
            {ErrorMsg}
          </Text>
          <View style={styles.CheckBox_View}>
            <CheckBox checked={ShowPW} onPress={this.ShowPW_Change} />
            <Text
              selectable={false}
              style={styles.CheckBox_Text}
              onPress={this.ShowPW_Change}
            >
              顯示密碼
            </Text>
          </View>

          <View style={styles.CheckBox_View}>
            <CheckBox checked={RememberPW} onPress={this.RememberPW_Change} />
            <Text
              selectable={false}
              style={styles.CheckBox_Text}
              onPress={this.RememberPW_Change}
            >
              記住密碼
            </Text>
          </View>

          <View
            style={{
              marginTop: 10,
              alignSelf: "center",
            }}
          >
            <Button
              buttonStyle={{ backgroundColor: "#5fa0ea" }}
              style={{
                width: 250,
              }}
              onPress={this.Login}
              title="登入"
            />
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#000000",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  Main: {
    flexDirection: "column",
    backgroundColor: "#000000",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  inputContainer: {
    height: 40,
    width: 250,
    marginTop: 5,
    marginBottom: 5,
    borderColor: "#AAAAAA",
    borderWidth: 2,
    borderRadius: 5,
    padding: 1,
  },
  input: {
    flex: 1,
    borderWidth: 0,
    color: "#ffffff",
    paddingHorizontal: 10,
  },
  DropDownContainer: {
    position: "absolute",
    alignSelf: "center",
    width: 250, // 同步inputContainer
    backgroundColor: "#222222",
    borderTopWidth: 0,
    borderWidth: 2,
    borderBottomWidth: 2,
    borderColor: "#AAAAAA", // 同步inputContainer
    top: 34,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    flexDirection: "column",
  },
  DropDownItemContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 0,
    borderTopWidth: 1,
    borderTopColor: "#AAAAAA", // 同步inputContainer
    paddingHorizontal: 10,
  },
  CheckBox_View: {
    flexDirection: "row",
    backgroundColor: "#000000",
    alignItems: "center",
    maxHeight: 50,
    marginBottom: -10,
  },
  CheckBox_Text: {
    marginLeft: -15,
    color: "#AAAAAA",
  },
});

export default LoginScreen;

/* ------------------------------------------------------- */
// 登入資訊存檔, 沒有加密可能危險
const StorageSaveKey = "ALP";
const SecretData: boolean = true; // 加密Storage存檔的值(目前只有壓縮)
type AccountLoginInfoList = {
  SelectedIndex: number;
  Accounts: AccountLoginInfo[];
};
type AccountLoginInfo = {
  AccountID: string;
  PassWord: string;
  RememberPW: boolean;
  ShowPW: boolean;
};
class AccountLoginInfoHelper {
  private static AccountList: AccountLoginInfoList = {
    SelectedIndex: -1,
    Accounts: [],
  };

  public static async GetAccountList() {
    let mdRead = await AccountLoginInfoHelper.Read();

    // 讀檔失敗初始化
    if (mdRead && this.IsAccountLoginInfoList(mdRead)) {
      AccountLoginInfoHelper.AccountList = mdRead;
    } else {
      AccountLoginInfoHelper.AccountList = { SelectedIndex: -1, Accounts: [] };
    }

    // 防呆處理
    if (AccountLoginInfoHelper.AccountList.Accounts.length == 0) {
      AccountLoginInfoHelper.AccountList.SelectedIndex = -1;
    } else if (
      AccountLoginInfoHelper.AccountList.SelectedIndex >=
      AccountLoginInfoHelper.AccountList.Accounts.length
    ) {
      AccountLoginInfoHelper.AccountList.SelectedIndex = 0;
    }
    return AccountLoginInfoHelper.AccountList;
  }
  public static async SaveAccount(
    _mdInfo: AccountLoginInfo,
    _bClearMemory: boolean
  ) {
    if (!_mdInfo) return;

    let nIdx = AccountLoginInfoHelper.AccountList.Accounts.findIndex(
      (q) => q.AccountID === _mdInfo.AccountID
    );
    if (nIdx < 0) {
      AccountLoginInfoHelper.AccountList.SelectedIndex =
        AccountLoginInfoHelper.AccountList.Accounts.length;
      AccountLoginInfoHelper.AccountList.Accounts.push(_mdInfo);
    } else {
      AccountLoginInfoHelper.AccountList.SelectedIndex = nIdx;
      AccountLoginInfoHelper.AccountList.Accounts[nIdx] = _mdInfo;
    }

    await AccountLoginInfoHelper.Save(AccountLoginInfoHelper.AccountList);

    if (_bClearMemory)
      AccountLoginInfoHelper.AccountList = { SelectedIndex: -1, Accounts: [] };
  }
  public static async RemoveAccount(_szAccount: string) {
    let nIdx = AccountLoginInfoHelper.AccountList.Accounts.findIndex(
      (q) => q.AccountID === _szAccount
    );
    if (nIdx < 0) return;

    if (AccountLoginInfoHelper.AccountList.SelectedIndex >= nIdx)
      AccountLoginInfoHelper.AccountList.SelectedIndex--;

    AccountLoginInfoHelper.AccountList.Accounts.splice(nIdx, 1);

    return await AccountLoginInfoHelper.Save(
      AccountLoginInfoHelper.AccountList
    );
  }
  public static IsAccountLoginInfoList(_Item: any) {
    let nSelectedIndex = Number(_Item["SelectedIndex"]);
    if (Number.isSafeInteger(nSelectedIndex) && _Item["Accounts"]) return true;
    return false;
  }
  /* --------------------------------------------------------------------- */
  private static CloneInfoList(
    _AccountInfo: AccountLoginInfoList,
    _bClearPW: boolean
  ): AccountLoginInfoList {
    let mdClone: AccountLoginInfoList = { SelectedIndex: -1, Accounts: [] };
    if (_AccountInfo) {
      mdClone.SelectedIndex = _AccountInfo.SelectedIndex;
      mdClone.Accounts = _AccountInfo.Accounts.map((_v) => {
        let mdClone: AccountLoginInfo = { ..._v };
        // 不記錄密碼的話, 清除密碼, 在存檔時使用
        if (_bClearPW && !mdClone.RememberPW) mdClone.PassWord = "";

        return mdClone;
      });
    }
    return mdClone;
  }
  private static async Save(_AccountInfo: AccountLoginInfoList) {
    let mdAccount = AccountLoginInfoHelper.CloneInfoList(_AccountInfo, true); // Clone出來

    // if (_AccountInfo.Accounts.length === 0) {
    //   return StorageHelper.RemoveValue(StorageSaveKey);
    // }

    let Base64String = "";
    if (SecretData) {
      /* ------------------------------------------- */
      // 壓縮版本
      let szJson = JSON.stringify(mdAccount); // AccountModel => JSON string
      let ZipBuffer = Buffer.from(
        CompressionExtensions.Zlib_Zip_byString(szJson)
      ); // JSON string => 壓縮Buffer
      Base64String = ZipBuffer.toString("base64"); // 壓縮Buffer => Base64編碼
    } else {
      /* ------------------------------------------- */
      // 純文字版本
      Base64String = JSON.stringify(mdAccount);
    }
    return StorageHelper.SaveData(StorageSaveKey, Base64String);
  }
  private static async Read() {
    try {
      let Base64String = await StorageHelper.ReadData(StorageSaveKey); // 讀取 Base64編碼資料

      if (Base64String) {
        let mdAccount: AccountLoginInfoList;
        if (SecretData) {
          /* ------------------------------------------- */
          // 壓縮版本
          let ZipBuffer = Buffer.from(Base64String, "base64"); // Base64編碼 => 壓縮Buffer
          let szJson = CompressionExtensions.Zlib_UnZip_toString(ZipBuffer); // 壓縮Buffer => JSON string
          mdAccount = JSON.parse(szJson); // JSON string => AccountModel
        } else {
          /* ------------------------------------------- */
          // 純文字版本
          mdAccount = JSON.parse(Base64String);
        }
        return mdAccount;
      } else return undefined;
    } catch (e) {
      return undefined;
    }
  }
}
