/**
 * Learn more about createBottomTabNavigator:
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
import { AntDesign } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";
import { Feather } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Entypo } from "@expo/vector-icons";
import {
  BottomTabNavigationOptions,
  BottomTabNavigationProp,
  createBottomTabNavigator,
} from "@react-navigation/bottom-tabs";
import {
  createStackNavigator,
  StackNavigationProp,
} from "@react-navigation/stack";
import * as React from "react";
import Colors, { kcColor } from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";
import TabQuoteScreen from "../screens/TabQuoteScreen";
import TabQuoteScreen_Add from "../screens/TabQuoteScreen_Add";
import TabQuoteScreen_Modify from "../screens/TabQuoteScreen_Modify";
import TabQuoteScreen_Info from "../screens/TabQuoteScreen_CommodityInfo";
import TabQuoteScreen_SelectCommodity from "../screens/TabQuoteScreen_SelectCommodity";
import TabTECPScreen from "../screens/TabTECPScreen";
import TECPSettingScreen from "../screens/TabTECPScreen_Setting";
import TabTradeScreen from "../screens/TabTradeScreen";
import TabTradeScreen_Order from "../screens/TabTradeScreen_Order";
import TabHistoryScreen from "../screens/TabHistoryScreen";
import TabSettingScreen from "../screens/TabSettingScreen";
import {
  BottomTabParamList,
  TabQuoteParamList,
  TabTECPParamList,
  TabTradeParamList,
  TabHistoryParamList,
  TabSettingParamList,
  RootStackParamList,
  CommodityInfoProps,
  BottomTabList,
} from "../types";

import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import {
  CompositeNavigationProp,
  EventArg,
  getFocusedRouteNameFromRoute,
  Route,
  PartialState,
  PartialRoute,
  StackNavigationState,
} from "@react-navigation/native";
import { navigate, RootState } from "./RootNavigation";
import {
  TabTECPScreen_Setting_BBand,
  TabTECPScreen_Setting_G_HLine,
  TabTECPScreen_Setting_G_Order,
  TabTECPScreen_Setting_KD,
  TabTECPScreen_Setting_KLine_Color,
  TabTECPScreen_Setting_KLine_Type,
  TabTECPScreen_Setting_MA,
  TabTECPScreen_Setting_MACD,
  TabTECPScreen_Setting_RSI,
} from "../screens/TECPSettingSubScreen";
import { View, Text, ViewStyle } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { LabelPosition } from "@react-navigation/bottom-tabs/lib/typescript/src/types";

const BottomTab_UseReplaceNavigate = true;
const ReflashAutoNavigate = false; // 子頁面重新整理時自動跳回子頁面, false時回到預設頁面(Ex.下單會回到庫存頁面)

export type IBottomTabProp = {
  route: any;
  navigation: StackNavigationProp<RootStackParamList, "Root">;
};

var BottomTabScreenSize: { Width: number; Height: number } = {
  Width: 0,
  Height: 0,
};
var OnHeightToLowChanged: ((_ToLow: boolean) => void) | undefined = undefined;
export var ToLowCallback = (_f: (_ToLow: boolean) => void) => {
  OnHeightToLowChanged = _f;
};
export var IsBottomTabHieghtToLow = () => {
  return BottomTabScreenSize.Height < 500;
};
const BottomTab = createBottomTabNavigator<BottomTabParamList>();
export default function BottomTabNavigator(_Props: IBottomTabProp) {
  const colorScheme = useColorScheme();

  const [ScreenSize, SetScreenSize] = React.useState(BottomTabScreenSize);

  const IsFocusTECPScreen =
    getFocusedRouteNameFromRoute(_Props.route) === "TabTECP";

  return (
    <View
      style={{ flex: 1, backgroundColor: kcColor("Background"), padding: 0 }}
      onLayout={(e) => {
        let Size = {
          Width: e.nativeEvent.layout.width,
          Height: e.nativeEvent.layout.height,
        };
        BottomTabScreenSize = Size;
        SetScreenSize(Size);
        OnHeightToLowChanged?.(IsBottomTabHieghtToLow());
      }}
    >
      <ActionSheetProvider>
        <BottomTab.Navigator
          initialRouteName="TabQuote"
          /* --------------------------------- */
          // initialRoute 返回初始選項卡
          // order 返回上一個選項卡（按照它們在選項卡欄中顯示的順序）
          // history 返回上次訪問的選項卡
          // none 不處理後退按鈕
          backBehavior="none"
          /* --------------------------------- */
          lazy={false}
          detachInactiveScreens={true}
          tabBarOptions={{
            // activeBackgroundColor: "rgb(18, 18, 18)", // 選取的icon底色
            activeTintColor: Colors[colorScheme].tint, // 選取的icon顏色
            // inactiveTintColor: "yellow", // 未選取的icon底色
            // inactiveBackgroundColor: "rgb(18, 18, 18)", // 未選取的icon顏色
            //showLabel: false, // 關掉Icon旁邊的文字
            style: {
              //borderTopWidth: 0,
              //borderTopColor: "rgb(18, 18, 18)",
              //maxHeight: ShowBottomTab ? 60 : 1,
            },
          }}
          tabBar={
            !IsBottomTabHieghtToLow() || !IsFocusTECPScreen
              ? undefined
              : (_SProp) => {
                  return (
                    <View
                      style={{
                        width: 1,
                        height: 1,
                        backgroundColor: "#00000000",
                      }}
                    />
                  );
                }
          }
          screenOptions={({ route }) => {
            let ret: BottomTabNavigationOptions = {
              tabBarVisibilityAnimationConfig: {
                show: { animation: "timing", config: { duration: 1 } }, // 設0有機會在手機瀏覽器上被判錯高度導致tabBar跑道螢幕範圍外
                hide: { animation: "timing", config: { duration: 1 } },
              },
            };

            // // 隱藏按鈕
            // if (["TabOrder"].includes(route.name)) {
            //   ret.tabBarButton = () => {
            //     return null;
            //   };
            // }

            return ret;
          }}
        >
          <BottomTab.Screen
            name="TabQuote"
            component={TabQuoteNavigator}
            options={{
              tabBarLabel: (_Prop) => Render_BottomTabLable(_Prop, "行情"),
              tabBarIcon: ({ color }) => (
                <TabBarIcon_Feather name="activity" color={color} size={30} />
              ),
            }}
            initialParams={{ screen: TabQuoteInitScreen }}
            // initialParams={{
            //   state: {
            //     index: 0,
            //     routes: [{ name: TabQuoteInitScreen, params: undefined }],
            //     stale: true,
            //   },
            // }}
          />
          <BottomTab.Screen
            name="TabTECP"
            component={TabTECPNavigator}
            options={{
              tabBarLabel: (_Prop) => Render_BottomTabLable(_Prop, "圖表"),
              tabBarIcon: ({ color }) => (
                <TabBarIcon_Antd name="linechart" color={color} size={30} />
              ),
            }}
            initialParams={{ screen: TabTECPInitScreen }}
            // listeners={{
            //   tabPress: (e) => {
            //     BottomTab_OnTabPress(_Props, e, "Root", { screen: "TabTECP" });
            //   },
            // }}
          />
          <BottomTab.Screen
            name="TabTrade"
            component={TabTradeNavigator}
            options={{
              tabBarLabel: (_Prop) => Render_BottomTabLable(_Prop, "交易"),
              tabBarIcon: ({ color }) => (
                <Entypo
                  selectable={false}
                  name="shuffle"
                  size={24}
                  color={color}
                  style={{ transform: [{ rotate: "-45deg" }] }}
                />
              ),
            }}
            initialParams={{ screen: TabTradeInitScreen }}
            // listeners={{
            //   tabPress: (e) => {
            //     BottomTab_OnTabPress(_Props, e, "Root", {
            //       screen: "TabTrade",
            //       params: { screen: TabTradeInitScreen },
            //     });
            //   },
            // }}
          />

          <BottomTab.Screen
            name="TabHistory"
            component={TabHistoryNavigator}
            options={{
              tabBarLabel: (_Prop) => Render_BottomTabLable(_Prop, "歷史"),
              tabBarIcon: ({ color }) => (
                <TabBarIcon_Material name="history" color={color} size={32} />
              ),
            }}
            // listeners={{
            //   tabPress: (e) => {
            //     BottomTab_OnTabPress(_Props, e, "Root", {
            //       screen: "TabHistory",
            //       params: { screen: "TabHistoryScreen" },
            //     });
            //   },
            // }}
          />
          <BottomTab.Screen
            name="TabSetting"
            component={TabSettingNavigator}
            options={{
              tabBarLabel: (_Prop) => Render_BottomTabLable(_Prop, "設定"),
              tabBarIcon: ({ color }) => (
                <TabBarIcon_Antd name="setting" color={color} size={26} />
              ),
            }}
            // listeners={{
            //   tabPress: (e) => {
            //     BottomTab_OnTabPress(_Props, e, "Root", {
            //       screen: "TabSetting",
            //       params: { screen: "TabSettingScreen" },
            //     });
            //   },
            // }}
          />
        </BottomTab.Navigator>
      </ActionSheetProvider>
    </View>
  );
}
function Render_BottomTabLable(
  _Prop: {
    focused: boolean;
    color: string;
    position: LabelPosition;
  },
  _szText: string
) {
  return (
    <Text
      selectable={false}
      style={{
        fontSize: _Prop.position == "beside-icon" ? 12 : 10,
        color: _Prop.color,
        marginLeft: _Prop.position == "beside-icon" ? 20 : 0,
      }}
    >
      {_szText}
    </Text>
  );
}

function BottomTab_OnTabPress<RouteName extends keyof RootStackParamList>(
  _Props: IBottomTabProp,
  _e: EventArg<"tabPress", true, undefined>,
  ...args: RouteName extends RootStackParamList[RouteName]
    ? [RouteName] | [RouteName, RootStackParamList[RouteName]]
    : [RouteName, RootStackParamList[RouteName]]
): void {
  let RootScreen = args[0];
  let Params = args[1];
  let TargetScreen = Params?.screen;
  let FocusScreen = getFocusedRouteNameFromRoute(_Props.route);
  // console.log("_Props.route: ", _Props.route);
  // console.log("RootState   : ", RootState());
  // console.log(_Props.navigation.dangerouslyGetState());
  if (
    BottomTab_UseReplaceNavigate &&
    TargetScreen &&
    //FocusScreen &&
    TargetScreen !== FocusScreen
  ) {
    // 基本上一定要有, 沒有就讓他走預設
    _e.preventDefault();
    navigate(RootScreen, Params);

    //let ParentState = _Props.navigation.dangerouslyGetState();
    let ParentState = RootState() as unknown as PartialState<
      StackNavigationState<RootStackParamList>
    >;
    let RoutesClone = ParentState.routes.map((v) => {
      //: PartialRoute<Route<keyof RootStackParamList, object | undefined>>[]
      if (v.name == "Root")
        return {
          key: v.key,
          name: v.name,
          //params: Params,
          params: v.params,
          state: v.state,
        };
      else
        return {
          key: v.key,
          name: v.name,
          params: v.params,
          state: v.state,
        };
    });

    /* ------------------------------------------ */
    let PreState = ParentState.routes[0].state;
    //let PreState = RootState()?.routes[0].state;
    let NRouteNames: string[] = [...BottomTabList];
    if (PreState && PreState.routeNames) NRouteNames = PreState.routeNames;
    let NHistory = PreState?.history;
    let NKey = PreState?.key;
    let NRoutes: PartialRoute<Route<string, object | undefined>>[] = [];
    if (PreState) {
      for (let i = 0; i < PreState.routes.length; i++) {
        NRoutes.push({
          name: PreState.routes[i].name,
          key: PreState.routes[i].key,
          params: PreState.routes[i].params,
          state: PreState.routes[i].state as PartialRoute<
            Route<string, object | undefined>
          >["state"],
        });
      }
    }
    let NIndex = NRoutes.findIndex((q) => q.name == TargetScreen);
    if (NIndex < 0) {
      NRoutes.push({
        name: TargetScreen,
        key: undefined,
        params: Params?.params,
      });
      NIndex = NRoutes.findIndex((q) => q.name == TargetScreen);
    }

    RoutesClone[0].state = {
      history: NHistory,
      index: NIndex,
      key: NKey,
      routeNames: NRouteNames,
      routes: NRoutes,
      stale: true,
    };

    // console.log("ParentState.routes: ", ParentState.routes[0]);
    // console.log("RoutesClone       : ", RoutesClone[0]);

    // let Reset = {
    //   index: ParentState.index,
    //   routeNames: ParentState.routeNames,
    //   key: ParentState.key,
    //   routes: RoutesClone,
    //   stale: true,
    //   type: ParentState.type,
    // };
    // console.log("Reset: ", Reset);
    _Props.navigation.reset({
      index: ParentState.index,
      routeNames: ParentState.routeNames,
      key: ParentState.key,
      routes: RoutesClone,
      stale: true,
      type: ParentState.type,
    });

    // _Props.navigation.navigate(RootScreen, Params);
    return;
  }

  if (BottomTab_UseReplaceNavigate) {
    _e.preventDefault();
  }
}
// function BottomTab_OnTabPress<RouteName extends keyof RootStackParamList>(
//   _Props: IBottomTabProp,
//   _e: EventArg<"tabPress", true, undefined>,
//   ...args: RouteName extends RootStackParamList[RouteName]
//     ? [RouteName] | [RouteName, RootStackParamList[RouteName]]
//     : [RouteName, RootStackParamList[RouteName]]
// ): void {
//   let RootScreen = args[0];
//   let Params = args[1];
//   let TargetScreen = Params?.screen;
//   let FocusScreen = getFocusedRouteNameFromRoute(_Props.route);

//   if (
//     BottomTab_UseReplaceNavigate &&
//     TargetScreen &&
//     FocusScreen &&
//     TargetScreen !== FocusScreen
//   ) {
//     console.log("_Props--------- : ", _Props.navigation.dangerouslyGetState());
//     console.log(`${TargetScreen} !== ${FocusScreen}`);
//     // 基本上一定要有, 沒有就讓他走預設
//     _e.preventDefault();
//     _Props.navigation.navigate(RootScreen, Params);
//     return;
//   }
//   console.log(`${TargetScreen} === ${FocusScreen}`);
//   // 下面不做事, 讓Event的Default去做
// }

// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
function TabBarIcon_Ionicons(props: {
  name: React.ComponentProps<typeof Ionicons>["name"];
  color: string;
  size: number;
}) {
  return (
    <Ionicons selectable={false} style={{ marginBottom: -3 }} {...props} />
  );
}
function TabBarIcon_Antd(props: {
  name: React.ComponentProps<typeof AntDesign>["name"];
  color: string;
  size: number;
}) {
  return (
    <AntDesign selectable={false} style={{ marginBottom: -3 }} {...props} />
  );
}
function TabBarIcon_Feather(props: {
  name: React.ComponentProps<typeof Feather>["name"];
  color: string;
  size: number;
}) {
  return <Feather selectable={false} style={{ marginBottom: -3 }} {...props} />;
}
function TabBarIcon_Material(props: {
  name: React.ComponentProps<typeof MaterialIcons>["name"];
  color: string;
  size: number;
}) {
  return (
    <MaterialIcons selectable={false} style={{ marginBottom: -3 }} {...props} />
  );
}
function TabBarIcon_MaterialCommunityIcons(props: {
  name: React.ComponentProps<typeof MaterialCommunityIcons>["name"];
  color: string;
  size: number;
}) {
  return (
    <MaterialCommunityIcons
      selectable={false}
      style={{ marginBottom: -3 }}
      {...props}
    />
  );
}
function TabBarIcon_Entypo(props: {
  name: React.ComponentProps<typeof Entypo>["name"];
  color: string;
  size: number;
}) {
  return <Entypo selectable={false} style={{ marginBottom: -3 }} {...props} />;
}

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
const TabQuoteStack = createStackNavigator<TabQuoteParamList>();
export type ITabQuoteProp = {
  route: any;
  navigation: CompositeNavigationProp<
    BottomTabNavigationProp<BottomTabParamList, "TabQuote">,
    StackNavigationProp<RootStackParamList>
  >;
  showActionSheetWithOptions: any;
};
const TabQuoteInitScreen = "TabQuoteScreen";
class TabQuoteNavigator extends React.Component<ITabQuoteProp> {
  constructor(_Props: ITabQuoteProp) {
    super(_Props);
    //this.OnConstructorRefreshHandle();
  }

  // 建構子進來時如果不是報價頁面的特殊處理
  OnConstructorRefreshHandle = () => {
    let FocusScreen = getFocusedRouteNameFromRoute(this.props.route);
    // 在非報價頁面(Ex. Commodity_Add)重新整理時發生, 導到報價頁面
    if (FocusScreen != TabQuoteInitScreen) {
      let ParentState = this.props.navigation.dangerouslyGetState(); // Tab層的State
      // 複製Routes狀態
      let RoutesClone: PartialRoute<
        Route<keyof BottomTabParamList, object | undefined>
      >[] = ParentState.routes.map((v) => {
        return {
          key: v.key,
          name: v.name,
          params: v.params,
        };
      });

      let CurrentRouteIdx = RoutesClone.findIndex((q) => q.name == "TabQuote");
      if (CurrentRouteIdx > -1) {
        // 把這個Route的狀態改成TabQuoteScreen
        RoutesClone[CurrentRouteIdx].state = {
          routes: [{ name: TabQuoteInitScreen }],
          stale: true,
        };
        // 重設回到報價頁面
        this.props.navigation.reset({
          index: ParentState.index,
          routes: RoutesClone,
          stale: true,
        });

        /* ----------------------------------- */
        // 再次跳轉道目標頁面, 會因為上面reset導致畫面閃一下
        if (ReflashAutoNavigate) {
          let TargetState = ParentState.routes[CurrentRouteIdx].state;
          if (TargetState && TargetState.routes.length > 0) {
            let TargetScreen =
              TargetState.routes[TargetState.routes.length - 1];

            setTimeout(() => {
              let Params = TargetScreen.params;
              let szName: keyof TabQuoteParamList =
                TargetScreen.name as keyof TabQuoteParamList;

              if (szName == "TabQuoteScreen_Order") {
                this.props.navigation.navigate("TabQuote", {
                  screen: szName,
                  //params: Params as OrderScreenProps,
                  params: Params as undefined,
                });
              } else if (szName == "TabQuoteScreen_Info") {
                this.props.navigation.navigate("TabQuote", {
                  screen: szName,
                  params: Params as CommodityInfoProps,
                });
              } else {
                this.props.navigation.navigate("TabQuote", {
                  screen: szName,
                  params: Params as undefined, // 這樣as undefined還是會硬把params帶進去, 強制不帶的話要直接拔掉這行
                });
              }
            }, 1);
          }
        }
        /* ----------------------------------- */
      }
    }
  };

  render() {
    let FocusScreen = getFocusedRouteNameFromRoute(this.props.route);
    let ShowTabBar =
      FocusScreen == TabQuoteInitScreen || FocusScreen == undefined;
    setTimeout(() => {
      this.props.navigation.setOptions({
        tabBarVisible: ShowTabBar,
      });
    }, 1);

    return (
      <TabQuoteStack.Navigator initialRouteName={TabQuoteInitScreen}>
        <TabQuoteStack.Screen
          name={TabQuoteInitScreen}
          component={TabQuoteScreen}
          options={{
            headerTitle: "行情",
            headerTitleAlign: "center",
          }}
        />
        <TabQuoteStack.Screen
          name="TabQuoteScreen_Add"
          component={TabQuoteScreen_Add}
          options={{
            headerTitle: "增加商品",
            headerTitleAlign: "center",
          }}
        />
        <TabQuoteStack.Screen
          name="TabQuoteScreen_Modify"
          component={TabQuoteScreen_Modify}
          options={{
            headerTitle: "編輯",
            headerTitleAlign: "center",
          }}
        />
        <TabQuoteStack.Screen
          name="TabQuoteScreen_Info"
          component={TabQuoteScreen_Info}
          options={{
            headerTitle: "商品規格",
            headerTitleAlign: "center",
          }}
        />
        <TabQuoteStack.Screen
          name="TabQuoteScreen_Order"
          component={TabTradeScreen_Order}
          options={{ headerTitle: "新交易單", headerTitleAlign: "center" }}
        />
        <TabQuoteStack.Screen
          name="TabQuoteScreen_Select"
          component={TabQuoteScreen_SelectCommodity}
          options={{ headerTitle: "商品列表", headerTitleAlign: "center" }}
        />
      </TabQuoteStack.Navigator>
    );
  }
}

const TabTECPInitScreen = "TabTECPScreen";
const TabTECPStack = createStackNavigator<TabTECPParamList>();
export type ITabTECPProp = {
  route: any;
  navigation: CompositeNavigationProp<
    BottomTabNavigationProp<BottomTabParamList, "TabTECP">,
    StackNavigationProp<RootStackParamList>
  >;
};
class TabTECPNavigator extends React.Component<ITabTECPProp> {
  constructor(_Props: ITabTECPProp) {
    super(_Props);
  }

  render() {
    let FocusScreen = getFocusedRouteNameFromRoute(this.props.route);
    let ShowTabBar =
      FocusScreen == TabTECPInitScreen || FocusScreen == undefined;
    setTimeout(() => {
      this.props.navigation.setOptions({
        tabBarVisible: ShowTabBar,
      });
    }, 1);

    return (
      <TabTECPStack.Navigator initialRouteName={TabTECPInitScreen}>
        <TabTECPStack.Screen
          name={TabTECPInitScreen}
          component={TabTECPScreen}
          options={{ headerTitle: "圖表", headerTitleAlign: "center" }}
        />
        <TabTECPStack.Screen
          name="TabTECPScreen_Order"
          component={TabTradeScreen_Order}
          options={{ headerTitle: "新交易單", headerTitleAlign: "center" }}
        />
        <TabTECPStack.Screen
          name="TabTECPScreen_Info"
          component={TabQuoteScreen_Info}
          options={{
            headerTitle: "商品規格",
            headerTitleAlign: "center",
          }}
        />
        <TabTECPStack.Screen
          name="TabTECPScreen_Select"
          component={TabQuoteScreen_SelectCommodity}
          options={{ headerTitle: "商品列表", headerTitleAlign: "center" }}
        />
        <TabTECPStack.Screen
          name="TabTECPScreen_Setting"
          component={TECPSettingScreen}
          options={{ headerTitle: "指標設定", headerTitleAlign: "center" }}
        />
        <TabTECPStack.Screen
          name="TabTECPScreen_Setting_BBand"
          component={TabTECPScreen_Setting_BBand}
          options={{ headerTitle: "MA", headerTitleAlign: "center" }}
        />
        <TabTECPStack.Screen
          name="TabTECPScreen_Setting_G_Order"
          component={TabTECPScreen_Setting_G_Order}
          options={{ headerTitle: "MA", headerTitleAlign: "center" }}
        />
        <TabTECPStack.Screen
          name="TabTECPScreen_Setting_KD"
          component={TabTECPScreen_Setting_KD}
          options={{ headerTitle: "MA", headerTitleAlign: "center" }}
        />
        <TabTECPStack.Screen
          name="TabTECPScreen_Setting_KLine_Color"
          component={TabTECPScreen_Setting_KLine_Color}
          options={{ headerTitle: "MA", headerTitleAlign: "center" }}
        />
        <TabTECPStack.Screen
          name="TabTECPScreen_Setting_G_HLine"
          component={TabTECPScreen_Setting_G_HLine}
          options={{ headerTitle: "MA", headerTitleAlign: "center" }}
        />
        <TabTECPStack.Screen
          name="TabTECPScreen_Setting_KLine_Type"
          component={TabTECPScreen_Setting_KLine_Type}
          options={{ headerTitle: "MA", headerTitleAlign: "center" }}
        />
        <TabTECPStack.Screen
          name="TabTECPScreen_Setting_MA"
          component={TabTECPScreen_Setting_MA}
          options={{ headerTitle: "MA", headerTitleAlign: "center" }}
        />
        <TabTECPStack.Screen
          name="TabTECPScreen_Setting_MACD"
          component={TabTECPScreen_Setting_MACD}
          options={{ headerTitle: "MA", headerTitleAlign: "center" }}
        />
        <TabTECPStack.Screen
          name="TabTECPScreen_Setting_RSI"
          component={TabTECPScreen_Setting_RSI}
          options={{ headerTitle: "MA", headerTitleAlign: "center" }}
        />
      </TabTECPStack.Navigator>
    );
  }
}

const TabTradeStack = createStackNavigator<TabTradeParamList>();
export type ITabTradeProp = {
  route: any;
  navigation: CompositeNavigationProp<
    BottomTabNavigationProp<BottomTabParamList, "TabTrade">,
    StackNavigationProp<RootStackParamList>
  >;
};
const TabTradeInitScreen = "TabTradeScreen";

class TabTradeNavigator extends React.Component<ITabTradeProp> {
  constructor(_Props: ITabTradeProp) {
    super(_Props);
    // this.OnConstructorRefreshHandle();
  }

  // 建構子進來時如果不是報價頁面的特殊處理
  private OnConstructorRefreshHandle = () => {
    let FocusScreen = getFocusedRouteNameFromRoute(this.props.route);
    // 在非報價頁面(Ex. Commodity_Add)重新整理時發生, 導到報價頁面
    if (FocusScreen != TabTradeInitScreen) {
      let ParentState = this.props.navigation.dangerouslyGetState(); // Tab層的State
      // 複製Routes狀態
      let RoutesClone: PartialRoute<
        Route<keyof BottomTabParamList, object | undefined>
      >[] = ParentState.routes.map((v) => {
        return {
          key: v.key,
          name: v.name,
          params: v.params,
        };
      });

      let CurrentRouteIdx = RoutesClone.findIndex((q) => q.name == "TabTrade");
      if (CurrentRouteIdx > -1) {
        // 把這個Route的狀態改成TabQuoteScreen
        RoutesClone[CurrentRouteIdx].state = {
          routes: [{ name: TabTradeInitScreen }],
          stale: true,
        };
        // 重設回到報價頁面
        this.props.navigation.reset({
          index: ParentState.index,
          routes: RoutesClone,
          stale: true,
        });

        /* ----------------------------------- */
        // 再次跳轉道目標頁面, 會因為上面reset導致畫面閃一下
        if (ReflashAutoNavigate) {
          let TargetState = ParentState.routes[CurrentRouteIdx].state;
          if (TargetState && TargetState.routes.length > 0) {
            let TargetScreen =
              TargetState.routes[TargetState.routes.length - 1];

            setTimeout(() => {
              let Par = TargetScreen.params;
              let szName: keyof TabTradeParamList =
                TargetScreen.name as keyof TabTradeParamList;

              if (szName == "TabTradeScreen_Order") {
                this.props.navigation.navigate("TabTrade", {
                  screen: szName,
                  //params: Par as OrderScreenProps,
                  params: Par as undefined,
                });
              } else if (szName == "TabTradeScreen_Info") {
                this.props.navigation.navigate("TabTrade", {
                  screen: szName,
                  params: Par as CommodityInfoProps,
                });
              } else {
                this.props.navigation.navigate("TabTrade", {
                  screen: szName,
                  params: Par as undefined, // 這樣as undefined還是會硬把params帶進去, 強制不帶的話要直接拔掉這行
                });
              }
            }, 1);
          }
        }
        /* ----------------------------------- */
      }
    }
  };

  render() {
    let FocusScreen = getFocusedRouteNameFromRoute(this.props.route);
    let ShowTabBar =
      FocusScreen == TabTradeInitScreen || FocusScreen == undefined;
    setTimeout(() => {
      this.props.navigation.setOptions({
        tabBarVisible: ShowTabBar,
      });
    }, 1);

    return (
      <TabTradeStack.Navigator initialRouteName={TabTradeInitScreen}>
        <TabTradeStack.Screen
          name={TabTradeInitScreen}
          component={TabTradeScreen}
          options={{ headerTitle: "交易", headerTitleAlign: "center" }}
        />
        <TabTradeStack.Screen
          name="TabTradeScreen_Order"
          component={TabTradeScreen_Order}
          options={{ headerTitle: "新交易單", headerTitleAlign: "center" }}
        />
        <TabTradeStack.Screen
          name="TabTradeScreen_Info"
          component={TabQuoteScreen_Info}
          options={{
            headerTitle: "商品規格",
            headerTitleAlign: "center",
          }}
        />
        <TabTradeStack.Screen
          name="TabTradeScreen_Select"
          component={TabQuoteScreen_SelectCommodity}
          options={{ headerTitle: "商品列表", headerTitleAlign: "center" }}
        />
      </TabTradeStack.Navigator>
    );
  }
}

const TabHistoryStack = createStackNavigator<TabHistoryParamList>();

function TabHistoryNavigator() {
  return (
    <TabHistoryStack.Navigator>
      <TabHistoryStack.Screen
        name="TabHistoryScreen"
        component={TabHistoryScreen}
        options={{ headerTitle: "歷史", headerTitleAlign: "center" }}
      />
    </TabHistoryStack.Navigator>
  );
}

const TabSettingStack = createStackNavigator<TabSettingParamList>();

function TabSettingNavigator() {
  return (
    <TabSettingStack.Navigator>
      <TabSettingStack.Screen
        name="TabSettingScreen"
        component={TabSettingScreen}
        options={{ headerTitle: "設定", headerTitleAlign: "center" }}
      />
    </TabSettingStack.Navigator>
  );
}
