export default class ArrayExtensions {
  // 找最後一個
  static FindLast<T>(
    _Array: T[],
    predicate: (value: T, index?: number, obj?: T[]) => boolean
  ) {
    let nCount = _Array.length;
    if (nCount <= 0) return undefined;

    for (let i = nCount - 1; i >= 0; i--) {
      if (predicate(_Array[i], i, _Array)) return _Array[i];
    }
    return undefined;
  }

  // 找最後一個 Index
  static FindLastIndex<T>(
    _Array: T[],
    predicate: (value: T, index?: number, obj?: T[]) => boolean
  ) {
    let nCount = _Array.length;
    if (nCount <= 0) return -1;

    for (let i = nCount - 1; i >= 0; i--) {
      if (predicate(_Array[i], i, _Array)) return i;
    }
    return -1;
  }

  static ToString<T>(_Array: T[]) {
    let szItems = "";
    for (let i = 0; i < _Array.length; i++) {
      if (i != 0) szItems += ", ";
      szItems += _Array[i];
    }
    return `[${szItems}]`;
  }

  // 交集
  static Intersection<T>(_Array1: T[], _Array2: T[]) {
    let set1 = new Set(_Array1);
    let set2 = new Set(_Array2);
    let result = [...set1.values()].filter((q) => {
      return set2.has(q);
    });
    return result;
  }

  // 聯集
  static Union<T>(_Array1: T[], _Array2: T[]) {
    let result = Array.from(new Set([..._Array1, ..._Array2]));
    return result;
  }

  // 集差
  static Difference<T>(_Src: T[], _Compare: T[]) {
    let set2 = new Set(_Compare);
    let result = _Src.filter((q) => {
      return !set2.has(q);
    });
    return result;
  }

  // 補集
  static Complement<T>(_Array1: T[], _Array2: T[]) {
    // let arr1 = [1, 2, 3, 4, 5];
    // let arr2 = [2, 4, 6, 8, 10];
    // => [1, 3, 5, 6, 8, 10]

    let set1 = new Set(_Array1);
    let set2 = new Set(_Array2);

    let result = [
      ..._Array1.filter((x) => !set2.has(x)),
      ..._Array2.filter((y) => !set1.has(y)),
    ];
    return result;
  }
}
