import { kcTECPCollection } from "..";
import { MathEx } from "../../kcExternal";
import { kcHistoryOHLCModel } from "../../kcModel";
import { SvgLinePath, TopInfo } from "../kcTECPCollection";
import RSISetting from "../TECPSetting/RSISetting";
import { XUnit } from "../XInfo";
import { YUnit } from "../YInfo";

type RSIValues = {
  XUnitValues: { Value: kcRSIModel; XUnit: XUnit }[];
  Max?: number;
  Min?: number;
};

export default class kcRSIData {
  constructor(_Owner: kcTECPCollection, _Setting: RSISetting) {
    this.m_Owner = _Owner;
    this.m_DataSrc = _Owner.mlOHLC;
    this.m_Setting = _Setting;
    this.m_Datas.length = this.m_DataSrc.length;
    this.m_fGetValue = (_md) => _md.ClosePrice;
  }

  // Data
  private m_Owner: kcTECPCollection;
  private m_DataSrc: kcHistoryOHLCModel[];
  private m_fGetValue: (_md: kcHistoryOHLCModel) => number;
  private m_Datas: kcRSIModel[] = [];
  private m_Setting: RSISetting;

  public get Setting() {
    return this.m_Setting;
  }
  public get Datas() {
    return this.m_Datas;
  }

  // Core Function
  public Value(Index: number) {
    if (Index < 0 || Index >= this.m_Datas.length) return undefined;

    if (!this.m_Datas[Index]) this.m_Datas[Index] = new kcRSIModel();

    if (!this.m_Datas[Index].HasValue) {
      // setTimeout(() => {
      let mdRSI = this.CountValueCore(Index);
      if (mdRSI != undefined) this.m_Datas[Index].SetValue(mdRSI);
      // }, 500);
    }
    return this.m_Datas[Index].HasValue ? this.m_Datas[Index] : undefined;
  }
  public Init() {
    this.m_Datas.length = 0;
    this.m_Datas.length = this.m_DataSrc.length;
  }
  public Update(_StartIdx: number, _EndIdx: number) {
    if (this.m_Datas.length != this.m_DataSrc.length)
      this.m_Datas.length = this.m_DataSrc.length;

    for (let i = _StartIdx; i <= _EndIdx; i++) {
      if (this.m_Datas[i]) this.m_Datas[i].SetUpdate();
    }
  }
  private CountValueCore(_nIndex: number): kcRSIModel | undefined {
    let mdRSI = new kcRSIModel();
    let nBase1 = this.m_Setting.RSI1_Base;
    let nBase2 = this.m_Setting.RSI2_Base;

    let dUp1 = 0,
      dDn1 = 0,
      dUp2 = 0,
      dDn2 = 0;
    for (let i = 0; i < nBase1 || i < nBase2; i++) {
      if (_nIndex - i - 1 < 0) break;
      let mdNowOHLC = this.m_Owner.GetOHLCEx(_nIndex - i);
      let mdPreOHLC = this.m_Owner.GetOHLCEx(_nIndex - i - 1);

      if (!mdNowOHLC || !mdPreOHLC) continue;

      let dPDif = mdNowOHLC.ClosePrice - mdPreOHLC.ClosePrice;
      if (i < nBase1) {
        if (dPDif > 0) dUp1 += dPDif;
        else dDn1 -= dPDif;
      }
      if (i < nBase2) {
        if (dPDif > 0) dUp2 += dPDif;
        else dDn2 -= dPDif;
      }
    }

    let dRSI1 = 0,
      dRSI2 = 0;
    if (dDn1 == 0) {
      if (dUp1 == 0) dRSI1 = 0;
      else dRSI1 = 100;
    } else {
      let dRS = dUp1 / dDn1;
      dRSI1 = 100 - 100 / (1 + dRS);
    }
    if (dDn2 == 0) {
      if (dUp2 == 0) dRSI2 = 0;
      else dRSI2 = 100;
    } else {
      let dRS = dUp2 / dDn2;
      dRSI2 = 100 - 100 / (1 + dRS);
    }

    mdRSI.SetValue({ RSI1: dRSI1, RSI2: dRSI2 });
    return mdRSI;
  }

  // Update Setting Function

  public UpdateSetting(_MaSetting: RSISetting) {
    let UpdateResault = this.m_Setting.SetSetting(_MaSetting);

    if (UpdateResault.DataChanged) {
      this.Init();
    }
  }

  // 輔助 Function
  public map<U>(
    callbackfn: (value: kcRSIModel, index: number, array: kcRSIModel[]) => U
  ): U[] {
    let aRet: U[] = [];
    for (let i = 0; i < this.m_Datas.length; i++) {
      let Item = this.Value(i);
      aRet.push(callbackfn(this.m_Datas[i], i, this.m_Datas));
    }
    return aRet;
  }

  // Draw Function
  public GetValues(_XUnits: XUnit[]): RSIValues | undefined {
    if (!this.m_Setting.EnableAny) return undefined;

    let aValues: RSIValues["XUnitValues"] = [];
    let dMax: number = 100;
    let dMin: number = 0;

    for (let i = 0; i < _XUnits.length; i++) {
      let dValue = this.Value(_XUnits[i].nDataIndex);
      if (dValue && dValue.HasValue)
        aValues.push({ Value: dValue, XUnit: _XUnits[i] });
    }

    if (aValues.length == 0) return undefined;

    return {
      XUnitValues: aValues,
      Max: dMax,
      Min: dMin,
    };
  }
  public GetSVGPath(
    _RSIValues: RSIValues | undefined,
    _YUnit: YUnit
  ): SvgLinePath[] | undefined {
    if (
      !_RSIValues ||
      !_RSIValues.XUnitValues ||
      _RSIValues.XUnitValues.length === 0
    )
      return undefined;

    if (!this.m_Setting.EnableAny) return undefined;

    let szPath_RSI1 = "";
    let szPath_RSI2 = "";

    for (let i = 0; i < _RSIValues.XUnitValues.length; i++) {
      let mdRSI = _RSIValues.XUnitValues[i].Value;
      if (!mdRSI) continue;

      let XCoord = _RSIValues.XUnitValues[i].XUnit.fXMidPos;
      if (this.m_Setting.RSI1_Enable && mdRSI.RSI1) {
        let YCoord = _YUnit.ValueToCoord(mdRSI.RSI1);
        szPath_RSI1 += `${i == 0 ? "M" : "L"} ${XCoord} ${YCoord}`;
      }
      if (this.m_Setting.RSI2_Enable && mdRSI.RSI2) {
        let YCoord = _YUnit.ValueToCoord(mdRSI.RSI2);
        szPath_RSI2 += `${i == 0 ? "M" : "L"} ${XCoord} ${YCoord}`;
      }
    }

    let aPaths: SvgLinePath[] = [];
    if (this.m_Setting.RSI1_Enable) {
      aPaths.push({
        PathColor: this.m_Setting.RSI1_LineColor,
        PathWidth: this.m_Setting.RSI1_LineWidth,
        SvgPath: szPath_RSI1,
      });
    }
    if (this.m_Setting.RSI2_Enable) {
      aPaths.push({
        PathColor: this.m_Setting.RSI2_LineColor,
        PathWidth: this.m_Setting.RSI2_LineWidth,
        SvgPath: szPath_RSI2,
      });
    }

    return aPaths;
  }
  public GetTopInfo() {
    if (!this.m_Setting.EnableAny) return undefined;

    let mdRSI = this.Value(this.m_Datas.length - 1);
    let mdPreRSI = this.Value(this.m_Datas.length - 2);
    if (!mdRSI) return undefined;

    let lTopInfos: TopInfo[] = [];
    let FloatNum = this.m_Owner.kcUnit?.Commodity?.FloatNum ?? 5;

    // RSI1
    if (this.m_Setting.RSI1_Enable) {
      let szRSI1 = mdRSI.RSI1 ? MathEx.kcRound(mdRSI.RSI1, FloatNum) : " ";
      let szValueColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

      if (mdRSI.RSI1 && mdPreRSI && mdPreRSI.RSI1) {
        if (mdRSI.RSI1 < mdPreRSI.RSI1)
          szValueColor = this.m_Owner.Setting.KLine.DownKColor;
        // 下跌 KLine下跌色
        else if (mdRSI.RSI1 > mdPreRSI.RSI1)
          szValueColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      }

      lTopInfos.push({
        Text: `RSI${this.m_Setting.RSI1_Base}`,
        TextColor: this.m_Setting.RSI1_LineColor,
        Value: `${szRSI1}`,
        // Color: szValueColor,
      });
    }

    // RSI2
    if (this.m_Setting.RSI2_Enable) {
      let szRSI2 = mdRSI.RSI2 ? MathEx.kcRound(mdRSI.RSI2, FloatNum) : " ";
      let szValueColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

      if (mdRSI.RSI2 && mdPreRSI && mdPreRSI.RSI2) {
        if (mdRSI.RSI2 < mdPreRSI.RSI2)
          szValueColor = this.m_Owner.Setting.KLine.DownKColor;
        // 下跌 KLine下跌色
        else if (mdRSI.RSI2 > mdPreRSI.RSI2)
          szValueColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      }

      lTopInfos.push({
        Text: `RSI${this.m_Setting.RSI2_Base}`,
        TextColor: this.m_Setting.RSI2_LineColor,
        Value: `${szRSI2}`,
        // Color: szValueColor,
      });
    }

    return lTopInfos;
  }
  public AddTopInfo(_Collection: TopInfo[]) {
    let mdTop = this.GetTopInfo();
    if (mdTop && _Collection) _Collection.push(...mdTop);
    return mdTop;
  }
  public GetLeftInfo(_nDataIndex: number) {
    if (_nDataIndex < 0 || _nDataIndex >= this.m_Datas.length) return undefined;
    if (!this.m_Setting.EnableAny) return undefined;

    let mdRSI = this.Value(_nDataIndex);
    let mdPreRSI = this.Value(_nDataIndex - 1);
    if (!mdRSI) return undefined;

    let lTopInfos: TopInfo[] = [];
    let FloatNum = this.m_Owner.kcUnit?.Commodity?.FloatNum ?? 5;

    // RSI1
    if (this.m_Setting.RSI1_Enable) {
      let szRSI1 = mdRSI.RSI1 ? MathEx.kcRound(mdRSI.RSI1, FloatNum) : " ";
      let szValueColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

      if (mdRSI.RSI1 && mdPreRSI && mdPreRSI.RSI1) {
        if (mdRSI.RSI1 < mdPreRSI.RSI1)
          szValueColor = this.m_Owner.Setting.KLine.DownKColor;
        // 下跌 KLine下跌色
        else if (mdRSI.RSI1 > mdPreRSI.RSI1)
          szValueColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      }

      lTopInfos.push({
        Text: `RSI${this.m_Setting.RSI1_Base}`,
        TextColor: this.m_Setting.RSI1_LineColor,
        Value: `${szRSI1}`,
        Color: szValueColor,
      });
    }

    // RSI2
    if (this.m_Setting.RSI2_Enable) {
      let szRSI2 = mdRSI.RSI2 ? MathEx.kcRound(mdRSI.RSI2, FloatNum) : " ";
      let szValueColor = this.m_Owner.Setting.KLine.EqualKColor; // 預設 平盤 KLine平盤色

      if (mdRSI.RSI2 && mdPreRSI && mdPreRSI.RSI2) {
        if (mdRSI.RSI2 < mdPreRSI.RSI2)
          szValueColor = this.m_Owner.Setting.KLine.DownKColor;
        // 下跌 KLine下跌色
        else if (mdRSI.RSI2 > mdPreRSI.RSI2)
          szValueColor = this.m_Owner.Setting.KLine.UpKColor; // 上漲 KLine上漲色
      }

      lTopInfos.push({
        Text: `RSI${this.m_Setting.RSI2_Base}`,
        TextColor: this.m_Setting.RSI2_LineColor,
        Value: `${szRSI2}`,
        Color: szValueColor,
      });
    }

    return lTopInfos;
  }
  public AddLeftInfo(_nDataIndex: number, _Collection: TopInfo[]) {
    let mdLeft = this.GetLeftInfo(_nDataIndex);
    if (mdLeft && _Collection) _Collection.push(...mdLeft);
    return mdLeft;
  }
}

class kcRSIModel {
  constructor() {}

  private m_HasValue: boolean = false;

  private m_RSI1?: number;
  private m_RSI2?: number;

  public get HasValue() {
    return this.m_HasValue;
  }
  public get RSI1() {
    return this.m_RSI1;
  }
  public get RSI2() {
    return this.m_RSI2;
  }

  public SetValue(_mdValue: kcRSIModel | { RSI1?: number; RSI2?: number }) {
    if (_mdValue) {
      this.m_RSI1 = _mdValue.RSI1;
      this.m_RSI2 = _mdValue.RSI2;

      this.m_HasValue = true;
    }
  }

  public SetUpdate() {
    this.m_HasValue = false;
  }
}
