import * as React from "react";
import { StyleSheet, Pressable } from "react-native";
import { Text, View } from "./Themed";
import { kcSetState, kcSetUnmount } from "../kcExternal";
import { kcData, kcUnitModel } from "../kcData";
import { ObjectExtensions } from "../kcExternal";
import { kcAccountLDFModel, kcInventoryLDFModel } from "../kcData";
import {
  AccountLDFUpdateType,
  OnAccountLDFUpdate_Event,
  OnInventoryUpdate_Event,
} from "../kcData/kcAccountLDFModel";
import { kcColorObj, kcColor } from "../constants/Colors";
import { Ionicons } from "@expo/vector-icons";
import { Foundation } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { OrderScreenDisplayType } from "../kcData/OrderParamHelper";

type IState = {
  nFixedRedraw: number; // 笨方式 用來強制繪圖
};

type IProp = {
  TradeIdx: number;
  delCanUpdate: () => boolean;
  OnItemPress: (_TradeIdx: number, _Type: OrderScreenDisplayType) => void;
};

export default class KC_TradeScreenRowItem extends React.Component<
  IProp,
  IState
> {
  state: IState = {
    nFixedRedraw: 0, // 笨方式 用來強制繪圖
  };

  /* ------------------------------------------------------------- */
  // Member
  //mlInventory: kcInventoryLDFModel[] = [];
  nFixedRedraw: number = 0;
  m_TradeIdx: number = -1;
  m_Inventory: kcInventoryLDFModel | undefined = undefined;
  PreCanUpdate: boolean = true;

  /* ------------------------------------------------------------- */
  // HookFunc
  componentDidMount() {
    this.ChangeFocusTradeIdx(this.props.TradeIdx);
    this.GetInventory_async();
    kcAccountLDFModel.InventoryUpdateEvent_Add(this.OnInventoryUpdate);
  }

  componentWillUnmount() {
    kcAccountLDFModel.InventoryUpdateEvent_Remove(this.OnInventoryUpdate);
    kcSetUnmount(this, true);
  }

  shouldComponentUpdate(nextProps: IProp, nextState: IState) {
    const CanUpdate = nextProps.delCanUpdate();

    if (this.PreCanUpdate != CanUpdate) {
      this.PreCanUpdate = CanUpdate;
      if (CanUpdate) {
        this.GetInventory_async();
        return false;
      }
    }

    if (!CanUpdate) return false;
    if (!ObjectExtensions.ObjectsEqual(nextState, this.state)) return true;
    if (!ObjectExtensions.ObjectsEqual(nextProps, this.props)) return true;
    return false;
  }

  /* ------------------------------------------------------------- */
  private ChangeFocusTradeIdx = (_TradeIdx: number) => {
    this.m_TradeIdx = _TradeIdx;
  };

  private GetInventory_async = async () => {
    let mdAccount = kcData.AccountLDF();
    if (!mdAccount) return;

    let Inventory = mdAccount.GetInventory(this.m_TradeIdx);
    if (!Inventory) return;

    this.OnInventoryUpdate(
      mdAccount,
      mdAccount.Account,
      AccountLDFUpdateType.Inventory,
      Inventory.StockCode,
      Inventory
    );
  };

  private FixedRedrawUI = () => {
    this.nFixedRedraw = this.nFixedRedraw + 1;
    kcSetState(this, { nFixedRedraw: this.nFixedRedraw }); // 強制更動畫面
  };

  /* ------------------------------------------------------------- */
  // On
  OnInventoryUpdate: OnInventoryUpdate_Event = (
    _mdSender,
    _szAccount,
    _Type,
    _StockCode,
    _Inventory
  ) => {
    if (_Inventory.TradeIdx != this.m_TradeIdx) return;
    this.m_Inventory = _Inventory;
    this.FixedRedrawUI();
  };

  private OnPress = (_bIsLong: boolean, _bIsBalance: boolean) => {
    if (!this.props.OnItemPress) return;
    let Type: OrderScreenDisplayType = "Update";
    if (_bIsBalance) Type = "Balance";
    this.props.OnItemPress(this.m_TradeIdx, Type);
  };

  /* ------------------------------------------------------------- */
  render() {
    if (!this.m_Inventory) return <></>;

    let md = this.m_Inventory;
    let IsMarket = md.BuySellType == "Buy" || md.BuySellType == "Sell";

    let szPriceInout = md.Price
      ? md.Price?.toFixed(md.FloatNum) +
        " → " +
        md.NowPrice?.toFixed(md.FloatNum)
      : "";

    if (!IsMarket) szPriceInout = "掛單價格 : " + md.Price.toFixed(md.FloatNum);

    let szStopLoss =
      !md.StopLossPrice || md.StopLossPrice == -1
        ? "--"
        : md.StopLossPrice.toFixed(md.FloatNum);

    let szStopProfit =
      !md.StopProfitPrice || md.StopProfitPrice == -1
        ? "--"
        : md.StopProfitPrice.toFixed(md.FloatNum);

    let szDeadlineTime = md.DeadlineTime
      ? md.DeadlineTime.format("YYYY-MM-DD HH:mm:ss")
      : "--";

    return (
      <View
        style={{
          flexDirection: "column",
          backgroundColor: kcColor("ListValueBackground"),
          height: 80,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            backgroundColor: "#00000000",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Ionicons
            name="ios-chevron-back"
            size={24}
            color={kcColor("ListValueBackground")}
            style={{ marginLeft: 10, marginRight: 10 }}
            selectable={false}
          />
          <Pressable
            onLongPress={() => this.OnPress(true, false)}
            onPress={() => this.OnPress(false, false)}
            style={{
              flexDirection: "column",
              backgroundColor: "#00000000",
              flex: 1,
            }}
          >
            <View style={[styles.MainView, { flex: 1 }]}>
              <View style={styles.LeftView}>
                <Text
                  selectable={false}
                  style={[
                    styles.TextNormal,
                    kcColorObj("BuySell", md.BuySellType),
                  ]}
                >
                  {md.BuySellType + " " + md.VolHand.toFixed(2)}
                </Text>
                <Text
                  selectable={false}
                  style={[styles.TextNormal, kcColorObj("Title")]}
                >
                  {szPriceInout}
                </Text>
              </View>
              <View style={styles.RightView}>
                {IsMarket && (
                  <Text
                    selectable={false}
                    style={[
                      styles.TextNormal,
                      kcColorObj("Value", md.ProfitMoney),
                    ]}
                  >
                    {"$" + md.ProfitMoney.toFixed(2)}
                  </Text>
                )}
                {!IsMarket && (
                  <Text
                    selectable={false}
                    style={[styles.TextNormal, kcColorObj("Value")]}
                  >
                    {md.NowPrice.toFixed(md.FloatNum)}
                  </Text>
                )}
              </View>
            </View>
            <View style={styles.MainView}>
              <View style={styles.LeftView}>
                <Text
                  selectable={false}
                  style={[styles.TextSmall, kcColorObj("SubTitle")]}
                >
                  {"停利 : " + szStopProfit}
                </Text>
              </View>
              <View style={styles.RightView}>
                {IsMarket && (
                  <Text
                    selectable={false}
                    style={[styles.TextSmall, kcColorObj("SubTitle")]}
                  >
                    {"庫存費 : " + md.SwapMoney.toFixed(2)}
                  </Text>
                )}
              </View>
            </View>
            <View style={styles.MainView}>
              <View style={styles.LeftView}>
                <Text
                  selectable={false}
                  style={[styles.TextSmall, kcColorObj("SubTitle")]}
                >
                  {"停損 : " + szStopLoss}
                </Text>
              </View>
              <View style={styles.RightView}>
                {IsMarket && (
                  <Text
                    selectable={false}
                    style={[styles.TextSmall, kcColorObj("SubTitle")]}
                  >
                    {md.Time.format("YYYY-MM-DD HH:mm:ss")}
                  </Text>
                )}
                {!IsMarket && (
                  <Text
                    selectable={false}
                    style={[styles.TextSmall, kcColorObj("SubTitle")]}
                  >
                    {szDeadlineTime}
                  </Text>
                )}
              </View>
            </View>
          </Pressable>
          <MaterialCommunityIcons
            name="file-remove-outline"
            size={20}
            color={kcColor("Yellow")}
            style={{ marginLeft: 10, marginRight: 20 }}
            onPress={() => this.OnPress(false, true)}
            selectable={false}
          />
        </View>
        <View
          style={{
            height: 1,
            backgroundColor: kcColor("Border"),
            marginTop: 3,
            marginLeft: 30,
            marginRight: 10,
          }}
        ></View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  MainView: {
    //flex: 1,
    flexDirection: "row",
    backgroundColor: kcColor("ListValueBackground"),
    //justifyContent: "center",
    alignItems: "center",
  },
  LeftView: {
    //flex: 1,
    flexDirection: "column",
    backgroundColor: "#00000000",
    justifyContent: "center",
    alignItems: "flex-start",
    //height: 70,
  },
  RightView: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#00000000",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  ButtonBorder: {
    height: 1,
    backgroundColor: "#303030",
  },
  TextSmall: {
    fontSize: 14,
  },
  TextNormal: {
    fontSize: 14,
  },
  TextBig: {
    fontSize: 24,
    fontWeight: "bold",
  },
});
