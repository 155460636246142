import * as React from "react";
import { StyleSheet, Pressable } from "react-native";
import { Text, View } from "./Themed";
import { kcSetState, kcSetUnmount } from "../kcExternal";
import { kcData, kcUnitModel } from "../kcData";
import { ObjectExtensions } from "../kcExternal";
import { kcColorObj, kcColor } from "../constants/Colors";

type IState = {
  nFixedRedraw: number; // 笨方式 用來強制繪圖
};

type IProp = {
  StockCode: string;
  OnPress?: (_mdUnit: kcUnitModel, _bIsLongPress: boolean) => void;
  delCanUpdate: () => boolean;
};

export default class KC_QuoteScreenRow extends React.Component<IProp, IState> {
  state: IState = {
    nFixedRedraw: 0, // 笨方式 用來強制繪圖
  };

  /* ------------------------------------------------------------- */
  // Member
  nFixedRedraw: number = 0;
  StockCode: string = "";
  mdUnit?: kcUnitModel = undefined;
  PreCanUpdate: boolean = true;

  /* ------------------------------------------------------------- */
  // HookFunc
  componentDidMount() {
    // console.log("KC_QuoteScreenRow mount", this);

    this.ChangeFocusStock(this.props.StockCode);
    kcData.UnitEvent_Add(this.OnUnitDataChanged);
  }

  componentWillUnmount() {
    // console.log("KC_QuoteScreenRow Unmount", this);

    kcData.UnitEvent_Remove(this.OnUnitDataChanged);
    kcSetUnmount(this, true);
  }

  shouldComponentUpdate(nextProps: IProp, nextState: IState) {
    // if (this.props.StockCode == "XAUUSD")
    // console.log("KC_QuoteScreenRow shouldComponentUpdate", this);

    const CanUpdate = nextProps.delCanUpdate();

    let NeedChangeStock = false;
    if (this.PreCanUpdate != CanUpdate) {
      this.PreCanUpdate = CanUpdate;
      NeedChangeStock = CanUpdate;
    }

    if (this.StockCode != nextProps.StockCode) NeedChangeStock = true;
    if (NeedChangeStock) {
      this.ChangeFocusStock(nextProps.StockCode);
      return false;
    }

    if (!CanUpdate) return false;
    if (!ObjectExtensions.ObjectsEqual(nextState, this.state)) return true;
    if (!ObjectExtensions.ObjectsEqual(nextProps, this.props)) return true;
    return false;
  }

  /* ------------------------------------------------------------- */
  private ChangeFocusStock = (StockCode: string) => {
    this.StockCode = StockCode;
    this.mdUnit = kcData.GetUnit(StockCode);
    if (this.mdUnit) this.OnUnitDataChanged(this.mdUnit);
  };

  private FixedRedrawUI = () => {
    this.nFixedRedraw = this.nFixedRedraw + 1;
    kcSetState(this, { nFixedRedraw: this.nFixedRedraw }); // 強制更動畫面
  };

  /* ------------------------------------------------------------- */
  // On
  private OnUnitDataChanged = (_mdUnit: kcUnitModel) => {
    if (this.StockCode != _mdUnit.StockCode) return;
    this.mdUnit = _mdUnit;

    this.FixedRedrawUI();
  };

  private onPress = (_bIsLong: boolean) => {
    if (!this.mdUnit) return;
    if (!this.props.OnPress) return;
    this.props.OnPress(this.mdUnit, _bIsLong);
  };

  /* ------------------------------------------------------------- */
  render() {
    // if (this.props.StockCode == "XAUUSD")
    //   console.log("KC_QuoteScreenRow render", this);
    const mdUnit = this.mdUnit;
    const LastInfo = this.mdUnit?.LastInfo;
    const Commodity = this.mdUnit?.Commodity;

    /* 無商品 Unit尚未完成特例 */
    if (!mdUnit || !LastInfo || !Commodity) {
      return (
        <Pressable
          onLongPress={() => this.onPress(true)}
          onPress={() => this.onPress(false)}
        >
          <View style={styles.MainView}>
            <View style={styles.LeftView}>
              <Text
                selectable={false}
                style={[styles.TextXS, kcColorObj("Yellow")]}
              >
                {this.props.StockCode}
              </Text>
              <Text
                selectable={false}
                style={[styles.TextXSB, kcColorObj("Value")]}
                numberOfLines={1}
              >
                {" "}
              </Text>

              <Text
                selectable={false}
                style={[styles.TextSmall, kcColorObj("Value")]}
              >
                {"點差: "}
              </Text>
            </View>
          </View>
          <View
            style={{ height: 1, backgroundColor: kcColor("Border") }}
          ></View>
        </Pressable>
      );
    }

    const Dif = LastInfo.AskPrice - LastInfo.BidPrice;
    const PriceDiff = (Dif / Commodity.PriceScale[0].TickPrice).toFixed(0);
    const ABString = mdUnit.GetAskBidSubString();

    return (
      <Pressable
        onLongPress={() => this.onPress(true)}
        onPress={() => this.onPress(false)}
      >
        <View style={styles.MainView}>
          <View style={styles.LeftView}>
            {/* <Text
              selectable={false}
              style={[styles.TextSmall, kcColorObj("Value")]}
            >
              {LastInfo.Time.format("HH:mm:ss").toString()}
            </Text> */}

            <Text
              selectable={false}
              style={[styles.TextXS, kcColorObj("Yellow")]}
            >
              {this.props.StockCode}
            </Text>
            <Text
              selectable={false}
              style={[styles.TextXSB, kcColorObj("Title")]}
              numberOfLines={1}
            >
              {Commodity.StockName}
            </Text>
            <Text
              selectable={false}
              style={[styles.TextSmall, kcColorObj("Value")]}
            >
              {"點差: " + PriceDiff}
            </Text>
          </View>
          <View
            style={[
              styles.RightView,
              {
                borderColor:
                  kcColor("Value", LastInfo.BidPrice - LastInfo.BasePrice) +
                  "50",
              },
            ]}
          >
            <Text selectable={false}>
              <Text
                selectable={false}
                style={[
                  styles.TextNormal,
                  kcColorObj("Value", LastInfo.BidPrice - LastInfo.BasePrice),
                ]}
              >
                {ABString.BidString_Left}
              </Text>
              <Text
                selectable={false}
                style={[
                  styles.TextBig,
                  kcColorObj("Value", LastInfo.BidPrice - LastInfo.BasePrice),
                ]}
              >
                {ABString.BidString_Right}
              </Text>
            </Text>
            <Text
              selectable={false}
              style={[styles.TextSmall, kcColorObj("Value")]}
            >
              最低: {LastInfo.LowPrice.toFixed(ABString.FloatNum)}
            </Text>
          </View>
          <View
            style={[
              styles.RightView,
              {
                borderColor:
                  kcColor("Value", LastInfo.AskPrice - LastInfo.BasePrice) +
                  "50",
              },
            ]}
          >
            <Text selectable={false}>
              <Text
                selectable={false}
                style={[
                  styles.TextNormal,
                  kcColorObj("Value", LastInfo.AskPrice - LastInfo.BasePrice),
                ]}
              >
                {ABString.AskString_Left}
              </Text>
              <Text
                selectable={false}
                style={[
                  styles.TextBig,
                  kcColorObj("Value", LastInfo.AskPrice - LastInfo.BasePrice),
                ]}
              >
                {ABString.AskString_Right}
              </Text>
            </Text>
            <Text
              selectable={false}
              style={[styles.TextSmall, kcColorObj("Value")]}
            >
              最高: {LastInfo.HighPrice.toFixed(ABString.FloatNum)}
            </Text>
          </View>
        </View>
        <View
          style={{
            height: 1,
            backgroundColor: kcColor("Border"),
            marginLeft: -10,
            marginRight: -10,
          }}
        ></View>
      </Pressable>
    );
  }
}

const styles = StyleSheet.create({
  MainView: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: kcColor("Background"),
    justifyContent: "center",
  },
  LeftView: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#00000000",
    justifyContent: "center",
    alignItems: "flex-start",
    height: 70,
  },
  RightView: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#00000000",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderRadius: 15,
    borderColor: "#00000000",
    margin: 5,
    marginTop: 10,
    marginBottom: 10,
    padding: 3,
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
  TextXS: {
    fontSize: 14,
  },
  TextXSB: {
    fontSize: 14,
  },
  TextSmall: {
    fontSize: 14,
  },
  TextNormal: {
    fontSize: 16,
    fontWeight: "bold",
  },
  TextBig: {
    fontSize: 24,
    fontWeight: "bold",
  },
});
