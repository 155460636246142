/**
 * If you are not familiar with React Navigation, check out the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
  RouteProp,
  Theme,
} from "@react-navigation/native";
import {
  createStackNavigator,
  StackNavigationProp,
} from "@react-navigation/stack";
import * as React from "react";
import { ActivityIndicator, ColorSchemeName, Text, View } from "react-native";
import NotFoundScreen from "../screens/NotFoundScreen";
import LoginScreen from "../screens/TabLoginScreen";
import { RootStackParamList } from "../types";
import BottomTabNavigator from "./BottomTabNavigator";
import LinkingConfiguration from "./LinkingConfiguration";
import { navigationRef } from "./RootNavigation";
import { kcColor } from "../constants/Colors";
import * as Font from "expo-font";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  AntDesign,
  Ionicons,
} from "@expo/vector-icons";
import { WaitSleep } from "../kcExternal";

export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  let kcDark = DarkTheme;
  kcDark.colors.card = kcColor("TopButtomBackground");
  kcDark.colors.border = kcColor("Border");
  let kcDefault = DefaultTheme;
  kcDefault.colors.card = kcColor("TopButtomBackground");
  kcDefault.colors.border = kcColor("Border");

  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? kcDark : kcDefault}
      ref={navigationRef}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();
export type IRootStackProps = {
  route: RouteProp<RootStackParamList, keyof RootStackParamList>;
  navigation: StackNavigationProp<RootStackParamList>;
};
class RootNavigator extends React.Component {
  constructor(_Prop: any) {
    super(_Prop);
  }

  state = { loading: true };

  componentDidMount() {
    this.LoadFontAsync();
  }
  componentWillUnmount() {}

  LoadFontAsync = async () => {
    await MaterialCommunityIcons.loadFont();
    await MaterialIcons.loadFont();
    await AntDesign.loadFont();
    await Ionicons.loadFont();

    this.setState({ loading: false });
  };

  render() {
    if (this.state.loading) {
      return (
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
            backgroundColor: kcColor("Background"),
          }}
        >
          <ActivityIndicator size="large" />
        </View>
      );
    }
    return (
      <Stack.Navigator
        screenOptions={(_Prop: IRootStackProps) => {
          return { headerShown: false, animationEnabled: false };
        }}
        initialRouteName="Root"
      >
        <Stack.Screen name="Root" component={BottomTabNavigator} />
        <Stack.Screen
          name="Login"
          component={LoginScreen}
          options={{ title: "Login" }}
        />
        <Stack.Screen
          name="NotFound"
          component={NotFoundScreen}
          options={{ title: "Oops!" }}
        />
      </Stack.Navigator>
    );
  }
}
