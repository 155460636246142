import {
  CurrencyType,
  kcCommodityCategory,
  kcExchangeCode,
} from "./kcModel_Enum";
import kcPriceScaleModel from "./kcPriceScaleModel";
import kcTradeTimeModel from "./kcTradeTimeModel";
import { CompressionExtensions, MathEx, StringConverter } from "../kcExternal";

export default class kcCommodityModel {
  constructor(props: object) {
    Object.assign(this, { ...props });

    this.FloatNum = kcCommodityModel.ToFloatNumber(this.PriceScale);
  }

  /* ---------------------------Member--------------------------- */
  StockCode: string = ""; // 商品代碼
  StockName: string = ""; // 商品名稱
  ExchangeCode: kcExchangeCode = kcExchangeCode.UnDef; // 交易所代碼
  CommCategorye: kcCommodityCategory = kcCommodityCategory.Unknow; // 商品類別
  ContractSize: number = 0; // 一手數量
  Margin: number = 0; // 保證金比例
  SwapLong: number = 0; // 多單未平倉成本(1手)
  SwapShort: number = 0; // 空單未平倉成本(1手)
  Currency: CurrencyType = CurrencyType.UnKnow; // 貨幣
  TradeTime: kcTradeTimeModel[] = [];
  PriceScale: kcPriceScaleModel[] = []; // 跳動點數

  // 為了方便額外加入
  FloatNum: number;

  ToString() {
    let szRet = "";
    szRet += this.StockCode + ", ";
    szRet += this.StockName + ", ";
    szRet += this.ExchangeCode + ", ";
    szRet += this.CommCategorye + ", ";
    szRet += this.ContractSize + ", ";
    szRet += this.Margin + ", ";
    szRet += this.SwapLong + ", ";
    szRet += this.SwapShort + ", ";
    szRet += this.Currency + ", ";
    szRet += this.TradeTime + ", ";
    szRet += this.PriceScale;
    return szRet;
  }

  Update(_mdData: kcCommodityModel) {
    Object.assign(this, { ..._mdData });

    this.FloatNum = kcCommodityModel.ToFloatNumber(this.PriceScale);
  }

  /* ---------------------------static Function--------------------------- */
  // 轉換成kcCommodityModel, 格式錯誤則為undefined
  static Create_FromJSon(
    ZipJSonBuffer: Uint8Array
  ): kcCommodityModel | undefined {
    let JSonBuffer = CompressionExtensions.Zlib_UnZip(ZipJSonBuffer);
    let szJSon: string = StringConverter.UTF8_GetString(JSonBuffer);
    let Obj = JSON.parse(szJSon);

    let mdCommodity: kcCommodityModel | undefined = undefined;
    if (Obj) mdCommodity = new kcCommodityModel(Obj);

    return mdCommodity;
  }

  // 轉換成kcCommodityModel[], 格式錯誤則為空Array
  static CreateList_FromJSon(ZipJSonBuffer: Uint8Array): kcCommodityModel[] {
    let JSonBuffer = CompressionExtensions.Zlib_UnZip(ZipJSonBuffer);
    let szJSon: string = StringConverter.UTF8_GetString(JSonBuffer);
    let Obj = JSON.parse(szJSon);

    let mlCommoditys: kcCommodityModel[];
    if (Obj && Array.isArray(Obj)) {
      mlCommoditys = Obj.map((q) => new kcCommodityModel(q));
    } else mlCommoditys = [];
    return mlCommoditys;
  }
  static ToFloatNumber(_PriceScale: kcPriceScaleModel[]) {
    let PriceScale = 0.00001;
    let FloatNum = 5;

    if (_PriceScale.length != 0) {
      PriceScale = _PriceScale[0].TickPrice;
      FloatNum = MathEx.GetDecimalNumber(PriceScale);
    }

    return FloatNum;
  }
}
