import * as React from "react";
import { StyleSheet, Pressable } from "react-native";
import { Text, View } from "./Themed";
import { kcSetState, kcSetUnmount } from "../kcExternal";
import { kcData, kcUnitModel } from "../kcData";
import { ObjectExtensions } from "../kcExternal";
import { kcAccountLDFModel, kcInventoryLDFModel } from "../kcData";
import {
  AccountLDFUpdateType,
  OnAccountLDFUpdate_Event,
} from "../kcData/kcAccountLDFModel";
import RowValue from "../components/kcRowValue";
import ColValue from "../components/kcColValue";
import { kcColorObj, kcColor } from "../constants/Colors";
import { Ionicons } from "@expo/vector-icons";

type IState = {
  nFixedRedraw: number; // 笨方式 用來強制繪圖
};

type IProp = {
  delCanUpdate: () => boolean;
};

export default class KC_TradeScreenAccInfo extends React.Component<
  IProp,
  IState
> {
  state: IState = {
    nFixedRedraw: 0, // 笨方式 用來強制繪圖
  };

  /* ------------------------------------------------------------- */
  // Member
  nFixedRedraw: number = 0;
  PreCanUpdate: boolean = true;
  m_TradeAccount: kcAccountLDFModel | undefined;

  /* ------------------------------------------------------------- */
  // HookFunc
  componentDidMount() {
    this.GetAccount_async();
    kcAccountLDFModel.AccountLDFUpdateEvent_Add(this.OnAccountLDFUpdate);
  }

  componentWillUnmount() {
    kcAccountLDFModel.AccountLDFUpdateEvent_Remove(this.OnAccountLDFUpdate);
    kcSetUnmount(this, true);
  }

  shouldComponentUpdate(nextProps: IProp, nextState: IState) {
    const CanUpdate = nextProps.delCanUpdate();

    if (this.PreCanUpdate != CanUpdate) {
      this.PreCanUpdate = CanUpdate;
      if (CanUpdate) return true;
    }

    if (!CanUpdate) return false;
    if (!ObjectExtensions.ObjectsEqual(nextState, this.state)) return true;
    if (!ObjectExtensions.ObjectsEqual(nextProps, this.props)) return true;
    return false;
  }

  /* ------------------------------------------------------------- */
  private GetAccount_async = async () => {
    let mdAccount = kcData.AccountLDF();
    if (!mdAccount) {
      setTimeout(this.GetAccount_async, 100);
      return;
    }

    this.OnAccountLDFUpdate(
      mdAccount,
      mdAccount.Account,
      AccountLDFUpdateType.Inventory,
      mdAccount.mlInventory,
      undefined
    );
  };

  private FixedRedrawUI = () => {
    this.nFixedRedraw = this.nFixedRedraw + 1;
    kcSetState(this, { nFixedRedraw: this.nFixedRedraw }); // 強制更動畫面
  };

  /* ------------------------------------------------------------- */
  // On
  private OnAccountLDFUpdate: OnAccountLDFUpdate_Event = (
    _mdSender: kcAccountLDFModel,
    _szAccount: string,
    _Type: AccountLDFUpdateType,
    _Param1: any,
    _Param2: any
  ) => {
    switch (_Type) {
      case AccountLDFUpdateType.Quote:
      case AccountLDFUpdateType.Inventory: {
        this.m_TradeAccount = _mdSender;
        this.FixedRedrawUI();
        break;
      }
    }
  };

  /* ------------------------------------------------------------- */
  render() {
    //if (!this.m_TradeAccount) return <></>;
    let {
      SecurityMoney,
      NetWorthMoney,
      TotalPrepaidMoney,
      CanUsePrepaidMoney,
      PrepaidRate,
      TotalProfitMoney,
    } = this.m_TradeAccount
      ? this.m_TradeAccount
      : {
          SecurityMoney: 0,
          NetWorthMoney: 0,
          TotalPrepaidMoney: 0,
          CanUsePrepaidMoney: 0,
          PrepaidRate: 0,
          TotalProfitMoney: 0,
        };
    return (
      <View
        style={{
          backgroundColor: kcColor("Background"),
          flexDirection: "row",
          justifyContent: "center",
          borderColor: kcColor("Border"),
          borderWidth: 2,
          margin: 10,
          padding: 10,
          borderRadius: 15,
        }}
      >
        <View style={styles.MainView}>
          <View style={styles.LeftView}>
            <Text
              selectable={false}
              style={[styles.TextSmall, kcColorObj("Value")]}
            >
              {"預付款: " + TotalPrepaidMoney.toFixed(2)}
            </Text>

            <Text
              selectable={false}
              style={[styles.TextSmall, kcColorObj("Value")]}
            >
              {"可用款: " + CanUsePrepaidMoney.toFixed(2)}
            </Text>
            <Text
              selectable={false}
              style={[styles.TextSmall, kcColorObj("Yellow")]}
            >
              {PrepaidRate.toFixed(2) + " % "}
            </Text>
          </View>

          <View style={styles.RightView}>
            <Text
              selectable={false}
              style={[styles.TextSmall, kcColorObj("Value")]}
            >
              {"結餘: " + SecurityMoney.toFixed(2)}
            </Text>
            <Text
              selectable={false}
              style={[styles.TextBig, kcColorObj("Value", TotalProfitMoney)]}
            >
              {"$" + TotalProfitMoney.toFixed(2)}
            </Text>
            <Text
              selectable={false}
              style={[styles.TextSmall, kcColorObj("Value")]}
            >
              {"淨值: " + NetWorthMoney.toFixed(2)}
            </Text>
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  MainView: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#00000000",
    justifyContent: "center",
  },
  LeftView: {
    flex: 1,
    //paddingLeft: 32,
    flexDirection: "column",
    backgroundColor: "#00000000",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  RightView: {
    flexDirection: "column",
    backgroundColor: "#00000000",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  TextSmall: {
    fontSize: 14,
  },
  TextNormal: {
    fontSize: 16,
  },
  TextBig: {
    fontSize: 20,
    fontWeight: "bold",
  },
});
