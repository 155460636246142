import * as React from "react";
import { StyleSheet, ScrollView, SafeAreaView } from "react-native";
import { Text, View } from "../components/Themed";
import { kcSetState, kcSetUnmount } from "../kcExternal";
import { Button } from "react-native-elements";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { kcAccountLDFModel, kcInventoryLDFModel } from "../kcData";
import * as kcData from "../kcData/kcData";
import {
  AccountLDFUpdateType,
  OnAccountLDFUpdate_Event,
} from "../kcData/kcAccountLDFModel";
import { CompositeNavigationProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs";
import {
  BottomTabParamList,
  RootStackParamList,
  TabTradeParamList,
} from "../types";
import OrderParamHelper, {
  OrderScreenDisplayType,
  TradeOrderParam,
} from "../kcData/OrderParamHelper";
import { kcColorObj, kcColor } from "../constants/Colors";
import KC_TradeScreenAccInfo from "../components/KC_TradeScreenAccInfo";
import KC_TradeScreenRow from "../components/KC_TradeScreenRow";

type IState = {
  nFixedRedraw: number; // 笨方式 用來強制繪圖
  TotalProfit: number;
  bIsScreenFocus: boolean;
  mlStockCode: string[];
};

type IProp = {
  navigation: CompositeNavigationProp<
    StackNavigationProp<TabTradeParamList, "TabTradeScreen">,
    CompositeNavigationProp<
      BottomTabNavigationProp<BottomTabParamList, "TabTrade">,
      StackNavigationProp<RootStackParamList>
    >
  >;
};

class Screen extends React.Component<IProp, IState> {
  state: IState = {
    nFixedRedraw: 0, // 笨方式 用來強制繪圖
    TotalProfit: 0,
    bIsScreenFocus: true,
    mlStockCode: [],
  };

  /* ------------------------------------------------------------- */
  // Member
  nFixedRedraw: number = 0;
  m_dicRowAnimateding: Map<string, boolean> = new Map<string, boolean>();
  m_bAnyRowAnimateding: boolean = false;

  /* ------------------------------------------------------------- */
  // HookFunc

  componentDidMount() {
    this.props.navigation.addListener("focus", this.OnFocus);
    this.props.navigation.addListener("blur", this.OnBlur);
    kcAccountLDFModel.AccountLDFUpdateEvent_Add(this.OnAccountLDFUpdate);

    this.props.navigation.setOptions({
      headerTitle: this.Render_HeaderTitle,
      headerLeft: this.Render_HeaderLeft,
      headerRight: this.Render_HeaderRight,
    });
  }

  componentWillUnmount() {
    this.props.navigation.removeListener("focus", this.OnFocus);
    this.props.navigation.removeListener("blur", this.OnBlur);
    kcAccountLDFModel.AccountLDFUpdateEvent_Remove(this.OnAccountLDFUpdate);
    kcSetUnmount(this, true);
  }

  shouldComponentUpdate(nextProps: IProp, nextState: IState) {
    if (!this.CheckCanUpdate()) return false;
    return true;
  }

  private OnFocus = () => {
    kcSetState(this, { bIsScreenFocus: true });
    this.state.bIsScreenFocus = true; // 解畫面不會馬上更新的問題
    this.GetAccount_async();
  };

  private OnBlur = () => {
    kcSetState(this, { bIsScreenFocus: false });
  };

  /* ------------------------------------------------------------- */

  private GetAccount_async = async () => {
    let mdAccount = kcData.AccountLDF();
    if (!mdAccount) {
      setTimeout(this.GetAccount_async, 100);
      return;
    }

    this.OnAccountLDFUpdate(
      mdAccount,
      mdAccount.Account,
      AccountLDFUpdateType.Inventory,
      mdAccount.mlInventory,
      undefined
    );
    this.FixedRedrawUI();
  };

  private NavigationToTabTradeScreen_Order = (
    _StockCode?: string,
    _FocusTrade?: kcInventoryLDFModel,
    _DisplayType?: OrderScreenDisplayType
  ) => {
    // console.log(
    //   "NavigationToTabTradeScreen_Order",
    //   _StockCode,
    //   _FocusTrade,
    //   _DisplayType
    // );

    if (_FocusTrade) {
      _StockCode = _FocusTrade.StockCode;
      if (_DisplayType == undefined) _DisplayType = "Balance";
    } else {
      if (!_StockCode) _StockCode = "XAUUSD";
      _DisplayType = "New";
    }

    let Params: TradeOrderParam = {
      StockCode: _StockCode,
      DisplayType: _DisplayType,
      FocusTrade: _FocusTrade,
    };
    OrderParamHelper.SetOrderParams(Params);
    this.props.navigation.navigate("TabTradeScreen_Order");
  };

  private CheckCanUpdate = () => {
    let Ret = this.state.bIsScreenFocus && !this.m_bAnyRowAnimateding;
    return Ret;
  };

  private FixedRedrawUI = () => {
    this.nFixedRedraw = this.nFixedRedraw + 1;
    kcSetState(this, { nFixedRedraw: this.nFixedRedraw }); // 強制更動畫面
  };

  /* ------------------------------------------------------------- */
  // On
  private OnAccountLDFUpdate: OnAccountLDFUpdate_Event = (
    _mdSender: kcAccountLDFModel,
    _szAccount: string,
    _Type: AccountLDFUpdateType,
    _Param1: any,
    _Param2: any
  ) => {
    switch (_Type) {
      case AccountLDFUpdateType.Quote: {
        break;
      }
      case AccountLDFUpdateType.Inventory: {
        let mlInventory_Param: kcInventoryLDFModel[];
        mlInventory_Param = _Param1 as kcInventoryLDFModel[];
        let mlStockCode: string[] = [];
        mlInventory_Param.forEach((md) => {
          if (!mlStockCode.includes(md.StockCode))
            mlStockCode.push(md.StockCode);
        });
        kcSetState(this, { mlStockCode: mlStockCode });
        break;
      }
      default:
        return;
    }

    let TotalProfit = _mdSender.TotalProfitMoney;
    kcSetState(this, { TotalProfit: TotalProfit });
  };

  private OnCanUpdate = () => {
    return this.CheckCanUpdate();
  };

  private OnRowCallOrder = (
    _StockCode: string,
    _Type: OrderScreenDisplayType,
    _TraderIdx?: number
  ) => {
    // console.log("OnRowCallOrder", _StockCode, _Type, _TraderIdx);
    let FocusTrade: kcInventoryLDFModel | undefined = undefined;
    if (_Type != "New") {
      if (_TraderIdx === undefined) return;
      FocusTrade = kcData.AccountLDF().GetInventory(_TraderIdx);
    }

    this.NavigationToTabTradeScreen_Order(_StockCode, FocusTrade, _Type);
  };

  private OnRowAnimatedingChange = (
    _StockCode: string,
    _IsAnimateding: boolean
  ) => {
    this.m_dicRowAnimateding.set(_StockCode, _IsAnimateding);
    if (_IsAnimateding) {
      this.m_bAnyRowAnimateding = true;
    } else {
      let bAllReady = true;
      this.m_dicRowAnimateding.forEach((_Ising) => {
        if (_Ising) bAllReady = false;
      });
      if (!bAllReady) return;
      this.m_bAnyRowAnimateding = false;
      this.FixedRedrawUI();
    }
  };

  /* ------------------------------------------------------------- */
  // Render
  private Render_HeaderTitle = () => {
    return (
      <View
        style={{
          backgroundColor: "#00000000",
          //flex: 1,
          width: 300,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            backgroundColor: "#00000000",
            color: kcColor("Title"),
            fontSize: 18,
          }}
          selectable={false}
        >
          {"交易"}
        </Text>
      </View>
    );
  };
  private Render_HeaderRight = () => {
    return (
      <Button
        icon={<Ionicons name="ios-add" size={28} color="#ffffff" />}
        buttonStyle={styles.button}
        onPress={() => {
          this.NavigationToTabTradeScreen_Order();
        }}
      />
    );
  };

  private Render_HeaderLeft = () => {
    return <></>;
  };

  private Render_EmptyICon = () => {
    return (
      <View
        style={{
          backgroundColor: "#00000000",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <MaterialCommunityIcons
          selectable={false}
          name="file-clock-outline"
          size={100}
          color={kcColor("ListTitleBackground")}
        />
        <Text style={{ color: kcColor("ListIcon") }} selectable={false}>
          場內無庫存紀錄
        </Text>
      </View>
    );
  };

  render() {
    let bEmpty =
      this.state.mlStockCode === undefined ||
      this.state.mlStockCode.length == 0;
    return (
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: kcColor("Background"),
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        {bEmpty && [
          <KC_TradeScreenAccInfo delCanUpdate={this.OnCanUpdate} />,
          <View
            style={{
              backgroundColor: kcColor("Background"),
              flexDirection: "column",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {this.Render_EmptyICon()}
            {/* <View style={{ height: 10, marginBottom: 80 }} /> */}
          </View>,
        ]}

        {!bEmpty && (
          <ScrollView style={{ backgroundColor: kcColor("Background") }}>
            <KC_TradeScreenAccInfo delCanUpdate={this.OnCanUpdate} />
            {this.state.mlStockCode.map((_StockCode) => {
              return (
                <KC_TradeScreenRow
                  StockCode={_StockCode}
                  key={"TradeRow" + _StockCode}
                  delCanUpdate={this.OnCanUpdate}
                  OnOrder={this.OnRowCallOrder}
                  OnAnimatedingChange={this.OnRowAnimatedingChange}
                />
              );
            })}
            <View style={{ height: 0, marginBottom: 80 }} />
          </ScrollView>
        )}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: "#00000000",
    borderWidth: 0,
    borderRadius: 15,
  },
});

export default Screen;
