import * as React from "react";
import { StyleSheet, Pressable, Animated } from "react-native";
import { Text, View } from "./Themed";
import { kcSetState, kcSetUnmount } from "../kcExternal";
import { kcData, kcUnitModel } from "../kcData";
import { ObjectExtensions } from "../kcExternal";
import {
  kcAccountLDFModel,
  kcInventoryLDFModel,
  kcHistoryTradeLDFModel,
} from "../kcData";
import {
  AccountLDFUpdateType,
  OnAccountLDFUpdate_Event,
  OnInventoryUpdate_Event,
} from "../kcData/kcAccountLDFModel";
import { kcColorObj, kcColor } from "../constants/Colors";
import { Ionicons } from "@expo/vector-icons";
import KC_TradeScreenRowItem from "../components/KC_TradeScreenRowItem";

import { MaterialIcons } from "@expo/vector-icons";
import { OrderScreenDisplayType } from "../kcData/OrderParamHelper";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import kcAnimated from "../kcExternal/kcAnimated";
type IState = {
  nFixedRedraw: number; // 笨方式 用來強制繪圖
};

type IProp = {
  mdHistoryTrade: kcHistoryTradeLDFModel;
  OnAnimatedingChange?: (
    _mdHistoryTrade: kcHistoryTradeLDFModel,
    _IsAnimateding: boolean
  ) => void;
  delCanUpdate: () => boolean;
};

const DefaultHeigh = 0;
const EachItemHeigh = 70;
const DefaultTime = 200;
const EachItemTime = 0;
const USE_KCANIMATED = true;

export default class KC_TradeScreenRow extends React.Component<IProp, IState> {
  state: IState = {
    nFixedRedraw: 0, // 笨方式 用來強制繪圖
  };

  /* ------------------------------------------------------------- */
  // Member
  //mlInventory: kcInventoryLDFModel[] = [];
  nFixedRedraw: number = 0;
  PreCanUpdate: boolean = true;
  bIsClose: boolean = true;
  AnimatedValue: any = new Animated.Value(0);
  kcAnimated: kcAnimated = new kcAnimated(0);

  /* ------------------------------------------------------------- */
  // HookFunc
  componentDidMount() {
    //this.AnimatedValue.addListener(this.OnAnimatedValueChange);
    this.kcAnimated.SetCallback(this.OnAnimatedValueChange);
  }

  componentWillUnmount() {
    this.AnimatedValue.removeAllListeners();
    kcSetUnmount(this, true);
  }

  shouldComponentUpdate(nextProps: IProp, nextState: IState) {
    if (!ObjectExtensions.ObjectsEqual(nextState, this.state)) return true;
    return false;
  }

  /* ------------------------------------------------------------- */
  private FixedRedrawUI = () => {
    this.nFixedRedraw = this.nFixedRedraw + 1;
    kcSetState(this, { nFixedRedraw: this.nFixedRedraw }); // 強制更動畫面
  };

  private AnimatedZoomIn = () => {
    this.AnimatedStateChange(false);
    const Cnt = 1;
    const ItemsTime = Cnt * EachItemTime;
    if (USE_KCANIMATED) this.kcAnimated.RunDown(DefaultTime + ItemsTime);
    else {
      Animated.timing(this.AnimatedValue, {
        toValue: 0,
        duration: DefaultTime + ItemsTime,
        useNativeDriver: false,
      }).start();
    }
  };

  private AnimatedZoomOut = () => {
    this.AnimatedStateChange(false);
    const Cnt = 1;
    const ItemsTime = Cnt * EachItemTime;
    if (USE_KCANIMATED) this.kcAnimated.RunUp(DefaultTime + ItemsTime);
    else {
      Animated.timing(this.AnimatedValue, {
        toValue: 100,
        duration: DefaultTime + ItemsTime,
        useNativeDriver: false,
      }).start();
    }
  };

  private AnimatedStateChange = (_bEnd: boolean) => {
    if (!this.props.OnAnimatedingChange) return;
    this.props.OnAnimatedingChange(this.props.mdHistoryTrade, !_bEnd);
  };

  /* ------------------------------------------------------------- */
  // On

  private OnPress = (_bIsLong: boolean) => {
    if (this.bIsClose) this.AnimatedZoomOut();
    else this.AnimatedZoomIn();

    this.bIsClose = !this.bIsClose;
  };

  private OnAnimatedValueChange = (_value: number, _End: boolean) => {
    if (USE_KCANIMATED) this.FixedRedrawUI();
    if (this.bIsClose && _value != 0) return;
    if (!this.bIsClose && _value != 1) return;

    this.AnimatedStateChange(true);
  };

  /* ------------------------------------------------------------- */
  render() {
    const md = this.props.mdHistoryTrade;

    const Cnt = 1;
    const ItemsHeigh = Cnt * EachItemHeigh;

    let AnimatedItemsHeigh;
    let AnimatedItemsOpacity;
    let AnimatedIconRotate;

    if (USE_KCANIMATED) {
      AnimatedItemsHeigh = kcAnimated.TranferValue(
        this.kcAnimated.Value,
        DefaultHeigh,
        DefaultHeigh + ItemsHeigh
      );
      AnimatedItemsOpacity = kcAnimated.TranferValue(
        this.kcAnimated.Value,
        0,
        1
      );
      AnimatedIconRotate =
        kcAnimated.TranferValue(this.kcAnimated.Value, 0, -180) + "deg";
    } else {
      AnimatedItemsHeigh = this.AnimatedValue.interpolate({
        inputRange: [0, 100],
        outputRange: [DefaultHeigh, DefaultHeigh + ItemsHeigh],
      });
      AnimatedItemsOpacity = this.AnimatedValue.interpolate({
        inputRange: [0, 100],
        outputRange: [0, 1],
      });
      AnimatedIconRotate = this.AnimatedValue.interpolate({
        inputRange: [0, 100],
        outputRange: ["0deg", "-180deg"],
      });
    }

    let szPriceInout = md.Price
      ? md.Price?.toFixed(md.FloatNum) +
        " → " +
        md.PriceOut?.toFixed(md.FloatNum)
      : "";

    let szStopLoss =
      !md.StopLossPrice || md.StopLossPrice == -1
        ? "--"
        : md.StopLossPrice.toFixed(md.FloatNum);

    let szStopProfit =
      !md.StopProfitPrice || md.StopProfitPrice == -1
        ? "--"
        : md.StopProfitPrice.toFixed(md.FloatNum);

    let szDeadlineTime = md.DeadlineTime
      ? md.DeadlineTime.format("YYYY-MM-DD HH:mm:ss")
      : "--";

    let IsBalance = md.StockCode == "Balance";
    let szBalance = "轉入";
    if (IsBalance && md.ProfitMoney < 0) szBalance = "轉出";

    let IsFailTrader = md.Type == "委託失敗" || md.Type == "委託取消";

    let com = kcData.GetCommodity(md.StockCode);
    return (
      <View
        style={{
          flexDirection: "column",
          backgroundColor: kcColor("ListTitleBackground"),
        }}
      >
        <View
          style={{
            flexDirection: "row",
            flex: 1,
            alignItems: "center",
            backgroundColor: "#00000000",
          }}
        >
          <Pressable
            style={styles.MainView}
            onLongPress={() => this.OnPress(true)}
            onPress={() => this.OnPress(false)}
          >
            <Animated.View
              style={{
                marginLeft: 10,
                marginRight: 10,
                transform: [{ rotate: AnimatedIconRotate }],
              }}
            >
              <MaterialIcons
                name="arrow-drop-down"
                size={24}
                color={kcColor("Icon")}
                selectable={false}
              />
            </Animated.View>

            <View style={styles.LeftView}>
              <Text selectable={false} numberOfLines={1}>
                <Text
                  selectable={false}
                  style={[styles.TextXS, kcColorObj("Yellow")]}
                >
                  {"" + md.StockCode + " "}
                </Text>
                {com && (
                  <Text
                    selectable={false}
                    style={[styles.TextSmall, kcColorObj("Title")]}
                  >
                    {com?.StockName}
                  </Text>
                )}
              </Text>
              <Text selectable={false} numberOfLines={1}>
                {!IsBalance && (
                  <Text
                    selectable={false}
                    style={[
                      styles.TextSmall,
                      kcColorObj("BuySell", md.BuySellType),
                      { textAlign: "right" },
                    ]}
                  >
                    {"" + md.BuySellType + " " + md.VolHand?.toFixed(2) + " "}
                  </Text>
                )}
                {IsBalance && (
                  <Text
                    selectable={false}
                    style={[
                      styles.TextSmall,
                      kcColorObj("BuySell", md.BuySellType),
                    ]}
                  >
                    {" "}
                  </Text>
                )}

                {!IsFailTrader && !IsBalance && (
                  <Text
                    selectable={false}
                    style={[styles.TextSmall, kcColorObj("SubTitle")]}
                  >
                    {szPriceInout}
                  </Text>
                )}
                {!IsFailTrader && IsBalance && (
                  <Text
                    selectable={false}
                    style={[styles.TextSmall, kcColorObj("SubTitle")]}
                  >
                    {szBalance}
                  </Text>
                )}
              </Text>
            </View>
            <View style={styles.RightView_NonFlex}>
              {!IsFailTrader && (
                <Text
                  selectable={false}
                  style={[
                    styles.TextNormal,
                    kcColorObj("Value", md.ProfitMoney),
                  ]}
                >
                  {"$" + md.ProfitMoney.toFixed(2)}
                </Text>
              )}
              {IsFailTrader && (
                <Text
                  selectable={false}
                  style={[styles.TextNormal, , kcColorObj("Yellow_3")]}
                >
                  {md.Type}
                </Text>
              )}
              {/* <Text style={[styles.TextSmall, kcColorObj("Value")]}>
                {"單號 : #" + md.TradeIdx}
              </Text> */}
            </View>
          </Pressable>
        </View>
        <Animated.View
          style={{
            height: AnimatedItemsHeigh,
            overflow: "hidden",
            opacity: AnimatedItemsOpacity,
          }}
        >
          <View
            style={{
              flexDirection: "column",
              backgroundColor: kcColor("ListValueBackground"),
              height: 70,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                backgroundColor: kcColor("ListValueBackground"),
                alignItems: "center",
                flex: 1,
              }}
            >
              <Ionicons
                name="ios-chevron-back"
                size={24}
                color={kcColor("ListValueBackground")}
                style={{ marginLeft: 10, marginRight: 10 }}
                selectable={false}
              />
              <View
                style={{
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <View style={[styles.MainView_Sub]}>
                  <View style={styles.LeftView_Sub}>
                    {!IsBalance && (
                      <Text
                        selectable={false}
                        style={[styles.TextSmall, kcColorObj("SubTitle")]}
                      >
                        {"停利 : " + szStopProfit}
                      </Text>
                    )}
                  </View>
                  <View style={styles.RightView_Sub}>
                    <Text
                      selectable={false}
                      style={[styles.TextSmall, kcColorObj("SubTitle")]}
                    >
                      {md.TradeIdx >= 0 ? " # " + md.TradeIdx : " "}
                    </Text>
                  </View>
                </View>
                <View style={[styles.MainView_Sub]}>
                  <View style={styles.LeftView_Sub}>
                    {!IsBalance && (
                      <Text
                        selectable={false}
                        style={[styles.TextSmall, kcColorObj("SubTitle")]}
                      >
                        {"停損 : " + szStopLoss}
                      </Text>
                    )}
                  </View>
                  <View style={styles.RightView_Sub}>
                    {!IsFailTrader && !IsBalance && (
                      <Text
                        selectable={false}
                        style={[styles.TextSmall, kcColorObj("SubTitle")]}
                      >
                        {"庫存 : " + md.SwapMoney.toFixed(2)}
                      </Text>
                    )}
                    {(IsFailTrader || IsBalance) && (
                      <Text
                        selectable={false}
                        style={[styles.TextSmall, kcColorObj("SubTitle")]}
                      >
                        {" "}
                      </Text>
                    )}
                  </View>
                </View>
                <View style={[styles.MainView_Sub]}>
                  {!IsBalance && (
                    <View style={styles.LeftView_Sub}>
                      <Text
                        selectable={false}
                        style={[styles.TextSmall, kcColorObj("SubTitle")]}
                      >
                        {md.Time.format("YYYY-MM-DD HH:mm:ss")}
                      </Text>
                    </View>
                  )}
                  <View style={styles.RightView_Sub}>
                    <Text
                      selectable={false}
                      style={[styles.TextSmall, kcColorObj("SubTitle")]}
                    >
                      {md.TimeOut.format("YYYY-MM-DD HH:mm:ss")}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                height: 1,
                backgroundColor: kcColor("Border"),
                marginTop: 3,
                marginLeft: 30,
                marginRight: 10,
              }}
            ></View>
          </View>
        </Animated.View>

        <View
          style={{
            height: 1,
            backgroundColor: kcColor("Border"),
            marginTop: -1, // 故意蓋住最後一條線
          }}
        ></View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  MainView: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: kcColor("ListTitleBackground"),
    //justifyContent: "center",
    alignItems: "center",
    paddingRight: 15,
  },
  LeftView: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#00000000",
    justifyContent: "center",
    alignItems: "flex-start",
    height: 60,
  },
  RightView: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#00000000",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  RightView_NonFlex: {
    flexDirection: "column",
    backgroundColor: "#00000000",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  TextXS: {
    fontSize: 12,
  },
  TextSmall: {
    fontSize: 14,
  },
  TextNormal: {
    fontSize: 16,
    fontWeight: "bold",
  },
  TextBig: {
    fontSize: 24,
    fontWeight: "bold",
  },

  MainView_Sub: {
    //flex: 1,
    flexDirection: "row",
    backgroundColor: kcColor("ListValueBackground"),
    //justifyContent: "center",
    alignItems: "center",
    paddingRight: 15,
  },
  LeftView_Sub: {
    //flex: 1,
    flexDirection: "column",
    backgroundColor: "#00000000",
    justifyContent: "center",
    alignItems: "flex-start",
    //height: 70,
  },
  RightView_Sub: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#00000000",
    justifyContent: "center",
    alignItems: "flex-end",
  },
});
