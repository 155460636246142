import moment, { Moment } from "moment";
import { MomentExtensions } from "../kcExternal";
import kcHistoryOHLCModel from "./kcHistoryOHLCModel";

export default class kcHistoryTickModel {
  constructor(props: object) {
    Object.assign(this, { ...props });

    this.Time = MomentExtensions.Parse(this.Time);
  }

  Time: Moment = moment(0);
  Price: number = 0;
  Vol: number = 0;

  ToString() {
    let szRet = "";
    szRet += this.Time.utc().format("YYYY/MM/DD HH:mm:ss.SSS") + ", ";
    szRet += this.Price + ", ";
    szRet += this.Vol + ", ";
    return szRet;
  }

  /* ---------------------------static Function--------------------------- */
  static FixedBufferSize: number = 20;
  static FromListBuffer(bytes: Uint8Array): kcHistoryTickModel[] {
    let mdRet: kcHistoryTickModel[] = [];

    try {
      let nDataCount = bytes.length / kcHistoryTickModel.FixedBufferSize;
      for (let i = 0; i < nDataCount; i++) {
        let md = kcHistoryTickModel.CreateFromBuffer(
          bytes,
          i * kcHistoryTickModel.FixedBufferSize
        );
        if (md) mdRet.push(md);
      }
    } catch {}

    return mdRet;
  }

  static ToOHLCList(_mlTicks: kcHistoryTickModel[]): kcHistoryOHLCModel[] {
    let mdRet: kcHistoryOHLCModel[] = _mlTicks.map((mdTick) => {
      return new kcHistoryOHLCModel({
        Time: mdTick.Time,
        OpenPrice: mdTick.Price,
        HighPrice: mdTick.Price,
        LowPrice: mdTick.Price,
        ClosePrice: mdTick.Price,
        Vol: mdTick.Vol,
      });
    });
    try {
    } catch {}
    return mdRet;
  }

  static CreateFromBuffer(
    bytes: Uint8Array,
    offset: number
  ): kcHistoryTickModel | undefined {
    if (offset + kcHistoryTickModel.FixedBufferSize > bytes.length)
      return undefined;

    let dv = new DataView(bytes.buffer, offset);

    let nIdx = 0;

    let dOADate: number = dv.getFloat64(nIdx, true);
    let tTime: Moment = MomentExtensions.FromOADate(dOADate).utc();
    nIdx += 8;

    let dPrice = dv.getFloat64(nIdx, true);
    nIdx += 8;

    let nVol = dv.getUint32(nIdx, true);
    nIdx += 4;

    let mdTick: kcHistoryTickModel = new kcHistoryTickModel({
      Time: tTime,
      Price: dPrice,
      Vol: nVol,
    });

    return mdTick;
  }
}
