import moment from "moment";
import { MathEx } from "../kcExternal";
import {
  kcAccountInformationModel,
  kcCommodityModel,
  kcHistoryTrade,
} from "../kcModel";

export default class kcHistoryTradeLDFModel {
  constructor(
    _HisTradeIn: kcHistoryTrade,
    _HisTradeOut: kcHistoryTrade,
    _Commodity: kcCommodityModel | undefined,
    _Account: kcAccountInformationModel
  ) {
    this.m_HasTradeIn = _HisTradeIn != null;
    this.m_HTradeIn = _HisTradeIn;
    this.m_HTradeOut = _HisTradeOut;
    this.m_mdAccount = _Account;

    if (!_HisTradeOut) return;

    /* --------------------------------------------------------------------------------------- */
    if (_HisTradeOut.Type == "出入金" || _HisTradeOut.Type == "分潤") {
      this.m_ProfitMoney = _HisTradeOut.Money;
      this.m_ProfitMoney_Local = _HisTradeOut.Money;
      this.m_ProfitMoney_Point = _HisTradeOut.Money;
      this.m_FloatNum = 2;
      return;
    }

    /* --------------------------------------------------------------------------------------- */
    // 最小跳動點數
    if (_Commodity) this.UpdateCommodity(_Commodity);

    /* --------------------------------------------------------------------------------------- */
    // 創造假的進場單
    if (!_HisTradeIn) {
      this.m_HTradeIn = _HisTradeOut.Clone();

      if (_HisTradeOut.Type == "平倉") {
        this.m_HTradeIn.TradeIdx = _HisTradeOut.RefTradeIdx;
        this.m_HTradeIn.RefTradeIdx = -1;
        this.m_HTradeIn.RefTime = undefined;
        this.m_HTradeIn.Type = "成交已平倉";
        this.m_HTradeIn.Time = _HisTradeOut.RefTime
          ? _HisTradeOut.RefTime
          : moment();
        this.m_HTradeIn.IBAllocationTime = undefined;
        // this.m_HTradeIn.Device = _HisTradeOut.RefDevice;

        if (
          _HisTradeOut.BuySellType == "Buy" ||
          _HisTradeOut.BuySellType == "BuyLimit" ||
          _HisTradeOut.BuySellType == "BuyStop"
        ) {
          this.m_HTradeIn.BuySellType = "Sell";

          //this. m_HTradeIn.Price = _HisTradeOut.Price + (_HisTradeOut.PriceDiff).kcRound(this.m_FloatNum);
          this.m_HTradeIn.Price =
            _HisTradeOut.Price +
            MathEx.kcRound(_HisTradeOut.PriceDiff, this.m_FloatNum);
        }

        if (
          _HisTradeOut.BuySellType == "Sell" ||
          _HisTradeOut.BuySellType == "SellLimit" ||
          _HisTradeOut.BuySellType == "SellStop"
        ) {
          this.m_HTradeIn.BuySellType = "Buy";

          // this.m_HTradeIn.Price = _HisTradeOut.Price - (_HisTradeOut.PriceDiff).kcRound(this.m_FloatNum);
          this.m_HTradeIn.Price =
            _HisTradeOut.Price -
            MathEx.kcRound(_HisTradeOut.PriceDiff, this.m_FloatNum);
        }
      }
    }
    /* --------------------------------------------------------------------------------------- */

    this.m_ProfitMoney_Local =
      this.m_HTradeOut.PriceDiff * this.m_HTradeOut.Vol;
    this.m_ProfitMoney = this.m_HTradeOut.Money;
    // this.m_ProfitMoney_Point = (this.m_HTradeOut.PriceDiff / PriceScale).kcRound(m_FloatNum);

    // 搬到 UpdateCommodity() 裡面算
    // this.m_ProfitMoney_Point = MathEx.kcRound(
    //   this.m_HTradeOut.PriceDiff / PriceScale,
    //   this.m_FloatNum
    // );
  }
  /* internal */ IsSpaceRow: boolean = false; // 特殊Row 目前用在Gview 最下方
  /* internal */ nSepcSortIdx: number = 0; // Keep Sort Idx

  private m_bCommodityInited: boolean = false;

  private m_HasTradeIn: boolean = false;

  private m_HTradeIn: kcHistoryTrade = new kcHistoryTrade(); // 有可能是假的不可以開放給外面使用
  private m_HTradeOut: kcHistoryTrade = new kcHistoryTrade();
  private m_mdAccount: kcAccountInformationModel;
  // private kcImgString m_TradeIdxImg;

  /* --------------------------------------------------------------------------------------------------- */
  // 動態計算相關
  private m_ProfitMoney: number = 0; // 獲利 USD
  private m_ProfitMoney_Local: number = 0; // 獲利 當地貨幣
  private m_ProfitMoney_Point: number = 0; // 獲利 點差
  private m_FloatNum: number = 0; // 小數點位數

  // [DisplayName("獲利\r\n(USD)")]
  // public decimal ProfitMoney { get { return m_ProfitMoney; } set { m_ProfitMoney = value; } } // Spec Row會使用修改
  public get ProfitMoney() {
    return this.m_ProfitMoney;
  }
  public set ProfitMoney(_value) {
    this.m_ProfitMoney = _value;
  }
  // [DisplayName("獲利\r\n(商品幣種)")]
  // public decimal ProfitMoney_Local { get { return m_ProfitMoney_Local; } }
  public get ProfitMoney_Local() {
    return this.m_ProfitMoney_Local;
  }
  // [DisplayName("獲利\r\n(點數)")]
  // public decimal ProfitMoney_Point { get { return m_ProfitMoney_Point; } }
  public get ProfitMoney_Point() {
    return this.m_ProfitMoney_Point;
  }

  /* --------------------------------------------------------------------------------------------------- */
  // Account
  // [DisplayName("使用者\r\n序號")]
  // public int AccIdx { get { return m_mdAccount.Information.AccIdx; } }
  // [DisplayName("使用者\r\n帳號")]
  // public string Account { get { return m_mdAccount.Information.Account; } }
  public get Account(): string {
    return this.m_mdAccount.Account;
  }

  /* --------------------------------------------------------------------------------------------------- */
  // 進出共用
  // [DisplayName("商品")]
  // public string StockCode { get { return m_HTradeIn.StockCode; } }
  public get StockCode() {
    if (this.Type == "出入金") return "Balance";
    return this.m_HTradeIn.StockCode;
  }
  // [DisplayName("交易\r\n序號")]
  // public int TradeIdx { get { return m_HTradeIn.TradeIdx; } }
  public get TradeIdx() {
    return this.m_HTradeIn.TradeIdx;
  }
  // [DisplayName("交易\r\n序號")]
  // public kcImgString TradeIdxImg { get { return m_TradeIdxImg; } set { m_TradeIdxImg = value; } }
  // [DisplayName("帳單\r\n狀態")]
  // public TradeType Type { get { return m_HTradeIn.Type; } } // 進場狀態
  public get Type() {
    return this.m_HTradeIn.Type;
  }
  // [DisplayName("成交量")]
  // public decimal Vol { get { return m_HTradeOut.Vol; } } // 出場手數
  public get Vol() {
    return this.m_HTradeOut.Vol;
  }
  // [DisplayName("手數")]
  // public decimal VolHand { get { return (decimal)m_HTradeOut.HVol; } } // 出場手數
  public get VolHand() {
    if (this.Type == "出入金") return undefined;
    return this.m_HTradeOut.HVol;
  }
  // [DisplayName("庫存費")]
  // public decimal SwapMoney { get { return m_HTradeOut.SwapMoney; } } // 會包含進場與出場
  public get SwapMoney() {
    return this.m_HTradeOut.SwapMoney;
  }

  /* --------------------------------------------------------------------------------------------------- */
  // 進場
  // [DisplayName("類型")]
  // public BuySellType BuySellType { get { return m_HTradeIn.BuySellType; } }
  public get BuySellType() {
    if (this.Type == "出入金") return "";
    return this.m_HTradeIn.BuySellType;
  }
  // [DisplayName("開倉\r\n時間")]
  // public DateTime Time { get { return m_HTradeIn.Time; } }
  public get Time() {
    return this.m_HTradeIn.Time;
  }
  // [DisplayName("開倉\r\n價格")]
  // public decimal Price { get { return m_HTradeIn.Price; } }
  public get Price() {
    if (this.Type == "出入金") return undefined;
    return this.m_HTradeIn.Price;
  }
  // [DisplayName("停利價")]
  // public decimal? StopProfitPrice
  // { // Form Order 要使用 無法單純用 GviewHelper做
  //     get
  //     {
  //         if (m_HTradeIn.StopProfitPrice == -1)
  //             return null;
  //         return m_HTradeIn.StopProfitPrice;
  //     }
  // }
  public get StopProfitPrice(): number | undefined {
    if (this.m_HTradeIn.StopProfitPrice == -1) return undefined;
    return this.m_HTradeIn.StopProfitPrice;
  }
  // [DisplayName("停損價")]
  // public decimal? StopLossPrice
  // { // Form Order 要使用 無法單純用 GviewHelper做
  //     get
  //     {
  //         if (m_HTradeIn.StopLossPrice == -1)
  //             return null;
  //         return m_HTradeIn.StopLossPrice;
  //     }
  // }
  public get StopLossPrice(): number | undefined {
    if (this.m_HTradeIn.StopLossPrice == -1) return undefined;
    return this.m_HTradeIn.StopLossPrice;
  }

  // [DisplayName("開倉\r\n原因")]
  // public string Device { get { return m_HTradeIn.Device; } }
  // [DisplayName("到期\r\n時間")]
  // public DateTime DeadlineTime { get { return m_HTradeIn.DeadlineTime; } } // 無進場單時 暫時無考慮
  public get DeadlineTime() {
    return this.m_HTradeIn.DeadlineTime;
  }
  // [DisplayName("預付款")]
  // public decimal PrepaidMoney { get { return m_HTradeIn.PrepaidMoney; } }
  public get PrepaidMoney() {
    return this.m_HTradeIn.PrepaidMoney;
  }
  // [DisplayName("備註")]
  // public string Ex { get { return m_HTradeIn.Ex; } } // 無進場單時 暫時無考慮
  public get Ex() {
    return this.m_HTradeIn.Ex;
  }

  /* --------------------------------------------------------------------------------------------------- */
  // 出場
  // [DisplayName("交易\r\n序號")]
  // public int TradeIdxOut { get { return m_HTradeOut.TradeIdx; } }
  public get TradeIdxOut() {
    return this.m_HTradeOut.TradeIdx;
  }
  // [DisplayName("帳單\r\n狀態")]
  // public TradeType TypeOut { get { return m_HTradeOut.Type; } }
  public get TypeOut() {
    return this.m_HTradeOut.Type;
  }
  // [DisplayName("關聯\r\n序號")]
  // public int RefTradeIdx { get { return m_HTradeOut.RefTradeIdx; } }
  public get RefTradeIdx() {
    return this.m_HTradeOut.RefTradeIdx;
  }
  // [DisplayName("平倉\r\n價格")]
  // public decimal PriceOut { get { return m_HTradeOut.Price; } }
  public get PriceOut() {
    if (this.Type == "出入金") return undefined;
    return this.m_HTradeOut.Price;
  }
  // [DisplayName("平倉\r\n時間")]
  // public DateTime TimeOut { get { return m_HTradeOut.Time; } }
  public get TimeOut() {
    return this.m_HTradeOut.Time;
  }
  // [DisplayName("平倉\r\n原因")]
  // public string DeviceOut { get { return m_HTradeOut.Device; } }
  // [DisplayName("分潤\r\n金額")]
  // public decimal CommissionMoney { get { return m_HTradeOut.CommissionMoney; } }
  public get CommissionMoney() {
    return this.m_HTradeOut.CommissionMoney;
  }
  // [DisplayName("分潤\r\n時間")]
  // public DateTime? CommissionTime { get { return m_HTradeOut.CommissionTime; } }
  public get CommissionTime() {
    return this.m_HTradeOut.CommissionTime;
  }
  // [DisplayName("分潤\r\n狀態")]
  // public bool CommissionReady { get { return m_HTradeOut.CommissionTime.HasValue; } }
  public get CommissionReady() {
    return this.m_HTradeOut.CommissionTime ? true : false;
  }
  // [DisplayName("金額")]
  // public decimal MoneyOut { get { return m_HTradeOut.Money; } }
  public get MoneyOut() {
    return this.m_HTradeOut.Money;
  }

  /* --------------------------------------------------------------------------------------------------- */
  // internal int FloatNum { get { return m_FloatNum; } }
  public get FloatNum() {
    return this.m_FloatNum;
  }
  // internal string StringFloatFormat { get { return "N" + m_FloatNum; } }
  public get StringFloatFormat() {
    return "N" + this.m_FloatNum;
  }

  public UpdateCommodity(_mdCommodity: kcCommodityModel) {
    if (this.m_bCommodityInited) return;
    if (this.StockCode != _mdCommodity.StockCode) return;

    let PriceScale = 0.00001;
    this.m_FloatNum = 5;

    if (_mdCommodity.PriceScale.length != 0) {
      PriceScale = _mdCommodity.PriceScale[0].TickPrice;
      //this.m_FloatNum = PriceScaleExtensions.kcFloatNum(PriceScale, _Commodity.PriceScale);

      this.m_FloatNum = MathEx.GetDecimalNumber(PriceScale);
      this.m_bCommodityInited = true;
    } else {
      console.log(
        "[kcHistoryTradeLDFModel] constructor() => _Commodity == null"
      );
    }

    this.m_ProfitMoney_Point = MathEx.kcRound(
      this.m_HTradeOut.PriceDiff / PriceScale,
      this.m_FloatNum
    );
  }

  public static CreateFailTrade(
    _mdTrade: kcHistoryTrade,
    _Account: kcAccountInformationModel
  ) {
    let mdTradeIn = _mdTrade.Clone();
    if (_mdTrade.RefTime !== undefined) mdTradeIn.Time = _mdTrade.RefTime;
    return new kcHistoryTradeLDFModel(mdTradeIn, _mdTrade, undefined, _Account);
  }
  public static CreateCancelTrade(
    _mdTrade: kcHistoryTrade,
    _Account: kcAccountInformationModel
  ) {
    let mdTradeIn = _mdTrade.Clone();
    if (_mdTrade.RefTime !== undefined) mdTradeIn.Time = _mdTrade.RefTime;
    return new kcHistoryTradeLDFModel(mdTradeIn, _mdTrade, undefined, _Account);
  }
}
