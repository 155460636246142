import kcHistoryTrade from "./kcHistoryTrade";

export default class kcInventoryModel {
  constructor(props: object) {
    Object.assign(this, { ...props });
    this.mlInventory = this.mlInventory.map((md) => new kcHistoryTrade(md));
  }

  mlInventory: kcHistoryTrade[] = [];

  SecurityMoney: number = 0; // 餘額
  TotalPrepaidMoney: number = 0; // 已用預付款
  NonAllocCommissionNum: number = 0; // 尚未分配傭金的單子
  TotalProfitCommissionMoney: number = 0; // 獲利傭金累計(只在獲利時累計, 用來計算內轉預扣)
  TotalCommissionMoney: number = 0; // 傭金累計
  CommissionOweProfit: number = 0; // 積欠獲利(分潤使用)

  // 分潤預扣 PreDeduct => PD
  PDCommissionOweProfit: number = 0; // 分潤預扣的積欠獲利(成交改動)
  PDCommissionMoney: number = 0; // 分潤預扣的預扣款(可能用來取代TotalProfitCommissionMoney)
}
